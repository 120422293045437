[data-type="Tabs_Emoticon_Scroll"] {
    .l-grid__col {
        background: $color-gray-14;
    }
    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        border-bottom: 1px solid $color-gray-11;
    }
    .iScrollHorizontalScrollbar {
        display: none;
    }
    // MPINNER-11609 삭제예정 끝
    .w-scroll-css {
        border-bottom: 1px solid $color-gray-11;

        &::-webkit-scrollbar {
            display: none;
        }
    }
    .s-list {
        padding: 10px 10px 10px 16px;

        li {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 16px;
                background: $color-gray-14;
                content: "";
            }
            .c-chip {
                height: 40px;
                margin-bottom: 0;
                padding: 0 10px 0 44px;
                border-radius: 20px;
                line-height: 40px;
                font-size: 16px;
                color: $color-gray-03;
                border: 1px solid $color-gray-10;
                font-weight: normal;
                span {
                    display: inline-block;
                    margin: 0 0 0 4px;
                    font-size: 12px;
                    color: $color-gray-07;
                    vertical-align: middle;
                    line-height: normal;
                }
                .c-chip__icon {
                    @include size(40px);
                    position: absolute;
                    top: -2px;
                    left: 0;
                    margin: 0;
                    padding: 0;
                    &:before {
                        top: 50%;
                        left: 50%;
                        width: 20px;
                        height: 18px;
                        transform: translate(-50%, -50%);
                        background-size: 20px 18px;
                        background-color: transparent;
                    }
                    img {
                        width: 100%;
                    }
                }
                &--selected {
                    padding-left: 52px;
                    background: $color-11st-red;
                    color: $color-gray-14;
                    border-color: $color-11st-red;
                    .c-chip__icon {
                        @include size(50px);
                        top: -12px;
                        &:before {
                            width: 27px;
                            height: 24.3px;
                            background-size: 27px 24.3px;
                        }
                    }
                    span {
                        color: $color-gray-14;
                    }
                }
            }
        }
    }

    .c-list {
        display: flex;
        width: auto;
        margin: 0 -5px;
        padding: 16px;
        border-bottom: 1px solid $color-gray-11;
        li {
            position: relative;
            margin: 0 5px;
            padding: 0;
            flex: 1;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 4px;
                background: $color-gray-14;
                content: "";
            }
            .c-chip {
                width: 100%;
                height: 88px;
                margin-bottom: 0;
                padding: 0;
                border-radius: 4px;
                line-height: 18px;
                font-size: 14px;
                color: $color-gray-03;
                border: 1px solid $color-gray-10;
                font-weight: normal;
                span {
                    display: block;
                    margin: 0 auto;
                    font-size: 12px;
                    color: $color-gray-07;
                }
                .c-chip__icon {
                    @include size(40px);
                    display: block;
                    position: relative;
                    margin: 0 auto 2px;
                    padding: 0;
                    &:before {
                        top: 50%;
                        left: 50%;
                        width: 24px;
                        height: 21.6px;
                        transform: translate(-50%, -50%);
                        background-size: 24px 21.6px;
                        background-color: transparent;
                    }
                    img {
                        width: 100%;
                    }
                }
                &--selected {
                    background: $color-11st-red;
                    color: $color-gray-14;
                    border-color: $color-11st-red;
                    span {
                        color: $color-gray-14;
                    }
                }
            }
        }
    }
}
