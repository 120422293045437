@import "../../pui/sprites/sp_mart";
@import "../../common/partial/starrate";
@import "../../common/partial/count_btn";

[data-type="ProductGrid_Mart"] {
    z-index: 1;
    margin-bottom: -1px;

    &.l-grid__row {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
    }
    .l-grid__col {
        position: relative;
        border-bottom: 1px solid #f4f4f4;
        padding-bottom: 48px;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            //left: auto; //l-grid__row--border 대응
            left: -1px;
            height: 200%;
            border-left: 1px solid #f4f4f4;
            z-index: 9;
        }

        &:nth-child(n + 4) {
            .c-card.c-card__gallery .c-card__info .c-card__mart--rank {
                background-color: $color-gray-06;
            }
        }
    }

    .c-card.c-card__gallery {
        padding: 10px 10px 16px;

        .c-card__thumb {
            width: auto;
            height: auto;
        }

        .c-card__info {
            padding: 12px 6px 0;

            .c-card_name {
                padding-top: 6px;
                font-size: 14px;
                color: #111;
                margin-bottom: 4px;
            }

            .c-card__price {
                vertical-align: middle;

                strong {
                    font-size: 18px;
                    color: #111;
                }
            }

            .c-card__benefit {
                height: 19px;
                padding-top: 0;
                font-size: 13px;

                .benefit {
                    display: block;
                    font-size: 13px;
                }

                strong {
                    color: #cc2929;
                }
            }

            .w-fix__height {
                position: relative;
                height: 39px;

                .c-starrate {
                    position: absolute;
                    bottom: 0;
                }
            }

            .c-card__brand-mall {
                display: block;
                margin-bottom: 5px;
                font-size: 13px;
                line-height: 18px;

                &:after {
                    @include size(6px);

                    content: "";
                    display: inline-block;
                    margin: -2px 0 1px 2px;
                    border: solid;
                    border-width: 1px 1px 0 0;
                    transform: rotate(45deg);
                    vertical-align: middle;
                }
            }
        }

        .w-mart__info {
            display: block;
            letter-spacing: 0;

            .c-card__name {
                height: auto;
                max-height: 36px;
                color: $color-gray-02;
                font-size: 15px;
                line-height: 18px;
                margin-bottom: 5px;
            }

            .c-card__price {
                height: 26px;
                font-size: 11px;
                color: #111;

                strong {
                    margin-right: -2px;
                    vertical-align: -1px;
                }
                & + .c-starrate {
                    margin-top: 3px;
                }
            }

            .c-card__buy--count {
                height: 19px;
                margin-top: 2px;
                font-size: 13px;
                color: #7baed8;
                line-height: 19px;
            }

            .c-starrate {
                margin-top: 5px;
            }
        }
    }

    .w-prd__count {
        position: absolute;
        bottom: 16px;
        left: 0;
        width: 100%;
        box-sizing: border-box;

        //.c-prd__count {
        //    &--qty {
        //        line-height: 36px;
        //    }
        //}
        &--link {
            &::after {
                border-color: $color-gray-03;
            }
        }
    }
    .c-card__counsel {
        padding: 14px 0 30px;
    }

    .c-nolist {
        margin: 318px 0;
    }
}
