[data-type="ContentsRowList_Category"] {
    padding: 16px;
    letter-spacing: $body-spacing;

    .c-contents {
        .c-linkimg {
            display: block;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                height: 1px;
                background: rgba(0, 0, 0, 0.04);
                content: "";
            }
        }
        li {
            position: relative;
            margin-top: 12px;
            &:first-child {
                margin-top: 0;
            }
        }
        &-item {
            @include rounding-box;
            background: #fff;
            &__title {
                display: flex;
                position: relative;
                height: 48px;
                padding: 9px 13px 9px 44px;
                border-top: 1px solid $color-gray-11;
                align-items: center;
            }
            .c-profile__pic {
                @include size(24px);
                position: absolute;
                top: 12px;
                left: 12px;
                &:after {
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    z-index: 2;
                }
                span {
                    width: 24px;
                    height: 24px;
                }
            }

            &__author {
                display: block;
                overflow: hidden;
                font-size: 15px;
                color: $color-gray-03;
                font-weight: normal;
                white-space: nowrap;
            }

            &__option {
                overflow: hidden;
                flex: 1;
                font-size: 0;
                white-space: nowrap;
            }

            &__badge {
                display: inline-block;
                padding-left: 5px;
                font-size: 14px;
                color: $color-11st-blue;
            }

            &__keyword {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__contents {
                display: block;
                position: relative;
                min-height: 144px;
                padding: 15px 148px 12px 12px;
                font-size: 0;
                .c-contents-item__timestamp {
                    display: inline-block;
                    position: static;
                    padding: 0;
                    font-size: 14px;
                    vertical-align: top;
                    color: $color-gray-06;
                    font-weight: normal;
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: inline-block;
                        width: 1px;
                        height: 11px;
                        margin: -1px 5px 1px;
                        border-right: 1px solid $color-gray-10;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }

            &__thumb {
                position: absolute;
                top: 0;
                right: 0;
                padding: 12px;
                .c-lazyload {
                    width: 120px;
                    height: 120px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
                .c-badge--new {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 20px;
                    height: 20px;
                    background: $color-11st-red;
                    font-size: 11px;
                    line-height: 20px;
                    color: $color-gray-14;
                    text-align: center;
                }
            }

            &__detail {
                overflow: hidden;
                height: 92px;
                margin-bottom: 6px;
            }

            &__description {
                @include text-ellipsis-multiple(3);
                overflow: hidden;
                font-size: 16px;
                line-height: 22px;
                color: $color-gray-02;
            }

            &__sub {
                @include text-ellipsis-multiple(3);
                margin-top: 4px;
                font-size: 15px;
                color: $color-gray-04;
                line-height: 21px;
            }

            &__count {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
            }

            &--recently {
                .c-contents {
                    &-item {
                        &__timestamp {
                            color: $color-11st-blue;
                        }
                    }
                }
            }

            .c-certified {
                display: inline-block;
                height: 20px;
                padding: 0 4px;
                margin: -1px 6px 1px 0;
                border: 1px solid rgba(241, 112, 0, 0.5);
                border-radius: 10px;
                font-size: 11px;
                text-align: center;
                line-height: 20px;
                color: #f17000;
                vertical-align: middle;
                &:before {
                    @include sprite-retina($sp_eui_ico_official_mark2);
                    display: inline-block;
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }
        }
    }

    .c-relates {
        border-top: 0;
        background: $color-gray-14;
    }
}
