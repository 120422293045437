[data-type="ContentsGrid_Service_Swipe"] {
    @include badge-new;
    padding: 0 10px;
    letter-spacing: $body-spacing;

    .c-flex {
        display: flex;

        &:first-child {
            .c-card {
                margin-top: 0;
            }
        }
        &__col {
            flex: 1;
        }
    }
    .c-card {
        margin: 6px;

        &__thumb {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        &__info {
            padding: 10px 12px;
        }
        &__text {
            @include text-ellipsis-multiple(2);
            height: 36px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 18px;
        }
        &__title {
            display: block;
            overflow: hidden;
            height: 19px;
            margin-top: 5px;
            font-size: 15px;
            font-weight: normal;
            color: $color-11st-blue;
            line-height: 19px;
        }
    }
    .c-pagination {
        margin-top: 2px;

        &__indicator {
            span {
                margin-top: 10px;
                vertical-align: top;
            }
        }
    }
}
