[data-type="ProductList_Best_Recetly"] {
    .c-accordion {
        &__item {
            position: relative;
            padding-right: 60px;
            border-top: 1px solid $color-gray-12;

            &:before {
                position: absolute;
                top: 0;
                left: 101px;
                bottom: 0;
                width: 1px;
                background: $color-gray-12;
                content: "";
            }
            &:after {
                @include size(11px);
                position: absolute;
                top: 50%;
                left: 96px;
                border-radius: 100%;
                background: #f8b907;
                transform: translateY(-50%);
                content: "";
            }
        }
        &__button {
            @include size(60px 65px);
            overflow: hidden;
            position: absolute;
            top: 16px;
            right: 0;
            border: 0;
            background: none;
            color: transparent;
            outline: none;

            &:after {
                @include ico-arrow-down(8px, $color-gray-07);
                @include transition(0.3s);

                position: absolute;
                top: 50%;
                right: 24px;
                margin: -5px 0 0 -7px;
                content: "";
            }
            &[aria-expanded="true"] {
                &:after {
                    @include rotate(-45deg);

                    margin-top: -2px;
                }
            }
        }
        &__detail {
            display: none;
            position: relative;
            padding: 0;
            background: $color-gray-14;
            border-top: 1px solid $color-gray-12;

            &:before {
                @include ico-arrow-down(9px, $color-gray-12);
                @include rotate(-45deg);

                position: absolute;
                top: -5px;
                left: 45px;
                background: $color-gray-14;
                content: "";
            }
        }
        &:first-child {
            border-top: 0;

            .c-accordion__item {
                &:before {
                    top: 48px;
                }
            }
        }
    }
    .s-list {
        display: flex;
        overflow-x: auto;
        padding: 14px 10px 12px;
        scroll-snap-type: x mandatory;

        li {
            padding: 4px 6px;
            scroll-snap-align: center;

            &:last-child {
                padding-right: 16px;
            }
        }
    }
    .c-card-item--list {
        padding: 16px 0;
        background: none;

        .c-card-item__link {
            padding: 0 0 0 16px;
            border: 0;

            &:before {
                display: none;
            }
        }
        .c-card-item__thumb {
            @include size(64px);
            overflow: hidden;
            border-radius: 100%;
        }
        .c-card-item__info {
            padding: 0 8px 0 42px;
            background: none;
            vertical-align: middle;
        }
        .c-card-item__name {
            display: block;
            height: auto;
            margin: 0;
            max-height: 40px;
            line-height: 20px;
        }
    }
    .c-card-item--box {
        @include size(100px 182px);

        .c-card-item__name {
            display: block;
            height: 38px;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 19px;
        }
        .c-card-item__info {
            padding: 10px 8px 12px;
        }
    }
    .c-card-item__more {
        @include size(100px 182px);
    }
}
