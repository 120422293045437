.c-tab {
    background: #fff;

    &__list {
        display: flex;
        padding-right: 1px;
        box-sizing: border-box;
    }

    &__item {
        width: initial;
        flex: 1;
        &:first-child {
            .c-tab__link {
                margin-left: 0;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
            }
        }
        &:last-child {
            .c-tab__link {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }
        }
    }

    &__link {
        display: block;
        overflow: hidden;
        position: relative;
        z-index: 1;
        height: 44px;
        margin-right: -1px;
        margin-left: -1px;
        font-size: 15px;
        line-height: 44px;
        color: $color-gray-02;
        text-align: center;
        box-sizing: border-box;

        &[aria-selected="true"] {
            z-index: 2;
            font-weight: bold;
            color: $color-11st-red;
            border-radius: 4px;
        }
        &:disabled,
        &--disabled {
            z-index: 0;
            color: $color-gray-09;
        }
    }

    &__icon {
        @include size(22px);
        display: inline-block;
        overflow: hidden;
        margin: -1px 5px 1px 0;
        vertical-align: middle;

        img {
            @include size(100%);
            display: inline-block;
            vertical-align: top;
        }
    }
}
button.c-tab__link {
    width: calc(100% + 2px);
}
.c-tab-bar {
    .c-tab {
        &__list {
            padding-right: 0;
        }
        &__item {
            &:first-child {
                .c-tab__link {
                    border-radius: 0;
                }
            }
            &:last-child {
                .c-tab__link {
                    border-radius: 0;
                }
            }
        }
        &__link {
            width: 100%;
            height: 52px;
            margin-right: 0;
            border-bottom: 1px solid $color-gray-11;
            border-width: 0 0 1px;
            font-size: 16px;
            font-weight: bold;
            line-height: 52px;
            border-radius: 0;

            &[aria-selected="true"] {
                padding-bottom: 1px;
                border-bottom: 0;

                &:after {
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    height: 2px;
                    background: $color-11st-red;
                    content: "";
                }
            }
        }
    }
}
.c-tab-box-border {
    .c-tab {
        &__link {
            height: 40px;
            line-height: 40px;
            border: 1px solid $color-gray-10;
            background: $color-gray-14;

            &[aria-selected="true"] {
                border: 1px solid $color-11st-red;
            }
            &:disabled,
            &--disabled {
                border-color: rgba($color: $color-gray-09, $alpha: 0.2);
                background: $color-gray-13;
            }
        }
    }
}
.c-tab-box-fill {
    .c-tab {
        &__link {
            height: 40px;
            line-height: 40px;
            border: 1px solid rgba($color: $color-gray-09, $alpha: 0.2);
            background: $color-gray-13;
            background-image: linear-gradient(#fff, #fff), $color-11st-gradient;
            background-origin: border-box;
            background-clip: content-box, border-box;

            &[aria-selected="true"] {
                border: 1px solid transparent;
                background: $color-11st-gradient;
                font-weight: bold;
                color: $color-gray-14;
            }
        }
    }
}
.c-tab-line {
    .c-tab {
        &__item {
            &:first-child {
                .c-tab__link {
                    border-bottom-left-radius: 0;
                }
            }
            &:last-child {
                .c-tab__link {
                    border-bottom-right-radius: 0;
                }
            }
        }
        &__link {
            border: 1px solid $color-gray-10;
            border-bottom: 0;
            background: $color-gray-13;

            &:after {
                position: absolute;
                left: -1px;
                right: -1px;
                bottom: 0;
                height: 1px;
                background: $color-11st-red;
                content: "";
            }
            &[aria-selected="true"] {
                border-color: $color-11st-red;
                background: $color-gray-14;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;

                &:after {
                    left: 0;
                    right: 0;
                    background: $color-gray-14;
                }
            }
        }
    }
}
