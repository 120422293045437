[data-type="ContentsScroll_ImageBox"] {
    padding: 0 0 6px;
    .s-list {
        padding: 0 10px 10px;
        li {
            padding: 0 6px;
        }
    }
    .c-card-item {
        width: 152px;
        height: 190px;
        border: 0;
        &__link {
            &:after {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 80px;
                z-index: 1;
                background-image: linear-gradient(to top, rgba($color: $color-gray-01, $alpha: 0.8), rgba($color: $color-gray-01, $alpha: 0));
                content: "";
            }
        }
        &__thumb {
            position: relative;
        }
        &__video {
            position: relative;
            padding-top: 125%;
            video {
                position: absolute;
                top: 50%;
                left: 50%;
                width: auto;
                height: 100%;
                min-width: 100%;
                min-height: 100%;
                transform: translate(-50%, -50%);
            }
        }
        &__rank {
            width: auto;
            padding: 1px 8px;
            line-height: 24px;
            font-weight: bold;
            background: $color-11st-gradient;
        }
        &__info {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 0 12px 16px;
            z-index: 2;
            background: transparent;
        }
        &__name {
            display: block;
            height: 24px;
            margin: 0;
            font-size: 17px;
            color: $color-gray-14;
            font-weight: bold;
        }
        &__text {
            overflow: hidden;
            height: 18px;
            margin: 4px 0 0;
            font-size: 14px;
            color: $color-gray-14;
        }
        &__more {
            width: 130px;
            height: 190px;
        }
    }
    .c-lazyload img {
        object-fit: cover;
    }
}
