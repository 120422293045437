[data-type="GridList_Box_ImgText"] {
    @include grid(0, 2, false);
    background-color: #fff !important;
    .l-grid__col {
        padding-bottom: 12px;
    }
    .c-listimg {
        @include rounding-box;
        padding: 8px 0;
        &__item {
            .c-listimg__link {
                padding: 10px 0;
                border: 0;
                .c-listimg__icon-wrap {
                    @include size(52px);
                    .c-listimg__icon {
                        @include size(52px);
                    }
                    .c-badge--new {
                        position: absolute;
                        right: 0;
                        top: 0;
                        @include size(16px);
                        img {
                            @include size(16px);
                        }
                    }
                }
                .c-listimg__name {
                    padding-top: 4px;
                    font-size: 13px;
                }
            }
        }
    }
}
