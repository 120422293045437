[data-type="Contents_Mart_Tab"] {
    .c-tab__mart {
        padding: 17px 0 18px;
        text-align: center;
        position: relative;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-color: #0b83e6;

        [role="tablist"] {
            display: inline-block;
            border-radius: 17px;
            background-color: rgba(255, 255, 255, 0.12);
            box-shadow: #f4f4f4;
            border: 1px solid rgba(255, 255, 255, 0.04);
        }
        [role="tab"] {
            color: #fff;
            border-radius: 17px;
            display: inline-block;
            padding: 8px 21px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            position: relative;
            z-index: 2;
            background-color: transparent;
            border: 0 none;
            &:focus {
                outline: 0;
            }

            &:last-child {
                margin-left: -6px;
            }
        }

        [aria-selected="true"] {
            background-color: rgba(255, 255, 255, 1);
            &[aria-controls="ar-tabpanel-1"] {
                color: #0b83e6;
            }
            &[aria-controls="ar-tabpanel-2"] {
                color: #7741bc;
            }
        }
        .c-tab-bottom {
            position: absolute;
            bottom: 0;
            height: 12px;
            width: 100%;
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
            background-color: transparent;
        }
    }
}
