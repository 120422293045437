[data-type="ContentsScroll_ChannelBox"] {
    letter-spacing: $body-spacing;

    li {
        padding: 0 16px 12px;
    }
    .c-headline {
        &__title {
            height: 52px;
            padding: 8px 16px;
            font-size: 16px;

            strong + span {
                font-size: 14px;
                color: $color-gray-03;
            }
        }
    }
    .s-list {
        padding: 0 12px 24px 12px;
        li {
            padding: 0 6px;
        }
        .b-content-box {
            width: 298px;

            .b-card--col2 {
                .c-card {
                    width: 126px;
                }
            }
        }
    }
    .b-content-box {
        @include rounding-box;

        padding: 16px 16px 2px 16px;

        .c-title-link {
            display: block;
            position: relative;
            margin-bottom: 4px;
            line-height: 23px;
        }

        .c-title {
            display: inline-block;
            position: relative;
            max-width: 100%;
            height: 27px;
            padding-right: 18px;
            overflow: hidden;
            font-size: 18px;
            font-weight: bold;
            color: $color-gray-03;
            line-height: 27px;
            vertical-align: top;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:after {
                @include ico-arrow-right(9px, #333);
                position: absolute;
                top: 9px;
                right: 5px;
            }
        }

        .c-title__subtext {
            overflow: hidden;
            font-size: $font-size-body1;
            color: $color-gray-04;
            margin: -2px 0 18px;
            height: 18px;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .b-card--col2 {
            display: flex;
            padding-bottom: 14px;

            .c-card {
                display: inline-block;
                vertical-align: top;
                flex: 1;

                &:first-child {
                    margin-right: 6px;
                }
                &:last-child {
                    margin-left: 6px;
                }
                &__thumb {
                    @include thumbnail-playtime;
                    @include thumbnail-playicon;
                    &:before {
                        content: "";
                        display: block;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
                    }
                }
                &__info {
                    padding: 8px 0 6px;
                    height: 50px;
                }
                &__title {
                    display: -webkit-box;
                    overflow: hidden;
                    min-height: 40px;
                    font-size: $font-size-body1;
                    color: $color-gray-03;
                    font-weight: normal;
                    word-break: keep-all;
                    margin-top: 0;
                    line-height: 20px;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            .c-tag-wrap {
                white-space: normal;
                overflow: hidden;
                display: block;
                margin: 0;
                padding-top: 4px;
                height: 24px;
                .c-tag {
                    font-size: 13px;
                    border-radius: 0;
                    border: 0;
                    color: #0b83e6;
                    background-color: #f4f4f4;
                    padding: 2px 4px;
                    margin: 0 4px 4px 0;
                    height: 19px;
                    line-height: 17px;
                    z-index: 2;
                    white-space: nowrap;
                }
            }
        }

        .c-list {
            &__item {
                padding: 0;
                display: block;
                height: 45px;
                &:first-child {
                    .c-list__item-link {
                        padding-top: 0;
                        height: 45px;
                    }
                }
                &:last-child {
                    .c-list__item-link {
                        border-bottom: 0;
                        height: 45px;
                        padding-bottom: 0;
                    }
                }
                &-link {
                    margin: 0;
                    padding: 0;
                    height: 45px;
                    line-height: 47px;
                    border-bottom: 1px solid $color-gray-12;
                    white-space: initial;
                    font-size: $font-size-body1;
                }
            }

            &__text {
                line-height: 20px;
                &-color {
                    color: $color-11st-blue;
                    line-height: 20px;
                    padding-right: 8px;
                }
            }
        }
    }
}
