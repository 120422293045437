[data-type="Cap_Title_Sorting"] {
    &.l-grid__row {
        background: #fff;
    }
    .c-list-header {
        display: flex;
        height: 60px;
        padding: 14px 16px 11px;
        white-space: nowrap;
        align-items: center;

        .c-title {
            display: block;
            overflow: hidden;
            position: relative;
            height: 34px;
            font-size: 17px;
            color: $color-gray-01;
            white-space: normal;
            line-height: 34px;
        }
    }

    .c-sorting-inline {
        margin-left: auto;
        padding-left: 10px;
        font-size: 0;

        &__item {
            display: inline-block;

            & + .c-sorting-inline__item {
                position: relative;
                margin-left: 8px;
                padding-left: 12px;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 10px;
                    background: #eee;
                    @include translateY(-50%);
                    content: "";
                }
            }
            &.on {
                .c-sorting-inline__link {
                    color: $color-11st-red;

                    &:before {
                        display: inline-block;
                        width: 7px;
                        height: 12px;
                        margin: -2px 9px 2px 0;
                        border: solid $color-11st-red;
                        border-width: 0 1px 1px 0;
                        vertical-align: middle;
                        transform: rotate(45deg);
                        content: "";
                    }
                }
            }
        }
        &__link {
            display: block;
            position: relative;
            font-size: 14px;
            color: #999;
            line-height: 20px;
        }
    }
}
