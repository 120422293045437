@import "../../pui/sprites/sp_kkuk";

[data-type="Contents_Review_MY"] {
    letter-spacing: $body-spacing;
    &.l-grid__row {
        background-color: $color-gray-14;
    }
    .l-grid__col {
        padding: 16px 0;
    }
    .c-userinfo {
        display: block;
        width: 100%;
        padding: 0 16px;

        &__thumb {
            display: block;
            position: relative;
            overflow: hidden;
            width: 64px;
            height: 64px;
            margin: 0 auto;
            border-radius: 100%;
            line-height: 0;
            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid rgba(0, 0, 0, 0.05);
                border-radius: 100%;
                box-sizing: border-box;
                content: "";
                z-index: 2;
            }
            span {
                display: block;
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;
                margin: 0 auto;
                border-radius: 50%;
                z-index: 2;
                text-indent: -99999px;
                background-size: cover;
                background-position: 50% 50%;
                &.profile1 {
                    background-image: url("/MW/img/pui/sprites/kkuk/profile_1.png");
                }
                &.profile2 {
                    background-image: url("/MW/img/pui/sprites/kkuk/profile_2.png");
                }
                &.profile3 {
                    background-image: url("/MW/img/pui/sprites/kkuk/profile_3.png");
                }
                &.profile4 {
                    background-image: url("/MW/img/pui/sprites/kkuk/profile_4.png");
                }
                &.profile5 {
                    background-image: url("/MW/img/pui/sprites/kkuk/profile_5.png");
                }
            }
        }
        &__detail {
            display: block;
            padding-top: 12px;
            text-align: center;
        }
        &__name {
            font-size: $font-size-subtitle1;
            color: $color-gray-02;
        }
        &__ranking-wrap {
            text-align: center;
        }
        &__ranking {
            margin: 8px 0 0 0;
            display: inline-block;
            @include clearfix();

            dt,
            dd {
                float: left;
                font-size: $font-size-body2;
            }
            dt {
                color: $color-gray-06;

                &:before {
                    display: inline-block;
                    width: 3px;
                    height: 3px;
                    margin: -1px 8px 1px;
                    border-radius: 100%;
                    background: $color-gray-09;
                    vertical-align: middle;
                    content: "";
                }
                &:first-child:before {
                    display: none;
                }
            }
            dd {
                padding-left: 6px;
                color: $color-11st-blue;
            }
        }
    }
    // 리뷰 박스 변경 적용 후 standby 클래스 삭제해야 함
    .c-standby {
        padding: 8px 16px 0;

        &__link {
            display: block;
            position: relative;
            padding: 12px;
            font-size: $font-size-body2;
            color: $color-gray-02;
            line-height: 24px;
            @include rounding-box;

            &:before {
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 4px;
                vertical-align: top;
                @include sprite-retina($sp_kkuk_qna);
                content: "";
            }
            &:after {
                position: absolute;
                top: 50%;
                right: 18px;
                margin-top: -2px;
                border: solid $color-gray-07;
                border-width: 1px 1px 0 0;
                width: 8px;
                height: 8px;
                transform: rotate(45deg) translateY(-50%);
                content: "";
            }
            strong {
                font-weight: normal;
                color: $color-11st-red;
            }
        }
    }

    .c-write-review {
        padding: 8px 16px 0;
        &__box {
            display: block;
            position: relative;
            padding: 12px 76px 12px 12px;
            margin-bottom: 12px;
            height: 82px;
            font-size: $font-size-body1;
            color: $color-gray-02;
            line-height: 24px;
            overflow: hidden;
            @include rounding-box;
        }
        &__image {
            display: block;
            float: left;
            width: 56px;
            height: 56px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        &__name {
            display: table-cell;
            height: 56px;
            max-height: 56px;
            padding-left: 12px;
            vertical-align: middle;
            span {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                overflow: hidden;
                max-height: 42px;
                line-height: 20px;
            }
        }

        &__button {
            position: absolute;
            right: 16px;
            top: 21px;
            text-indent: -99999px;
            display: block;
            width: 38px;
            height: 38px;
            border: 1px solid #ddd;
            border-radius: 24px;
            &:before {
                position: absolute;
                left: 5px;
                top: 6px;
                display: inline-block;
                width: 24px;
                height: 24px;
                margin-right: 4px;
                background-color: $color-gray-14;
                vertical-align: top;
                @include sprite-retina($sp_kkuk_qna);
                content: "";
            }
        }
        &__button-big {
            display: block;
            margin: 16px 16px 0;
            height: 48px;
            line-height: 48px;
            text-align: center;
            color: $color-gray-14;
            font-size: $font-size-body1;
            border-radius: 4px;
            background-color: $color-11st-red;
            span {
                position: relative;
                padding-right: 20px;
                &:after {
                    position: absolute;
                    right: 5px;
                    top: 50%;
                    width: 8px;
                    height: 8px;
                    margin-top: -4px;
                    border-top: 1px solid $color-gray-14;
                    border-left: 1px solid $color-gray-14;
                    transform: rotate(135deg);
                    content: "";
                }
            }
        }
    }
}
