.c-floating {
    position: fixed;
    top: auto;
    left: 8px;
    bottom: 61px;
    z-index: 101;
    transform: none;

    &__link {
        @include size(69px);
        display: block;

        img {
            @include size(100%);
        }
    }
    &__close {
        @include size(24px);
        position: absolute;
        top: 0;
        right: 0;
        border: 0;
        background: none;

        &:before {
            @include size(20px);
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 100%;
            background: rgba($color: $color-gray-02, $alpha: 0.9);
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2);
            transform: translate(-50%, -50%);
            content: "";
        }
        span {
            display: inline-block;
            width: 100%;
            height: 100%;
            overflow: hidden;
            color: transparent;
            vertical-align: top;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 49%;
                width: 1px;
                height: 9px;
                margin-top: -5px;
                background: $color-gray-14;
                content: "";
            }
            &:before {
                @include rotate(45deg);
            }
            &:after {
                @include rotate(-45deg);
            }
        }
    }
    &--enlarge {
        .c-floating__link {
            @include size(90px);
            .lottie-container {
                @include size(90px);
                display: block;
                line-height: 1;
            }
        }
        .c-floating__close {
            right: -10px;
        }
    }
    &--flexible {
        bottom: 72px;
        left: 12px;

        .c-floating {
            &__banner {
                overflow: hidden;
                width: 144px;
                height: 56px;
                background-color: $color-gray-14;
                border-radius: 56px;
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
                transition: width 0.3s cubic-bezier(0.42, 0, 1, 1);

                &--animated {
                    width: 56px;
                    transition-duration: 0.2s;
                }
            }
            &__link {
                display: block;
                width: auto;
                height: 100%;

                img {
                    width: auto;
                }
            }
            &__close {
                top: -3px;
                right: -22px;

                &:before {
                    @include size(18px);
                }
            }
        }
    }
}
