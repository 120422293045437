@import "../../skpui/component/c-card-deal__info";
[data-type="BillboardScroll"] {
    @include grid(0, 0, false);

    padding-top: 16px !important;
    margin-bottom: 8px;

    [data-type="ProductDeal_Shocking"] + & {
        margin-top: 8px;
    }
}
