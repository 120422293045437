[data-type="ProductGrid_Shocking"] {
    letter-spacing: 0;

    @include grid(2, 2, true);

    &.l-grid__row {
        background-color: #fff;
    }

    .l-grid__col--6.medium-3 {
        .c-cap {
            height: 30px;
            line-height: 26px;
            &__rank {
                position: relative;
                left: initial;
                top: initial;
                display: inline-block;
            }
        }
        .c-prd-info {
            height: 186px;
            &__rate {
                margin-right: 5px;
            }
            &__meta {
                dd:last-child {
                    em {
                        padding-left: 4px;
                    }
                }
            }
        }
    }
    .c-card__counsel {
        height: 90px;
    }
}
