[data-type="Tabs_NewRoundBtn_Scroll"] {
    .s-list {
        padding: 0 0 6px;
        padding-left: 16px;

        li {
            padding: 0;
            .c-chip {
                margin-bottom: 0;

                &--selected {
                    border-color: rgba($color-11st-red, 0.4);
                    background: rgba($color-11st-red, 0.02);
                }
            }
        }
    }

    .w-magnet-tab {
        width: 100%;
        max-width: 1280px;
        background: $color-gray-14;

        .s-list {
            padding: 10px 0 10px 16px;
        }
    }

    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
        }
        &--absolute {
            position: absolute;
        }
    }
}
