[data-type="Cap_Box_Title"] {
    letter-spacing: 0;
    .c-headline {
        &__title {
            line-height: 34px;
            padding: 14px 16px 11px 16px;

            a {
                position: relative;
                display: inline-block;
                padding-right: 16px;
                font-size: 15px;
                color: #666;
                line-height: 34px;
                &:after {
                    display: block;
                    content: "";
                    height: 9px;
                    width: 9px;
                    -webkit-transform: rotate(315deg);
                    transform: rotate(315deg);
                    position: absolute;
                    top: 3px;
                    right: 0;
                    margin: 0;
                    margin-top: 9px;
                    border: 1px solid #999;
                    border-width: 0 1px 1px 0;
                }

                strong {
                    color: $color-gray-02;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 34px;
                    white-space: nowrap;

                    & + span {
                        padding-right: 0;
                        font-weight: 700;
                    }
                }
            }
        }
        &__subtext {
            font-size: 14px;
            color: $color-gray-04;
            padding-bottom: 18px;
        }
    }
}
