[data-type="GridList_ImgTextCard_Col4"] {
    .c-listimg__item {
        .c-listimg__link {
            min-height: 80px;
            padding: 8px 0 10px 0;
            border: 0;
            .c-listimg__icon-wrap {
                @include size(60px);
                .c-listimg__icon {
                    @include size(60px);
                }
                .c-badge {
                    top: 4px;
                    left: auto;
                    right: 4px;
                    z-index: 10;
                    padding: 0 4px;
                    font-size: 9px;
                    font-weight: 700;
                }
            }
            .c-listimg__name {
                margin-top: 6px;
                font-size: 13px;
                overflow: hidden;
                overflow-wrap: break-word;
                white-space: normal;
                height: 16px;
                letter-spacing: $body-spacing;
            }
        }
        &:nth-child(4n + 1) {
            .c-listimg__link {
                padding-left: 8px;
            }
        }
        &:nth-child(4n) {
            .c-listimg__link {
                padding-right: 8px;
            }
        }
        @include mq-320 {
            &:nth-child(4n + 1) {
                .c-listimg__link {
                    padding-left: 0px;
                }
            }
            &:nth-child(4n) {
                .c-listimg__link {
                    padding-right: 0px;
                }
            }
        }
        @include mq-640 {
            &:nth-child(8n + 1) {
                .c-listimg__link {
                    padding-left: 8px;
                }
            }
            &:nth-child(8n) {
                .c-listimg__link {
                    padding-right: 8px;
                }
            }
        }

        &:nth-child(4n):nth-last-child(1) ~ .c-listimg__item,
        &:nth-child(4n):nth-last-child(2) ~ .c-listimg__item,
        &:nth-child(4n):nth-last-child(3) ~ .c-listimg__item,
        &:nth-child(4n):nth-last-child(4) ~ .c-listimg__item,
        &:nth-child(4n):nth-last-child(5) ~ .c-listimg__item {
            .c-listimg__link {
                border-bottom: 0;
            }
        }
    }
    .on {
        .c-listimg__link {
            background: #f4f4f4;
            color: $color-11st-red;
        }
    }
}
