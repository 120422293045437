[data-type="GridList_ImgSmallText_Col2"] {
    letter-spacing: $body-spacing;
    .c-list__item-link {
        height: 48px;
        line-height: 48px;
        .c-list__icon {
            position: relative;
            margin-top: -3px;
            .c-badge {
                padding: 0 3px 0 3px;
                font-size: 9px;
                top: -4px;
                left: 12px;
            }
        }
        .c-list__text {
            line-height: 24px;
        }
    }
}
