$sprite-meta-search: (
    "name": 'search', // sprite 이름
    "width": 187, // sprite 판 너비
    "height": 157, // sprite 판 높이
);

$sprite-map-search: (
  'badge_official': (
      "width": 40,
      "height": 17,
      "bg-top": -36 - (25 - 17)/2,
      "bg-left": 0 - (48 - 40)/2,
  ),
  'balloon_fullscreen': (
      "width": 79,
      "height": 28,
      "bg-top": 0 - (36 - 28)/2,
      "bg-left": 0 - (87 - 79)/2,
  ),
  'filter': (
      "width": 20,
      "height": 20,
      "bg-top": -101 - (28 - 20)/2,
      "bg-left": -96 - (28 - 20)/2,
  ),
  'ic_arrow': (
      "width": 8,
      "height": 14,
      "bg-top": -133 - (22 - 14)/2,
      "bg-left": -96 - (16 - 8)/2,
  ),
  'ic_cart': (
      "width": 20,
      "height": 20,
      "bg-top": -101 - (28 - 20)/2,
      "bg-left": -124 - (28 - 20)/2,
  ),
  'ic_search': (
      "width": 24,
      "height": 24,
      "bg-top": -61 - (32 - 24)/2,
      "bg-left": -80 - (32 - 24)/2,
  ),
  'ic_trip_type1': (
      "width": 32,
      "height": 32,
      "bg-top": 0 - (40 - 32)/2,
      "bg-left": -87 - (40 - 32)/2,
  ),
  'ic_trip_type2': (
      "width": 32,
      "height": 32,
      "bg-top": -61 - (40 - 32)/2,
      "bg-left": 0 - (40 - 32)/2,
  ),
  'ico_building': (
      "width": 16,
      "height": 16,
      "bg-top": -56 - (24 - 16)/2,
      "bg-left": -159 - (24 - 16)/2,
  ),
  'ico_building_gray': (
      "width": 16,
      "height": 16,
      "bg-top": -80 - (24 - 16)/2,
      "bg-left": -159 - (24 - 16)/2,
  ),
  'ico_calendar': (
      "width": 16,
      "height": 16,
      "bg-top": -104 - (24 - 16)/2,
      "bg-left": -159 - (24 - 16)/2,
  ),
  'ico_calendar2': (
      "width": 16,
      "height": 16,
      "bg-top": -36 - (24 - 16)/2,
      "bg-left": -48 - (24 - 16)/2,
  ),
  'ico_location': (
      "width": 16,
      "height": 16,
      "bg-top": -133 - (24 - 16)/2,
      "bg-left": 0 - (24 - 16)/2,
  ),
  'ico_location_gray': (
      "width": 16,
      "height": 16,
      "bg-top": -133 - (24 - 16)/2,
      "bg-left": -24 - (24 - 16)/2,
  ),
  'ico_more_image': (
      "width": 32,
      "height": 32,
      "bg-top": -61 - (40 - 32)/2,
      "bg-left": -40 - (40 - 32)/2,
  ),
  'ico_person': (
      "width": 16,
      "height": 16,
      "bg-top": -133 - (24 - 16)/2,
      "bg-left": -48 - (24 - 16)/2,
  ),
  'ico_person_gray': (
      "width": 16,
      "height": 16,
      "bg-top": -133 - (24 - 16)/2,
      "bg-left": -72 - (24 - 16)/2,
  ),
  'official': (
      "width": 20,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": -159 - (28 - 20)/2,
  ),
  'reset': (
      "width": 24,
      "height": 24,
      "bg-top": 0 - (32 - 24)/2,
      "bg-left": -127 - (32 - 24)/2,
  ),
  'search_mov_play': (
      "width": 20,
      "height": 20,
      "bg-top": -28 - (28 - 20)/2,
      "bg-left": -159 - (28 - 20)/2,
  ),
  'sorting_arrow': (
      "width": 24,
      "height": 24,
      "bg-top": -32 - (32 - 24)/2,
      "bg-left": -127 - (32 - 24)/2,
  ),
  'view_gallery_off': (
      "width": 24,
      "height": 24,
      "bg-top": -64 - (32 - 24)/2,
      "bg-left": -127 - (32 - 24)/2,
  ),
  'view_gallery_on': (
      "width": 24,
      "height": 24,
      "bg-top": -101 - (32 - 24)/2,
      "bg-left": 0 - (32 - 24)/2,
  ),
  'view_list_off': (
      "width": 24,
      "height": 24,
      "bg-top": -101 - (32 - 24)/2,
      "bg-left": -32 - (32 - 24)/2,
  ),
  'view_list_on': (
      "width": 24,
      "height": 24,
      "bg-top": -101 - (32 - 24)/2,
      "bg-left": -64 - (32 - 24)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-search($name, $width: null, $type: null) {
    $sp-w: map-get($sprite-meta-search, "width");
    $sp-h: map-get($sprite-meta-search, "height");
    $img: map-get($sprite-map-search, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;

    @if $type == null {
      background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      background-size: #{$bgw}px #{$bgh}px;
    } @else if $type == "mask" {
      mask-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      mask-size: #{$bgw}px #{$bgh}px;
    }
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-search($name: null, $tmp-width: null, $type: null) {
    display: inline-block;
    
    @if $type == null {
      vertical-align: top;
      background: url("/MW/img/svg/sprites/sp_search_202458_95821.svg") no-repeat;
    } @else if $type == "mask" {
      mask-image: url("/MW/img/svg/sprites/sp_search_202458_95821.svg");
    }
    @if $name != null {
      @include get-sprite-position-search($name, $width: $tmp-width, $type: $type);
    }
}