@import "../../common/partial/starrate";

[data-type="ProductGrid_Delivery"] {
    overflow: hidden;
    margin-bottom: -1px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        position: relative;
        border: 1px solid #f4f4f4;
        border-width: 0 0 1px;

        &:nth-child(odd) {
            &:after {
                position: absolute;
                top: 0;
                left: auto; //l-grid__row--border 대응
                right: -1px;
                bottom: 0;
                height: 100%;
                z-index: 9;
                border: 1px solid #f4f4f4;
                border-width: 0 1px 0 0;
                content: "";
            }
        }

        @media screen and (min-width: 640px) {
            &:after {
                position: absolute;
                top: 0;
                left: auto; //l-grid__row--border 대응
                right: -1px;
                bottom: 0;
                height: 100%;
                z-index: 9;
                border: 1px solid #f4f4f4;
                border-width: 0 1px 0 0;
                content: "";
            }
        }
    }

    .c-card {
        &.c-card__gallery {
            padding: 10px 10px 0;

            .c-card__thumb {
                width: auto;
                height: auto;
            }
            .c-card__info {
                min-height: 193px;
                padding: 10px 6px 0;

                .c-card__name {
                    display: block;
                    height: auto;
                    max-height: 36px;
                    margin-bottom: 6px;
                    line-height: 1.2;
                }
                .c-card__benefit {
                    @import "../../skpui/component/benefit_gallery";
                    overflow: hidden;
                    height: auto;
                    max-height: 48px;
                    padding-top: 4px;
                    font-size: 14px;

                    strong {
                        color: $color-gray-04;
                    }
                    .benefit {
                        &:first-child {
                            padding-bottom: 0;
                        }
                        & + .benefit {
                            padding-top: 6px;
                        }
                    }
                    & + .c-card__benefit {
                        padding-top: 6px;
                    }
                }
                .c-card__rate {
                    padding-right: 2px;

                    strong {
                        font-size: 18px;
                        font-weight: normal;
                    }
                }
                .c-card__price {
                    overflow: hidden;
                    height: auto;
                    margin-right: -12px;
                    vertical-align: initial;

                    strong {
                        font-size: 18px;
                    }
                }
                .c-starrate {
                    margin-top: 8px;

                    & + .c-card__benefit {
                        padding-top: 8px;
                    }
                }
            }
        }
        .c-card__counsel {
            padding: 25px 0 14px 0;
            height: 78px;
        }
    }
    .l-grid__col--12 {
        .c-card__counsel {
            padding: 14px 0;
            height: 66px;
        }
    }
    .l-grid__col--12.medium-6 {
        .c-card__counsel {
            padding: 12px 0 0;
            height: auto;
            margin-bottom: -8px;
        }
    }
    &.l-grid__row--border {
        .l-grid__col {
            &:after {
                left: auto;
            }
        }
    }
}
