// Prev header style before convert PUI
// checkbox & radio in base
.kword {
    display: none;

    .ctg {
        padding: 10px 15px 17px;
        border-bottom: 1px solid #edecf2;
        background: #fff;

        h2 {
            color: #111;
            font-size: 15px;
            font-weight: normal;
            line-height: 20px;
        }

        li {
            margin-top: 7px;

            a {
                @include text-ellipsis;

                @include vertical-middle(20px);

                display: block;
                position: relative;
                padding-left: 21px;
                color: #5f7fe4;
                font-size: 15px;

                &:before {
                    @include size(20px 19px);

                    position: absolute;
                    top: 0;
                    left: 0;
                    background: url("/MW/img/common/kword.png") no-repeat 0 0;
                    background-size: 20px 175px;
                    content: '';
                }
            }

            &.m2 a:before {
                background-position: 0 -20px;
            }

            &.m3 a:before {
                background-position: 0 -40px;
            }

            &.m4 a:before {
                background-position: 0 -60px;
            }

            &.m5 a:before {
                background-position: 0 -80px;
            }

            &.m6 a:before {
                background-position: 0 -100px;
            }

            &.m7 a:before {
                background-position: 0 -120px;
            }

            &.m8 a:before {
                background-position: 0 -140px;
            }
        }
    }

    .vrt {
        margin-top: 17px;
        font-size: 14px;
        line-height: 20px;

        span {
            color: #333;
        }

        .type {
            @include border-radius(9px);

            @include vertical-middle(18px);

            display: inline-block;
            padding: 0 7px;
            margin-right: 6px;
            border: 1px solid #333;
            font-size: 11px;
            vertical-align: middle;
        }
        &.top {
            display: block;
            height: 45px;
            line-height: 45px;
            margin-top: 0;
            padding-left: 15px;
            border-bottom: 1px solid #edecf2;
            background-color: #fff;
        }
    }

    .wrd {
        background: #fff;

        li {
            position: relative;
            border-bottom: 1px solid #edecf2;

            &:first-child {
                border-top: none;
            }

            a {
                @include text-ellipsis;

                @include vertical-middle(45px);

                display: block;
                padding: 0 40px 0 15px;
                color: #333;
                font-size: 16px;

                strong {
                    color: #f62e3d;
                    font-weight: normal;
                }
            }

            button {
                @include size(40px 45px);

                overflow: hidden;
                position: absolute;
                top: 0;
                right: 0;
                border: none;
                background: none;
                color: transparent;

                &:before {
                    @include size(14px 14px);

                    position: absolute;
                    top: 15px;
                    right: 15px;
                    background: url("/MW/img/common/kword.png") no-repeat 0 -160px;
                    background-size: 20px 175px;
                    content: '';
                }
            }
        }
    }
}

.schwd {
    display: none;
    overflow: hidden;
    position: relative;
    padding: 10px 8px;

    .sw_tab {
        display: table;
        width: 100%;
        background: #e2e3e7;
        margin-bottom: 10px;

        li {
            display: table-cell;
            width: 33.3%;
            border: 1px solid #cbcccf;
            border-width: 1px 0 1px 1px;
            text-align: center;

            &:last-child {
                border-right-width: 1px;
            }

            a {
                display: block;
                height: 2.4em;
                color: #6e7077;
                font-size: .95em;
                font-weight: bold;
                line-height: 2.3em;
            }

            &.on {
                border-color: #868ca7;
                border-right-width: 1px;
                background: #8f94ae;

                & + li {
                    border-left-width: 0;
                }

                a {
                    color: #fff;
                }

                .pg {
                    display: block;
                }
            }

            .pg {
                @include vertical-middle(4px);

                display: none;
                overflow: hidden;
                margin-top: -6px;

                span {
                    @include size(4px 4px);

                    display: inline-block;
                    margin: 0 2px 0 3px;
                    border-radius: 2px;
                    background: #676c8a;
                    vertical-align: top;

                    &.on {
                        background: #fff;
                    }
                }

                b {
                    @include vertical-middle(4px);

                    display: block;
                    overflow: hidden;
                    margin-top: -4px;
                    font-size: 4px;
                    color: transparent;
                }
            }
        }
    }

    & .prev, & .next {
        @include size(24px 46px);

        overflow: hidden;
        position: absolute;
        top: 50%;
        margin-top: -39px;
        background: rgba(0, 0, 0, 0.2);
        border: none;
        text-indent: -100px;
        z-index: 11;
    }

    & .prev:before, & .next:before {
        @include size(7px 11px);

        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -6px 0 0 -4px;
        background: url("/MW/img/common/schword.png") no-repeat;
        background-size: 56px 12px;
    }

    & .prev {
        left: 0;
        border-radius: 0 2px 2px 0;

        &:before {
            background-position: -41px 0;
        }
    }

    & .next {
        right: 0;
        border-radius: 2px 0 0 2px;

        &:before {
            background-position: -49px 0;
        }
    }

    .sw_time {
        padding: 10px;
        color: #919295;
        font-size: .75em;
        line-height: 1.1em;
    }
}

.sw_ft {
    .close {
        position: relative;
        height: 28px;
        border: none;
        background: #abb0b9;
        padding: 0 24px 0 10px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
        color: #fff;
        font-size: .85em;
        -webkit-perspective: 100px;
        perspective: 100px;

        &:before, &:after {
            @include size(2px 12px);

            content: '';
            position: absolute;
            left: auto;
            right: 14px;
            top: 8px;
            background: #fff;
        }

        &:before {
            @include rotate(45deg);
        }

        &:after {
            @include rotate(-45deg);
        }
    }

    header & {
        margin-top: 6px;
        text-align: right;
    }

    header .kword & {
        margin-right: 10px;
    }
}

.sw_con {
    position: relative;
    margin: 0 8px;

    ul {
        border: 1px solid #dfdfe3;
    }

    li {
        position: relative;
        background: #fff;
        border-top: 1px solid #dfdfe3;
        font-size: .95em;
        line-height: 2.8em;

        &:first-child {
            border-top: none;
        }

        a {
            @include text-ellipsis;

            display: block;
            height: 2.75em;
            margin: 0 35px 0 10px;
            color: #333;

            b {
                @include size(14px 13px);

                @include border-radius(2px);

                overflow: hidden;
                position: absolute;
                left: 9px;
                top: 50%;
                margin-top: -7px;
                background: #949dac;
                border: 1px solid #8c95a4;
                color: #fff;
                font-size: 10px;
                line-height: 14px;
                text-align: center;
            }
        }

        &.rk a {
            color: #e90e2d;

            b {
                border-color: #f13550;
                background: #f05168;
            }
        }
    }

    .none {
        padding: 60px 0;
        background: #fff;
        color: #abb0b9;
        font-size: .85em;
        line-height: 3em;
        text-align: center;
    }

    &.rec {
        li button {
            @include size(32px 40px);

            position: absolute;
            top: 0;
            right: 0;
            border: none;
            background: none;
            color: transparent;

            &:before, &:after {
                @include size(1px 16px);

                content: '';
                position: absolute;
                left: auto;
                right: 16px;
                top: 50%;
                margin-top: -8px;
                background: #abb0b9;
            }

            &:before {
                @include rotate(45deg);
            }

            &:after {
                @include rotate(-45deg);
            }
        }

        .del_all {
            border: none;
            background: none;
            padding: 10px;
            color: #808793;
            font-size: .85em;

            &:after {
                @include size(13px 12px);

                content: '';
                display: inline-block;
                margin-left: 4px;
                background: url("/MW/img/common/schword.png") no-repeat 0 0;
                background-size: 53px 12px;
                vertical-align: -1px;
            }
        }
    }

    &.fast li a {
        margin: 0 43px 0 33px;
    }

    &.ppu li a {
        margin: 0 10px 0 33px;
    }

    .rt {
        position: absolute;
        right: 9px;
        top: 0;
        min-width: 43px;
        color: #999;
        font-size: 13px;
        text-align: right;

        span {
            @include size(8px 12px);

            overflow: hidden;
            display: inline-block;
            margin-left: 5px;
            background-image: url("/MW/img/common/schword.png");
            background-size: 56px 12px;
            color: transparent;
            vertical-align: -0.2em;
        }

        &.up span {
            background-position: -14px 0;
        }

        &.dw span {
            background-position: -23px 0;
        }

        &.sm span {
            background-position: -32px 0;
        }

        &.nw {
            color: #f85864;
            font-weight: bold;
        }
    }

    .swp {
        overflow: hidden;
        position: relative;
        width: 100%;
    }
}

.sw_swp {
    overflow: hidden;
    position: relative;
    margin: 0 -8px;
}

.pop_atype {
    display: block;
    overflow: hidden;
    position: absolute;
    left: 50%;
    width: 280px;
    margin-left: -140px;
    background: #fff;
    z-index: 102;

    html.android.v4_0 & {
        @include translateZ(0px);
    }

    .btn_w {
        margin: 0 0 10px;
        padding: 10px 15px 0;
        border-top: 1px solid #eee;
        text-align: center;

        .bbtn {
            @include linear-gradient(#fff, #f2f2f2);

            @include border-radius(2px);

            @include box-shadow(0 2px 2px rgba(0, 0, 0, 0.08));

            padding: 0 14px;
            border: 1px solid #aaa;
            font-size: 14px;
            line-height: 38px;
            font-weight: bold;
            width: 100%;

            &.frd {
                border-color: #f62e3d;
                color: #f62e3d;
            }
        }

        &:after {
            content: '';
            display: block;
            clear: both;
        }
    }

    .close {
        @include size(50px 40px);

        @include translateZ(0px);

        position: absolute;
        overflow: hidden;
        top: 0;
        right: 0;
        padding: 0;
        border: none;
        background: none;
        text-indent: -10000px;

        &:before {
            @include size(1px 22px);

            @include rotate(45deg);

            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -11px 0 0 0;
            background: #5d5fd6;
        }

        &:after {
            @extend .close:before;

            @include size(22px 1px);

            margin: 0 0 0 -11px;
        }
    }
}

.tit_pop_yogiyo {
    margin: 12px 0 10px 0;
    font-size: 15px;
    color: #333;
    text-align: center;
}

.yogiyo_agrbox {
    overflow: hidden;
    border-top: 1px solid #ccc;

    .opt {
        padding: 0 0 0 15px;
        border-top: 1px solid #eee;
        font-size: 14px;
        text-align: left;

        &:first-child {
            border-top: none;
        }
    }

    .chkBox {
        position: relative;
        display: block;
        padding: 10px 50px 10px 30px;

        input[type='checkbox'] {
            z-index: 5;
            left: 0;
            top: 8px;

            &:checked + label {
                color: #28a9ff;
            }
        }

        label:before {
            position: absolute;
            left: 0;
            top: 8px;
        }
    }

    .ico_arr {
        @include size(25px 25px);

        position: absolute;
        right: 15px;
        top: 50%;
        margin: -12px 0 0 0;
        text-indent: -9999px;
        border: 0 none;
        background: none;

        &:before {
            @include size(9px 9px);

            @include rotate(45deg);

            position: absolute;
            left: 50%;
            top: 50%;
            margin: -5px 0 0 -10px;
            border: 1px solid #333;
            border-width: 1px 1px 0 0;
            content: '';
            -webkit-transition: transform 0.4s ease;
            transition: transform 0.4s ease;
        }

        &.down:before {
            @include rotate(135deg);

            margin: -10px 0 0 -5px;
        }
    }

    .on .ico_arr.down:before {
        @include rotate(-45deg);

        margin: -5px 0 0 -5px;
        border-color: #888;
    }

    .opt_detail_r2 {
        @include size(100% 160px);

        position: absolute;
        left: -9999px;
        background: #f5f5f5;
        color: #757575;
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        overflow: hidden;
        z-index: 1;

        .iscrollbox {
            padding: 20px;
        }

        &.on {
            position: relative;
            left: 0;
        }

        strong {
            color: #333;
        }

        .subject {
            margin: 20px 0 10px 0;

            &:first-child {
                margin-top: 0px;
            }
        }

        .gap {
            margin: 0 0 10px 0;
        }
    }
}

.m_hidden {
    overflow: hidden;
    width: 100%;
    background: #fff;

    .ad_image img {
        display: block;
        width: 100%;
    }
}

/* loading */
.loading_full {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 102;

    .circle {
        @include size(22px 22px);

        overflow: hidden;
        margin: -11px 0 0 -11px;
        background: url("/MW/img/common/loading.png") no-repeat 0 0;
        background-size: 100% 100%;
        text-indent: -1000px;
        -webkit-animation-name: myAnimationLoaingFull;
        -webkit-animation-duration: .65s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        animation-name: myAnimationLoaingFull;
        animation-duration: .65s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

@-webkit-keyframes myAnimationLoaingFull {
    from {
        -webkit-transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
    }
}

@keyframes myAnimationLoaingFull {
    from {
        transform: rotate(360deg);
    }

    to {
        transform: rotate(0deg);
    }
}

/* floatingBnr */
.floatingdown_bnr {
    display: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99;

    a:first-child {
        @include size(100% 100px);

        display: block;

        img {
            @include size(320px 110px);

            display: block;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -160px;
        }
    }

    .del {
        @include size(28px 28px);

        overflow: hidden;
        position: absolute;
        top: -33px;
        left: 50%;
        margin-left: 147px;
        border: none;
        background: #2b2b2b url("/MW/img/main/floatingdown_bnr_close.png") no-repeat 50% 50%;
        background-size: 13px 13px;
        -webkit-border-radius: 14px;
        border-radius: 14px;
        color: transparent;
        font-size: 1px;

        @media screen and (max-width: 359px) {
            & {
                margin-left: 130px;
            }
        }
    }
}

@keyframes sk_data_startAnimation {
    0% {
        -webkit-transform: translateZ(0px) scaleX(0);
        transform: translateZ(0px) scaleX(0);
    }

    100% {
        -webkit-transform: translateZ(0px) scaleX(1);
        transform: translateZ(0px) scaleX(1);
    }
}

/* App Guide */
.pop_appupdate {
    display: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    width: 300px;
    margin-left: -150px;
    border-radius: 8px;
    background: #fff;
    z-index: 102;

    h1 {
        @include vertical-middle(40px);

        background: #ebebeb;
        border-bottom: 1px solid #d3d3d3;
        border-radius: 8px 8px 0 0;
        color: #4a5566;
        font-size: 1.07em;
        text-align: center;
    }

    .con {
        padding: 20px 0 10px;

        p {
            color: #666;
            font-size: 1em;
            line-height: 1.34em;
            text-align: center;
        }
    }

    .btn_update {
        overflow: hidden;
        margin: 20px 5px 0;

        > div {
            float: left;
            width: 50%;
        }

        a {
            display: block;
            margin: 0 5px;
            background: #999;
            border-radius: 1px;
            color: #fff;
            font-size: .95em;
            font-weight: bold;
            line-height: 3.1em;
            text-align: center;

            &.up {
                background: #f62e3d;
            }
        }
    }

    .close {
        @include size(39px 39px);

        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        text-indent: -1000px;
        -webkit-transform: translateZ(0px);
        transform: translateZ(0px);

        &:before, &:after {
            @include size(2px 20px);

            content: '';
            position: absolute;
            left: auto;
            right: 18px;
            top: 10px;
            background: #757575;
        }

        &:before {
            @include rotate(45deg);
        }

        &:after {
            @include rotate(-45deg);
        }
    }

    html.android.v4_0 & {
        -webkit-perspective: 100px;
        perspective: 100px;
    }
}

/* Coach mark */
.coach_mark {
    @include size(320px 550px);

    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -160px;
    z-index: 102;

    & .sc1, & .sc2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 320px;
        -webkit-transition: 0.5s;
        transition: 0.5s;
    }

    .imgs1 {
        display: block;
        width: 280px;
        margin: auto;
    }

    .imgs2 {
        display: block;
        width: 250px;
        margin: auto;
    }

    .sc1 {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        -webkit-transform-origin: 0 0;
        transform-origin: 0 0;
    }

    .sc2 {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }

    &.next .sc1 {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
    }

    &.next .sc2 {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }

    .pgr {
        overflow: hidden;
        position: relative;
        height: 12px;
        margin-top: 11px;
        text-align: center;

        span {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            color: transparent;
        }

        &:before, &:after {
            @include size(12px 12px);

            @include border-radius(6px);

            content: '';
            display: inline-block;
            margin: 0 3px;
            background: #999;
            vertical-align: top;
        }
    }

    & .sc1 .pgr:before, & .sc2 .pgr:after {
        background: #fff;
    }

    & .btn_next, & .btn_prev {
        @include size(80px 120px);

        position: absolute;
        top: 205px;
        border: none;
        background: none;
        color: transparent;
        z-index: 2;
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }

    & .btn_next {
        right: 0px;
    }

    & .btn_prev {
        left: 0px;
    }

    & .btn_next:after, & .btn_prev:after {
        @include size(25px 25px);

        @include rotate(45deg);

        position: absolute;
        top: 40px;
        border: 3px solid #bbb;
        content: '';
    }

    & .btn_next:after {
        right: 8px;
        border-width: 3px 3px 0 0;
    }

    & .btn_prev:after {
        left: 8px;
        border-width: 0 0 3px 3px;
    }

    .btn_start {
        @include size(78px 30px);

        @include border-radius(2px);

        display: block;
        margin: 18px auto 0;
        border: 1px solid #fff;
        background: none;
        color: #fff;
        font-size: 14px;
    }

    .btn_skip {
        position: absolute;
        bottom: 0;
        right: 0;
        padding-right: 3px;
        border: none;
        border-bottom: 1px solid #fff;
        background: none;
        color: #fff;
        font-size: 17px;

        &:after {
            @include size(8px 8px);

            @include rotate(45deg);

            display: inline-block;
            border: 2px solid #fff;
            border-width: 2px 2px 0 0;
            content: '';
        }
    }
}

/* Call coop channel App */
.pop_ext_app {
    position: fixed;
    left: 50%;
    width: 340px;
    margin-left: -170px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.08);
    z-index: 102;

    .con {
        padding: 15px;
        border: 1px solid #afb0c2;
        background: #fff;
    }

    .bnr {
        overflow: hidden;

        a {
            display: block;
            max-width: 308px;
            max-height: 73px;
            margin: auto;
        }

        img {
            @include size(308px 73px);

            display: block;
        }
    }

    .btn_app {
        display: block;
        margin-top: 10px;
        background: #fff;
        border: 1px solid #ec565a;
        border-radius: 2px;
        color: #eb413b;
        font-size: 14px;
        line-height: 40px;
        text-align: center;
    }

    .txt_def {
        height: 69px;
        padding: 4px 0 0 80px;
        margin: 0 10px;
        background: url("/MW/img/common/ext_applink.png") no-repeat 0 2px;
        background-size: 65px 65px;
        color: #333;
        font-size: 14px;
        line-height: 19px;

        b {
            display: block;
            color: #1a1a1a;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .close {
        @include size(45px 45px);

        overflow: hidden;
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        text-indent: -200px;

        &:before, &:after {
            @include rotate(45deg);

            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            background: #5d5fd6;
        }

        &:before {
            @include size(1px 23px);

            margin: -13px 0 0 -1px;
        }

        &:after {
            @include size(23px 1px);

            margin: -1px 0 0 -12px;
        }
    }

    @media screen and (max-width: 359px) {
        & {
            width: 300px;
            margin-left: -150px;
        }

        & .con {
            padding: 15px 10px;
        }

        & .bnr img {
            @include size(278px 66px);
        }

        & .txt_def {
            margin: 0 5px;
        }
    }
}

/* SearchBox Swipe */
.mwContainer {
    position: relative;
    width: 100%;
    overflow: visible;

    .mwView {
        width: 100%;
        top: 0px;
        left: 0px;
        position: absolute;
    }
}

/* leftside floating banner */
.cp_floating {
    position: fixed;
    bottom: 44px;
    right: 0;
    z-index: 100;

    .cp_wrap {
        @include translateX(0px);

        height: 118px;
        padding-left: 40px;
        margin-right: -270px;
        -webkit-transition: -webkit-transform 0.4s ease;
        transition: transform 0.4s ease;
    }

    .cp_banner {
        @include size(268px 114px);

        @include border-radius(4px 0 0 4px);

        overflow: hidden;
        border: 2px solid #333c48;
        border-right: none;
        background: #3c4551;

        a {
            @include size(246px 114px);

            display: block;
            float: left;

            img {
                @include size(246px 114px);

                display: block;
                border-radius: 2px 0 0 2px;
            }
        }

        .stop {
            @include size(22px 114px);

            float: left;
            padding: 0 3px;
            border: none;
            border-left: 1px solid #353d48;
            background: #3a444f;
            color: #858c94;
            font-size: .7em;
            line-height: 1.1em;

            &:before {
                @include size(9px 9px);

                content: '';
                display: block;
                margin: 0 auto 5px;
                background: url("/MW/img/common/campaign_floating_r1.png") no-repeat 0 -102px;
            }
        }
    }

    .close {
        @include size(40px 50px);

        display: block;
        overflow: hidden;
        position: absolute;
        left: 0px;
        top: 50%;
        margin-top: -25px;
        border: none;
        background: url("/MW/img/common/campaign_floating_r1.png") no-repeat 0 -51px;
        text-indent: -1000px;
    }

    &.on .close {
        background-position: 0 0;
    }

    &.on .cp_wrap {
        @include translateX(-270px);

        border: none;
        -webkit-transition: -webkit-transform 0.4s ease;
        transition: -webkit-transform 0.4s ease;
    }
}

/* Whole popup */
.cp_allpopup {
    @include size(100% 100%);

    display: none;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;

    a {
        display: inline;
    }

    .cp_wrap {
        overflow: auto;
        position: relative;
        height: 100%;
        background: #fff;
        -webkit-overflow-scrolling: touch;
        overflow-scrolling: touch;
        z-index: 1;
    }

    html.android.v2_0 & {
        overflow: visible;
    }

    html.android.v2_0 & .cp_wrap {
        overflow: visible;
        height: auto;
        min-height: 100%;
    }

    html.android.v2_0 & .close {
        position: absolute;
    }

    .close {
        @include size(32px 32px);

        @include border-radius(2px);

        overflow: hidden;
        position: fixed;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.7) url("/MW/img/common/campaign_allpopup_r1.png") no-repeat 7px 8px;
        border: 1px solid #222;
        text-indent: -1000px;
        z-index: 2;
    }

    &.start .cp_wrap {
        @include translateY(100%);
    }

    &.animation .cp_wrap {
        @include translateY(0%);

        -webkit-transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
    }

    &.end .cp_wrap {
        @include translateY(100%);

        -webkit-transition: -webkit-transform 0.5s ease;
        transition: transform 0.5s ease;
    }

    &.start .close, &.animation .close, &end .close {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
}

@media screen and (-webkit-min-device-pixel-ratio: 1.5) {
    .cp_floating .cp_banner .stop:before, .cp_floating .close {
        background-image: url("/MW/img/common/campaign_floating_r2.png");
        background-size: 40px 111px;
    }

    .cp_allpopup .close {
        background-image: url("/MW/img/common/campaign_allpopup_r2.png");
        background-size: 15px 15px;
    }
}

body.allpopup {
    position: relative;
    height: 100%;

    #wrap {
        overflow: hidden;
        position: relative;
        height: 100%;
    }

    .cp_allpopup {
        display: block;
        overflow: hidden;
        height: 100%;
    }
}

/* Back Layer Control */
body.backType {
    position: relative;
    height: auto;
    min-height: 100%;

    #wrap {
        overflow: hidden;
    }

    #cts {
        z-index: auto;
    }
}

#back {
    @include size(100% 100%);

    display: none;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    z-index: 101;
}
