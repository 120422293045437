[data-type="ExhibitionBanner_ProductList"] {
    padding: 0 16px 24px;
    letter-spacing: $body-spacing;

    .c-content-box {
        @include rounding-box;
        overflow: hidden;

        .c-linkimg {
            display: block;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                height: 1px;
                background: rgba(0, 0, 0, 0.04);
                content: "";
            }
        }
    }
    .c-card-item {
        &-content {
            li {
                display: list-item;
                position: relative;
                padding: 16px;

                &:before {
                    position: absolute;
                    top: 0;
                    left: 16px;
                    right: 16px;
                    height: 1px;
                    background: $color-gray-12;
                    content: "";
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        &--list {
            min-height: 80px;
        }
        &__thumb {
            @include size(112px);
        }
        &__info {
            padding: 0 0 0 14px;

            .sktpass {
                overflow: hidden;
                height: 20px;
                padding-top: 1px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        &__name {
            display: block;
            height: auto;
            max-height: 38px;
            margin-bottom: 4px;
            line-height: 19px;
        }
        &__name-addition {
            overflow: hidden;
            height: 17px;
            margin-bottom: 6px;
            padding: 0;
            line-height: 17px;
        }
        &__rate {
            strong {
                font-weight: normal;
            }
        }
        &__price {
            height: auto;

            strong {
                font-size: 15px;
            }
        }
        &__counsel {
            padding: 0;
        }
    }
    .c-relates {
        border-top: 0;
        background: $color-gray-14;
    }
}
