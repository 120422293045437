@import "../../skpui/component/c-deal-cap";
@import "../../skpui/component/c-card-deal__info";

[data-type="ProductDeal_TimeDeal"] {
    letter-spacing: 0;

    @include grid(0, 0, false);

    .c-deal-cap {
        @include c-deal-cap--time;
    }

    .c-card.c-card__deal {
        border-color: #f4f4f4;
    }

    .c-card__info {
        @include c-card-deal__info;

        padding: 16px 32px;
        text-align: center;

        .c-card__rate {
            float: none;
            display: inline-block;
        }
        .c-card__benefit {
            .benefit {
                display: inline-block;
                margin-right: 4px;
                padding: 1px 5px;
                border: 1px solid #eee;
                font-size: 11px;
                line-height: 16px;
                color: #666;
                white-space: nowrap;
                vertical-align: top;
                em {
                    color: $color-11st-blue;
                }
            }
        }
        .c-flag-box {
            display: inline-flex;
        }
    }

    // MPINNER-11609 삭제예정 (.w-scroll)
    .w-scroll,
    .w-scroll-css {
        margin-top: -1px;
        z-index: 1;

        .s-list {
            width: 100%;
            min-width: 360px;
            max-width: 480px;
            margin: 0 auto;
            padding: 0 16px;

            li {
                position: relative;
                width: 16.66666666%;
                padding: 16px 0;
                min-width: 56px;

                a {
                    display: block;
                    width: 56px;
                    margin: 0 auto;
                    padding: 0 4px;
                    font-size: 11px;
                    line-height: 15px;
                    text-align: center;
                    white-space: nowrap;
                    color: #999;
                }

                &.on {
                    a {
                        font-weight: bold;
                        color: #111;
                    }

                    &:after {
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        width: 48px;
                        height: 3px;
                        margin: 0 auto;
                        background-color: $color-11st-red;
                        content: "";
                    }
                }
            }
        }
    }
}
