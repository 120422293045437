[data-type="Pagination_NumberButton"] {
    padding-bottom: 8px;

    .c-pagination__medium {
        padding: 8px 0;

        span {
            padding: 0 4px;
        }
        .c-pagination {
            &__list {
                display: flex;
                justify-content: center;
                align-items: center;
            }
            &__state {
                display: flex;
                font-size: 15px;
                color: $color-gray-08;
            }
            &__total {
                padding: 0;
                color: $color-gray-04;
            }
            &__previous,
            &__next {
                a {
                    @include size(72px 32px);
                    border: 1px solid $color-gray-10;
                    border-radius: 4px;

                    &:before {
                        margin: -4px 0 0;
                        background: none;
                    }
                }
            }
            &__previous {
                margin-right: 16px;

                a {
                    &:before {
                        @include ico-arrow-left(8px, $color-gray-03);
                    }
                }
            }
            &__next {
                margin-left: 16px;

                a {
                    &:before {
                        @include ico-arrow-right(8px, $color-gray-03);
                        margin-left: -4px;
                    }
                }
            }
        }
    }
}
