[data-type="GridList_ImgText_Col5_NoLine"] {
    background-color: #fff;
    .c-listimg {
        &__item {
            width: 20%;
            &:nth-child(4n+1) {
                clear: none;
            }
            .c-listimg__link {
                // border-width: 0 0 0 0;
                .c-listimg__name {
                    padding-top: 4px;
                }
            }
        }
    }
}
