[data-type="GridList_Trip_Col4_More"] {
    @include grid(1, 1, true);
    .c-listimg {
        .c-listimg__item {
            .c-listimg__link {
                border: 0;
                .c-listimg__icon-wrap {
                    @include size(64px);
                    .c-listimg__icon {
                        @include size(64px);
                    }
                }
                .c-listimg__name {
                    padding-top: 4px;
                }
            }
        }
    }
    .c-relates {
        border-top: 0;
        background-color: #fff;
        &__link-text:after {
            @include sprite-retina($sp_tour_more);
            border: 0;
            transform: initial;
            top: 15%;
            right: -9px;
        }
        &--close {
            .c-relates__link-text:after {
                @include rotate(180deg);
                margin-top: -5px;
            }
        }
    }
    .c-listimg__item:nth-child(8n)~.c-listimg__item {
        display: none;
    }
    & .open {
        .c-listimg__item:nth-child(8n)~.c-listimg__item {
            display: block;
        }
    }
}