[data-type="GridList_ImgText_Col3"] {
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
            background-color: $color-gray-14;
        }
        &--absolute {
            position: absolute;
        }
    }
    .c-listimg__item {
        width: 33.333333%;
        &:nth-child(4n + 1) {
            clear: none;
        }
        &:nth-child(3n + 1) {
            clear: both;
        }
        .c-listimg__link {
            padding: 10px 0 5px 0;
            height: 78px;
            .c-listimg__icon-wrap {
                @include size(80px 30px);
                .c-listimg__icon {
                    @include size(80px 30px);
                }
                .c-badge {
                    padding: 0 5px 0 4px;
                    font-size: 9px;
                    top: -2px;
                    left: initial;
                    right: -6px;
                }
            }
            .c-listimg__name {
                overflow: hidden;
                margin: 0 7px;
                padding: 8px 0;
                font-size: 13px;
                letter-spacing: $body-spacing;
                text-overflow: ellipsis;
            }
        }
    }
}
