[data-type="ExhibitionBanner_Tabs_Outlet"] {
    .c-tab {
        letter-spacing: 0;
        .c-tab__list {
            display: table;
            padding: 0 0 8px;
            text-align: center;
        }
        .c-tab__item {
            width: initial;
            padding: 4px 10px;
        }
        .c-tab__link {
            display: inline-block;
            position: relative;
            height: initial;
            padding: 2px 1px;
            border: 0;
            margin: 0;
            background-color: initial;
            font-size: 14px;
            line-height: 22px;
            color: #4a4a4a;
            vertical-align: top;
            white-space: nowrap;
            &:after {
                position: absolute;
                left: -2px;
                right: -2px;
                bottom: -2px;
                height: 2px;
                content: "";
            }
        }
        .on {
            .c-tab__link {
                border: 0;
                background-color: initial;
                color: #c29254;
                &:after {
                    background-color: #c29254;
                }
            }
        }
    }
    .c-card__link-text {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 18%;
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #fff;
        letter-spacing: 0;
        .c-card__link-detail {
            position: relative;
            display: block;
            padding-right: 10px;
            font-size: 13px;
            width: 78px;
            margin: 10px auto 0 auto;
            &:after {
                content: "";
                position: absolute;
                top: 52%;
                right: 2px;
                width: 8px;
                height: 8px;
                margin: -5px 0 0 -5px;
                border: 1px solid #fff;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
            }
        }
    }

    .c-cardgrid {
        display: table;
        width: 100%;
        padding: 0 8px;
        &__half {
            display: inline-table;
            width: 50%;
            padding: 16px 4px 10px 4px;
            .c-card__thumb {
                &:before {
                    @include size(100%);
                    content: "";
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    z-index: 1;
                    border: 1px solid rgba(212, 212, 212, 0.4);
                }
                img {
                    width: 100%;
                    height: auto;
                    max-width: initial;
                    max-height: initial;
                    left: -100%;
                    right: -100%;
                }
            }
            .c-card__info {
                padding: 8px 8px 0 0;
                .c-card__name {
                    height: initial;
                    min-height: 42px;
                    margin-bottom: 0;
                    -webkit-line-clamp: 2;
                    font-size: 14px;
                    color: #4a4a4a;
                }
                .c-card__branch {
                    color: #888;
                    font-size: 12px;
                }
            }
        }
    }
}
