@import "../../skpui/sprites/sp_tour";
[data-type="FlatButton_Trip_Reservation"] {
    @include grid(0, 0, true);
    border-top: 1px dashed #c9e3f5;
    background-color: #eef9ff;
    overflow: hidden;
    letter-spacing: 0;
    .c-icontitle {
        display: inline-block;
        height: 48px;
        line-height: 48px;
        font-size: 13px;
        padding-left: 36px;
        color: #666;
        &:before {
            @include sprite-retina($sp_tour_notice);
            content: "";
            display: block;
            position: absolute;
            left: 16px;
            top: 15px;
        }
    }
    .c-textlink-list {
        display: flex;
        float: right;
        height: 48px;
        line-height: 48px;
        margin-right: 2px;
        li {
            padding-right: 14px;
            a {
                position: relative;
                font-size: 13px;
                display: block;
                padding-right: 10px;
                color: #666;
                &:after {
                    transform: rotate(45deg);
                    height: 6px;
                    width: 6px;
                    content: "";
                    position: absolute;
                    top: 51%;
                    right: 1px;
                    margin-top: -4px;
                    border: solid #666;
                    border-width: 1px 1px 0 0;
                }
            }
        }
    }
}
