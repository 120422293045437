@import "../../common/sprites/sp_eui";

[data-type="Cap_Title_Sorting_Kkuk"] {
    &.l-grid__row {
        background-color: #fff !important;
    }

    .c-button-sorting {
        display: block;
        margin: 0;
        padding: 0;
        width: 80px;
        height: 32px;
        background-color: $color-gray-14;
        border: 1px solid $color-gray-11;
        border-radius: 16px;
        outline: none;
        font-size: $font-size-body2;
        color: $color-gray-04;
        span {
            display: inline-block;
            padding-left: 20px;
        }
    }

    .c-list-header {
        position: relative;
        display: flex;
        width: 100%;
        background-color: $color-gray-14;
        padding: 20px 16px;
        white-space: nowrap;
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #f4f4f4;
            content: "";
        }

        .c-button-sorting {
            position: absolute;
            right: 0;
            top: -6px;
            span {
                &:before {
                    @include sprite-retina($sp_eui-sorting);
                    content: "";
                    display: block;
                    position: absolute;
                    top: 8px;
                    left: 10px;
                }
            }
        }
    }

    .c-sorting-inline {
        display: table;
        // display: -webkit-flex;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        &__item {
            display: table-cell;

            &:nth-child(1),
            &:nth-child(2) {
                width: 50px;
            }
            &:nth-child(3) {
                width: auto;
                // flex-grow: 1;
                // -webkit-flex-grow: 1;
            }
            & + .c-sorting-inline__item {
                position: relative;
                // margin-left: 10px;
                // padding-left: 12px;

                &:before {
                    position: absolute;
                    top: 41%;
                    left: -12px;
                    width: 1px;
                    height: 12px;
                    background: $color-gray-11;
                    @include translateY(-41%);
                    content: "";
                }
            }
            &.on {
                .c-sorting-inline__link {
                    color: $color-11st-red;
                }
            }
        }
        &__link {
            position: relative;
            font-size: 16px;
            color: $color-gray-04;
            font-weight: bold;
            line-height: 20px;
            &.new {
                &:after {
                    content: "";
                    display: block;
                    width: 4px;
                    height: 4px;
                    background-color: $color-11st-red;
                    position: absolute;
                    top: -3px;
                    right: -6px;
                    border-radius: 2px;
                }
            }
        }
    }
}
