[data-type="GridList_ImgText_Col5_Big"] {
    background-color: $color-gray-14;
    letter-spacing: $body-spacing;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    .c-listimg {
        &__item {
            width: 20%;
            &:nth-child(4n + 1) {
                clear: none;
            }
            .c-listimg__link {
                padding: 14px 0 7px;
                border-width: 0 0 1px 0;
                background: none;

                .c-listimg__icon-wrap {
                    @include size(48px);
                }
                .c-listimg__name {
                    margin-top: 7px;
                    color: $color-gray-03;
                    line-height: 18px;
                }
            }
        }
        &--more {
            overflow: hidden;
            height: 285px;
        }
    }
}
