@import "../../common/partial/icon";
@import "../../common/helpers/variables";

@mixin c-deal-cap--time {
    position: relative;
    padding: 16px 16px 8px;
    font-size: 0;

    &__status {
        display: inline-block;
        position: relative;
        padding: 4px 14px;
        border: 1px solid rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        background-color: #999;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        color: #fff;
        vertical-align: top;
        z-index: 1;
    }

    &__time {
        display: inline-block;
        position: relative;
        padding: 4px 14px;
        border: 1px solid #eee;
        border-radius: 14px;
        background-color: #fff;
        font-weight: bold;
        font-size: 16px;
        line-height: 18px;
        color: #333;
        vertical-align: top;
        // transition-duration: 0.5s;

        &.disappear {
            color: transparent;
        }
    }

    .c-deal-cap__status + .c-deal-cap__time {
        margin-left: -14px;
        padding-left: 20px;
        border-radius: 0 14px 14px 0;
    }

    &__count {
        position: absolute;
        right: 16px;
        top: 22px;
        font-size: 13px;
        line-height: 17px;
        color: #333;
    }

    &--active .c-deal-cap__status {
        background-color: $color-11st-red;
    }

    &--urgent .c-deal-cap__count {
        color: $color-11st-red;
        animation: 3s linear 0s urgent infinite;

        + .c-deal-cap__count {
            animation-delay: -1.5s;
        }
    }
}

@keyframes urgent {
    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }
    35%,
    65% {
        opacity: 1;
    }
}

@mixin c-deal-cap--date {
    position: relative;
    padding: 10px;
    font-size: 0;

    &__date {
        font-size: 16px;
        line-height: 20px;

        .badge {
            display: inline-block;
            padding: 2px 10px;
            margin-left: 6px;
            border-radius: 10px;
            background-color: #666;
            font-size: 12px;
            line-height: 16px;
            color: #fff;
            vertical-align: top;
        }
    }
}
