[data-type="LineBanner_h80"] {
    display: block;
    background: $color-gray-11;
    .c-banner__link {
        height: 80px;
        display: block;
        .c-banner__img {
            height: auto;
            width: 300px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }
        .c-lazyload {
            padding-bottom: 0;
            height: 80px;
            &:before {
                background-color: transparent;
            }
            .c-banner__img {
                width: 300px;
                height: auto;
            }
        }
    }
}
