[data-type="LineBanner_Ad"] {
    .c-lazyload {
        padding-bottom: 0;
        height: 60px;

        &:before {
            background-color: transparent;
        }
        img {
            @include size(300px 60px);
        }
    }
}
