[data-type="Contents_Box_CouponLink"] {
    padding: 0 16px 20px;
    .c-addition {
        @include rounding-box;
        &-link {
            padding: 13px 16px;
            &:after {
                @include ico-arrow-right(8px, $color-gray-06);
                position: absolute;
                top: 50%;
                right: 18px;
                margin-top: -4px;
                content: "";
            }
        }
        &-coupon {
            &__rate {
                margin-right: 10px;
            }
            &__text {
                font-size: 14px;

                &:after {
                    display: none;
                }
            }
            &__normal {
                font-weight: 700;
            }
        }
    }
}
