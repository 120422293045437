@import "../../common/sprites/sp_eui";
@import "../../svg/sp_common";

.c-dialog,
.c-popup {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 102;
    width: 280px;
    border-radius: 8px;
    background: $color-gray-14;
    letter-spacing: $body-spacing;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.36), 0px 6px 24px -8px rgba(0, 0, 0, 0.12);
    outline: none;

    &__buttons {
        position: static;
        display: flex;
        height: 48px;
        margin: 0;
        padding: 0;
        border-top: 1px solid $color-gray-12;

        button,
        a {
            display: block;
            height: 48px;
            line-height: 48px;
            background: $color-gray-14;
            padding: 0;
            border: 0;
            border-right: 1px solid $color-gray-12;
            font-size: 16px;
            color: $color-gray-03;
            outline: none;
            flex: 1;

            &:last-child {
                border-right: none;
            }
        }
        .c-dialog__button-point,
        .c-popup__button-point {
            color: $color-11st-red;
        }
    }

    &__content {
        margin: 28px 16px;
        font-size: $font-size-body1;
        line-height: 22px;
        color: $color-gray-04;
        text-align: center;

        &-info {
            font-size: $font-size-subtitle2;
            font-weight: bold;
            color: $color-gray-02;
            .ellipsis {
                @include text-ellipsis;
                display: inline-block;
                max-width: 172px;
                vertical-align: top;
            }
            .emoji {
                display: block;
                margin: -8px auto 8px;
                font-size: 30px;
                line-height: normal;
            }
        }
        &-point {
            color: $color-11st-red;
        }
    }

    &__text {
        margin-top: 19px;
        font-size: $font-size-body1;
        color: $color-gray-04;
        line-height: 20px;

        &-bg {
            position: relative;
            &:before {
                position: absolute;
                top: 8px;
                right: 0;
                left: 0;
                bottom: 0;
                background: $color-11st-red;
                opacity: 0.1;
                content: "";
            }
        }
        &:first-child {
            margin: 7px 0;
            font-size: 15px;
            color: $color-gray-03;
            line-height: 22px;
        }
        & + & {
            margin-top: 8px;
        }
        & + .c-popup__content {
            margin-top: 14px;
        }
        & + .checkbox-wrap {
            margin-top: -4px;
        }
        span {
            color: $color-11st-red;
        }
    }

    &--center {
        text-align: center;
    }

    &--border {
        border-radius: 4px;
    }
    &--alarm {
        overflow: visible;
        &::before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            top: -32px;
            left: 50%;
            margin-left: -32px;
            @include size(56px);
            border: 4px solid $color-gray-14;
            background-image: linear-gradient(to right, rgb(255, 0, 0), rgb(255, 16, 196));
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -17px;
            left: 50%;
            margin-left: -17px;
            @include sprite-retina($sp_eui_alarm_w_34);
        }
        .c-popup__buttons {
            height: 44px;
            .c-popup__button-close {
                background-color: $color-11st-red;
                color: $color-gray-14;
                margin: 0 16px 16px;
                height: 44px;
            }
        }
    }

    // 알림설정 ui개선 (최종 배포 이후 수정 필요 alarm2 -> alarm)
    &--alarm2 {
        overflow: visible;
        &::before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            top: -32px;
            left: 50%;
            margin-left: -32px;
            @include size(56px);
            border: 4px solid $color-gray-14;
            background-image: linear-gradient(to right, rgb(255, 0, 0), rgb(255, 16, 196));
        }
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: -17px;
            left: 50%;
            margin-left: -17px;
            @include sprite-retina($sp_eui_alarm_w_34_2);
        }
        .c-popup__content {
            margin: 16px 0 20px;
        }
        .c-popup__text {
            margin-top: 12px;
            line-height: 19px;

            &--timedeal {
                margin-bottom: 12px;
                color: $color-gray-02;
                font-size: 18px;
                font-weight: 700;
                line-height: 22px;
            }
        }
        .c-popup__buttons {
            height: 44px;
            border-top: 0;
            margin: 0;
            .c-popup__button-close {
                height: 44px;
                border-radius: 4px;
                background-color: $color-11st-red;
                color: $color-gray-14;
            }
        }
    }

    &.c-popup--alarmon {
        padding: 28px 16px 16px 16px;
    }
    &--alarmon {
        overflow: visible;
        .c-popup,
        .c-dialog {
            &__image {
                display: block;
                overflow: hidden;
                position: absolute;
                border-radius: 50%;
                top: -32px;
                left: 50%;
                margin-left: -32px;
                @include size(56px);
                border: 4px solid $color-gray-14;
                background-color: $color-gray-13;
                img {
                    width: 100%;
                    border-radius: 50%;
                    border: 1px solid $color-gray-11;
                    box-sizing: border-box;
                }
            }
            &__content {
                margin: 16px 0 20px;
                &-point {
                    @include text-ellipsis;
                    display: inline-block;
                    max-width: 166px;
                    margin-right: 2px;
                    vertical-align: top;
                }
            }
            &__text {
                margin-top: 12px;
                line-height: 19px;
            }
            &__buttons {
                height: 44px;
                margin: 0;
                border-top: 0;
                button {
                    &:last-child {
                        color: $color-gray-14;
                    }
                }
            }
            &__button-close {
                background-color: $color-11st-red;
                color: $color-gray-14;
                height: 44px;
                border-radius: 4px;
            }
        }
    }
    &__button-next {
        margin: 12px 0 -4px -8px;
        border: 0;
        background-color: $color-gray-14;
        font-size: 13px;
        color: $color-gray-04;
        line-height: 24px;
        white-space: nowrap;
        &:before {
            @include get-sprite-common("icon_checkmark", 24);
            margin-right: 4px;
            content: "";
        }
    }
}

.c-dialog {
    &__title {
        height: 51px;
        padding: 0 16px;
        border-bottom: 1px solid $color-gray-11;
        background: $color-gray-14;
        font-size: 17px;
        color: $color-gray-03;
        line-height: 51px;
        text-align: center;
    }

    &__content {
        & + .c-dialog__text {
            margin-top: -10px;
        }
    }

    &__items {
        overflow: hidden;
        position: relative;
        max-height: 272px;

        &:after {
            position: absolute;
            right: 0;
            left: 0;
            bottom: 0;
            height: 18px;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            content: "";
        }

        ul {
            li {
                position: relative;
                min-height: 48px;
                padding: 0 16px;
                border-top: 1px solid $color-gray-13;
                font-size: 15px;
                color: $color-gray-03;
                line-height: 48px;

                &:first-child {
                    border-top: 0;
                }

                .c-radio + label {
                    height: 48px;
                    line-height: 48px;

                    &:before {
                        top: 12px;
                    }

                    &:after {
                        top: 18px;
                    }
                }

                .c-dialog__item-info {
                    margin-top: -10px;
                    padding: 0 0 16px 30px;
                    font-size: 13px;
                    color: $color-gray-07;
                    line-height: 18px;
                }
            }
        }
        .sr-only + & {
            margin-top: -1px;
        }
        .w-scroll-css {
            overflow-y: auto;
            max-height: 272px;
            overscroll-behavior: contain;
            -webkit-overflow-scrolling: touch;
        }
    }

    &__toggle {
        .c-checkbox,
        .c-radio {
            & + label {
                display: inline-block;
            }
        }
    }

    &__linkList {
        display: none;

        .c-dialog__title {
            @include sr-only;
        }

        .c-dialog__items {
            ul {
                margin: 0;

                li {
                    border-bottom: 1px solid #eaeaea;
                    font-size: 14px;
                    line-height: 30px;
                    min-height: 30px;

                    @include mq-360 {
                        line-height: 42px;
                        min-height: 42px;
                    }

                    a {
                        display: block;
                        padding-left: 16px;
                    }

                    &.on {
                        a {
                            padding-right: 20px;
                            color: #f00;
                            position: relative;
                            @include ico-check(#f00);
                        }
                    }

                    &:last-child {
                        border-bottom: 0;
                    }
                }
            }
        }

        .c-dialog__buttons {
            position: static;
            height: 36px;

            @include mq-360 {
                height: 48px;
            }

            button {
                width: 100%;
                height: 36px;

                @include mq-360 {
                    height: 48px;
                }

                &:last-child {
                    color: $color-gray-03;
                }
            }
        }
    }

    &.c-dialog--autoheight {
        .c-dialog__items {
            &:after {
                display: none;
            }
        }
    }
    &.c-dialog__sorting {
        height: initial;
    }

    &__content-left {
        text-align: left;
        margin: 12px 18px;
        .c-dialog__text {
            padding: 12px 0;
            margin: 0;
            border-bottom: 1px solid $color-gray-11;
            text-align: left;
        }
        .c-dialog__form {
            padding: 18px 2px;
        }
    }
}

.c-popup {
    padding: 16px;

    &__title {
        margin-bottom: 12px;
        font-size: $font-size-subtitle2;
        font-weight: bold;
        line-height: 23px;
        color: $color-gray-02;
    }

    &__subtitle {
        margin-top: 22px;
        color: $color-gray-02;
        font-size: $font-size-body2;
        font-weight: normal;

        &:first-of-type {
            margin-top: 0;
        }
    }

    &__content {
        margin: 12px 0 28px;
    }

    &__list {
        li {
            position: relative;
            padding-left: 10px;
            font-size: $font-size-body2;
            color: $color-gray-02;
            font-weight: normal;
            padding-bottom: 6px;
            &:before {
                content: "";
                display: block;
                width: 2px;
                height: 2px;
                background-color: $color-gray-09;
                position: absolute;
                left: 0;
                top: 8px;
            }
            strong {
                font-weight: normal;
                font-size: $font-size-body1;
                display: block;
                padding-bottom: 10px;
            }
            &:last-child {
                padding-bottom: 10px;
            }
        }
        &.type-2 {
            li {
                font-size: 14px;
                color: $color-gray-04;
                line-height: 20px;

                &:before {
                    top: 9px;
                    background-color: $color-gray-07;
                }
                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    &__text {
        &:first-child {
            margin-right: 20px;
        }

        &--left {
            text-align: left;
        }

        > li {
            position: relative;
            padding-left: 11px;
            &:before {
                content: "";
                position: absolute;
                top: 7px;
                left: 0;
                width: 3px;
                height: 3px;
                background: currentColor;
                border-radius: 50%;
            }

            &:not(:first-child) {
                margin-top: 20px;
            }

            ul {
                margin-top: 20px;
            }
        }
    }

    &__subtext {
        margin-top: 6px;
        font-size: $font-size-body2;
        color: $color-gray-07;
        line-height: 16px;

        &:last-child {
            padding-bottom: 4px;
        }
        & + & {
            margin-top: 8px;
        }
    }

    &__point {
        color: $color-11st-red;
    }

    &__button-submit {
        display: block;
        width: 100%;
        height: 44px;
        margin-top: -4px;
        border: 1px solid $color-11st-red;
        background-color: $color-gray-14;
        border-radius: 4px;
        font-size: $font-size-subtitle2;
        color: $color-11st-red;
        line-height: 44px;
        text-align: center;
        outline: none;
    }

    &__button {
        @extend .c-popup__button-submit;

        &--primary {
            color: $color-gray-14;
            background-color: $color-11st-red;
        }
        &--alarm:before {
            @include sprite-retina($sp_eui_alarm_red);

            content: "";
            display: inline-block;
            margin: -1px 4px 1px 0;
            vertical-align: middle;
        }
    }

    &__buttons {
        margin: 20px -16px -16px;
    }

    &__next-btn {
        display: block;
        margin: 18px 0 0 0;
        height: 44px;
        border: 1px solid $color-11st-red;
        text-align: center;
        line-height: 44px;
        color: $color-11st-red;
        background-color: $color-gray-14;
        font-size: $font-size-body1;
        width: 100%;
    }

    &__close {
        overflow: hidden;
        position: absolute;
        top: 4px;
        right: 4px;
        width: 30px;
        height: 30px;
        background: transparent;
        border: 0 none;
        color: transparent;
        outline: none;

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: auto;
            right: 50%;
            top: 50%;
            width: 1px;
            height: 19px;
            margin-top: -9px;
            background: $color-gray-02;
            transform: rotate(45deg);
        }
        &:after {
            transform: rotate(-45deg);
        }
    }

    &--border {
        .c-popup__text {
            margin-top: 12px;
        }
        .c-popup__close {
            top: 12px;
            right: 7px;

            &:before,
            &:after {
                height: 16px;
            }
        }
    }

    &--narrow {
        padding: 9px 42px 7px 16px;

        .c-popup__text {
            margin: 0;
            font-size: 14px;
            color: $color-gray-02;
        }
        .c-popup__close {
            top: 5px;
        }
    }
}

.c-popup.c-popup--alarm {
    padding: 40px 16px 32px 16px;
}

// 알림설정 ui개선 (최종 배포 이후 수정 필요 c-popup--alarm2 -> c-popup--alarm)
.c-popup.c-popup--alarm2 {
    padding: 28px 16px 16px 16px;
}
