[data-type="Cap_Box_Time"] {
    letter-spacing: 0;
    .c-headline {
        &__title {
            font-size: 18px;
            line-height: 34px;
            padding: 12px 16px 11px 16px;

            strong {
                float: left;
            }

            span {
                position: relative;
                display: inline-block;
                margin-left: 11px;
                padding-left: 12px;
                font-size: 16px;
                color: #666;
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 12px;
                    background-color: #eee;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -6px;
                }
                time {
                    color: $color-11st-red;
                    font-weight: bold;
                }
            }
        }

        .c-ad__btn {
            right: 16px;
        }
    }
}
