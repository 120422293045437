$sprite-meta-ootd: (
    "name": 'ootd', // sprite 이름
    "width": 208, // sprite 판 너비
    "height": 165, // sprite 판 높이
);

$sprite-map-ootd: (
  'ic_arrow_back': (
      "width": 24,
      "height": 24,
      "bg-top": -89 - (32 - 24)/2,
      "bg-left": -120 - (32 - 24)/2,
  ),
  'ic_arrow_expand': (
      "width": 14,
      "height": 8,
      "bg-top": -148 - (16 - 8)/2,
      "bg-left": -176 - (22 - 14)/2,
  ),
  'ic_check': (
      "width": 16,
      "height": 16,
      "bg-top": -124 - (24 - 16)/2,
      "bg-left": -176 - (24 - 16)/2,
  ),
  'ic_check_on': (
      "width": 16,
      "height": 16,
      "bg-top": -89 - (24 - 16)/2,
      "bg-left": -152 - (24 - 16)/2,
  ),
  'ic_chevron_right': (
      "width": 8,
      "height": 12,
      "bg-top": -133 - (20 - 12)/2,
      "bg-left": -160 - (16 - 8)/2,
  ),
  'ic_gnb_cart': (
      "width": 36,
      "height": 37,
      "bg-top": 0 - (45 - 37)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic_heart': (
      "width": 24,
      "height": 24,
      "bg-top": -133 - (32 - 24)/2,
      "bg-left": 0 - (32 - 24)/2,
  ),
  'ic_heart_lg': (
      "width": 30,
      "height": 30,
      "bg-top": -89 - (38 - 30)/2,
      "bg-left": -44 - (38 - 30)/2,
  ),
  'ic_heart_lg_on': (
      "width": 30,
      "height": 30,
      "bg-top": -89 - (38 - 30)/2,
      "bg-left": -82 - (38 - 30)/2,
  ),
  'ic_heart_on': (
      "width": 24,
      "height": 24,
      "bg-top": -133 - (32 - 24)/2,
      "bg-left": -32 - (32 - 24)/2,
  ),
  'ic_remove': (
      "width": 21,
      "height": 20,
      "bg-top": -96 - (28 - 20)/2,
      "bg-left": -176 - (29 - 21)/2,
  ),
  'ic_tabbar_home': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'ic_tabbar_home_on': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'ic_tabbar_like': (
      "width": 36,
      "height": 36,
      "bg-top": -45 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic_tabbar_like_on': (
      "width": 36,
      "height": 36,
      "bg-top": -45 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'ic_tabbar_menu': (
      "width": 36,
      "height": 36,
      "bg-top": -45 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'ic_tabbar_menu_on': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -132 - (44 - 36)/2,
  ),
  'ic_tabbar_recent': (
      "width": 36,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": -132 - (44 - 36)/2,
  ),
  'ic_tabbar_recent_on': (
      "width": 36,
      "height": 36,
      "bg-top": -89 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic_view_col1': (
      "width": 24,
      "height": 24,
      "bg-top": -133 - (32 - 24)/2,
      "bg-left": -64 - (32 - 24)/2,
  ),
  'ic_view_col1_on': (
      "width": 24,
      "height": 24,
      "bg-top": -133 - (32 - 24)/2,
      "bg-left": -96 - (32 - 24)/2,
  ),
  'ic_view_col2': (
      "width": 24,
      "height": 24,
      "bg-top": -133 - (32 - 24)/2,
      "bg-left": -128 - (32 - 24)/2,
  ),
  'ic_view_col2_on': (
      "width": 24,
      "height": 24,
      "bg-top": 0 - (32 - 24)/2,
      "bg-left": -176 - (32 - 24)/2,
  ),
  'ic_view_col3': (
      "width": 24,
      "height": 24,
      "bg-top": -32 - (32 - 24)/2,
      "bg-left": -176 - (32 - 24)/2,
  ),
  'ic_view_col3_on': (
      "width": 24,
      "height": 24,
      "bg-top": -64 - (32 - 24)/2,
      "bg-left": -176 - (32 - 24)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-ootd($name, $width: null, $type: null) {
    $sp-w: map-get($sprite-meta-ootd, "width");
    $sp-h: map-get($sprite-meta-ootd, "height");
    $img: map-get($sprite-map-ootd, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;

    @if $type == null {
      background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      background-size: #{$bgw}px #{$bgh}px;
    } @else if $type == "mask" {
      mask-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
      mask-size: #{$bgw}px #{$bgh}px;
    }
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-ootd($name: null, $tmp-width: null, $type: null) {
    display: inline-block;
    
    @if $type == null {
      vertical-align: top;
      background: url("/MW/img/svg/sprites/sp_ootd_202441_161714.svg") no-repeat;
    } @else if $type == "mask" {
      mask-image: url("/MW/img/svg/sprites/sp_ootd_202441_161714.svg");
    }
    @if $name != null {
      @include get-sprite-position-ootd($name, $width: $tmp-width, $type: $type);
    }
}