[data-type="BannerContents_Ad"] {
    .c-ad-box {
        position: relative;

        .c-lazyload {
            img {
                position: relative !important;
                width: 100%;
                height: auto;
            }
        }
        .c-ad__btn {
            position: absolute;
            right: 13px;
            bottom: 12px;
        }
    }
}
