[data-type="Contents_Box_Link"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding-bottom: 24px;
    }
    .c-headline {
        @include rounding-box;
        &__title {
            height: 46px;
            padding: 8px 40px 8px 16px;
            font-size: $font-size-body1;
            color: $color-gray-02;
            font-weight: normal;
            text-overflow: ellipsis;
            line-height: 33px;
            strong {
                overflow: hidden;
                display: inline-block;
                position: relative;
                max-width: 87px;
                padding-right: 9px;
                color: $color-11st-red;
                white-space: normal;
                vertical-align: top;
                &:after {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 10px;
                    background-color: #eee;
                    position: absolute;
                    top: 11px;
                    right: 4px;
                }
            }
        }
        &__link {
            &:after {
                right: 18px;
            }
        }
        &__emphasis {
            font-weight: normal;
        }
    }
}
