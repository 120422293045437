[data-type="Tabs_RoundBtn_ColorBG_Scroll"] {
    .c-magnet {
        height: initial !important;
        .w-scroll {
            padding: 12px 0;
        }
    }
    .w-scroll {
        padding: 12px 0;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .w-scroll-css {
        padding: 12px 0;
    }
    .s-list {
        padding: 0 10px 0 16px;

        li {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 16px;
                background: $color-gray-14;
                content: "";
            }
            .c-chip {
                margin-bottom: 0;
            }
        }
    }
}
