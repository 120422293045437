[data-type="ProductGrid_Reco_Repeat"] {
    letter-spacing: $body-spacing;

    .l-grid__col {
        .c-headline__infoTxt {
            top: 36px;
        }
        .c-headline__info-text {
            top: 36px;
        }
    }

    .w-card__gallery--recommand {
        margin: 0 16px 24px;
        @include rounding-box;
    }

    .b-card--recommand {
        margin: 0 16px;
        padding: 16px 0;
        border-bottom: 1px solid #f4f4f4;

        .c-card {
            padding: 0;

            .c-prd-info {
                padding: 9px 0 0 12px;

                &__name {
                    color: #333;
                    line-height: 19px;
                    -webkit-line-clamp: initial;
                }

                &__history {
                    display: block;
                    margin-top: 16px;
                    color: #999;
                    font-size: 12px;
                    letter-spacing: 0;
                }

                &__rate {
                    strong {
                        font-weight: normal;
                    }
                }

                &__price {
                    vertical-align: middle;
                    line-height: 20px;

                    strong {
                        line-height: 18px;
                    }
                }
            }

            &__thumb {
                width: 116px !important;
                height: 116px !important;
                float: left;
                margin: 0;
            }
        }

        + .c-headline {
            .c-headline__title {
                height: 54px;
                line-height: 32px;
                font-size: 15px;
                font-weight: normal;
                color: #333;

                i {
                    color: $color-11st-blue;
                    font-style: normal;
                }
            }
        }
    }

    .w-card__gallery {
        padding: 0 10px 12px;
        @include clearfix();

        .c-card.c-card__gallery {
            padding: 0 6px;

            .c-card__thumb {
                @include size(auto);
            }

            .c-card__info {
                padding: 8px 0;

                .c-card__name {
                    font-size: 13px;
                    margin-bottom: 4px;
                    height: 38px;
                    -webkit-line-clamp: initial;
                }

                .c-card__price {
                    min-height: initial;
                    height: initial;
                    line-height: initial;
                    vertical-align: middle;

                    strong {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .w-swiper {
        [class*="swiper-button"] {
            visibility: hidden;
        }

        .w-card__gallery--recommand {
            margin: 0 16px 18px;
        }

        .c-pagination {
            margin-top: -16px;

            &__indicator {
                line-height: 36px;
                height: 40px;
            }

            &__bullet {
                width: 8px;
                height: 8px;
                margin: 0 3px;
                background-color: #000;
                border-color: #fff;
                opacity: 0.3;

                &.on {
                    opacity: 1;
                }
            }
        }
    }
}
