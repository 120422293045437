[data-type="Tabs_Fixed"] {
    @import "../../eui/component/_tab";

    .w-scroll-css {
        .c-tab {
            &__link {
                width: initial;
                padding: 0 10px;
                white-space: nowrap;
            }
        }
    }
}
