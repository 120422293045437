[data-type="ExhibitionBanner_BrandList"] {
    letter-spacing: $body-spacing;

    .c-linkimg {
        display: block;
        position: relative;
    }
    .c-content-box {
        @include rounding-box;
        overflow: hidden;
        position: relative;
        margin: -52px 16px 8px;
        padding: 20px;
        background: $color-gray-14;

        .c-content__text {
            overflow: hidden;
            height: 62px;
            font-size: 15px;
            color: $color-gray-04;
            line-height: 21px;
        }
        .c-content__more {
            position: absolute;
            right: 20px;
            bottom: 19px;
            padding: 0 5px 0 24px;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 23px);
            font-size: 15px;
            color: $color-11st-blue;
            line-height: 21px;

            &:after {
                @include ico-arrow-right(8px, $color-11st-blue);
                display: inline-block;
                margin: -1px 0 1px 6px;
                vertical-align: middle;
                content: "";
            }
        }
    }
    .c-card-item-content {
        li {
            &:first-child {
                .c-card-item--list {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .c-card-item--list {
            padding: 16px;

            &:before {
                position: absolute;
                top: 0;
                left: 16px;
                right: 16px;
                height: 1px;
                background: $color-gray-12;
                content: "";
            }
            .c-card-item__rank {
                border-radius: 0;
            }
            .c-card-item__thumb {
                @include size(112px 112px);
            }
            .c-card-item__info {
                padding: 0 0 0 14px;

                .sktpass {
                    overflow: hidden;
                    height: 20px;
                    padding-top: 1px;

                    &:before {
                        margin-right: 0;
                        margin-left: 0;
                    }
                    span {
                        display: inline;
                    }
                }
            }
            .c-card-item__name {
                display: block;
                height: auto;
                max-height: 38px;
                margin-bottom: 4px;
                line-height: 19px;
            }
            .c-card-item__price {
                strong {
                    vertical-align: bottom;
                }
            }
            .c-card-item__rate {
                strong {
                    font-weight: normal;
                }
            }
            .c-card-item__benefit {
                margin-top: 7px;
            }
            .c-nation {
                margin-bottom: 6px;
            }
        }
    }
    .c-relates {
        margin-top: 8px;

        &--big {
            &:before {
                display: none;
            }
        }
    }
    & + [data-type="ExhibitionBanner_BrandList"] {
        margin-top: 10px !important;
    }
}
