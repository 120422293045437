[data-type="Banner_Notice_Rolling"] {
    overflow: hidden;
    height: 39px;
    background: linear-gradient(90deg, #ff8b8b 0%, #ffa57e 100%);
    .w-swiper--vertical {
        position: relative;
        height: 39px;
        .c-notification {
            position: absolute;
            left: 16px;
            top: 8px;
            width: 40px;
            height: 22px;
            display: block;
            line-height: 20px;
            bottom: initial;
            color: #fff;
            border: 1px solid #fff;
            border-radius: 24px;
            font-size: 13px;
            text-align: center;
            z-index: 2;
        }
    }
    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 39px !important;
    }
    .c-banner {
        &__coupon {
            position: relative;
            display: block;
            padding: 0 0 0 65px;
            width: 100%;
            height: 39px;
            color: #333;
            background: linear-gradient(90deg, #ff8b8b 0%, #ffa57e 100%);
            .c-banner__title {
                font-size: 14px;
                line-height: 38px;
                strong {
                    font-weight: bold;
                    color: #fff;
                }
            }
            &:after {
                display: none;
            }
        }
    }
}
