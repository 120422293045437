[data-type="Contents_Mart_Time"] {
    padding-bottom: 20px;
    .c-headline {
        padding: 0 16px;

        .c-headline__title {
            font-size: 15px;
            height: 42px;
            font-weight: normal;
            padding: 11px 0;
            line-height: 22px;

            &--icon {
                height: 24px;
                position: relative;
                width: initial;
                top: 4px;
                margin-right: 5px;
            }
        }
    }
    .w-mart__time {
        padding: 12px 0 0 0;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        background-color: #fff;
    }

    .w-card__mart {
        @include clearfix;
        margin: 0 16px 10px;
        padding: 0;
        font-size: 13px;
        font-weight: normal;
        color: #333;
        position: relative;
        @include rounding-box;

        &:last-child {
            margin-bottom: 0;
        }

        &::before {
            left: -18px;
            bottom: -21px;
            width: 140%;
        }

        > div {
            width: 100%;
            &:first-child {
                .c-card {
                    margin-left: 14px;
                    border-top: 0;
                }
            }
        }

        .c-card {
            height: 48px;
            padding: 14px 0 16px;
            margin-left: 0;
            position: relative;
            border-radius: 0;
            border: 0;
            box-shadow: none;
            border-top: 1px solid #f4f4f4;
            margin: 0 12px;
            text-align: left;
            overflow: hidden;

            @include mq-360 {
                margin: 0 14px;
            }

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 20px;
                right: 2px;
                @include ico-arrow-right(7px, #999);
            }

            &__logo {
                float: left;
                margin: 0;
                position: static;
                left: 0;
                height: 22px;

                img {
                    height: 22px;

                    &:nth-child(2) {
                        margin-left: 3px;
                    }

                    &[alt="GS프레시몰 새벽배송"] {
                        height: 19px;
                    }
                    @include mq-360 {
                        &[alt="GS프레시몰 새벽배송"] {
                            height: 22px;
                        }
                    }
                }

                &::after {
                    display: none;
                }
            }

            &__info {
                float: left;
                margin-left: 5px;
                padding: 0;
                vertical-align: top;
                line-height: 22px;
                background-color: transparent;
                white-space: nowrap;
                font-size: 11px;

                @include mq-360 {
                    font-size: 13px;
                }
            }

            &__info--dimm {
                color: #999;
            }

            &__status {
                float: left;
                border-radius: 15px;
                border: solid 1px rgba(11, 131, 230, 0.2);
                background-color: rgba(11, 131, 230, 0.02);
                display: inline-block;
                margin-right: 15px;
                height: 28px;
                line-height: 30px;
                font-size: 12px;
                margin-top: -4px;
                padding: 0 10px;
                white-space: nowrap;
                position: absolute;
                right: 0;
                top: 14px;

                letter-spacing: 0;
                color: $color-11st-blue;
                font-weight: bold;
                min-width: 78px;
                text-align: center;

                &--time {
                    width: 14px;
                    position: relative;
                    top: 3px;
                    margin-right: 2px;
                }
            }

            &__status--closed {
                color: $color-gray-07;
                border: solid 1px rgba(153, 153, 153, 0.2);
                background-color: rgba(153, 153, 153, 0.02);
            }
            &__status--dawn {
                color: #7741bc;
                border: solid 1px rgba(119, 65, 188, 0.2);
                background-color: rgba(119, 65, 188, 0.02);
            }
        }

        .c-card__mart--gsfresh-dawn {
            .c-card__logo {
                img {
                    height: 19px;
                    @include mq-360 {
                        height: 22px;
                    }
                }
            }
        }
    }

    // flex 적용 test
    [data-type="Contents_Mart_Time"] .w-card__mart .c-card__mart--flex {
        display: flex;
        & > span {
            flex: 1;
        }

        .c-card__info--wrap {
            .c-card__logo,
            .c-card__info {
                float: none;
            }
        }
        .c-card__status--wrap {
            .c-card__status {
                position: static;
                float: right;
            }
        }
    }
}
