[data-type="ExhibitionBannerG"] {
    letter-spacing: $body-spacing;
    background-color: $color-gray-11;
    .l-grid__col--12 {
        margin-bottom: 10px;
        padding-bottom: 1px;
        background-color: $color-gray-14;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .c-headline {
        height: 102px;
        padding: 18px 24px;
        &__link {
            &:after {
                display: none;
            }
            .c-headline__text {
                &:after {
                    display: block;
                    position: absolute;
                    top: 4px;
                    right: 2px;
                    @include ico-arrow-right(8px, $color-gray-14, 1px);
                }
            }
        }
        &__title {
            padding: 0;
            line-height: 20px;
            height: 26px;
            text-overflow: ellipsis;
            color: $color-gray-14;
        }
        &__text {
            position: relative;
            overflow: hidden;
            display: inline-block;
            padding-right: 12px;
            height: 17px;
            color: $color-gray-14;
            font-size: $font-size-body1;
        }
    }
    .b-content-box {
        @include rounding-box;

        margin: -25px 16px 24px 16px;
        padding: 16px 12px 12px 12px;
        position: relative;
        background-color: $color-gray-14;
        z-index: 1;
        overflow: hidden;
        letter-spacing: $body-spacing;
        .w-card__gallery {
            overflow: hidden;
            &:after {
                clear: both;
            }
        }

        li {
            width: 33.33333%;
            float: left;
            padding-right: 0;
            padding-left: 0;
            .c-card {
                &__gallery {
                    padding: 0 6px;
                    .c-card__thumb {
                        width: initial;
                        height: initial;
                    }
                    .c-card__info {
                        padding: 9px 0 0 0;
                        .c-card__price {
                            height: 18px;
                            vertical-align: middle;
                        }
                        .c-card__name {
                            height: 32px;
                            margin-bottom: 8px;
                            font-size: 13px;
                            -webkit-line-clamp: unset;
                        }
                        .c-card__price {
                            line-height: initial;
                            strong {
                                vertical-align: middle;
                                @include mq-320 {
                                    font-size: $font-size-subtitle2;
                                }
                                @include mq-360 {
                                    font-size: $font-size-subtitle1;
                                }
                            }
                        }
                    }
                }
            }
        }

        .c-content-item {
            display: block;
            overflow: hidden;
            position: relative;
            padding-right: 20px;
            &:after {
                @include size(10px);

                content: "";
                display: block;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                position: absolute;
                right: 5px;
                top: 24px;
            }
            &__thumb {
                @include size(54px);

                float: left;
                margin-right: 14px;
                img {
                    position: relative;
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 54px;
                    height: 54px;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    z-index: 1;
                }
            }
            &__title {
                display: block;
                font-weight: bold;
                padding-top: 7px;
                height: 26px;
                font-size: $font-size-subtitle2;
                color: $color-gray-03;
                white-space: pre-line;
                overflow: hidden;
            }
            &__subtext {
                display: block;
                padding-top: 4px;
                font-size: $font-size-body2;
                color: $color-gray-07;
            }
        }
    }
}
