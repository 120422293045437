[data-type="FullBanner"] {
    .c-banner__img {
        @include size(100% auto);
    }
    .c-lazyload {
        min-height: 40px;

        &:before {
            background-color: transparent;
        }
        .c-banner__img {
            position: relative !important;
        }
    }
}
