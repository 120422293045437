[data-type="Tabs_Text"] {
    @include grid(0, 2, false);

    .c-filter {
        dt {
            @include sr-only;
        }
        dd {
            display: flex;
            justify-content: space-between;
        }
        &__item {
            height: 50px;
            border: 0;
            background: none;
            font-size: 16px;
            color: $color-gray-07;

            &[aria-selected="true"] {
                font-weight: bold;
                color: $color-gray-03;
            }
        }
    }
}
