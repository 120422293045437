$sprite-meta-luxury: (
    "name": 'luxury', // sprite 이름
    "width": 265, // sprite 판 너비
    "height": 224, // sprite 판 높이
);

$sprite-map-luxury: (
  'etc-notice': (
      "width": 48,
      "height": 48,
      "bg-top": -36 - (56 - 48)/2,
      "bg-left": 0 - (56 - 48)/2,
  ),
  'ic-brand-plus': (
      "width": 18,
      "height": 18,
      "bg-top": -56 - (26 - 18)/2,
      "bg-left": -237 - (26 - 18)/2,
  ),
  'ic-brand-search': (
      "width": 24,
      "height": 24,
      "bg-top": -60 - (32 - 24)/2,
      "bg-left": -56 - (32 - 24)/2,
  ),
  'ic-check': (
      "width": 22,
      "height": 22,
      "bg-top": -60 - (30 - 22)/2,
      "bg-left": -152 - (30 - 22)/2,
  ),
  'ic-check-on': (
      "width": 22,
      "height": 22,
      "bg-top": -180 - (30 - 22)/2,
      "bg-left": -132 - (30 - 22)/2,
  ),
  'ic-filter': (
      "width": 16,
      "height": 16,
      "bg-top": -82 - (24 - 16)/2,
      "bg-left": -237 - (24 - 16)/2,
  ),
  'ic-filter-on': (
      "width": 16,
      "height": 16,
      "bg-top": -106 - (24 - 16)/2,
      "bg-left": -237 - (24 - 16)/2,
  ),
  'ic-gnb-back': (
      "width": 36,
      "height": 36,
      "bg-top": -92 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic-gnb-cart': (
      "width": 36,
      "height": 36,
      "bg-top": -92 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'ic-gnb-search': (
      "width": 36,
      "height": 36,
      "bg-top": -92 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'ic-heart': (
      "width": 24,
      "height": 24,
      "bg-top": -60 - (32 - 24)/2,
      "bg-left": -88 - (32 - 24)/2,
  ),
  'ic-heart-fill': (
      "width": 24,
      "height": 24,
      "bg-top": -60 - (32 - 24)/2,
      "bg-left": -120 - (32 - 24)/2,
  ),
  'ic-product-filter': (
      "width": 20,
      "height": 20,
      "bg-top": 0 - (28 - 20)/2,
      "bg-left": -237 - (28 - 20)/2,
  ),
  'ic-quotes': (
      "width": 13,
      "height": 8,
      "bg-top": -130 - (16 - 8)/2,
      "bg-left": -237 - (21 - 13)/2,
  ),
  'ic-radio': (
      "width": 22,
      "height": 22,
      "bg-top": -180 - (30 - 22)/2,
      "bg-left": -162 - (30 - 22)/2,
  ),
  'ic-radio-on': (
      "width": 22,
      "height": 22,
      "bg-top": -180 - (30 - 22)/2,
      "bg-left": -192 - (30 - 22)/2,
  ),
  'ic-refresh': (
      "width": 20,
      "height": 20,
      "bg-top": -28 - (28 - 20)/2,
      "bg-left": -237 - (28 - 20)/2,
  ),
  'ic-tabbar-brand': (
      "width": 36,
      "height": 36,
      "bg-top": -92 - (44 - 36)/2,
      "bg-left": -132 - (44 - 36)/2,
  ),
  'ic-tabbar-brand-on': (
      "width": 36,
      "height": 36,
      "bg-top": -136 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic-tabbar-home': (
      "width": 36,
      "height": 36,
      "bg-top": -136 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'ic-tabbar-home-on': (
      "width": 36,
      "height": 36,
      "bg-top": -136 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'ic-tabbar-like': (
      "width": 36,
      "height": 36,
      "bg-top": -136 - (44 - 36)/2,
      "bg-left": -132 - (44 - 36)/2,
  ),
  'ic-tabbar-like-on': (
      "width": 36,
      "height": 36,
      "bg-top": 0 - (44 - 36)/2,
      "bg-left": -193 - (44 - 36)/2,
  ),
  'ic-tabbar-menu': (
      "width": 36,
      "height": 36,
      "bg-top": -44 - (44 - 36)/2,
      "bg-left": -193 - (44 - 36)/2,
  ),
  'ic-tabbar-menu-on': (
      "width": 36,
      "height": 36,
      "bg-top": -88 - (44 - 36)/2,
      "bg-left": -193 - (44 - 36)/2,
  ),
  'ic-tabbar-pick': (
      "width": 36,
      "height": 36,
      "bg-top": -132 - (44 - 36)/2,
      "bg-left": -193 - (44 - 36)/2,
  ),
  'ic-tabbar-pick-on': (
      "width": 36,
      "height": 36,
      "bg-top": -180 - (44 - 36)/2,
      "bg-left": 0 - (44 - 36)/2,
  ),
  'ic-tabbar-recent': (
      "width": 36,
      "height": 36,
      "bg-top": -180 - (44 - 36)/2,
      "bg-left": -44 - (44 - 36)/2,
  ),
  'ic-tabbar-recent-on': (
      "width": 36,
      "height": 36,
      "bg-top": -180 - (44 - 36)/2,
      "bg-left": -88 - (44 - 36)/2,
  ),
  'logo-11-st': (
      "width": 37,
      "height": 16,
      "bg-top": -36 - (24 - 16)/2,
      "bg-left": -56 - (45 - 37)/2,
  ),
  'tooltip': (
      "width": 185,
      "height": 28,
      "bg-top": 0 - (36 - 28)/2,
      "bg-left": 0 - (193 - 185)/2,
  ),
);

// [STEP2] (선택) 기본 선언 후 이미지 크기와 위치를 오버라잇 없이 각각 설정
@mixin get-sprite-position-luxury($name, $width: null) {
    $sp-w: map-get($sprite-meta-luxury, "width");
    $sp-h: map-get($sprite-meta-luxury, "height");
    $img: map-get($sprite-map-luxury, $name);
    $img-w: map-get($img, "width");
    $img-h: map-get($img, "height");
    $img-r: $img-w / $img-h;
    $img-bgt: map-get($img, "bg-top");
    $img-bgl: map-get($img, "bg-left");

    @if $width == null {
      $width: $img-w;
    }

    $height: $width / $img-r;

    $bgw: floor($width * ($sp-w / $img-w));
    $bgh: floor($height * ($sp-h / $img-h));
    $bgl: ($bgw / $sp-w) * $img-bgl;
    $bgt: ($bgh / $sp-h) * $img-bgt;

    width: #{$width}px;
    height: #{$height}px;
    background-position: #{$bgl}#{"px"} #{$bgt}#{"px"};
    background-size: #{$bgw}px #{$bgh}px;
}

// [STEP1] (필수) 기본 선언
@mixin get-sprite-luxury($name: null, $tmp-width: null) {
    display: inline-block;
    vertical-align: top;
    background: url("/MW/img/svg/sprites/sp_luxury_2023419_140328.svg") no-repeat;
    @if $name != null {
      @include get-sprite-position-luxury($name, $width: $tmp-width);
    }
}
