@import "../../sprites/sp_flags";

// .s-list 클래스에서 @include로 사용
@mixin flags {
    li {
        &.on {
            .c-icontext__text {
                color: $color-11st-red;
            }
        }

        .c-icontext__icon {
            text-indent: -9999px;
            &:after {
                display: none;
            }
        }
        .flags {
            &_local {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_local);
                }
            }
            &_www {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_www);
                }
            }
            &_usa {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_usa);
                }
            }
            &_fra {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_fra);
                }
            }
            &_ity {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_ity);
                }
            }
            &_eng {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_eng);
                }
            }
            &_ger {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_ger);
                }
            }
            &_jpn {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_jpn);
                }
            }
            &_spn {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_spn);
                }
            }
            &_twi {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_twi);
                }
            }
            &_hk {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_hk);
                }
            }
            &_can {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_can);
                }
            }
            &_chn {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_chn);
                }
            }
            &_etc {
                .c-icontext__icon {
                    @include sprite-retina($sp_flags_etc);
                }
            }
        }
    }
}
