[data-type="ExhibitionBanner_BrandGrid"] {
    .c-linkimg {
        display: block;
        overflow: hidden;
        position: relative;
        height: 164px;

        &__inner {
            position: absolute;
            top: 0;
            left: 50%;
            width: 360px;
            transform: translateX(-50%);
        }
        &--lower {
            height: 135px;

            & + .c-linkimg {
                margin-top: 10px;
            }
        }
        .c-lazyload {
            img {
                width: 100%;
                object-fit: cover;
            }
            &:before {
                background-color: transparent;
            }
        }
    }
    .c-card-item {
        &-content {
            display: flex;
            overflow: hidden;
            position: relative;
            margin: -40px 16px 6px;
            border-radius: 4px;
            background: $color-gray-14;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.06), 0 0 1px 0 rgba(0, 0, 0, 0.28);
            flex-wrap: wrap;
        }
        &-list {
            @media screen and (min-width: 800px) {
                width: 25%;
            }

            position: relative;
            width: 50%;
            margin-bottom: -1px;

            &:before,
            &:after {
                position: absolute;
                z-index: 1;
                background: $color-gray-12;
                content: "";
            }
            &:before {
                left: 0;
                right: 0;
                bottom: 0;
                height: 1px;
            }
            &:after {
                top: 0;
                right: -1px;
                width: 1px;
                height: 500%;
            }
        }
        &--gallery {
            padding: 12px;
        }
        &__info {
            min-height: 154px;
            padding-bottom: 0;
        }
        &__rank {
            width: auto;
            min-width: 38px;
            padding: 0 6px;
            border-radius: 0;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 38px;
            margin-bottom: 4px;
            color: $color-gray-03;
            line-height: 19px;
        }
        &__benefit {
            height: auto;
            font-size: 14px;
            color: $color-gray-04;

            // MPQA-69969 배송정보 무조건 한줄노출 스펙으로 추가함
            .benefit {
                overflow: hidden;
                height: 17px;
                line-height: 17px;
            }
        }
        // TODO: MPSR-130947 배포 후 button 선택자 제거 Start
        &__lowest-info {
            &[type="button"]:after {
                margin-left: 4px;
            }
        }
        // MPSR-130947 배포 후 button 선택자 제거 End
        &__cart {
            margin-top: 12px;
        }
    }

    .sktpass {
        font-size: 14px;
    }
    .shooting-delivery span:before {
        display: none;
    }
}
