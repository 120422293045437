@import "../../common/helpers/variables";
@import "../../skpui/sprites/sp_components";

[data-type="GridScroll_ImgText_CtgrNavi2"] {
    position: relative;
    background-color: transparent;

    .l-grid__col--12 {
        position: relative;
    }

    .c-magnet--fixed & {
        width: 100%;
        z-index: 1;
    }

    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 100;
        }
        &--absolute {
            position: absolute;
        }
    }

    .b-category-navi {
        position: relative;
        overflow: hidden;
        background-color: #fff;
        z-index: 100;

        // PUIMW-412 삭제예정 시작
        .w-scroll {
            overflow: visible;

            &:after {
                display: block;
                position: relative;
                z-index: 3;
                width: 100%;
                height: 1px;
                background-color: $color-gray-11;
                content: "";
            }
        }
        // PUIMW-412 삭제예정 끝
        .w-scroll-css {
            position: relative;
            border-bottom: 1px solid $color-gray-11;
            padding-bottom: 1px;
        }
    }

    .w-scroll-css::-webkit-scrollbar {
        display: none;
        -webkit-appearance: none;
    }

    // skpui.magnet 기능 사용시
    .c-magnet--fixed {
        &.is--active {
            .w-magnet {
                width: 100%;

                // PUIMW-412 삭제예정 시작
                .w-scroll:after {
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: $color-gray-12;
                    z-index: 4;
                    content: "";
                }
                // PUIMW-412 삭제예정 끝
            }
        }
    }

    .c-listimg {
        &__item {
            width: 20%;

            @include mq-640 {
                width: 12.5%;

                &:nth-child(4n + 1) {
                    clear: none;
                }
            }
            .c-listimg__link {
                padding: 9px 0 10px;
                overflow: hidden;
                border: 0;

                .c-listimg__icon-wrap {
                    width: auto;
                    height: 36px;

                    .c-lazyload {
                        height: 100%;
                        padding-bottom: initial;

                        &:before {
                            background: none;
                        }
                    }

                    .c-listimg__icon {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .c-listimg__name {
                    font-size: 11px;
                    line-height: 14px;
                }
            }

            &.on {
                .c-listimg__link {
                    color: $color-11st-red;
                    background: none;
                }
            }

            &:nth-child(4n + 1) {
                clear: none;
            }
        }
    }

    .c-button-bottom {
        display: none;
        position: relative;
        height: 44px;
        border-bottom: 1px solid $color-gray-11;

        button {
            position: absolute;
            top: 0;
            right: 0;
            width: 60px;
            height: 44px;
            padding-right: 33px;
            border: 0;
            background: transparent;
            font-size: 14px;
            color: $color-gray-03;
            outline: none;

            &:before {
                @include size(8px);
                @include rotate(225deg);
                position: absolute;
                top: 50%;
                right: 19px;
                margin-top: -1px;
                border: 1px solid $color-gray-07;
                border-width: 0 1px 1px 0;
                content: "";
            }

            &[aria-expanded="true"] {
                &:after {
                    display: block;
                    content: "접기";
                }
            }
        }
    }

    .close {
        // PUIMW-412 삭제예정 시작
        .w-scroll {
            overflow: hidden;
        }
        // PUIMW-412 삭제예정 끝

        .c-listimg {
            display: table;
            padding-right: 40px;
            white-space: nowrap;

            &__item {
                display: table-cell;
                float: none;
                width: initial;

                .c-listimg__link {
                    min-width: 74px;
                }

                &:nth-child(4n + 1) {
                    clear: none;
                }

                &.on {
                    .c-listimg__link {
                        background: none;
                    }
                }
            }

            &--normal {
                padding-right: 0;
            }
        }

        .c-button-bottom {
            display: block;
            position: initial;
            height: 0;
            border: 0;

            button {
                @include size(36px 69px);
                position: absolute;
                top: 0;
                right: 0;
                background: #fff;
                border: 0;
                color: transparent;
                border-left: 1px solid $color-gray-12;
                outline: none;

                &:before {
                    @include size(8px);
                    @include rotate(45deg);
                    position: absolute;
                    left: 50%;
                    margin: -6px 0 0 -4px;
                    border: 1px solid #333;
                    border-width: 0 1px 1px 0;
                    content: "";
                }

                &:after {
                    @include size(14px 66px);
                    position: absolute;
                    top: 0;
                    left: -15px;
                    background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, $color-gray-14 100%);
                    content: "";
                }
            }
        }
    }

    .opened {
        .w-scroll-css {
            overflow: hidden;
        }
        .c-button-bottom {
            display: block;
        }
    }

    .c-nav--sub {
        border-bottom-color: rgba(0, 0, 0, 0.1);

        .c-nav__wrap {
            background: $color-gray-14;
        }
        .c-nav__list {
            overflow: hidden;
            padding: 0 8px;
        }
        .c-nav__item {
            font-size: 14px;

            a {
                padding: 0 8px;
                color: $color-gray-04;
                line-height: 55px;
            }

            &.on {
                a {
                    margin: 0 -2px;
                    font-weight: bold;
                    color: $color-11st-red;

                    span {
                        border-color: rgba($color: $color-11st-red, $alpha: 0.2);
                        background: rgba($color: $color-11st-red, $alpha: 0.02);
                    }
                }
            }
        }
    }

    .c-slide-list {
        background: $color-gray-13;

        .c-list {
            @include clearfix;
            padding: 11px 8px 0 16px;
            background: none;

            &__item {
                float: left;
                width: 33.3%;
            }

            &__item--selected {
                .c-list__link {
                    color: $color-11st-red;
                }
            }

            &__link {
                display: block;
                overflow: hidden;
                height: 31px;
                font-size: 13px;
                color: $color-gray-03;
                line-height: 31px;

                // A11Y-1726 삭제예정
                &[aria-current="true"] {
                    color: $color-11st-red;
                }
                // //A11Y-1726 삭제예정
            }
        }
    }

    .c-pagination {
        margin-top: -6px;

        &__indicator {
            span.on {
                background: $color-11st-red;
            }
        }
    }
    &#blckSn-7715 {
        margin-top: 0 !important;
    }
}
