[data-type="ProductGrid_ImgOverText_Col3"] {
    padding: 0 16px 20px 16px;
    .l-grid__col--4 {
        padding: 1px;
    }
    .c-card__onlyprice {
        border: 0;
        .c-card__info {
            &:before {
                height: 200%;
                background: linear-gradient(180deg, transparent 30%, rgba(0, 0, 0, .28) 80%, rgba(0, 0, 0, .39));
            }
            .c-card__price {
                font-size: 14px;
                width: 100%;
                @include text-ellipsis();
            }
            &--notext {
                &:before {
                    display: none;
                }
            }
        }
    }
}
