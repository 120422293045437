[data-type="GridScroll_ImgTextCircle_Roadshop"] {
    // MPINNER-11609 삭제예정 시작
    .w-scroll--css {
        padding: 8px 0;
        .s-list {
            padding: 4px;
        }
    }
    // MPINNER-11609 삭제예정 끝
    .s-list {
        padding: 12px;
    }
    .c-icontext {
        display: block;
        width: 68px;
        &__icon {
            display: block;
            width: 52px;
            height: 52px;
            margin: 0 auto;
            border-radius: 26px;
            overflow: hidden;

            &:after {
                display: none;
            }
            img {
                display: block;
                width: 100%;
                border-radius: 100%;
            }
        }
        &__text {
            display: block;
            margin-top: 9px;
            padding-top: 0;
            font-size: 14px;
            font-weight: normal;
            line-height: 18px;
            color: #262626;
            text-align: center;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &--selected {
            .c-icontext__text {
                color: #19b275;
            }
        }
    }
}
