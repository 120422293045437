@import "../../pui/sprites/sp_mart";

[data-type="ProductGrid_ImageBox"] {
    padding: 0 16px 24px;
    letter-spacing: $body-spacing;

    .c-content-box {
        @include rounding-box;
        @include clearfix;
        overflow: hidden;

        li {
            float: left;
            position: relative;
            width: 50%;
            margin-bottom: -1px;
            padding-top: 1px;
            border-bottom: 1px solid $color-gray-11;

            &:nth-child(odd) {
                &:after {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    width: 1px;
                    background: $color-gray-11;
                    content: "";
                }
            }
        }
    }
    .c-card-item__onlyprice {
        position: relative;
        padding: 10px;

        .c-card-item__rank {
            border-radius: 0;
        }
        .c-card-item__info {
            position: absolute;
            left: 0;
            bottom: 0;
            padding: 10px;
            width: 100%;
            background: transparent;
        }
        .c-card-item__price {
            height: 26px;
            padding: 0 8px;
            border-radius: 4px;
            background: rgba($color: $color-gray-14, $alpha: 0.6);
            font-size: 13px;
            color: $color-gray-02;
            line-height: 28px;

            strong {
                font-size: 15px;
            }
        }
        .c-card-item__button {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        .c-cart-button {
            @include size(34px);
            border: 0;
            border-top-left-radius: 4px;
            background: rgba($color: $color-gray-14, $alpha: 0.9);
            box-shadow: -1px -1px 6px 0 rgba(0, 0, 0, 0.1);

            &:after {
                @include sprite-retina($sp_mart_cart);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }
        .c-starrate {
            display: inline-block;
            margin: 0;
            padding: 6px 6px 5px;
            border-radius: 4px;
            background: rgba($color: $color-gray-14, $alpha: 0.6);
            vertical-align: top;

            .c-starrate__review {
                color: $color-gray-04;
            }
        }
    }
    .w-swiper {
        margin-bottom: -24px;

        .c-pagination {
            &__indicator {
                line-height: 51px;
            }
        }
    }
}
