[data-type="ProductCard_NewDealBox"] {
    @include grid(0, 0, false);
    letter-spacing: 0;

    .c-headline {
        height: 88px;
        margin-bottom: -30px;
        background: linear-gradient(to right, $color-11st-gradient-orange, $color-11st-red);

        &__title {
            color: $color-gray-14;
            text-align: center;

            span {
                margin-left: 0;
                padding: 0;
                font-size: 17px;
                font-weight: bold;
                line-height: 1;
                em {
                    display: inline-block;
                }
            }
        }

        &--amazon {
            background: linear-gradient(90deg, rgba(132, 215, 226, 1) 0%, rgba(165, 230, 204, 1) 70%, rgba(166, 231, 206, 1) 100%);

            .c-headline__title {
                color: $color-gray-02;
            }
        }
    }

    .c-card-item {
        @include rounding-box;
        display: flex;
        flex-wrap: wrap;
        position: initial;
        overflow: initial;
        padding: 16px;
        height: 100%;
        background-color: $color-gray-14;

        &__anchor {
            right: 16px;
            left: 16px;
        }
        &__thumb {
            @include size(124px);
            flex-shrink: 0;

            @media screen and (max-width: 359px) {
                // 작은 해상도에서 수량선택 숫자가 한자리만 보이는 현상 해결
                @include size(100px);
            }
        }
        &__info {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            align-content: space-between;
            height: 100%;
            margin-left: 14px;
            padding: 0;
            background-color: transparent;

            .c-nation {
                dd {
                    padding-top: 2px;
                }
            }
            .c-flag-box {
                margin-bottom: 2px;
            }
            .c-card-item {
                &__name {
                    height: auto;
                    max-height: 40px;
                    margin-bottom: 6px;
                    font-size: $font-size-subtitle1;
                    color: $color-gray-03;
                    line-height: 20px;
                    -webkit-line-clamp: initial;
                }
                &__rate {
                    strong {
                        font-weight: normal;
                        font-size: $font-size-subtitle1;
                        &.c-card-item__special {
                            font-size: $font-size-caption1;
                        }
                    }
                }
                &__price {
                    font-size: $font-size-caption1;
                    line-height: 20px;

                    del {
                        padding-left: 0;
                        font-size: $font-size-body2;
                        color: $color-gray-08;
                    }
                    strong {
                        font-size: $font-size-subtitle1;
                    }
                    &--origin {
                        color: $color-gray-08;
                        line-height: 20px;
                    }
                }
                &__deal-guide {
                    margin-top: 0;
                    font-size: $font-size-body2;
                    color: $color-gray-04;
                    font-weight: normal;
                }
                &__benefit {
                    font-size: 14px;
                    color: $color-gray-04;
                    line-height: 19px;

                    .benefit {
                        margin-top: 1px;
                        padding-top: 0;
                    }
                }
            }
            .c-starrate {
                margin-bottom: 7px;
            }
            .sktpass {
                overflow: hidden;
                height: 20px;
                margin-top: 1px;
                font-size: 14px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        &__benefit {
            margin-top: 3px;
        }

        &__cart {
            z-index: 2;
            width: 100%;
            margin: 9px 0 5px;
        }
        &__discount {
            overflow: hidden;
            display: flex;
            align-items: center;
            color: $color-gray-01;
            font-size: 12px;
            line-height: 18px;

            strong {
                font-size: 14px;
            }

            &--point {
                color: $color-11st-red;
            }
        }
        &__discount-title {
            margin-right: 4px;
            font-size: 13px;
        }
    }

    .w-swiper {
        padding-bottom: 27px;

        .swiper-slide {
            height: auto;
            padding: 0 16px;
        }

        .c-pagination {
            margin-bottom: -24px;

            &__indicator {
                line-height: 38px;
            }
        }
    }
}
