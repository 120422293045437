[data-type="ProductScroll_Trip_All"] {
    @include grid(1, 0, false);
    .l-grid__col {
        padding-top: 0;
        .s-list {
            padding-top: 0;
            li {
                padding-top: 0;
            }
        }
    }
    .swiper-slide {
        display: flex;
    }
    .c-nav {
        border-bottom: 0;
        &__wrap {
            background-color: #fff;
            .c-nav__list {
                overflow: hidden;
                max-width: initial;
                .c-nav__item {
                    position: relative;
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        right: -1px;
                        top: 19px;
                        width: 4px;
                        height: 4px;
                        background-color: rgba(0, 0, 0, 0.2);
                        border-radius: 2px;
                    }
                    a {
                        margin: 0 auto;
                        padding: 2px 22px;
                        font-size: 13px;
                        span {
                            display: inline-table;
                            vertical-align: middle;
                        }
                    }
                    &.on {
                        a {
                            span {
                                border: 0;
                                color: #111;
                                font-size: 17px;
                                font-weight: bold;
                            }
                        }
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    .c-tourcard {
        @include size(100% 286px);
        overflow: hidden;
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
        margin-bottom: 4px;
        &__region {
            position: relative;
            height: 128px;
            background-size: 100% 128px;
            background-repeat: no-repeat;
        }
        &__title {
            position: absolute;
            bottom: 0;
            font-size: 28px;
            color: #111;
            padding: 0 16px;
            display: -webkit-box;
            height: 58px;
            max-height: 58px;
            line-height: 30px;
            width: 100%;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        &__category {
            padding-top: 12px;
            display: flex;
            li {
                flex-grow: 1;
            }
        }
        &__product {
            display: block;
            color: #333;
        }
        &__name {
            display: block;
            width: 74px;
            padding: 8px 0;
            margin: 0 auto;
            text-align: center;
            font-size: 12px;
            &-title {
                display: block;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 74px;
                height: 15px;
            }
            &-cate {
                display: block;
                height: 15px;
            }
        }
        &__icon {
            @include size(56px);
            display: block;
            margin: 0 auto;
            img {
                @include size(56px);
                display: block;
            }
        }
        &__price {
            display: block;
            width: 74px;
            margin: 0 auto;
            font-weight: bold;
            font-family: Roboto;
            font-size: 12px;
            text-align: center;
            color: #111;
        }
    }
}
