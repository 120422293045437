[data-type="ProductGrid_Global"] {
    @include grid(2, 2, true);
    .c-card {
        &.c-card__gallery {
            .c-card__thumb {
                @include size(initial);
                margin: 0 auto;
                @media screen and (min-width: 360px) and (max-width: 374px) {
                    & {
                        @include size(initial);
                    }
                }

                @media screen and (min-width: 375px) and (max-width: 413px) {
                    & {
                        @include size(initial);
                    }
                }

                @media screen and (min-width: 414px) and (max-width: 459px) {
                    & {
                        @include size(initial);
                    }
                }

                @media screen and (min-width: 460px) and (max-width: 479px) {
                    & {
                        @include size(initial);
                    }
                }

                @media screen and (min-width: 480px) and (max-width: 639px) {
                    & {
                        @include size(initial);
                    }
                }
            }
            .c-card__info {
                padding: 8px 0 0;
                height: 178px;
                .c-card__price {
                    min-height: initial;
                    vertical-align: middle;
                    del {
                        display: none;
                    }
                }
                .c-card__benefit {
                    strong {
                        color: #999;
                    }
                    @import "../../skpui/component/benefit_gallery";
                }
            }
        }
    }
}
