@import "../../skpui/component/c-card-deal__info";
@import "../../skpui/component/c-deal-cap";
[data-type="ProductDeal_Shocking"] {
    [data-type="GridScroll_ImgText_CtgrNavi"] + & {
        margin-top: 8px;
    }
    letter-spacing: 0;
    @include grid(0, 0, false);
    .l-grid__col:after {
        border-color: #d1d1d6;
    }
    &.l-grid__row {
        background-color: $color-gray-11;
        .l-grid__col {
            padding: 0;
            background-color: #fff;
        }
    }
    .l-grid__col + .l-grid__col {
        margin-top: 8px;
    }
    .c-deal-cap {
        @include c-deal-cap--date;
        padding: 10px;
        border-bottom: 1px solid #e4e4e4;
        text-align: center;
    }
    .c-card__info {
        @include c-card-deal__info;

        .c-card__benefit {
            overflow: hidden;
            height: 20px;
            margin-right: 70px;
            margin-top: 8px;
            padding-top: initial;
            font-size: 0;

            .benefit {
                display: inline-block;
                margin-right: 4px;
                padding: 1px 5px;
                border: 1px solid #eee;
                font-size: 11px;
                line-height: 16px;
                color: #666;
                white-space: nowrap;
                vertical-align: top;

                .icon_11pay {
                    @include sprite-retina($sp_icon_11pay);

                    display: inline-block;
                    vertical-align: top;
                }
            }

            .open__time {
                padding-top: 4px;
                font-size: 12px;
                color: $color-11st-red;
            }
        }
        .c-card__qty {
            bottom: 16px;
            font-size: 12px;
            color: #a9a9a9;

            &--urgent {
                color: $color-11st-red;
            }
            &.red {
                color: $color-11st-red;
            }
            &.gray {
                color: #999999;
            }
            &.blue {
                color: #2cb1f8;
            }
        }
        .c-card__reserv strong {
            font-size: 16px;
            color: $color-11st-red;
        }

        .c-card__private {
            margin-top: 10px;
            font-size: 12px;
            color: #a9a9a9;
        }
    }
    .c-card__comming-soon {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 260px;
        height: 68px;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);
        p {
            padding: 15px;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
        }
    }
    .c-card__overlay__text {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        color: transparent;
        background: hsla(0, 0%, 7%, 0.4);
        z-index: 2;
        span {
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            margin-top: -15px;
            color: #fff;
            font-weight: 700;
            font-size: 26px;
            line-height: 26px;
            text-align: center;
            letter-spacing: 1px;
            z-index: 3;
        }
    }
    .c-card__mov__button {
        top: 50%;
        height: auto;
        margin-top: -54px;
    }
    .c-addition {
        &-coupon__text {
            flex: 1;

            &:after {
                display: none;
            }
        }
        &-link--double {
            .c-addition-coupon__text:after {
                display: block;
            }
        }
    }
    & + [data-type="ProductDeal_Shocking"] {
        margin-top: 8px;
    }
    .c-addition {
        &-tag {
            &__list {
                overflow: hidden;
                height: 20px;
                line-height: 20px;
            }

            &__item {
                display: inline-block;
                max-width: 100%;
                margin-right: 5px;
                padding: 0 3px;
                background-color: #eee;
                line-height: 16px;
                white-space: nowrap;
                vertical-align: middle;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

@keyframes urgent {
    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }
    35%,
    65% {
        opacity: 1;
    }
}
