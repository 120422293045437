[data-type="Cap_Box_Btn"] {
    letter-spacing: $body-spacing;

    .c-headline {
        &__title {
            display: flex;
            padding-right: 56px;
            align-items: center;
        }
    }
}
