[data-type="FlatButton_ImgText_Profile"] {
    letter-spacing: 0;
    .c-head__intro {
        height: 45px;
        padding: 13px 14px;
        font-size: 15px;
        color: $color-gray-03;
        border-bottom: 1px solid $color-gray-11;
    }
    .c-headline {
        padding: 15px 104px 14px 16px;
        &__text {
            display: flex;
            min-height: 40px;
            padding-left: 50px;
            flex-direction: column;
            justify-content: center;
        }
        &__title {
            @include text-ellipsis;
            height: auto;
            margin: 0;
            padding: 0;
            font-size: 17px;
            color: $color-gray-02;
            line-height: normal;
            span {
                color: $color-11st-red;
            }
        }
        &__subtext {
            @include text-ellipsis;
            font-size: 13px;
            color: $color-gray-04;
            margin: 0;
            padding: 0;
            + .c-headline__title {
                margin: 6px 0 0;
            }
        }
        .c-button {
            position: absolute;
            right: 18px;
            top: 22px;
            height: 40px;
            margin: 0;
            padding: 0 16px;
            color: $color-11st-red;
            border: 1px solid $color-11st-red;
            border-radius: 20px;
            line-height: 40px;
            font-size: 15px;
            &:before {
                @include sprite-retina($sp_eui_ico_editor_apply);
                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
                content: "";
            }
            &__mypage {
                top: 50%;
                height: 34px;
                padding: 0 13px;
                font-size: 13px;
                color: $color-11st-blue;
                border: 1px solid $color-11st-blue;
                border-radius: 17px;
                line-height: 34px;
                transform: translateY(-50%);
                &:before {
                    display: none;
                }
            }
        }
        .c-profile {
            &__pic {
                @include size(40px);
                float: none;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                margin: 0;
                span {
                    @include size(40px);
                }
                &:after {
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    z-index: 2;
                }
            }
        }
    }
}
