[data-type="ExhibitionBannerG_Scroll"] {
    letter-spacing: $body-spacing;
    background-color: $color-gray-11;

    .l-grid__col--12 {
        margin-bottom: 10px;
        padding-bottom: 1px;
        background-color: $color-gray-14;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .c-headline {
        height: 88px;
        padding: 23px 16px;

        &__link {
            display: flex;
            justify-content: space-between;

            &:after {
                @include ico-arrow-right(8px, $color-gray-14);
                position: absolute;
                top: 8px;
                right: 2px;
            }
        }
        &__title {
            padding: 0 14px 0 0;
            line-height: 20px;
            height: 26px;
            color: $color-gray-14;
            text-overflow: ellipsis;
            flex: 1;
        }
        &__text {
            overflow: hidden;
            padding-right: 12px;
            height: 17px;
            color: $color-gray-14;
            font-size: $font-size-body1;
            text-align: right;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    //PUIMW-412 삭제예정 시작
    .w-scroll {
        margin-top: -33px;
    }
    //PUIMW-412 삭제예정 끝
    .w-scroll-css {
        margin-top: -33px;
    }
    .s-list {
        height: 100%;
        padding-bottom: 20px;
    }
    .c-card-item {
        &__name {
            display: block;
            height: 40px;
            margin-bottom: 6px;
        }
        &__cart {
            width: 100%;
            height: 35px;
            border-top: 1px solid $color-gray-11;
        }
        &__thumb {
            background-color: $color-gray-14;
        }
    }
    .c-card-item--box {
        height: 100%;
        background: none;
        border-color: rgba(0, 0, 0, 0.06);
    }
    .c-card-item {
        &__link {
            display: block;
        }
    }
    .c-card-item--space {
        .c-card-item {
            &__link {
                height: calc(100% - 35px);
            }
            &__info {
                padding-bottom: 15px;
            }
            &__name {
                height: auto;
                max-height: 42px;
            }
            &__benefit {
                padding-top: 8px;
                font-size: 14px;
            }
        }
        .c-starrate {
            margin-top: 6px;
        }
    }

    // 수량 + 장바구니 버튼
    .w-prd__count .c-prd__count {
        button {
            width: 34px;
            height: 34px;
            border: 0;
        }
        &--save {
            width: 40px;
            height: 34px;
            border: 0;
            border-left: 1px solid $color-gray-11;
            border-radius: 0;

            &:before {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &--qty {
            height: 34px;
            border: 0;
            line-height: 36px;
        }
        &--remove {
            left: 7px;
        }
        &--add {
            right: 7px;
        }
    }
}
