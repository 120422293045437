@import "../../pui/sprites/sp_kkuk";
@import "../../pui/sprites/sp_kkuk_foot";

[data-type="Contents_ReviewCard_Basic"] {
    .l-grid__col {
        background-color: #eee;
    }
    .c-profile {
        &.tooltip {
            & + .c-tooltip {
                display: block;
            }
        }
    }
    .c-tooltip {
        position: absolute;
        left: 16px;
        top: 58px;
        display: none;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        font-size: 12px;
        background-color: $color-gray-14;
        border-radius: 2px;
        box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.4);
        z-index: 1;
        strong {
            color: $color-11st-blue;
        }
        &:before {
            content: "";
            display: block;
            position: absolute;
            left: 16px;
            top: -5px;
            width: 6px;
            height: 8px;
            border: solid #e8e8e8;
            border-width: 1px 1px 0 0;
            background-color: $color-gray-14;
            transform: rotate(314deg);
            z-index: 1;
        }
    }
    .c-card-review__product {
        .c-card__title {
            white-space: initial;
        }
    }
    .c-card-review__media {
        position: relative;
        .c-card--video {
            display: block;
        }
        video {
            display: block;
            width: 100%;
            height: inherit;
        }
        .c-card__play {
            @include sprite-retina($sp_eui_play_b);
            position: absolute;
            left: 50%;
            top: 50%;
            text-indent: -99999px;
            margin: -30px;
            z-index: 1;
            &--replay {
                @include sprite-retina($sp_eui_replay);
            }
        }
    }
    .c-card-review__text {
        position: relative;
        padding: 12px 16px;
        font-size: $font-size-subtitle2;
        color: $color-gray-03;

        p {
            line-height: 1.5;
            max-height: 66px;
            overflow: hidden;
        }
        button {
            display: block;
            position: absolute;
            right: 16px;
            bottom: 8px;
            margin: 0;
            border: 0;
            outline: none;
            padding-left: 50px;
            background-color: transparent;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
            span {
                position: relative;
                display: block;
                padding: 4px 18px 4px 0;
                background-color: $color-gray-14;
                color: $color-11st-blue;
                font-size: $font-size-subtitle2;
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: 8px;
                    height: 8px;
                    margin-top: -4px;
                    border-top: 1px solid $color-11st-blue;
                    border-left: 1px solid $color-11st-blue;
                    transform: rotate(-135deg);
                    transform-origin: 30% 30%;
                    content: "";
                }
            }
        }

        &.expanded {
            p {
                height: initial;
                max-height: initial;
            }
            button {
                display: none;
            }
        }
    }
    .c-card-review__intro {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 254px;
        height: 99px;
        text-indent: -99999px;
        z-index: 29;
        transform: translate(-50%, -50%);
        background: url("/MW/img/kkuk/img_guide.png") no-repeat 0 0;
        background-size: 254px 99px;
    }
    .c-card-review__animation {
        position: absolute;
        width: 360px;
        height: 360px;
        /* media query 320 */
        z-index: 99;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        @include mq-320 {
            width: 320px;
            height: 320px;
        }
        @include mq-360 {
            width: 360px;
            height: 360px;
        }
        @include mq-375 {
            width: 375px;
            height: 375px;
        }
        @include mq-414 {
            width: 414px;
            height: 414px;
        }
        @include mq-480 {
            width: 480px;
            height: 480px;
        }
    }
    .c-feedback {
        position: relative;
        background-color: transparent;
        margin: 0;
        border: 0;
        height: 36px;
        line-height: 41px;
        font-size: $font-size-body2;
        color: $color-gray-03;
        padding-left: 38px;
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        &--thumb {
            &:before {
                background-image: url("//c.m.011st.com/MW/img/pui/sprites/kkuk_foot/kukkuk_off.svg");
                height: 184px;
                width: 65px;
                background-position: -15px -145px;
                background-size: 65px 184px;
                content: "";
                display: block;
                position: absolute;
                left: 7px;
                top: 5px;
            }
        }
        &.on {
            &.c-feedback--thumb {
                &:before {
                    background-image: url("//c.m.011st.com/MW/img/pui/sprites/kkuk_foot/kukkuk_on.svg");
                    height: 184px;
                    width: 65px;
                    background-position: -15px -145px;
                    background-size: 65px 184px;
                }
            }
        }
        &.animating {
            &.c-feedback--thumb {
                &:before {
                    background-image: none;
                }
            }
        }
        span {
            display: inline-block;
            position: relative;
            width: 26px;
            text-indent: -99999px;
            &:before {
                @include sprite-retina($sp_kkuk_kkuk);
                content: "";
                display: block;
                position: absolute;
                top: 7px;
            }
        }
        em {
            display: inline-block;
        }
    }
    article {
        margin-bottom: 10px;
        background-color: $color-gray-14;
        &:last-child {
            margin-bottom: 0;
        }
        header {
            position: relative;
            padding: 11px 16px 12px 16px;
            border-bottom: 1px solid #f4f4f4;
            z-index: initial;
            &.b-profile {
                .c-count-view {
                    right: 58px;
                }
                .c-button--share {
                    position: absolute;
                    top: 14px;
                    right: 16px;
                }
            }
        }
        footer {
            border-top: 1px solid $color-gray-12;
            padding: 8px;
            .kukkuk {
                position: absolute;
                z-index: 10;
                left: -8px;
                right: 0;
                width: 65px;
                height: 184px;
                bottom: -8px;
            }
            p {
                color: $color-gray-03;
                font-size: $font-size-body1;
                text-align: right;
                float: right;
                line-height: 39px;
                padding-right: 8px;
                letter-spacing: 0;
                span {
                    color: $color-11st-blue;
                }
                strong {
                    font-size: $font-size-subtitle2;
                    font-weight: bold;
                }
            }
        }
    }
}
