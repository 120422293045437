[data-type="ExhibitionBanner_Gift"] {
    .c-banner {
        position: relative;

        &__info {
            position: absolute;
            left: 10px;
            right: 20px;
            bottom: 20px;
            padding-right: 103px;
            color: $color-gray-14;
        }
        &__text,
        &__title {
            padding: 0 10px;
        }
        &__text {
            overflow: hidden;
            height: 22px;
            font-size: 14px;
            line-height: 24px;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        }
        &__title {
            height: 35px;
            margin: -1px 0 -5px;
            font-size: 21px;
            line-height: 35px;
            white-space: normal;
            text-overflow: initial;
            text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
        }
        &__button {
            position: absolute;
            bottom: 20px;
            right: 20px;
        }
        &__more {
            @include size(77px 28px);
            display: block;
            border-radius: 14px;
            background: rgba($color: $color-gray-01, $alpha: 0.4);
            font-size: 13px;
            color: $color-gray-14;
            line-height: 28px;
            text-align: center;

            &:after {
                @include ico-arrow-right(7px, $color-gray-14);
                display: inline-block;
                margin: -1px 0 1px 3px;
                vertical-align: middle;
                content: "";
            }
        }
        // MPSR-90957 배포 후 코드정리 필요 - &__more에 하단 선언문 옮겨놓기이이이이
        p.c-banner__more {
            position: absolute;
            bottom: 0;
            right: 0;
        }
        // //MPSR-90957 배포 후 코드정리 필요
    }
    .c-lazyload {
        &:before {
            background-color: transparent;
        }
        &:after {
            @include size(100%);
            position: absolute;
            top: 0;
            left: 0;
            background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 45%, rgba(0, 0, 0, 0.5));
            content: "";
        }
        img {
            width: 100%;
            object-fit: cover;
        }
    }
}
