[data-type="ProductCard_Deal_Scroll_Box"] {
    letter-spacing: $body-spacing;

    .c-headline {
        display: flex;
        height: 80px;
        padding: 19px 40px;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;

        &__text {
            overflow: hidden;
            flex: 1;
        }
        &__title {
            height: auto;
            padding: 0;
            font-size: 17px;
            color: $color-gray-03;
            line-height: 1.2;

            strong {
                display: inline-block;
                position: relative;
                overflow: hidden;
                max-width: 100%;
                position: relative;
                vertical-align: top;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:before {
                    position: absolute;
                    left: -4px;
                    right: -4px;
                    bottom: 0;
                    height: 12px;
                    background: rgba($color: $color-11st-blue, $alpha: 0.15);
                    content: "";
                }
            }
        }
        &__sub {
            overflow: hidden;
            margin-bottom: 4px;
            font-size: 14px;
            color: $color-gray-03;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
    .c-card {
        &-item {
            padding: 0 16px;
            margin-bottom: 24px;
            position: initial;
            overflow: initial;
            &__link {
                @include rounding-box;
            }
            &__thumb {
                .c-lazyload {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }
            }
            &__info {
                height: 216px;
                padding: 12px 16px 10px 16px;
                border-radius: 4px;

                .c-nation {
                    float: left;
                    margin-right: 8px;

                    dd {
                        padding-top: 2px;
                    }
                }
                .c-flag-box {
                    padding-top: 2px;

                    &:first-child {
                        margin-bottom: 5px;
                    }
                }
                .c-card-item {
                    &__name {
                        display: block;
                        margin-bottom: 32px;
                        font-size: $font-size-subtitle1;
                    }
                    &__name-addition {
                        overflow: hidden;
                        height: 19px;
                        margin: -29px 0 10px;
                        padding-bottom: 0;
                    }
                    &__price-info {
                        padding-bottom: 8px;
                    }
                    &__price {
                        margin-right: 4px;
                        font-size: $font-size-body2;
                        line-height: 18px;

                        strong {
                            font-size: $font-size-headline3;
                            vertical-align: bottom;
                        }
                        del {
                            padding-left: 0;
                            font-size: $font-size-body2;
                            color: $color-gray-08;
                        }
                        &--origin {
                            color: $color-gray-08;
                        }
                    }
                    &__rate {
                        strong {
                            font-weight: normal;
                            font-size: $font-size-headline3;
                            &.c-card-item__special {
                                font-size: 15px;
                                white-space: nowrap;
                            }
                        }
                    }
                    &__deal-guide {
                        margin-top: 0;
                        padding: 0 0 12px;
                        font-size: $font-size-body2;
                        color: $color-gray-04;
                        font-weight: normal;
                    }
                    &__delivery {
                        display: flex;
                        overflow: hidden;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 38px;
                        border-top: 1px solid $color-gray-12;
                        padding: 0 16px;
                        line-height: 38px;

                        dd {
                            display: inline-block;
                            font-size: $font-size-body2;
                            color: $color-gray-04;
                            & + dd {
                                &:before {
                                    @include size(2px);
                                    display: inline-block;
                                    margin: -1px 5px 1px;
                                    border-radius: 2px;
                                    background: $color-gray-10;
                                    vertical-align: middle;
                                    content: "";
                                }
                            }
                            &.time {
                                color: $color-11st-blue;
                            }
                        }
                        .sktpass {
                            &:before {
                                margin-right: 0;
                            }
                            span {
                                display: inline;
                            }
                        }
                    }
                }
                .c-starrate {
                    margin-top: 0;
                }
            }
            &.c-card-item--soldout {
                .c-card-item__thumb {
                    &::before {
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.45);
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
                .c-card-item__timedeal-text {
                    display: block;
                    position: absolute;
                    top: 54%;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 13px;
                    text-decoration: underline;
                    z-index: 2;
                }
            }
        }
    }
    .c-pagination {
        margin-top: -24px;
    }
}
