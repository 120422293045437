[data-type="ProductList_BestRanking_Review"] {
    letter-spacing: 0;
    @include grid(2, 2, true);
    &.l-grid__row {
        background-color: #fff;
    }
    .l-grid__col--12 {
        width: 100%;
        float: none;
        .c-cap {
            height: 24px;
            line-height: 24px;
            @include size(35px 112px);
            float: left;
            display: block;
            padding-top: 40px;
            &__rank {
                position: relative;
                display: block;
                left: initial;
                top: initial;
                line-height: initial;
                width: 100%;
                font-size: 28px;
                font-style: italic;
                &-rising {
                    top: -7px;
                    left: -2px;
                    line-height: 1;
                    &:before {
                        top: 3px;
                    }
                    &.new {
                        padding-left: 0;
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
        .b-card--recommand {
            .c-card.c-card__gallery {
                min-height: 112px;
                .c-card__thumb {
                    width: 112px !important;
                    height: 112px !important;
                    margin: 0;
                    float: left;
                }
                .c-prd-info {
                    padding-top: 0;
                    padding-left: 12px;
                    .c-prd-info__benefit {
                        margin-top: 5px;
                        height: initial;
                        .benefit {
                            display: block;
                        }
                    }
                }
            }
        }
    }
    [data-type="GridScroll_ImgText_CtgrNavi"]+& {
        margin-top: 8px;
    }
}


