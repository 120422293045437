[data-type="Cap_List_Sorting_Checkbox"] {
    position: relative;

    .c-list-header {
        font-size: 0;

        h1,
        h2,
        h3,
        h4 {
            height: 52px;
            padding: 0 16px;
            font-size: 14px;
            font-weight: normal;
            color: $color-gray-03;
            line-height: 52px;
        }
    }
    .c-checkbox {
        & + label {
            padding-left: 26px;
            font-size: 14px;
            line-height: 24px;
        }
        &-box {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
        }
    }
}
