[data-type="Contents_Tag_Outlet_Group"] {
    @include grid(0, 2, false);
    text-align: center;
    .l-grid__col {
        padding-top: 0;
        margin-bottom: 10px !important;
        height: 80px;
        overflow: hidden;
    }
    .c-tag {
        padding: 0 12px;
        background-color: #fff;
        border-color: #e4e4e4;
        color: #333;
        &.active {
            border-color: #c29254;
            background-color: #c29254;
            color: #fff;
        }
    }
}
