@import "../../common/partial/starrate";
@import "../../common/partial/count_btn";
@import "../../common/partial/review_event";
@import "../../eui/component/nation";
@import "../../eui/component/time_flag";
@import "../../main/sprites/sp_flags";
@import "../../skpui/sprites/sp_review";
@import "../../common/sprites/sp_soldout";
@import "../../svg/sp_live11";
@import "../../svg/sp_common";

.c-card-item {
    overflow: hidden;
    display: block;
    position: relative;
    background: $color-gray-14;

    @include thumbnail-playicon;
    @include thumbnail-playicon-large;
    @include thumbnail-playicon-youtube;
    @include thumbnail-playtime;
    @include thumbnail-replayicon;

    &__thumb {
        display: block;
        position: relative;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        .c-card-item__play {
            position: absolute;
            width: 60px;
            top: 50%;
            left: 50%;
            z-index: 10;
            transform: translate(-50%, -50%);
            text-align: center;
            color: $color-gray-14;
            font-weight: bold;
            margin-top: -10px;
            padding-top: 64px;
            font-size: 14px;
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.45);
            line-height: 1;
            &::before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                @include get-sprite-live11("btn-play");
            }
        }
    }

    &__thumb-emblem {
        @include size(34px);
        position: absolute;
        top: 6px;
        left: 6px;
        z-index: 1;

        .c-lazyload {
            &:before {
                background-size: 100% 100%;
            }
        }
    }

    &__swatch {
        margin-top: 12px;
        font-size: 0;
        text-align: center;

        .c-card-item__thumb {
            @include size(26px 26px);
            display: inline-block;
            margin-left: 5px;
            overflow: hidden;
            position: relative;
            border: 1px solid $color-gray-10;
            border-radius: 100%;

            &:first-child {
                margin-left: 0;
            }
            img {
                border-radius: 100%;
            }
        }
        .c-card-item__swatch-item,
        .c-card-item__swatch-number {
            display: inline-block;
        }
        .c-card-item__swatch-number {
            margin-left: 5px;
            font-size: 12px;
            color: $color-gray-07;
            line-height: 26px;
            vertical-align: top;
        }
        .c-lazyload {
            &:before {
                background-size: auto 14px;
            }
        }
    }

    &__info {
        display: block;
        position: relative;
        padding: 8px 16px;
        background: $color-gray-14;
        letter-spacing: $body-spacing;

        dt {
            @include sr-only;
        }
        .c-card-item__info-text {
            margin-top: 5px;
            font-size: 13px;
            color: $color-gray-07;
        }
        .c-starrate {
            margin-top: 7px;
        }
        .c-prd-info__meta {
            margin-top: 7px;

            dt {
                @include sr-only-reset;

                font-size: 12px;
                line-height: 16px;
                vertical-align: top;
            }
        }
        .c-card-item__point {
            color: $color-11st-red;
        }
        .sktpass {
            font-size: 13px;
            color: $color-gray-04;

            &:before {
                @include get-sprite-common("logo_sktpass", 56);
                margin: -1px 4px 1px -1px;
                vertical-align: middle;
                content: "";
            }
            em,
            .c-card-item__point {
                font-weight: normal;
                color: $color-11st-blue;
            }
            span {
                display: inline-block;
            }
        }
    }

    &__name {
        display: -webkit-box;
        height: 19px;
        max-height: 4.2em;
        overflow: hidden;
        clear: both;
        margin-bottom: 10px;
        font-size: 15px;
        color: $color-gray-02;
        line-height: 1.4em;
        word-break: break-all;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        &-addition {
            color: $color-11st-blue;
            font-size: $font-size-body1;
            padding-bottom: 10px;
            word-break: break-all;
        }
    }

    &__name-flag {
        color: $color-11st-blue;
    }

    &__rate {
        float: left;
        padding-right: 4px;
        font-size: 12px;
        line-height: 18px;
        color: $color-11st-red;

        strong {
            display: inline-block;
            font-size: 16px;
            font-weight: normal;
            line-height: 20px;
            vertical-align: bottom;
        }
        .c-card-item__special {
            font-size: 14px;
        }
    }

    &__price {
        @include clearfix;
        display: inline-block;
        position: relative;
        font-size: 12px;
        color: $color-gray-01;
        line-height: 18px;
        white-space: nowrap;
        vertical-align: top;

        dd {
            display: inline-block;
        }
        em {
            display: inline-block;
            margin-right: 4px;
            font-size: 16px;
            font-weight: bold;
            color: $color-11st-red;
            line-height: 20px;
        }
        strong {
            display: inline-block;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
        }
        del {
            display: inline-block;
            padding-left: 4px;
            font-size: 12px;
            color: $color-gray-07;
            line-height: 16px;
            white-space: nowrap;
        }
        &-detail {
            margin-top: 2px;
            font-size: 14px;
            color: $color-gray-07;
        }
        &--point {
            color: $color-11st-red;
        }
        .c-card-item__detail {
            display: block;
            font-size: 13px;
            color: $color-gray-04;
        }
        .c-card-item__special {
            @include sr-only-reset;

            float: left;
            margin-right: 3px;
            font-size: 14px;
            font-weight: bold;
            color: $color-11st-red;
            line-height: 20px;
        }
    }

    &__benefit {
        @include retail-delivery; // 슈팅배송
        @include retail-install; // 슈팅설치
        @include retail-fresh; // 슈팅신선
        @include retail-plus; // 슈팅배송+2
        @include retail-seller; // 슈팅셀러

        display: block;
        padding-top: 15px;
        font-size: 12px;
        color: $color-gray-07;
        box-sizing: border-box;

        dt + .benefit {
            padding-top: 0;
        }

        .benefit {
            padding-top: 2px;
            em {
                color: $color-11st-blue;
            }
        }

        .retail-delivery {
            padding-top: 2px;
        }
    }

    &__qty {
        position: absolute;
        bottom: 8px;
        right: 16px;
        font-size: 13px;
        color: $color-gray-07;
    }

    &__extra {
        letter-spacing: $body-spacing;

        dt {
            @include sr-only;
        }
    }

    &__relates {
        padding: 0 16px 8px;

        .c-addition {
            padding: 0 1px;
            background-color: #fff;
            font-size: 13px;
            color: #333;
            line-height: 19px;
            border-top: 1px solid rgba(0, 0, 0, 0.04);

            > div,
            > ul,
            > dl,
            > a {
                display: flex;
                position: relative;
                margin-top: 6px;
                padding: 8px 0 6px;
            }
        }
    }

    &__bottom-info {
        position: relative;
        height: 38px;
        padding: 0 16px;
        border-top: 1px solid $color-gray-12;
        font-size: 13px;
        color: $color-gray-04;
        line-height: 40px;

        em {
            color: $color-11st-blue;
        }
        .c-card-item__delivery {
            @include retail-delivery;

            .delivery,
            .retail-delivery,
            .shooting-delivery {
                display: inline-block;
                position: relative;

                & + .delivery {
                    margin-left: 3px;
                    padding-left: 6px;

                    &:before {
                        @include separators-dot(2px, 2px, $color-gray-10);
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -1px;
                        content: "";
                    }
                }
            }
            .shooting-delivery {
                font-size: 13px;

                span {
                    &:before {
                        margin: -1px 6px 1px;
                        background-color: $color-gray-10;
                    }
                }
            }
        }
        .c-card-item__qty {
            bottom: 0;
            color: $color-gray-04;
            line-height: 36px;
        }
        .c-card-item__soldout {
            color: $color-11st-red;
        }
    }

    &__counsel {
        display: block;
        padding: 14px 0;
        font-size: 13px;
        line-height: 17px;
        color: $color-gray-04;

        em {
            display: block;
            color: $color-11st-blue;
        }
    }

    &__fluid {
        margin-top: 8px;

        dd:not([class]) {
            font-size: 0;
            span {
                display: inline;
                vertical-align: middle;
            }
        }

        &-info {
            font-size: 17px;
            font-weight: bold;
            color: $color-gray-02;
            & > span {
                margin-right: 3px;
            }

            strong {
                font-size: 14px;
                font-weight: normal;

                span {
                    font-size: 20px;
                    font-weight: bold;
                }
            }
        }
        &-price {
            font-size: 13px;
            color: $color-gray-04;

            span {
                font-weight: normal;

                strong {
                    color: $color-11st-blue;
                    font-weight: normal;
                }
            }
        }
    }

    &__rank {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 24px;
        height: 24px;
        border-bottom-right-radius: 4px;
        background: rgba($color: $color-11st-red, $alpha: 0.8);
        font-size: 12px;
        color: #fff;
        line-height: 26px;
        text-align: center;
        content: "";

        &--light {
            border: 1px solid rgba($color: $color-11st-red, $alpha: 0.1);
            border-radius: 0;
            background: #fffbfb;
            color: $color-11st-red;
        }
    }

    &__more-text {
        padding-left: 50px;
        background-color: transparent;
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 40%);
        font-size: 14px;
        color: $color-11st-blue;
        line-height: 18px;
        &:after {
            display: inline-block;
            margin: -1px 4px 1px 2px;
            vertical-align: middle;
            @include ico-arrow-right(7px, $color-11st-blue);
        }
    }

    &__bargain {
        margin: 10px 0 1px;
        font-size: 14px;
        color: $color-gray-07;

        .c-iconinfo--help {
            z-index: 5;
            margin: -11px 0 -9px -5px;
        }
    }

    &__lowest {
        $lowest: &;
        // TODO: MPSR-130947 배포 후 button 선택자 제거 Start
        & + & {
            #{$lowest}-info {
                margin-top: 2px;
                &[type="button"] {
                    margin-top: 0;
                }
            }
        }
        &-info {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            position: relative;
            z-index: 2;
            min-height: 17px;
            font-size: 13px;
            color: $color-11st-red;

            &[type="button"] {
                display: inline-block;
                min-height: auto;
                border: 0;
                background: none;
                line-height: 18px;
                text-align: left;
                &:after {
                    @include sprite-retina($sp_eui_info);
                    display: inline-block;
                    margin: -2px 0 1px;
                    vertical-align: middle;
                    content: "";
                }
                #{$lowest}-text {
                    display: inline-block;
                    line-height: inherit;
                    & + #{$lowest}-value {
                        margin-left: 2px;
                    }
                }
                #{$lowest}-value {
                    display: inline-block;
                    margin-right: 2px;
                    line-height: inherit;
                }
            }
        }
        // MPSR-130947 배포 후 button 선택자 제거 End
        &-text {
            flex-shrink: 0;
            line-height: 16px;
            & + .c-card-item__lowest-value {
                margin-left: 4px;
            }
        }
        &-value {
            flex-shrink: 0;
            line-height: 16px;
            &:before {
                display: inline-block;
                margin-right: 2px;
                border-top: 5px solid $color-11st-red;
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                vertical-align: middle;
                content: "";
            }
        }
        .c-iconinfo--help {
            align-self: flex-start;
            flex-shrink: 0;
            width: 20px;
            height: 28px;
            margin: -6px 0 -6px -1px;
        }
    }

    &__store {
        margin-top: 10px;
        font-size: 14px;
        color: $color-gray-04;

        dd {
            display: flex;
            line-height: 19px;
            align-items: center;
        }
        .official {
            display: inline-block;
            height: 19px;
            margin-right: 2px;
            font-weight: normal;
            color: #ff7e0e;
            white-space: nowrap;

            &:before {
                @include sprite-retina($sp_eui_official);
                display: inline-block;
                margin: -1px 2px 0 0;
                vertical-align: middle;
                content: "";
            }
        }
        .store {
            display: inline-block;
            overflow: hidden;
            height: 19px;
        }
        .talk {
            @include sprite-retina($sp_eui_11talk);
            display: inline-block;
            margin: -1px 0 0 2px;
            color: transparent;
            vertical-align: middle;
            white-space: nowrap;
        }
    }

    &__mart {
        display: block;
        font-size: 13px;

        dt {
            @include sr-only;
        }
        &.c-card-item__mart-emart {
            color: $color-emart;
        }
        &.c-card-item__mart-hp {
            color: $color-homeplus;
        }
        &.c-card-item__mart-gsfresh {
            color: $color-gsfresh;
        }
    }

    &__advertise {
        font-size: 14px;
        color: $color-11st-green;
        line-height: 19px;

        dd {
            overflow: hidden;
            height: 19px;
            margin-top: 1px;
        }
    }

    &__anchor {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .c-like-button {
        position: absolute;
        width: 36px;
        height: 36px;
        border: 0;
        background: none;
        outline: none;

        @include zzim-product-svg(off);

        &[aria-pressed="true"],
        &.on {
            @include zzim-product-svg(on);
        }
    }

    // 220919 c-alarm-button 스타일 제거
    // 알림설정 ui개선 (최종 배포 이후 수정 필요 c-alarm2-button -> c-alarm-button)
    .c-alarm2-button {
        position: absolute;
        width: 28px;
        height: 28px;
        border: 1px solid $color-gray-10;
        border-radius: 50%;
        background: none;
        outline: none;

        &[aria-pressed="false"] {
            border-color: $color-11st-red;
        }
        &[aria-pressed="false"]:after {
            @include alarm-product(off);
        }
        &[aria-pressed="true"]:after {
            @include alarm-product(on);
        }
    }

    .c-addition-review {
        dt {
            @include sr-only-reset;
            @include size(40px);
            position: absolute;
            padding: 11px 0;
            border: 1px solid #e7e7e7;
            line-height: 16px;
        }
        dd {
            white-space: normal;
        }
    }

    .w-prd__count {
        padding: 0;

        .c-prd__count--save {
            right: 0;
        }
    }

    &.c-card-item--soldout {
        .c-card-item__soldout-text {
            @include size(100%);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            background: rgba(0, 0, 0, 0.45);
            color: transparent;

            &::before {
                @include sprite-retina($sp_soldout_soldout);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }

        .c-card-item__timedeal-text {
            display: block;
            position: absolute;
            top: 54%;
            width: 100%;
            text-align: center;
            color: #fff;
            font-size: 13px;
            text-decoration: underline;
            z-index: 2;
        }

        &.c-card-item--list {
            .c-card-item__soldout-text:before {
                font-size: 20px;
            }
        }
    }

    &-badge {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 0;
        z-index: 2;

        &__status {
            display: inline-block;
            padding: 3px 8px;
            background-color: #666;
            font-weight: normal;
            font-size: 13px;
            line-height: 18px;
            color: #fff;
            letter-spacing: 0;
            vertical-align: top;

            &--active {
                background-color: $color-11st-red;
            }
        }

        &__time {
            display: inline-block;
            padding: 3px 8px;
            background-color: #fafafa;
            font-weight: bold;
            font-size: 14px;
            line-height: 18px;
            color: #333;
            letter-spacing: 1px;
            vertical-align: top;
        }
    }

    &__badge-text {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
    }

    &__comming-soon {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 260px;
        height: 68px;
        margin: auto;
        background-color: rgba(255, 255, 255, 0.85);
        box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.3);

        p {
            padding: 15px;
            font-size: 13px;
            line-height: 19px;
            text-align: center;
        }
    }

    &__more {
        @include scroll-more(142px, 240px);

        &-inner {
            display: table-cell;
            font-size: 15px;
            color: $color-gray-03;
            line-height: 22px;
            vertical-align: middle;
            text-align: center;

            &:before {
                @include sprite-retina($sp_icons_more);
                display: block;
                margin: 0 auto 20px;
                content: "";
            }
        }
    }

    &__cart {
        position: relative;
        min-height: 32px;

        .c-card-item__amount {
            overflow: hidden;
            position: relative;
            margin-right: 40px;
            padding: 0 30px;
            border: 1px solid $color-gray-11;
            border-radius: 4px;

            &--visible {
                overflow: visible;
            }

            .c-card-item__quantity {
                display: block;
                overflow: hidden;
                height: 32px;
                height: 30px;
                border: 1px solid $color-gray-11;
                border-width: 0 1px;
                border-radius: 0;
                font-size: 16px;
                color: $color-gray-03;
                line-height: 32px;
                text-align: center;
            }

            button {
                overflow: hidden;
                position: absolute;
                top: 0;
                bottom: 0;
                width: 30px;
                border: 0;
                background: none;
                color: transparent;

                &:before,
                &:after {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    width: 24px;
                    height: 3px;
                    margin: -2px 0 0 -12px;
                    background-color: $color-gray-07;
                    transform: scale(0.5);
                    content: "";
                }
            }
            .c-card-item__remove {
                left: 0;

                &:after {
                    display: none;
                }
            }
            .c-card-item__add {
                right: 0;

                &:after {
                    transform: scale(0.5) rotate(90deg);
                }
            }
        }

        .c-card-item__save {
            @include size(32px);
            overflow: hidden;
            position: absolute;
            top: 0;
            right: 0;
            border: 1px solid $color-gray-11;
            border-radius: 4px;
            background-color: $color-gray-14;
            color: transparent;

            &:before {
                @include sprite-retina($sp_mart_cart);
                position: absolute;
                top: 50%;
                left: 50%;
                margin: 0 auto;
                transform: translate(-50%, -50%);
                content: "";
            }
        }
    }

    &__addition {
        .c-card-item__addition-link,
        .c-card-item__more {
            display: block;
            overflow: hidden;
            width: 100%;
            position: relative;
            height: 32px;
            padding-left: 12px;
            border: 1px solid $color-gray-11;
            border-radius: 4px;
            background: $color-gray-14;
            font-size: 13px;
            color: $color-gray-03;
            line-height: 32px;
            text-align: left;

            .ellipsis {
                display: inline-block;
                max-width: 100%;
                margin-right: 3px;
            }
        }
        .c-card-item__addition-link {
            &:after {
                @include ico-arrow-right(7px, $color-gray-07);
                position: absolute;
                top: 50%;
                right: 12px;
                margin-top: -3.5px;
            }
        }
        .c-card-item__more {
            padding-right: 17px;

            &:after {
                @include ico-arrow-down(7px, $color-gray-07);
                content: "";
                position: absolute;
                top: 50%;
                right: 10px;
                margin-top: -5px;
            }
            span {
                vertical-align: top;
            }
        }
        .c-card-item__point {
            color: $color-11st-red;
        }
        .c-card-item__deal {
            display: block;
            overflow: hidden;
            position: relative;
            z-index: 2;
            height: 32px;
            padding: 0 20px 0 7px;
            border: 1px solid $color-gray-11;
            border-radius: 4px;
            background: none;
            font-size: 13px;
            color: $color-gray-02;
            line-height: 32px;

            &:before {
                @include size(18px);
                display: inline-block;
                margin: -2px 0 2px;
                background: url("/MW/img/common/ico_percent.png") no-repeat;
                background-size: 100% 100%;
                vertical-align: middle;
                content: "";
            }
            &:after {
                @include ico-arrow-right(7px, $color-gray-07);
                position: absolute;
                top: 50%;
                right: 12px;
                margin-top: -3px;
                content: "";
            }
        }
    }

    &--deal {
        .c-card-item__thumb {
            .c-badge-shocking {
                overflow: hidden;
                position: absolute;
                top: 8px;
                left: 8px;
                width: 80px;
                height: 80px;
                color: transparent;
                z-index: 1;

                img {
                    @include size(80px);
                }
            }

            img {
                display: block;
                position: relative;
            }
        }
        .c-card-item__info {
            padding: 8px 16px;
        }
        .c-card-item__link {
            display: block;
        }
        .c-card-item__name {
            -webkit-line-clamp: 1;
        }
        .c-card-item__rate {
            vertical-align: bottom;
        }
        .c-card-item__benefit {
            overflow: hidden;
            padding-top: 8px;

            .benefit {
                display: inline-block;
                position: relative;
                margin: 0 11px 0 0;

                &:before {
                    position: absolute;
                    top: 7px;
                    left: -8px;
                    width: 2px;
                    height: 2px;
                    background: $color-gray-10;
                    content: "";
                }
            }
        }
        .c-card-item__price {
            vertical-align: bottom;
            line-height: 20px;
        }
        .c-card-item__deal-guide {
            overflow: hidden;
            margin-top: 8px;
            padding: 2px 0;
            font-weight: bold;
            font-size: 12px;
            color: $color-11st-red;
            line-height: 16px;
        }
        .c-card-item-badge {
            top: auto;
            bottom: 0;
            opacity: 0.9;
        }
    }

    &--gallery {
        .c-cap {
            height: 30px;
            line-height: 30px;
        }
        .c-card-item__info {
            padding: 8px 0;
        }
        .c-card-item__name {
            height: 40px;
            -webkit-line-clamp: 2;
            &-addition {
                font-size: $font-size-body2;
            }
        }
        .c-card-item__price {
            line-height: initial;

            del {
                display: block;
                padding-left: 0;
            }
            .c-card-item__special {
                height: 36px;
            }
        }
        .c-card-item__benefit {
            height: 42px;
            padding: 0;
            margin-top: 6px;
        }
        .c-relates {
            padding: 0;
            background: none;

            &__icon {
                @include size(auto 22px);
                display: inline-block;
                vertical-align: top;

                img {
                    @include size(auto 100%);
                }
            }
            &__coupon,
            &__button-link {
                display: block;
                position: relative;
                height: 46px;
                padding: 12px 0;
                font-size: 13px;
                color: $color-gray-03;
                line-height: 22px;
                &.c-relates__button-link--activated {
                    padding-right: 20px;

                    &:after {
                        @include ico-arrow-right(7px, $color-gray-07);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        margin-top: -4px;
                        content: "";
                    }
                }
            }
            &__coupon {
                .c-relates__icon {
                    width: auto;

                    img {
                        width: auto;
                    }
                }
            }
        }
        .c-card-item__button {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin-top: 100%;

            .c-like-button,
            .c-alarm-button,
            .c-alarm2-button {
                top: 0;
                right: 0;
                z-index: 5;
                margin-top: 10px;
                &.c-alarm2-button {
                    margin-top: 6px;
                }
            }
            .c-ad__btn {
                position: absolute;
                top: -16px;
            }
        }
        &.c-card-item--swatch {
            .c-like-button {
                margin-top: 50px;
            }
        }
    }

    &--zzim {
        .c-card-item__name {
            margin-right: 40px;
        }
        .c-flag-box {
            margin-right: 30px;
        }
    }

    &--list {
        display: table;
        width: 100%;

        .c-card-item__link {
            display: table;
            width: 100%;

            & + .c-card-item__info {
                display: table;
                width: 100%;
                padding: 0;
                table-layout: fixed;
            }
        }
        .c-cap {
            display: table-cell;
            width: 40px;
            vertical-align: middle;
            text-align: center;

            &__rank {
                position: static;
                font-size: 25px;
                color: $color-gray-03;
            }
        }
        .c-item-cap {
            display: table-cell;
            width: 44px;
            padding: 0;
            vertical-align: top;
            text-align: center;
        }
        .c-card-item__thumb {
            @include size(144px);
            display: table-cell;
            vertical-align: top;

            &.play {
                &:after {
                    @include sprite-retina($sp_review_play_s);
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    content: "";
                }
            }
        }
        .c-card-item__swatch {
            .c-card-item__thumb {
                @include size(26px 26px);
                display: inline-block;
            }
        }
        .c-card-item__info {
            display: table-cell;
            padding: 0 8px 6px;
            vertical-align: top;
        }
        .c-card-item__name {
            height: 40px;
            -webkit-line-clamp: 2;
        }
        .c-card-item__price {
            dd {
                display: block;
            }
            del {
                display: block;
                padding-left: 0;
            }
            .c-card-item__special {
                height: 36px;
            }
        }
        .c-card-item__benefit {
            margin-top: 10px;
            padding-top: 0;
        }
        .c-addition-desc1 {
            width: 100%;

            dt {
                @include sr-only-reset;
                margin-right: 4px;
                line-height: 16px;
            }
        }
        .c-card-item__button {
            position: absolute;
            top: 0;
            right: 0;

            .c-like-button {
                position: relative;
            }
        }
        .c-relates {
            padding: 0;
            background: none;

            &__icon {
                @include size(auto 22px);
                display: inline-block;
                vertical-align: top;

                img {
                    @include size(auto 100%);
                }
            }
            &__coupon,
            &__button-link {
                display: block;
                position: relative;
                height: 46px;
                padding: 12px 0;
                font-size: 13px;
                color: $color-gray-03;
                line-height: 22px;
                &.c-relates__button-link--activated {
                    padding-right: 20px;

                    &:after {
                        @include ico-arrow-right(7px, $color-gray-07);
                        position: absolute;
                        top: 50%;
                        right: 10px;
                        margin-top: -4px;
                        content: "";
                    }
                }
            }
            &__coupon {
                .c-relates__icon {
                    width: auto;

                    img {
                        width: auto;
                    }
                }
            }
        }
    }

    &--big {
        .c-card-item__info {
            padding: 16px 16px 18px;
        }
        .c-card-item__name {
            height: initial;
            margin-bottom: 5px;
            -webkit-line-clamp: 2;
        }
        .c-card-item__benefit {
            overflow: hidden;
            padding-top: 6px;

            .benefit {
                display: inline-block;
                position: relative;
                height: 20px;
                margin: 0 10px 0 0;
                line-height: 20px;

                &:before {
                    position: absolute;
                    top: 7px;
                    left: -8px;
                    width: 2px;
                    height: 2px;
                    background: $color-gray-10;
                    content: "";
                }
                & + .benefit {
                    padding-top: 0;
                }
            }
        }
        .c-starrate,
        .c-prd-info__meta {
            position: absolute;
            right: 16px;
            bottom: 46px;
        }
        .c-starrate {
            display: flex;
        }
        .c-card-item__price-info {
            & + .c-starrate,
            & + .c-prd-info__meta {
                bottom: 21px;
            }
        }
    }

    &--box {
        @include rounding-box;
        width: 152px;
        height: 240px;

        .c-card-item__info {
            padding: 12px 12px 10px;
        }
        .c-card-item__name {
            height: 40px;
        }
        .c-card-item__counsel {
            padding: 0;
            line-height: 20px;
        }
        .c-card-item__button {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            margin-top: 100%;

            .c-ad__btn {
                position: absolute;
                top: -16px;
                outline: none;
            }
        }
        .c-card-item__addition {
            border-top: 1px solid $color-gray-12;
        }
        .c-cart-button {
            width: 100%;
            height: 39px;
            border: 0;
            background: none;
            font-size: 15px;
            color: $color-11st-red;

            &:before {
                @include sprite-retina($sp_mart_cart);
                display: inline-block;
                margin: -2px 2px 2px 0;
                vertical-align: middle;
                content: "";
            }
        }
    }
}
