@import "../../common/mixins/mq-640";
[data-type="GridList_ImgText_Col4_Event"] {
    .c-listimg {
        margin-bottom: 0;
    }
    .c-listimg__item {
        @include mq-640 {
            & {
                width: 25%;
            }
        }
    }
}
