[data-type="ExhibitionBanner_BrandDetail"] {
    letter-spacing: $body-spacing;

    .c-linkimg {
        display: block;
        position: relative;

        & + .c-content-box {
            margin-top: -30px;

            .c-content__text {
                color: $color-gray-04;
            }
        }
    }
    .c-content-box {
        position: relative;
        margin-top: -30px;
        padding: 24px;
        background: $color-gray-14;

        .c-content__text {
            font-size: 15px;
            line-height: 21px;
        }
    }
    .c-logoimg {
        padding-top: 16px;

        .c-lazyload {
            padding: 0;

            img {
                display: block;
                position: relative !important;
                width: 128px;
                height: 48px;
                object-fit: contain;
            }
        }
        & + .c-content-box {
            margin: 0;
            padding: 4px 16px 16px;

            .c-content__text {
                overflow: hidden;
                max-height: 21px;
                color: $color-gray-02;
                text-align: center;
                word-break: break-all;
            }
        }
    }
}
