[data-type="Tabs_Mart_Scroll"] {
    @import "../../skpui/component/_icontext_now"; // 나우배송 카테고리 아이스크롤 영역 아이콘+텍스트 조합 링크
    background-color: #fafafa;

    .w-magnet {
        width: 100%;
        max-width: 1280px;
        background-color: #fafafa;
    }
    .s-list {
        padding: 0;
        li {
            padding: 0;
        }
    }
    .c-icontext {
        overflow: hidden;
        position: relative;
        padding-top: 3px;
        width: 80px;
        height: 62px;
        border-radius: 0;
        background-color: #fafafa;
        &__hover {
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            bottom: -70px;
            transition: all 0.4s ease;
        }
        &__icon {
            width: 36px;
            height: 36px;
            margin: 0 auto;
            &:after {
                display: none;
            }
            .img-on {
                display: none;
            }
            .img-off {
                display: block;
            }
        }
        &__text {
            margin-top: 0px;
            font-size: 13px;
            font-weight: normal;
            letter-spacing: 0;
            color: #666;
        }
        &:after {
            content: "";
            display: block;
            width: 1px;
            height: 42px;
            position: absolute;
            top: 10px;
            right: 0;
            background-color: #f4f4f4;
        }
    }
    .on {
        .c-icontext {
            transition: all 0.4s ease;
            &__hover {
                bottom: 0;
                z-index: 1;
            }
            &__icon {
                z-index: 2;
                .img-on {
                    display: block;
                }
                .img-off {
                    display: none;
                }
            }
            &__text {
                position: relative;
                color: #fff;
                z-index: 2;
                font-weight: bold;
            }
            &:after {
                display: none;
            }
        }
    }
    .b-boundary {
        display: block;
        width: 100%;
        height: 2px;
    }
    .w-scroll-css {
        position: relative;

        .b-boundary {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
        }
    }
    //  탭 4개 이하의 경우 스크롤 제거 너비 flex처리
    .w-scroll__none {
        .s-list {
            display: flex;
            align-content: stretch;
            overflow: hidden;
            li {
                flex: 1;
                .c-icontext {
                    width: auto;
                }
            }
        }
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }

    .s-list {
        display: table;
        @include mq-400 {
            display: flex;
        }
        li {
            @include mq-400 {
                display: block;
                flex: 1 1 0;
            }

            .c-icontext {
                @include mq-400 {
                    width: 100%;
                }
            }
        }
    }

    // 240115 개선 후 버전 (Tabs_Meta_Scroll 블럭과 동일함)
    .c-nav {
        border-bottom: 2px solid var(--tabPointColor, #{$color-11st-red});
        background-color: $color-gray-13;

        &__wrap {
            overflow-x: auto;
            overflow-y: hidden;
            margin-right: 0;
            -webkit-overflow-scrolling: touch;
        }
        &__image {
            overflow: hidden;
            width: 36px;
            height: 36px;
            margin: 0 auto 2px;
        }
        &__icon {
            display: block;
            width: 36px;
            height: 36px;
        }
        &__text {
            overflow: hidden;
            display: block;
            height: 18px;
            margin: 0 4px;
            color: $color-gray-04;
            font-size: 13px;
            line-height: 18px;
            text-align: center;
            white-space: normal;
        }
        &__list {
            overflow: hidden;
            padding: 0;
            table-layout: fixed;
        }
        &__item {
            position: relative;
            z-index: 10;

            // 공통 nav의 선택자 우선순위 문제로 depth 추가
            .c-nav__link {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 62px;
                padding: 0;
                font-weight: 400;

                &:after {
                    content: "";
                    position: absolute;
                    top: 10px;
                    bottom: auto;
                    left: 100%;
                    width: 1px;
                    height: 42px;
                    background-color: $color-gray-12;
                }
            }
            &--on {
                z-index: 20;

                .c-nav {
                    &__icon:first-of-type {
                        margin-top: -36px;
                    }
                    &__text {
                        color: $color-gray-14;
                        font-weight: 700;
                    }
                    &__link {
                        background-color: var(--tabPointColor, #{$color-11st-red});

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        &--spread {
            .c-nav {
                &__list {
                    width: auto;
                    table-layout: auto;
                }
                &__link {
                    width: 80px;
                }
            }
        }
    }
}
