.c-round-button {
    @include border-radius(28px);

    display: block;
    overflow: hidden;
    position: relative;
    height: 44px;
    padding: 0 8px;
    margin: 0;
    background: #f3f3f3;
    font-size: 15px;
    color: #333;
    line-height: 44px;
    white-space: nowrap;

    &__icon {
        @include size(28px 28px);

        display: block;
        float: left;
        margin-top: 8px;
        margin-right: 8px;

        img {
            @include border-radius(28px);

            display: block;
            width: 100%;
        }
    }

    &__text {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
    }

    &.c-round-button--textlink {
        background: #e3f4f2;
        padding: 0 16px;

        &:after {
            @include size(8px 8px);

            @include rotate(45deg);

            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: -5px;
            border: 1px solid #8dcfc8;
            border-width: 1px 1px 0 0;
            content: '';
        }
    }
}
