[data-type="GridList_ImgText_Col5_Home"] {
    background-color: #fff;
    letter-spacing: $body-spacing;
    // padding: 10px 0; //여행탭 대응을 위한 간격 수정
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    .c-listimg {
        // margin: -6px 0; 여행탭 대응을 위한 간격 수정
        &__item {
            width: 20%;
            &:nth-child(4n + 1) {
                clear: none;
            }
            @include mq-640 {
                width: 10%;
            }
            .c-listimg__link {
                padding: 6px 0;
                border-width: 0 0 0 0;
                .c-listimg__icon-wrap {
                    width: 36px;
                    height: 36px;
                    .c-badge {
                        padding: 0 3px;
                        left: initial;
                        right: -4px;
                        font-size: 11px;
                    }
                }
                .c-listimg__name {
                    padding-top: 3px;
                }
            }
        }
    }
    #carrier_304 & {
        border-bottom: 1px solid #f4f4f4;
    }
}
