[data-type="Cap_List_Sorting"] {
    position: relative;

    &.l-grid__row {
        background-color: #fff !important;
    }

    .c-list-header {
        font-size: 0;

        h1,
        h2,
        h3,
        h4 {
            height: 52px;
            padding: 0 16px;
            font-size: 15px;
            font-weight: normal;
            color: $color-gray-03;
            line-height: 52px;
        }
        .c-list__title {
            display: inline-block;
            padding: 0 0 0 16px;
            font-size: 13px;
            color: $color-gray-07;
        }
    }

    .c-list__sorting {
        position: absolute;
        right: 8px;
        top: 0;
        font-size: 0;

        button,
        .c-dropdown {
            position: relative;
            border: 0;
            height: 52px;
            font-size: 14px;
            color: $color-gray-03;
            background: none;
            padding: 0 28px 0 10px;

            &:focus {
                outline: 0;
            }
            &:after {
                @include ico-arrow-down(8px, $color-gray-03, 1px);
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 10px;
                margin: -7px 0 0 8px;
                content: "";
            }

            & + button {
                margin-left: 8px;

                &:before {
                    @include size(1px 10px);
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    margin-top: -5px;
                    background: $color-gray-11;
                    content: "";
                }
            }
        }
    }
}
