@import "../../common/partial/count_btn";

[data-type="ContentsScroll_BasicBox_Mart"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .c-headline {
        &__title {
            height: 54px;
            font-size: $font-size-subtitle2;
            font-weight: normal;
            line-height: 34px;

            span {
                color: $color-11st-blue;
            }

            img {
                position: static;
                width: auto;
                height: 24px;
                margin-top: 3px;
            }
        }
    }

    .s-list {
        height: 100%;
        padding: 0 10px 24px;

        li {
            position: relative;
            padding: 0 6px;

            &:nth-child(n + 4) {
                .c-card__mart--rank {
                    background: $color-gray-06;
                }
            }

            .c-card {
                width: 142px;
                height: 100%;
                min-height: 285px;
                padding-bottom: 35px;

                &__thumb {
                    width: 142px;
                    height: 142px;
                }

                &__info {
                    padding: 12px 12px 10px 12px;
                }

                &__name {
                    height: auto;
                    max-height: 38px;
                    margin-bottom: 5px;
                    font-size: $font-size-body1;
                    color: #333;
                }

                &__rate {
                    strong {
                        font-weight: normal;
                    }
                }

                &__price {
                    color: #111;
                    strong {
                        vertical-align: middle;
                    }
                }

                &__mart {
                    &--rank {
                        top: 0;
                        margin-right: 4px;
                        vertical-align: top;
                    }
                }

                .w-prd__count {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 0;

                    .c-prd__count {
                        &--qty {
                            height: 35px;
                            line-height: 35px;
                            border-bottom: 0;
                        }

                        button {
                            width: 34px;
                            height: 35px;
                            border-radius: 0;

                            &:first-child {
                                border-width: 1px 0 0 0;
                            }

                            &:last-child {
                                border-width: 1px 0 0 0;
                            }
                        }
                    }

                    &--link {
                        height: 35px;
                        line-height: 36px;
                        border-width: 1px 0 0 0;

                        &::after {
                            top: 13px;
                            right: 13px;
                        }
                    }

                    .c-prd__count--save {
                        top: 0;
                        right: 0;
                        width: 40px;
                        height: 35px;
                        border-radius: 0;
                        border-width: 1px 0 0 1px;

                        &::before {
                            left: 6px;
                        }
                    }
                }

                &__mart--info {
                    display: block;
                }

                .c-starrate {
                    margin: 7px 0 2px;
                }
            }

            .more {
                @include scroll-more(142px, 285px);
            }

            .c-ad__btn {
                position: absolute;
                left: 7px;
                top: 127px;
                outline: none;
            }
        }
    }

    .s-list__tall {
        li {
            .c-card--box {
                height: 305px;
                .c-card__buy--count {
                    margin-top: 1px;
                    height: 18px;
                    line-height: 18px;
                    font-size: 13px;
                    color: #7baed8;
                }
            }
            .more {
                height: 305px;
            }
        }
    }
}
