.c-store-products {
    display: flex;
    padding-bottom: 16px;

    &__info {
        width: 80px;
        flex: initial;
        font-size: 15px;
    }

    &__list-wrap {
        flex: 2;
    }

    &__rank {
        display: block;
        padding-bottom: 4px;
        font-size: 15px;
        color: #333;
    }

    &__name {
        padding-right: 8px;
        color: #111;
        font-weight: bold;

        &:after {
            content: '';
            display: inline-block;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            height: 8px;
            width: 8px;
            border: 1px solid #bbb;
            border-width: 1px 1px 0 0;
            margin: 0 0 1px 3px;
        }
    }

    &__button-coupon {
        display: block;
        padding: 4px;
        width: 70px;
        margin: 8px 0 0 0;
        border: 1px solid #e4e4e4;
        font-size: 11px;
        text-align: center;
        color: #999;
    }

    &__list {
        display: block;
        width: 100%;

        li {
            float: left;
            width: 33.33333%;
            padding-left: 8px;
        }
    }

    &__card {
        text-align: center;
        font-size: 12px;

        &-thumb {
            display: block;
            width: 100%;
            max-width: 72px;
            margin: 0 auto;

            img {
                width: 100%;
                height: 100%;
            }
        }

        &-info {
            display: block;
            padding-top: 8px;
            margin: 0 auto;
            max-width: 72px;
            text-align: left;
        }

        &-title {
            display: block;
            max-height: 30px;
            overflow: hidden;
            color: #333;
        }

        &-price {
            display: block;
            padding-top: 3px;
            max-width: 72px;
            margin: 0 auto;
            text-align: left;
            font-size: 10px;

            strong {
                font-size: 12px;
                color: #111;
                letter-spacing: 0;
            }
        }
    }
}
