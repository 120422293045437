[data-type="Tabs_Text_Keyword_Scroll"] {
    @include grid(0, 0, false);
    padding-bottom: 10px !important;

    .c-headline__title {
        font-size: $font-size-subtitle2;
        color: $color-gray-03;
        height: 34px;
        padding: 0 0 0 16px;
        line-height: 24px;
    }
    .s-list {
        padding: 0 10px 6px 16px;

        li {
            padding: 0;
            .c-chip {
                margin-bottom: 0;
                color: $color-11st-blue;
                border: 1px solid rgba(11, 131, 230, 0.4);
                background-color: rgba(11, 131, 230, 0.04);
            }
        }
    }
}
