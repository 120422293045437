[data-type="LineBanner_Pagination_Trip"] {
    .w-swiper {
        a {
            @include size(100% 80px);
            display: block;
            img {
                @include size(300px 80px);
                margin: 0 auto;
                display: block;
            }
        }
        .c-pagination {
            position: absolute;
            width: 100%;
            z-index: 1;
            bottom: 0;
            &__indicator {
                line-height: 28px;
                .c-pagination__bullet {
                    background-color: transparent;
                    &.on {
                        background: rgba(0, 0, 0, 0.6);
                    }
                }
            }
        }
        .swiper-button-prev:before,
        .swiper-button-next:before {
            @include size(12px);
            top: 58%;
        }
        .swiper-button-prev:before {
            border-width: 0 0 1px 1px;
        }
        .swiper-button-next:before {
            margin: -9px 0 0 -10px;
            border-width: 1px 1px 0 0;
        }
    }
}