[data-type="Contents_SubTitle_Notice"] {
    letter-spacing: $body-spacing;

    .c-headline {
        &__title {
            height: 54px;
            padding: 8px 40px 8px 16px;
            font-size: $font-size-subtitle1;
            font-weight: normal;
            white-space: normal;
        }
        &__11st {
            &-blue {
                font-weight: normal;
                color: $color-11st-blue;
            }
            &-primary {
                font-weight: normal;
                color: $color-11st-red;
            }
        }
        &__link {
            &:after {
                right: 22px;
            }
        }
        &__emphasis {
            font-weight: normal;
        }
        &__tag {
            margin: -2px 0 2px 4px;
            vertical-align: middle;
        }
    }
}
