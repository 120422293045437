[data-type="LineBanner"] {
    display: block;
    background: $color-gray-11;

    .c-banner__link {
        .c-lazyload {
            padding-bottom: 0;
            height: 60px;

            &:before {
                background-color: transparent;
            }
            .c-banner__img {
                width: 300px;
                height: 60px;
            }
        }
    }
}
