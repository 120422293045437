[data-type="ExhibitionBannerE"] {
    margin: 8px 0 0;
    background-color: #fff;
    &:nth-child(1) {
        margin-top: -16px;
    }
    .w-scroll {
        margin: 0;
        padding: 8px 0;
        .s-list {
            margin-bottom: 0;
        }
    }
    .c-lazyload {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}

[data-type-item="ExhibitionBannerE"] {
    margin: 8px 0 8px;
    background: #fff;
    border-bottom: 1px solid #d1d1d6;
    &:last-child {
        margin-bottom: 0;
    }
    &:nth-child(1) {
        margin-top: -16px;
    }
    .w-scroll {
        margin: 0;
        padding: 8px 0;
        .s-list {
            margin-bottom: 0;
        }
    }
    .c-lazyload {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
    }
}
