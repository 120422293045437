@import "../../pui/sprites/sp_emoticon";
[data-type="Information_Survey"], [data-type="Information_Survey2"] {
    @include grid(2,2,true);
    .c-rating {
        padding-top: 2px;
        text-align: center;
        p {
            margin-bottom: 11px;
            text-align: center;
            line-height: 19px;
            font-size: 15px;
            color: #999;
            strong {
                color: #111;
                font-weight: normal;
            }
        }
        .c-button {
            @include border-radius(50px);

            display: inline-block;
            padding: 0 16px;
            margin-right: 6px;
            height: 30px;
            line-height: 28px;
            color: #333;
            font-size: 13px;
            border: 1px solid #ddd;
            background-color: #fff;
            &:last-of-type {
                margin-right: 0;
            }

            &.active {
                color: $color-11st-red;
            }
            &--bad,
            &--good {
                padding: 0 14px 0 10px;
                background: none #fff;
                color: $color-11st-red;
                &:before {
                    content: '';
                    display: inline-block;
                    margin-top: -3px;
                    margin-right: 6px;
                    vertical-align: middle;
                }
            }

            &--bad {
                &:before {
                    @include sprite-retina($sp_emoticon_voc_no);
                }
            }

            &--good {
                &:before {
                    @include sprite-retina($sp_emoticon_voc_yes);
                }
            }
        }
    }
}
