@import "../../allsprites/sprites/sp_search";

[data-type="ContentsScroll_KeywordRanking"] {
    .b-keyword-search {
        position: relative;
        letter-spacing: 0;
        .c-keyword-item {
            position: relative;
            display: block;
            padding: 0 30px 0 0;
            height: 48px;
            margin-right: 38px;
            margin-left: 88px;
            &__title {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                display: inline-block;
                max-width: 100%;
                line-height: 50px;
                color: #333;
                font-size: 15px;
                strong {
                    font-style: italic;
                    color: $color-11st-red;
                    padding-right: 5px;
                }
            }
            &__description {
                display: none;
            }
            &__status {
                position: absolute;
                display: block;
                width: 9px;
                height: 10px;
                top: 20px;
                right: 12px;
                .status {
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 10px;
                    text-indent: -99999px;
                    overflow: hidden;
                    &:before {
                        content: "";
                        display: block;
                    }
                    &.up:before {
                        @include ranking-arrow(up);
                    }
                    &.down:before {
                        @include ranking-arrow(down);
                    }
                    &.neut {
                        height: 2px;
                        top: 3px;
                        right: 0;
                        &:before {
                            @include ranking-arrow(same);
                        }
                    }
                    &.new {
                        width: auto;
                        text-indent: 0;
                        font-family: $font-family;
                        font-weight: bold;
                        position: absolute;
                        left: -7px;
                        &:before {
                            content: "NEW";
                            color: $color-11st-red;
                            font-size: 10px;
                        }
                    }
                }
            }
        }
        .w-swiper--vertical {
            position: relative;
            height: 48px;
        }
        .c-notification {
            display: block;
            position: absolute;
            top: 14px;
            left: 16px;
            bottom: initial;
            z-index: 1;
            width: 60px;
            height: 20px;
            background: $color-11st-gradient;
            font-size: 11px;
            font-weight: normal;
            color: $color-gray-14;
            line-height: 22px;
            letter-spacing: -0.5px;
            transition: background 0.2s ease-in;
            text-align: center;
        }
        .b-keyword-search__list {
            display: none;
            .b-keyword-search__list-title {
                height: 47px;
                line-height: 54px;
                border-bottom: 1px solid $color-gray-12;
                padding-left: 16px;
                font-size: 15px;
                color: #111;
            }
            .c-keyword-item {
                display: flex;
                overflow: hidden;
                padding: 0 25px 0 0;
                margin: 0 16px;
                height: 40px;
                border-right: 0;
                border-bottom: 1px solid $color-gray-12;
                text-align: left;

                &__title {
                    line-height: 40px;
                }
                &__description {
                    display: inline-block;
                    overflow: hidden;
                    margin-left: 6px;
                    font-size: 12px;
                    color: $color-gray-06;
                    line-height: 40px;
                    vertical-align: top;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    flex: 1;
                }
                &__status {
                    top: 15px;
                    right: 6px;
                }
            }
            .c-pagination {
                position: relative;
                border-top: 1px solid $color-gray-11;
                margin-top: -1px;
                z-index: 1;
                text-align: initial;

                &__indicator {
                    text-align: initial;
                    line-height: 32px;
                    padding-left: 16px;

                    span {
                        @include size(6px);
                        border: none;
                        background-color: rgba(0, 0, 0, 0.14);
                        opacity: 1;

                        &.on {
                            border: 0;
                            background-color: $color-gray-01;
                        }
                        & + span {
                            margin-left: 4px;
                        }
                    }
                }
            }
            .c-textinfo {
                position: absolute;
                right: 20px;
                bottom: 9px;
                font-size: 11px;
                line-height: 11px;
                color: $color-gray-06;
            }
        }
        .c-button-toggle {
            position: absolute;
            right: 0;
            top: 0;
            display: block;
            border: 0;
            width: 44px;
            height: 46px;
            background-color: $color-gray-14;
            text-indent: -99999px;
            z-index: 1;
            outline: none;
            &:before {
                @include size(8px);
                position: absolute;
                top: 40%;
                right: 22px;
                transition: 0.3s;
                border: 1px solid $color-gray-04;
                border-width: 0 1px 1px 0;
                transform: rotate(45deg);
                content: "";
            }
            &.on {
                &:before {
                    transform: rotate(223deg);
                    top: 50%;
                }
            }
        }
        &.on {
            .w-swiper--vertical {
                display: none;
            }
            .b-keyword-search__list {
                display: block;
            }
        }
        &--amazon {
            .c-notification {
                background: $color-amazon-gradient;
            }
            .c-keyword-item__title {
                strong {
                    color: $color-amazon-bluegreen;
                }
            }
        }
    }
}
