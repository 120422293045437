[data-type="Cap_Box_RealTime"] {
    letter-spacing: 0;

    .c-headline {
        &__title {
            font-size: 18px;
            line-height: 34px;
            padding: 12px 16px 11px 16px;

            strong {
                float: left;
            }
        }
        &__time {
            position: absolute;
            right: 0;
            top: 0;
        }
        &__float {
            display: flex;
            position: absolute;
            top: 50%;
            right: 16px;
            margin: 0;
            padding: 0;
            transform: translateY(-50%);

            &:before {
                display: none;
            }
            .c-headline__time {
                display: inline-block;
                position: static;
                padding: 0;
                margin: 0;

                &:after {
                    display: none;
                }
            }
        }
        &__refresh {
            border: 0;
            background: none;

            &:after {
                @include sprite-retina($sp_eui_arrow_refresh3);
                display: inline-block;
                margin: -2px 0 2px 6px;
                padding: 5px;
                vertical-align: middle;
                content: "";
            }
        }
    }
}
