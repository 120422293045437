[data-type="ProductList_RankingBox"] {
    letter-spacing: 0;

    @include grid(0, 0, false);

    .c-card {
        &-item {
            padding: 16px;
            position: initial;
            overflow: initial;
            background-color: transparent;
            &--wrap {
                @include rounding-box;
                margin: 0 16px;
                padding-bottom: 8px;
                overflow: hidden;
                li {
                    position: relative;

                    &:after {
                        content: "";
                        height: 1px;
                        background-color: $color-gray-12;
                        position: absolute;
                        left: 16px;
                        right: 16px;
                        bottom: 0;
                    }
                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
            &__link {
                position: relative;
            }
            &__thumb {
                @include size(112px);
            }
            &__swatch {
                padding-left: 6px;
                text-align: left;
                .c-card-item__thumb {
                    @include size(26px);
                    display: inline-block;
                }
            }
            &__rank {
                border-bottom-right-radius: 0;
            }
            &__info {
                padding: 0 0 0 14px;
                height: 114px;

                .c-nation {
                    display: block;
                    padding-right: 4px;
                }
                .c-card-item {
                    &__name {
                        font-size: $font-size-subtitle2;
                        color: $color-gray-03;
                        height: initial;
                        max-height: 40px;
                        margin-bottom: 7px;
                        line-height: 20px;
                        -webkit-line-clamp: initial;
                    }
                    &__price strong {
                        font-size: $font-size-headline3;
                    }
                    &__rate {
                        strong {
                            font-weight: normal;
                            font-size: $font-size-subtitle1;
                            &.c-card-item__special {
                                font-size: $font-size-body1;
                            }
                        }
                    }
                    &__price {
                        font-size: $font-size-body2;
                        strong {
                            font-size: $font-size-subtitle1;
                        }
                    }
                    &__deal-guide {
                        margin-top: 0;
                        font-size: $font-size-body2;
                        color: $color-gray-04;
                        font-weight: normal;
                    }
                    &__benefit {
                        dd {
                            color: $color-gray-04;
                            font-size: $font-size-body1;
                            &.allclub {
                                &::before {
                                    margin-top: -2px;
                                }
                            }
                        }
                    }
                }
                .c-starrate {
                    margin-top: 6px;
                }
            }
        }
    }

    .w-swiper {
        padding-bottom: 26px;

        .c-pagination {
            margin: 1px 0 -22px;
        }
    }
}
