[data-type="GridScroll_ImgTextTag_Box"] {
    letter-spacing: $body-spacing;

    .s-list {
        padding: 0 12px 12px;

        li {
            padding: 0 6px;
        }
    }
    .c-card-item--box {
        width: 96px;
        height: 123px;
        margin-bottom: 12px;

        .c-lazyload {
            &:before {
                background: $color-gray-12;
            }
        }
        .c-card-item__thumb {
            @include size(64px);
            overflow: hidden;
            margin: 14px auto 8px;
            border-radius: 100%;
        }
        .c-card-item__info {
            padding: 8px 10px 12px;
            text-align: center;
        }
        .c-card-item__name {
            display: block;
            height: 17px;
            margin: 0;
            font-size: 14px;
            line-height: 17px;
        }
        .c-card-item__rate {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 100%;

            strong {
                overflow: hidden;
                max-width: 88px;
                height: 20px;
                padding: 0 3px;
                border: 1px solid rgba($color: $color-11st-red, $alpha: 0.4);
                border-radius: 10px;
                background: $color-gray-14;
                font-size: 12px;
                color: $color-11st-red;
                line-height: 20px;
            }
        }
    }
}
