[data-type="LineBanner_Trip"] {
    .c-banner__img {
        @include size(328px 48px);
    }
    .c-lazyload {
        height: 48px;

        &:before {
            background-color: transparent;
        }
        .c-banner__img {
            position: relative !important;
        }
    }
}
