[data-type="ContentsScroll_ContentsFormBox"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .c-headline {
        &__title {
            display: flex;
            height: 54px;
            padding: 9px 30px 9px 16px;
            font-size: $font-size-subtitle2;
            font-weight: normal;
            span {
                display: inline-block;
                overflow: hidden;
                color: $color-11st-blue;
                text-overflow: ellipsis;
            }
        }
    }
    .s-list {
        padding: 0 10px 24px;
        li {
            padding: 0 6px;
            .c-card {
                width: 142px;
                height: 230px;
                &__thumb {
                    width: 142px;
                    height: 142px;
                }
                &__info {
                    padding: 10px 12px;
                }
                &__title {
                    display: -webkit-box;
                    overflow: hidden;
                    min-height: 38px;
                    margin: 0;
                    color: $color-gray-03;
                    font-size: 13px;
                    font-weight: normal;
                    line-height: 19px;
                    white-space: pre-line;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &__writer {
                    display: block;
                    padding-top: 7px;
                    color: $color-11st-blue;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 0;
                    &-thumb {
                        @include size(20px);
                        display: inline-block;
                        overflow: hidden;
                        position: relative;
                        margin-right: 4px;
                        border-radius: 100%;
                        vertical-align: middle;

                        &:after {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: 1px solid rgba(0, 0, 0, 0.04);
                            border-radius: 100%;
                            content: "";
                        }
                        img {
                            width: 100%;
                        }
                    }
                    &-info {
                        display: inline-block;
                        overflow: hidden;
                        max-width: 80%;
                        vertical-align: middle;
                        font-size: $font-size-body1;
                        color: $color-gray-07;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                &__thumb {
                    @include thumbnail-playtime;
                    @include thumbnail-playicon;
                    .c-badge {
                        &-keyword {
                            position: absolute;
                            z-index: 1;
                            display: inline-block;
                            padding: 0 4px;
                            height: 16px;
                            line-height: 16px;
                            background-color: rgba(255, 255, 255, 0.8);
                            font-size: 10px;
                            color: rgba(0, 46, 111, 0.75);
                        }
                    }
                    img {
                        width: auto;
                        height: 100%;
                        max-width: initial;
                        max-height: initial;
                        left: 50%;
                        top: 100%;
                        transform: translate(-50%, -50%);
                    }
                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
                    }
                }
            }

            .more {
                @include scroll-more(130px, 230px);

                .keyword {
                    display: inline-block;
                    width: 130px;
                    height: 18px;
                    color: $color-11st-blue;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}
