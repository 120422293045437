[data-type="Product_StoreMarketing"] {
    padding: 0 8px;
    letter-spacing: $body-spacing;

    .c-card-item {
        &--list {
            @include rounding-box;

            .c-relates {
                margin-top: 16px;
                border-top: 1px solid $color-gray-12;

                &__coupon {
                    height: 49px;
                    padding: 14px 0;
                    font-size: 14px;
                }
                &__icon {
                    margin-right: 7px;
                }
            }
        }
        &__link {
            display: block;
            padding: 16px 16px 0;
        }
        &__thumb {
            @include size(80px);
        }
        &__info {
            padding-top: 3px;
            padding-left: 14px;
        }
        &__name {
            display: block;
        }
        &__rate {
            strong {
                font-size: 18px;
                font-weight: normal;
            }
        }
        &__price {
            strong {
                font-size: 18px;
            }
        }
        &__counsel {
            margin: -7px 0 -6px;
            padding: 0;

            em {
                font-size: 15px;
            }
        }
    }
    .c-addition {
        margin-top: 16px;
        border-color: $color-gray-13;

        &-coupon {
            height: auto;
            padding: 14px 0;

            &__rate {
                margin-right: 10px;
            }
            &__text {
                font-size: 14px;

                &:after {
                    display: none;
                }
            }
        }
    }
    .swiper-wrapper {
        padding-bottom: 24px;
    }
    .swiper-slide {
        padding: 0 8px;
    }
    .c-pagination {
        margin-top: -24px;
    }
}
