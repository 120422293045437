[data-type="ProductCard_TimeDeal_Scroll_Box"] {
    letter-spacing: 0;

    @include grid(0, 0, false);

    .c-headline {
        height: 94px;
        background: linear-gradient(90deg, rgba(132, 215, 226, 1) 0%, rgba(165, 230, 204, 1) 70%, rgba(166, 231, 206, 1) 100%);
        &__title {
            font-size: $font-size-title;
            line-height: 32px;
            padding: 24px 16px 11px 16px;
            height: 64px;
            text-align: center;

            strong {
                position: relative;
                display: inline-block;
                padding-right: 8px;
                height: 28px;
                vertical-align: top;
                & + span {
                    margin-left: 0;
                    padding: 0;
                    font-size: $font-size-title;
                    font-weight: bold;
                    line-height: 32px;
                    color: $color-gray-02;
                    &::before {
                        display: none;
                    }
                }
            }
            time {
                color: $color-11st-red;
                font-weight: bold;
                display: inline-block;
                em {
                    @include size(30px 28px);
                    line-height: 30px;
                    border-radius: 4px;
                    position: relative;
                    font-style: normal;
                    display: block;
                    float: left;
                    background-color: #06234a;
                    color: $color-gray-14;
                    font-size: $font-size-body1;
                    text-align: center;
                    margin-right: 20px;
                    letter-spacing: 1px;
                    &::after {
                        content: ":";
                        width: 20px;
                        height: 28px;
                        color: #06234a;
                        font-size: 20px;
                        position: absolute;
                        left: 30px;
                        top: 0;
                        text-align: center;
                    }
                    &:nth-last-of-type(1) {
                        margin-right: 0;
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
        border-bottom: 1px solid $color-gray-12;
    }

    .c-card {
        &-item {
            padding: 0 16px;
            margin-top: -20px;
            position: initial;
            overflow: initial;
            &__link {
                @include rounding-box;
            }
            &__thumb {
                .c-lazyload {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
            &__info {
                height: 183px;
                padding: 12px 16px 10px 16px;
                border-radius: 4px;

                .c-nation {
                    float: left;
                    margin-right: 8px;

                    dd {
                        padding-top: 2px;
                    }
                }
                .c-flag-box {
                    padding-top: 2px;

                    &:first-child {
                        margin-bottom: 5px;
                    }
                }
                .c-card-item {
                    &__name {
                        display: block;
                        font-size: $font-size-subtitle1;
                        margin-bottom: 28px;
                        line-height: 19px;
                    }
                    &__name-addition {
                        overflow: hidden;
                        height: 19px;
                        margin: -25px 0 6px;
                        padding-bottom: 0;
                    }
                    &__price-info {
                        font-size: 0;
                    }
                    &__price {
                        margin-right: 3px;

                        strong {
                            font-size: $font-size-headline3;
                        }
                    }
                    &__rate {
                        strong {
                            font-weight: normal;
                            font-size: $font-size-headline3;
                            &.c-card-item__special {
                                font-size: $font-size-body1;
                            }
                        }
                    }
                    &__price {
                        font-size: $font-size-body2;
                        del {
                            padding-left: 0;
                            font-size: $font-size-body2;
                            color: #949494;
                        }
                        &--origin {
                            color: #949494;
                            line-height: 20px;
                        }
                    }
                    &__deal-guide {
                        margin-top: 0;
                        padding: 0 0 7px;
                        font-size: $font-size-body2;
                        color: $color-gray-04;
                        font-weight: normal;
                    }
                    &__delivery {
                        display: flex;
                        overflow: hidden;
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 38px;
                        border-top: 1px solid $color-gray-12;
                        padding: 0 16px;
                        line-height: 38px;

                        dd {
                            display: inline-block;
                            color: $color-gray-04;
                            font-size: $font-size-body2;
                            & + dd {
                                &:before {
                                    @include size(2px);
                                    display: inline-block;
                                    margin: -1px 5px 1px;
                                    border-radius: 2px;
                                    background: $color-gray-10;
                                    vertical-align: middle;
                                    content: "";
                                }
                            }
                            &.delivery {
                                span {
                                    position: relative;
                                    &::before {
                                        content: "";
                                        position: absolute;
                                        left: -8px;
                                        top: 50%;
                                        margin-top: -1px;
                                        display: block;
                                        width: 2px;
                                        height: 2px;
                                        border-radius: 50%;
                                        background-color: #ddd;
                                    }
                                }
                            }
                            &.time {
                                color: $color-11st-blue;
                            }
                        }
                        .sktpass {
                            &:before {
                                margin-right: 0;
                            }
                            span {
                                display: inline;
                            }
                        }
                    }
                }
            }
            &.c-card-item--soldout {
                .c-card-item__thumb {
                    &::before {
                        border-radius: 4px;
                        height: 100%;
                        width: 100%;
                        background-color: rgba(0, 0, 0, 0.45);
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
            }
        }
    }
    .w-swiper {
        padding-bottom: 8px;
    }
    .w-scroll {
        z-index: 1;

        .s-list {
            margin: 0 auto;
            padding: 0;

            li {
                position: relative;
                padding: 8px 4px 16px;

                a {
                    display: block;
                    margin: 0 auto;
                    width: 63px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    white-space: nowrap;
                    color: #007186;
                    .c-card-box {
                        @include size(63px);
                        @include rounding-box;
                        display: block;
                        padding: 4px;
                        margin-bottom: 8px;
                        img {
                            width: auto;
                            height: 100%;
                            max-width: initial;
                            max-height: initial;
                            left: -100%;
                            right: -100%;
                        }
                    }
                }

                &.on {
                    a {
                        color: $color-gray-02;
                        .c-card-box {
                            border: 2px solid $color-gray-02;
                        }
                    }
                }
                @include mq-320 {
                    a {
                        width: 52px;
                        font-size: 11px;
                        .c-card-box {
                            @include size(52px);
                        }
                    }
                }
                @include mq-360 {
                    a {
                        width: 63px;
                        font-size: 12px;
                        .c-card-box {
                            @include size(63px);
                        }
                    }
                }
            }
        }
    }
}
