@import "../../pui/sprites/sp_btn_store";
@import "../../pui/sprites/sp_icons";

[data-type="ProductGrid_Store"] {
    background-color: #e3e3e8;
    &.l-grid__row {
        .l-grid__col {
            position: relative;
            background-color: #fff;
            &::after {
                content: "";
                display: block;
                border-bottom: 1px solid #f4f4f4;
            }
        }
    }
    .c-brandinfo {
        display: table;
        width: 100%;
        padding: 16px;
        position: relative;

        &__ranking {
            display: table-cell;
            vertical-align: middle;
            padding-right: 10px;
            line-height: 40px;
        }
        &__thumb {
            @include size(40px 40px);
            display: table-cell;
            position: relative;
            background: #fff;
            line-height: 0;

            &:after {
                @include size(100%);
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 100%;
                content: "";
            }
            img {
                @include size(40px);
                display: block;
                margin: 0;
                border-radius: 24px;
                object-fit: contain;
            }
            .c-lazyload {
                &:before {
                    background-color: transparent;
                    background-size: 24px 20px;
                }
            }
        }
        &__info {
            display: table-cell;
            padding-left: 8px;
            vertical-align: middle;
        }
        &__name {
            display: block;
            overflow: hidden;
            height: 16px;
            color: #333;
            font-size: 15px;
            line-height: 15px;
        }
        &__sale {
            display: block;
            height: 16px;
            overflow: hidden;
            padding-top: 2px;
            color: #999;
            font-size: 12px;
            line-height: 16px;
        }
        &.c-brandinfo__arrow:after {
            @include rotate(45deg);
            @include size(8px);
            content: "";
            position: absolute;
            top: 50%;
            right: 16px;
            margin-top: 0px;
            border: solid #999;
            border-width: 1px 1px 0 0;
        }
        a {
            display: block;
            margin-right: 100px;
        }
        .c-brandinfo__btns {
            position: absolute;
            z-index: 33;
            right: 12px;
            top: 50%;
            margin-top: -27px;
            display: block;
            height: 54px;
            button {
                display: inline-block;
                background-color: transparent;
                border: 0;
                outline: none;
            }
            a {
                display: inline-block;
                background-color: transparent;
                border: 0;
                margin-right: 0;
                width: 52px;
                position: relative;
                &:before {
                    content: "";
                    display: block;
                    margin: 6px auto 10px;
                    @include size(36px);
                }
                &:after {
                    content: "";
                    display: block;
                    border: 1px solid #eee;
                    border-radius: 50%;
                    position: absolute;
                    @include size(36px);
                    top: 0;
                    left: 50%;
                    margin-left: -18px;
                }
                .c-like__count {
                    display: block;
                    margin-top: 4px;
                    font-size: 10px;
                    color: #666;
                    text-align: center;
                    letter-spacing: normal;
                }
            }
            .c-like {
                &:before {
                    @include sprite-retina($sp_btn_store_zim_off);
                }
                &.on {
                    &:before {
                        @include sprite-retina($sp_btn_store_zim_on);
                    }
                }
            }
            .c-storeCoupon {
                &:before {
                    @include sprite-retina($sp_btn_store_coupon);
                }
            }
        }
    }
    .b-product--col3 {
        display: flex;
        padding: 0 12px 16px;
        li {
            flex: 1;
            justify-content: center;
            align-items: center;
            width: calc(100% / 3);
            .c-card {
                width: auto;
                margin: 0 4px;
                &__thumb {
                    width: auto;
                    height: auto;
                }
                &.c-card__gallery {
                    .c-card__info {
                        .c-card__price {
                            vertical-align: middle;
                            min-height: initial;
                            max-height: initial;
                            height: auto;
                            max-width: 100%;
                            strong {
                                height: 16px;
                                @include text-ellipsis;
                                max-width: 90%;
                            }
                            em {
                                color: $color-11st-blue;
                            }
                        }
                    }
                }
            }
        }
    }
}
