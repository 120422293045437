[data-type="ContentsList_BoxRow"] {
    .b-content-box {
        @include rounding-box;

        margin: -25px 16px 24px 16px;
        padding: 4px 20px;
        position: relative;
        background-color: #fff;
        z-index: 1;
        letter-spacing: $body-spacing;

        li {
            padding: 16px 0;
            border-bottom: 1px solid $color-gray-12;
            &:last-child {
                border-bottom: 0;
            }
        }

        .c-content-item {
            display: block;
            overflow: hidden;
            position: relative;
            padding-right: 20px;
            &:after {
                @include size(10px);

                content: "";
                display: block;
                border: 1px solid #999;
                border-width: 1px 1px 0 0;
                transform: rotate(45deg);
                position: absolute;
                right: 5px;
                top: 24px;
            }
            &__thumb {
                @include size(54px);

                float: left;
                margin-right: 14px;
                img {
                    position: relative;
                }
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 54px;
                    height: 54px;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    z-index: 1;
                }
            }
            &__title {
                display: block;
                font-weight: bold;
                padding-top: 7px;
                height: 26px;
                font-size: $font-size-subtitle2;
                color: $color-gray-03;
                white-space: pre-line;
                overflow: hidden;
            }
            &__subtext {
                display: block;
                padding-top: 4px;
                font-size: $font-size-body2;
                color: $color-gray-07;
            }
        }
    }
}
