[data-type="Contents_NoticeMore"] {
    .c-expanded {
        padding: 14px 16px 12px;
        font-size: 16px;
        color: $color-gray-03;
        line-height: 34px;

        &[open] {
            summary {
                &:after {
                    margin-top: 0;
                    transform: rotate(315deg);
                }
            }
        }
        summary {
            position: relative;
            padding-right: 32px;
            list-style: none;
            outline: none;

            &::-webkit-details-marker {
                display: none;
            }
            &:after {
                @include ico-arrow-down(8px, $color-gray-07);
                position: absolute;
                top: 50%;
                right: 14px;
                margin-top: -5px;
                content: "";
            }
        }
        &__contents {
            font-size: 14px;
            color: $color-gray-04;
            line-height: 17px;

            li {
                position: relative;
                margin-top: 8px;
                padding-left: 10px;

                &:before {
                    @include size(2px);
                    position: absolute;
                    top: 8px;
                    left: 0;
                    border-radius: 100%;
                    background: $color-gray-04;
                    content: "";
                }
            }
        }
    }
}
