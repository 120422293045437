@charset "UTF-8";
@import "../pui/sprites/sp_mart";
.w-count--floating {
    position: fixed;
    display: none;
    //bottom: -150px;
    z-index: 999;
    width: 100%;
    // MPINNER-10049 적용 후 수정예정
    &[tabindex]:focus {
        outline: 0;
    }

    &-holder,
    &-full {
        z-index: 102;

        .c-count {
            display: flex;
            flex-direction: column;
            height: 100%;
            box-sizing: border-box;

            &__list {
                flex: 1;
            }
        }
        .w-count--floating__close {
            display: block;
        }
    }
    &-holder {
        height: 400px;

        &:before {
            @include size(48px 4px);

            content: "";
            position: absolute;
            top: 6px;
            left: 50%;
            border-radius: 2px;
            background-color: $color-gray-10;
            transform: translateX(-50%);
            z-index: 2;
        }
        .c-count {
            padding-top: 32px;
        }
        .w-count--floating__close {
            z-index: -1;
            opacity: 0;
        }
    }
    &-full {
        height: 100%;
        transition: 0.3s ease-in height;

        .c-count {
            height: calc(100% - 8px);
            margin-top: 8px;
            padding-top: 48px;
            transition: 0.3s ease-in padding-top;
        }
        .w-count--floating__close {
            z-index: 2;
            opacity: 1;
            transition: 0.5s ease-in opacity;
        }
    }
    &__close {
        @include size(56px);

        display: none;
        position: absolute;
        top: 8px;
        right: 0;
        border: none;
        background-color: transparent;
        color: transparent;

        &:before,
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 6px;
            height: 88px;
            border-radius: 3px;
            background-color: $color-gray-03;
        }
        &:before {
            transform: translate(-50%, -50%) scale(0.25) rotate(45deg);
        }
        &:after {
            transform: translate(-50%, -50%) scale(0.25) rotate(-45deg);
        }
    }
}

.c-count {
    min-height: 125px;
    padding: 20px 16px 16px;
    border-radius: 12px 12px 0 0;
    color: $color-gray-04;
    font-size: 14px;
    background-color: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.15);
    box-sizing: border-box;

    h1 {
        font-size: 14px;
        color: #666;
        margin: 0 0 0 -16px;
        text-align: center;
        height: 20px;
    }
    &__logo {
        display: inline-block;
        height: 22px;
        overflow: hidden;
        vertical-align: top;
        img {
            height: 100%;

            &[alt="새벽배송"] {
                margin-left: 5px;
            }
        }
    }

    &__spot {
        margin-left: 6px;
        font-weight: normal;
        vertical-align: top;
        line-height: 24px;
        display: inline-block;
        height: 20px;
        color: #333;
    }
    &__logo--text {
        position: relative;
        padding-left: 8px;
        margin-left: 8px;
        height: 20px;
        overflow: hidden;
        line-height: 24px;
        display: inline-block;
        vertical-align: top;
        font-weight: normal;
        font-size: 14px;
        &::before {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 4px;
            width: 1px;
            height: 12px;
            background-color: #f4f4f4;
        }
    }
    &__gauge {
        padding-top: 16px;
        &--bg {
            background-color: #f4f4f4;
            border-radius: 12px;
            height: 24px;
            width: 100%;
        }
        &--live {
            letter-spacing: 0;
            display: block;
            border-radius: 12px;
            height: 24px;
            text-align: center;
            color: transparent;
            overflow: hidden;
            line-height: 24px;
            max-width: 100%;
            min-width: 33%;
            width: 33.3%;
            -webkit-transition: width 0.7s ease-in-out;
            transition: width 0.7s ease-in-out;
            &.step1 {
                width: 33.3%;
            }
            &.step2 {
                width: 66.6%;
            }
            &.step3 {
                width: 100%;
            }
            em {
                color: #fff;
                font-size: 12px;
                opacity: 0;
                -webkit-transition: opacity 0.3s ease-in-out;
                transition: opacity 0.3s ease-in-out;
                i {
                    font-style: normal;
                    font-weight: bold;
                }
                span {
                    font-size: 9px;
                    position: relative;
                    top: -1px;
                }
            }
        }
        .show_text {
            em {
                opacity: 1;
            }
        }
    }
    &__sum {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row-reverse;
        position: relative;
        margin-top: 12px;
        color: $color-gray-05;
        font-size: 13px;
        line-height: 18px;
        & > span {
            margin-left: 6px;
        }
        em {
            color: $color-gray-03;
        }
        strong {
            font-size: 16px;
            line-height: 21px;
            i {
                font-style: normal;
                vertical-align: -1px;
            }
            span {
                font-size: 12px;
            }
        }
    }
    &__list {
        overflow-y: auto;
        margin: 16px -16px -16px;
        border-top: 1px solid $color-gray-12;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;

        &-title {
            height: 59px;
            padding: 2px 16px 0;
            color: $color-gray-01;
            font-size: 17px;
            line-height: 59px;
            box-sizing: border-box;
        }
        &-product {
            border-top: 1px solid $color-gray-12;

            li {
                position: relative;

                &:before {
                    display: block;
                    margin: 0 16px;
                    border-top: 1px solid $color-gray-12;
                    content: "";
                }
                &:first-child {
                    &:before {
                        display: none;
                    }
                }
            }
        }
        .c-card-item {
            &.c-card-item--list {
            }
            &__link {
                padding: 16px;
                box-sizing: border-box;
            }
            &__thumb {
                @include size(96px);
                .c-lazyload {
                    width: 96px;
                }
            }
            &__info {
                padding: 0 0 0 14px;

                & > dl {
                    margin-bottom: 44px;
                }
                .c-starrate {
                    margin-top: 6px;
                }
            }
            &__name {
                height: auto;
                max-height: 38px;
                margin-bottom: 6px;
                color: $color-gray-03;
                line-height: 19px;
                -webkit-box-orient: initial;
            }
            &__price {
                color: $color-gray-02;
            }
            .c-card-item__cart,
            .c-card-item__addition {
                position: absolute;
                left: 126px;
                bottom: 15px;
                width: 155px;
            }
            .c-card-item__addition-link {
                box-sizing: border-box;
            }
        }
    }
}

.dim {
    &.dim--transparent {
        background-color: rgba(0, 0, 0, 0);
    }
}
