[data-type="ContentsGrid_Service_Col2"] {
    padding: 0 10px 2px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 6px;

        &:first-child,
        &:nth-child(2) {
            padding-top: 0;
        }
    }
    .c-card {
        &__thumb {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        &__info {
            padding: 10px 12px;
        }
        &__text {
            @include text-ellipsis-multiple(2);
            height: 36px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 18px;
        }
        &__title {
            display: block;
            overflow: hidden;
            height: 19px;
            margin-top: 5px;
            font-size: 15px;
            font-weight: normal;
            color: $color-11st-blue;
            line-height: 19px;
        }
    }
    @include badge-new;
    &#blckSn-7062 {
        padding-bottom: 16px;
    }
}
