[data-type="FlatButton_ReplaceAndLink"] {
    .c-relates {
        padding: 11px 0 12px;
        overflow: visible;
        &__link-text {
            &--goto {
                &:after {
                    @include rotate(45deg);
                    top: 55%;
                }
            }
        }
    }
}
