[data-type="ProductGrid_StoreRanking"] {
    @include grid(2, 2, true);
    background-color: $color-gray-14;

    .l-grid__col--12 {
        &:last-child::after {
            display: none;
        }
    }

    .c-seller-product {
        position: relative;
        width: 100%;
        display: flex;

        &__info {
            display: block;
            width: 80px;
            flex: 1 0 80px;

            .ranking {
                display: block;
                font-size: 12px;
                color: $color-gray-04;
                letter-spacing: 0;
            }

            .c-seller-product__link {
                overflow: hidden;
                display: block;
                word-break: keep-all;

                .name {
                    position: relative;
                    font-size: 12px;

                    .arrow {
                        position: relative;
                        display: inline-block;
                        width: 12px;
                        height: 12px;
                        padding-left: 4px;

                        &:before {
                            height: 9px;
                            width: 9px;
                            transform: rotate(314deg);
                            position: absolute;
                            top: 7px;
                            left: 0;
                            margin: -4px 0 0 -2px;
                            border: 1px solid #d6d6d6;
                            border-width: 0 1px 1px 0;
                            content: "";
                        }
                    }
                }
            }

            .func {
                display: flex;
                position: absolute;
                bottom: 10px;
                font-size: 10px;

                a {
                    position: relative;
                    width: 38px;
                    margin: 0 auto;
                    text-align: center;
                    white-space: nowrap;

                    &:before {
                        content: "";
                        display: block;
                        margin: 5px auto 11px;
                        margin-top: 5px;
                        margin-bottom: 11px;
                    }

                    &:after {
                        content: "";
                        display: block;
                        border: 1px solid $color-gray-11;
                        border-radius: 50%;
                        position: absolute;
                        @include size(34px);
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }

                    &:visited:after {
                        border-color: $color-gray-11;
                    }

                    &:nth-child(2) {
                        margin-left: 6px;
                    }

                    &.like {
                        &:before {
                            @include zzim-store(off);
                            margin-top: 5px;
                            margin-bottom: 11px;
                        }

                        &.on {
                            &:before {
                                @include zzim-store(on);
                                margin-top: 5px;
                                margin-bottom: 11px;
                            }
                        }
                    }

                    &.coupon {
                        &:before {
                            @include sprite-retina($sp_btn_store_coupon);
                        }
                    }
                }
            }
        }

        &__list {
            display: flex;
            width: 100%;
            margin-left: 12px;
            margin-right: -4px;

            li {
                width: 33%;
                padding: 0 4px;

                .c-card.c-card__gallery {
                    .c-card__thumb {
                        @include size(100%);

                        @media screen and (max-width: 459px) and (min-width: 414px) {
                            @include size(100%);
                        }
                    }

                    .c-card__info {
                        padding: 8px 0 0;

                        .c-card__name {
                            font-size: 12px;
                            margin-bottom: 2px;
                            height: initial;
                            max-height: initial;
                            word-break: break-all;
                        }

                        .c-card__price {
                            font-size: 11px;
                            min-height: initial;
                            height: auto;
                            line-height: 19px;
                            vertical-align: bottom;

                            strong {
                                font-size: 14px;
                            }
                        }

                        .c-card__counsel {
                            padding: 2px 0 0 0;
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}
