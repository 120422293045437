@import "../../../sass/pui/sprites/sp_icons";

[data-type="BannerContents_Fashion_Scroll"] {
    position: relative;
    .swiper-container {
        height: 400px;
    }
    .swiper-wrapper {
        transition-timing-function: initial;
    }
    .swiper-slide {
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        height: calc(100% - 93px);
        text-align: center;
        font-size: 18px;
        background: #fff;
        align-items: center;
        transform: translate3d( -16px, 0, 0) !important;
        &.last {
            transform: translate3d(0, 0, 0) !important;
        }
    }
    .swiper-container-horizontal>.swiper-pagination-progressbar {
        position: absolute;
        top: 50px;
        left: auto;
        right: 0;
        width: 320px;
        height: 1px;
    }
    .c-parallax {
        &__title {
            position: absolute;
            top: 16px;
            left: 32px;
            display: block;
            color: #fff;
            font-size: 24px;
            font-weight: 600;
            z-index: 1;
            height: 29px;
            span {
                display: block;
                position: relative;
                padding-right: 30px;
                height: 29px;
                line-height: 30px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
                &:after {
                    position: absolute;
                    right: -3px;
                    top: -2px;
                    display: block;
                    content: '';
                    @include sprite-retina($sp_icons_chevron);
                }
            }
        }
        &__image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            text-indent: -99999px;
        }
        &__info {
            position: relative;
            z-index: 1;
        }
        &__pagination {
            position: absolute;
            overflow: hidden;
            bottom: 20px;
            left: 16px;
            width: 100%;
            height: 2px;
            background-color: rgba(255, 255, 255, 0.3);
            z-index: 100;
            &-bar {
                position: absolute;
                opacity: 1;
                height: 2px;
                z-index: 101;
                background-color: #fff;
            }
        }
        &__more {
            position: relative;
            width: 104px;
            height: 100%;
            background-color: rgba(0,0,0,.2);
            span {
                position: absolute;
                top: 40%;
                transform: translateY(-50%);
                display: block;
                width: 48px;
                text-align: center;
                color: #fff;
                font-size: 13px;
                left: 50%;
                transform: translateX(-50%);
                letter-spacing: 0;
                &:before {
                    content: '';
                    display: block;
                    width: 48px;
                    height: 48px;
                    margin-bottom: 8px;
                    border-radius: 100px;
                    background-color: rgba(0, 0, 0, .1);
                }
                &:after {
                    @include sprite-retina($sp_icons_arrow_more);
                    content: '';
                    width: 24px;
                    height: 24px;
                    display: block;
                    position: absolute;
                    top: 13px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .c-card {
        top: -16px;
        padding: 10px;
        margin: 0 16px;
        min-height: 74px;
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: 4px 4px 8px 0 rgba(0, 0, 0, 0.15);
        z-index: 1;
        &--background {
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 93px;
            background-color: #787a7d;
        }
        &__thumb {
            width: 52px;
            height: 52px;
            position: absolute;
            z-index: 1;
        }
        &__info {
            padding: 0;
            padding-top: 4px;
            padding-left: 60px;
            background: transparent;
            .c-card__name {
                color: #333;
                margin-bottom: 7px;
            }
            .c-card__rate {
                strong {
                    font-size: 18px;
                }
            }
            .c-card__price {
                strong {
                    font-size: 18px;
                }
            }
        }
    }
    .c-insta-id {
        position: absolute;
        left: 32px;
        top: 50px;
        color: rgba(255, 255, 255, 0.8);
        text-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
        font-size: 13px;
        z-index: 1;
        letter-spacing: normal;
        img {
            width: 24px;
            height: 24px;
            margin-top: -1px;
            vertical-align: middle;
        }
    }
}
