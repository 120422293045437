.c-icontext {
    display: block;

    &__icon {
        display: block;
        position: relative;
        width: 52px;
        height: 52px;
        border-radius: 50%;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }

        &:after {
            position: absolute;
            left: -1px;
            right: -1px;
            top: -1px;
            bottom: -1px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 50%;
            content: '';
        }
    }

    &__text {
        display: block;
        margin-top: 5px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: #747474;
        text-align: center;
        white-space: nowrap;
    }

    &--selected {
        .c-icontext__icon {
            border-color: #fc4352;
        }

        .c-icontext__text {
            color: #000;
        }
    }
}
