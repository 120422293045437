@import "../../common/partial/icon";

[data-type="ExhibitionBanner_Outlet_MDPick"] {
    .mod-swiper--bgimg {
        position: relative;
        padding-top: 16px;
        padding-bottom: 10px;
        .c-headline {
            text-align: center;
            padding: 10px 0 18px;
            z-index: 1;
            background-color: transparent;
            z-index: 2;
            .c-headline__title {
                display: inline-block;
                position: relative;
                width: auto;
                height: initial;
                padding: 0 30px;
                line-height: 24px;
                font-size: 22px;
                color: #fff;
            }
            .c-headline__subtext {
                margin-top: 5px;
                padding: 0;
                color: #fff;
            }
        }
        .swiper-background-wrapper {
            position: absolute;
            overflow: hidden;
            width: 100%;
            height: 60%;
            top: 0;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background: rgba(0, 0, 0, 0.2);
                z-index: 1;
            }
            .swiper-background {
                position: absolute;
                width: 110%;
                height: 110%;
                top: -5px;
                left: -5px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                transition: background 0.5s linear;
                filter: blur(5px);
                -o-filter: blur(5px);
                -ms-filter: blur(5px);
                -moz-filter: blur(5px);
                -webkit-filter: blur(5px);
            }
        }
        .swiper-slide {
            padding: 0 16px;
            text-align: center;
            font-size: 18px;
            background: transparent;
            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            -webkit-align-items: center;
            align-items: center;
        }
        @include pagination-roadshop;
        .c-pagination__indicator {
            span {
                &.on {
                    background-color: #c29254;
                }
            }
        }
        .swiper-pagination {
            left: 50%;
            transform: translateX(-50%);
            span {
                margin: 10px 3px;
            }
        }
        .c-card__deal {
            border-bottom: 0;
            width: 100%;
        }
        .c-card__info {
            padding-top: 20px;
            height: 70px;
            .c-card__name {
                font-size: 18px;
                height: 24px;
                margin-bottom: 0;
            }
            .c-card__tag {
                display: block;
                width: 100%;
                text-align: center;
                font-size: 13px;
                color: #878787;
                line-height: 18px;
                height: 22px;
                overflow: hidden;
            }
        }
    }
}
