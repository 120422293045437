[data-type="ProductCard_TimeDeal_Scroll"] {
    letter-spacing: 0;

    @include grid(0, 0, false);

    .c-headline {
        &__title {
            font-size: 18px;
            line-height: 34px;
            height: 59px;
            padding: 12px 16px 11px 16px;

            strong {
                float: left;
            }

            span {
                position: relative;
                display: inline-block;
                margin-left: 11px;
                padding-left: 12px;
                font-size: 16px;
                font-weight: normal;
                color: #666;
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 12px;
                    background-color: #eee;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -6px;
                }
                time {
                    color: $color-11st-red;
                    font-weight: bold;
                }
            }
        }
        border-bottom: 1px solid $color-gray-12;
    }

    .c-card.c-card__dealcard {
        border-color: #f4f4f4;
        outline: none;
        .c-card__info {
            @include c-card-deal__info;
            padding-bottom: 0;
            height: 160px;

            .c-card__rate {
                float: none;
            }

            .c-card__delivery {
                position: absolute;
                bottom: 0;
                width: 100%;
                margin: 8px -16px 0;
                .c-card__qty {
                    font-size: 13px;
                    color: $color-gray-06;
                    &--urgent {
                        animation: 3s linear 0s urgent infinite;
                        & + .c-card__qty--urgent {
                            animation-delay: -1.5s;
                        }
                    }
                }
                .c-card__soldout {
                    color: $color-11st-red;
                }
            }
            .c-card__delivery-info {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;

                .c-card__delivery {
                    position: static;
                    margin: 0;
                }
                .c-card__qty {
                    line-height: 38px;

                    &:not(.c-card__soldout) {
                        flex-shrink: 0;
                        position: static;
                        margin-left: 8px;
                        transform: none;
                    }
                }
                .shooting-delivery,
                .shooting-install,
                .shooting-fresh,
                .shooting-plus,
                .shooting-seller {
                    font-size: 13px;

                    strong {
                        margin-right: 4px;
                    }
                }
            }
            .c-flag-box {
                position: relative;
                z-index: 2;
                margin-top: -3px;
            }
        }
    }

    .w-scroll {
        border-top: 1px solid $color-gray-12;
        z-index: 1;

        .s-list {
            width: 100%;
            min-width: 320px;
            max-width: 480px;
            margin: 0 auto;
            padding: 0 8px;

            li {
                position: relative;
                width: 16.66666666%;
                padding: 16px 0;

                a {
                    display: block;
                    margin: 0 auto;
                    width: 64px;
                    font-size: 12px;
                    line-height: 15px;
                    text-align: center;
                    white-space: nowrap;
                    color: $color-gray-06;
                    .c-card-box {
                        @include size(63px);
                        @include rounding-box;
                        display: block;
                        padding: 4px;
                        margin-bottom: 8px;
                    }
                }

                &.on {
                    a {
                        color: $color-11st-red;
                        .c-card-box {
                            border: 2px solid $color-11st-red;
                        }
                    }
                }
                @include mq-320 {
                    a {
                        width: 54px;
                        font-size: 11px;
                        .c-card-box {
                            @include size(54px);
                        }
                    }
                }
                @include mq-360 {
                    a {
                        width: 64px;
                        font-size: 12px;
                        .c-card-box {
                            @include size(63px);
                        }
                    }
                }
            }
        }
    }

    .c-flag__item--ooah,
    .c-flag__item--freshtable {
        margin-top: 3px;
    }
}
@keyframes urgent {
    0%,
    25%,
    75%,
    100% {
        opacity: 0;
    }
    35%,
    65% {
        opacity: 1;
    }
}
