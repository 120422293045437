@import "../../pui/sprites/sp_icons";

[data-type="ExhibitionBannerH"] {
    padding-bottom: 16px;
    letter-spacing: $body-spacing;

    .s-list {
        padding: 0 10px 4px;

        li {
            padding: 0 6px 4px;
        }

        .c-content-box {
            width: 316px;
            margin: 0;
        }
    }
    .c-content-box {
        @include rounding-box;
        overflow: hidden;
        margin: 0 16px 8px;

        .c-linkimg {
            display: block;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                height: 1px;
                background: rgba(0, 0, 0, .04);
                content: '';
            }
        }
    }
    .c-card-item {
        &-content {
            padding: 14px 0;

            li {
                display: list-item;
                padding: 6px 20px;
            }
        }
        &--list {
            min-height: 80px;
        }
        &__thumb {
            @include size(80px);

            &:after {
                @include size(80px);
                position: absolute;
                top: 0;
                left: 0;
                border: 1px solid rgba(0, 0, 0, .04);
                content: '';
            }
        }
        &__info {
            padding: 10px 0 0 12px;

            .c-starrate {
                margin-top: 6px;
            }
        }
        &__name {
            display: block;
            height: 18px;
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 18px;
        }
        &__price {
            height: auto;

            strong {
                font-size: 15px;
            }
        }
        &__counsel {
            padding: 0;
        }
    }
    .c-relates {
        border: none;
        background: none;

        &__link {
            em {
                color: $color-11st-blue;
            }
        }
        &__arrow {
            &:after {
                @include sprite-retina($sp_icons_flat_button_arrow_point);
            }
        }
        &__refresh {
            &:after {
                @include sprite-retina($sp_icons_reloading_point);
            }
        }
    }
}