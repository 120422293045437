[data-type="Billboard_Big"] {
    .swiper-slide-preload {
        position: initial;
        top: initial;
        left: initial;
    }
    .c-pagination {
        position: absolute;
        bottom: -4px;
        z-index: 100;
        width: 100%;
    }
    [class*="swiper-button"] {
        visibility: hidden;
    }
}
