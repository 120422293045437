[data-type="Contents_Tag_Filter"] {
    @include grid(0, 0, false);
    background-color: $color-gray-13;

    dl {
        dt {
            float: left;
            padding: 6px 0;
            width: 52px;
            font-size: $font-size-body1;
            text-align: center;
            line-height: 40px;
            border-bottom: 1px solid $color-gray-12;
        }

        &.filter--single,
        &.c-filter-normal {
            dt {
                @include sr-only;
            }
        }
    }
    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        border-bottom: 1px solid $color-gray-12;
    }
    // MPINNER-11609 삭제예정 끝
    .w-scroll-css {
        border-bottom: 1px solid $color-gray-12;

        &:after {
            @include clearfix;
        }
    }
    .s-list {
        padding: 6px 10px 6px 6px;

        li {
            padding: 0 6px 0 0;
            .c-chip {
                margin: 4px 0;
                color: $color-gray-04;
                &--selected {
                    color: $color-11st-red;
                }
            }
        }
    }
    .c-filter-normal {
        padding: 16px 12px 8px 16px;
        background: $color-gray-14;

        dd {
            font-size: 0;
        }
        .c-chip {
            margin-right: 4px;
        }
    }
}
