@import "../../common/partial/starrate";
[data-type="ProductList_ReviewBox"] {
    @include grid(2, 2, true);
    letter-spacing: $body-spacing;

    .c-card-item {
        &__thumb {
            @include size(112px);
        }
        &__info {
            padding: 0 8px 0 14px;

            .sktpass {
                overflow: hidden;
                height: 20px;
                padding-top: 1px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        &__rank {
            border-bottom-right-radius: 0;
        }
        &__rate {
            strong {
                font-weight: normal;
            }
        }
        &__name {
            display: block;
            height: 38px;
            margin-bottom: 6px;
            line-height: 19px;
        }
        &__benefit {
            font-size: 14px;
            color: $color-gray-04;

            .shooting-delivery,
            .shooting-install,
            .shooting-fresh,
            .shooting-plus,
            .shooting-seller {
                font-size: 13px;

                strong {
                    margin: -1px 4px 1px 0;
                }
            }
        }
    }

    .c-review-box {
        position: relative;
        @include rounding-box;
        border-radius: 4px;
        width: 100%;
        margin-top: 16px;
        &__contents {
            display: block;
            padding-bottom: 16px;
            .c-review-box__quotation {
                position: relative;
                overflow: hidden;
                display: -webkit-box;
                height: 82px;
                padding: 16px 16px 0 34px;
                font-size: $font-size-subtitle2;
                color: $color-gray-03;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                line-height: 1.5;
                &:before {
                    @include review-quote(15px, 14px);
                }
            }
            .c-review-box__information {
                display: flex;
                padding: 5px 0 0 34px;
                color: $color-gray-07;
                font-size: $font-size-caption1;
                .c-review-box__id {
                    position: relative;
                    padding-right: 12px;
                    &:after {
                        content: "";
                        display: block;
                        width: 2px;
                        height: 2px;
                        background-color: $color-gray-09;
                        position: absolute;
                        right: 5px;
                        top: 6px;
                        border-radius: 2px;
                    }
                }
                .c-starrate {
                    dt {
                        @include sr-only;
                    }
                    &__review,
                    &__sati-text {
                        color: $color-gray-03;
                        font-size: $font-size-body2;
                        font-weight: normal;
                        float: left;
                    }
                }
            }
        }
    }

    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        margin: 0 -16px -16px;

        .s-list {
            padding: 0 10px;

            li {
                padding: 0 6px;
            }
        }
        .c-review-box {
            width: 304px;
            margin-bottom: 16px;
        }
    }
    // MPINNER-11609 삭제예정 끝

    // 추구 scroll snap 테스트 시 활용
    .w-scroll-css {
        margin: 0 -16px -16px;

        .s-list {
            padding: 0 10px 16px;

            li {
                padding: 0 6px;

                .c-review-box {
                    width: 304px;
                }
            }
        }
    }

    &#blckSn-7988 {
        margin-bottom: 0 !important;
    }
}
