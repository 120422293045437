[data-type="Contents_Notice"] {
    .w-swiper--vertical {
        position: relative;
        height: 56px;

        &:after {
            @include ico-arrow-right(9px, $color-gray-07);
            position: absolute;
            top: 50%;
            right: 16px;
            z-index: 1;
            margin-top: -4px;
            content: "";
        }
    }
    .c-headline--low {
        .c-headline {
            &__link {
                &:after {
                    display: none;
                }
            }
            &__title {
                display: flex;
                overflow: hidden;
                padding-right: 44px;
                font-size: 16px;
                font-weight: normal;
                align-items: center;

                strong {
                    overflow: hidden;
                    font-weight: normal;
                    text-overflow: ellipsis;
                }
            }
            &__type {
                display: inline-block;
                height: 24px;
                margin-right: 8px;
                padding: 0 12px;
                border-radius: 24px;
                background: $color-11st-red;
                font-size: 12px;
                font-weight: bold;
                color: $color-gray-14;
                line-height: 25px;
            }
            &__new {
                display: inline-block;
                margin-left: 6px;
                padding: 0;
                font-size: 10px;
                font-weight: bold;
                color: $color-11st-red;

                &:before {
                    display: none;
                }
            }
        }
    }
}
