[data-type="Contents_NoticeBoard_Detail"] {
    .c-headline {
        &:after {
            position: absolute;
            left: 16px;
            right: 16px;
            bottom: 0;
            height: 1px;
            background: $color-gray-12;
            content: "";
        }
        &__text {
            padding: 24px 16px 16px;
        }
        &__title {
            height: auto;
            margin-bottom: 12px;
            padding: 0;
            font-size: 20px;
            font-weight: normal;
            color: $color-gray-02;
            line-height: 24px;
            white-space: normal;

            strong {
                display: block;
                overflow: hidden;
                font-weight: normal;
                word-break: break-all;
            }
        }
        &__type {
            display: block;
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: bold;
            color: $color-11st-red;
            line-height: 17px;
        }
        &__date {
            font-size: 13px;
            color: $color-gray-07;
        }
    }
    .c-content-detail {
        padding: 24px 16px;
        font-size: 15px;
        color: $color-gray-03;

        img {
            width: 100%;
        }
    }
}
