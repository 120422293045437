[data-type="Contents_RankingStore"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .b-ranking {
        padding-bottom: 16px;
        li {
            padding-bottom: 8px;
        }
        &-item {
            position: relative;
            @include rounding-box;

            &__title {
                position: relative;
                display: block;
                padding: 18px 60px 18px 38px;
            }

            &__rank {
                position: absolute;
                top: 30px;
                left: 18px;
                z-index: 1;
                width: 20px;
                strong {
                    font-size: $font-size-title;
                    color: $color-gray-02;
                    font-style: italic;
                    font-weight: 500;
                }
            }

            &__keyword {
                display: -webkit-box;
                color: $color-gray-02;
                padding: 2px 8px 6px 64px;
                font-size: 17px;
                overflow: hidden;
                max-height: 41px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &__desc {
                padding-left: 64px;
                font-size: $font-size-body1;
                color: $color-gray-04;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            &__thumb {
                @include size(44px);
                position: absolute;
                top: 18px;
                left: 46px;
                display: block;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 44px;
                    height: 44px;
                    border: 1px solid rgba(0, 0, 0, 0.05);
                    z-index: 1;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }
            .c-like-button--store {
                @include size(38px);
                position: absolute;
                top: 22px;
                right: 16px;
                background-color: $color-gray-14;
                border: 1px solid $color-gray-10;
                border-radius: 24px;
                &:before {
                    @include zzim-store(off);
                }
                &.active {
                    &:before {
                        @include zzim-store(on);
                    }
                }
            }

            .c-addition {
                margin: 0 16px;
                background-color: $color-gray-14;
                &-link {
                    padding: 12px 16px 12px 30px;
                }
                &-coupon {
                    &__text {
                        padding-right: 11px;
                        font-size: $font-size-body2;
                        color: $color-gray-03;

                        &:after {
                            @include size(8px);
                            margin-top: -4px;
                        }
                    }
                }
            }
        }
    }
}
