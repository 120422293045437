[data-type="ProductGrid_Basic"] {
    @include grid(2, 2, true);
    .c-card {
        &.c-card__gallery {
            .c-card__info {
                height: 156px;
                @include mq-320 {
                    height: 176px;
                }
                @include mq-360 {
                    height: 156px;
                }
                .c-card__benefit {
                    strong {
                        color: #999;
                    }
                    @import "../../skpui/component/benefit_gallery";
                }
                .c-card__price {
                    vertical-align: initial;
                }
            }
        }
        .c-card__counsel {
            padding: 25px 0 14px 0;
            height: 78px;
        }
    }
    .l-grid__col--12 {
        .c-card__counsel {
            padding: 14px 0;
            height: 66px;
        }
    }
    .l-grid__col--12.medium-6 {
        .c-card__counsel {
            padding: 12px 0 0;
            height: auto;
            margin-bottom: -8px;
        }
    }
}
