[data-type="ProductScroll_Trip_Airport"] {
    @include grid(1, 0, true);
    .l-grid__col {
        padding-top: 0;
        .s-list {
            padding-top: 0;
            li {
                padding-top: 0;
            }
        }
    }
    .c-airpass {
        @include size(200px 167px);
        display: block;
        position: relative;
        overflow: hidden;
        padding: 13px 16px 0 16px;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 16px;
        background-color: #fff;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
        &__departure {
            display: block;
            padding-bottom: 8px;
            font-size: 12px;
            color: #888;
        }
        &__destination {
            display: block;
            padding-bottom: 5px;
            font-size: 18px;
            font-weight: bold;
            color: #111;
        }
        &__date {
            display: block;
            padding-bottom: 9px;
            font-size: 12px;
            color: #888;
        }
        &__price {
            display: block;
            color: #111;
            font-size: 15px;
            strong {
                font-weight: bold;
                font-size: 18px;
            }
        }
        &__feature {
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            padding-left: 16px;
            height: 32px;
            line-height: 34px;
            background-color: rgba(0, 0, 0, 0.05);
            font-size: 12px;
            color: #888;
            padding-right: 84px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        &__flight {
            @include size(48px);
            display: block;
            position: absolute;
            bottom: 8px;
            right: 8px;
        }
        &--region {
            background-color: #f0f9ff;
        }
        &--more {
            background-color: #f4f4f4;
            padding-top: 39px;
            .c-airpass__text {
                display: inline-block;
                font-size: 18px;
                line-height: 26px;
                font-weight: bold;
                color: #666;
                &-go {
                    position: relative;
                    padding-right: 20px;
                    &:after {
                        @include rotate(45deg);
                        height: 6px;
                        width: 6px;
                        content: "";
                        position: absolute;
                        top: 50%;
                        right: 8px;
                        margin: -2px 0 0 -5px;
                        border: 1px solid #666;
                        border-width: 2px 2px 0 0;
                        margin-left: -8px;
                    }
                }
            }
        }
    }
}
