@import "../../pui/sprites/sp_order";
@import "../../pui/sprites/sp_review";

[data-type="Contents_DetailBox"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;
    background-color: $color-gray-14 !important;
    padding-bottom: 12px !important;

    .l-grid__col {
        padding-bottom: 12px;
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3) {
            .c-card__order-text {
                @include sprite-retina($sp_order_red);
            }
        }
    }

    .c-card {
        color: $color-gray-02;
        &--box {
            padding-bottom: 30px;
        }

        &__thumb {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @include thumbnail-playtime;
            @include thumbnail-contentslink;
            video {
                display: block;
                width: 100%;
                height: inherit;
            }
            .c-cover {
                &:after {
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: "";
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                    @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
                }
            }
            @include badge-new;
        }
        &__writer {
            font-size: 0;

            &-info {
                display: inline-block;
                margin: 1px 0 -1px;
                font-size: 13px;
                vertical-align: middle;
            }
            .c-card__thumb {
                @include size(28px);
                display: inline-block;
                overflow: hidden;
                position: relative;
                margin-right: 8px;
                border-radius: 100%;
                vertical-align: middle;

                &:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    border-radius: 100%;
                    content: "";
                }
            }
        }
        &__info {
            padding: 10px 16px 19px;

            .c-card__content {
                position: relative;
            }
            .c-card__title {
                display: -webkit-box;
                overflow: hidden;
                height: auto;
                max-height: 54px;
                margin: 8px 0;
                font-size: 20px;
                line-height: 26px;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
            .c-card__text {
                overflow: hidden;
                max-height: 40px;
                font-size: 14px;
                color: $color-gray-02;
                line-height: 20px;
                @include text-ellipsis-multiple(2);
            }
            .c-card__more-text {
                position: absolute;
                bottom: 1px;
                right: 0;
            }
        }
        &__order-text {
            position: absolute;
            top: -4px;
            right: 16px;
            z-index: 2;
            width: 31px;
            height: 34px;
            line-height: 30px;
            text-align: center;
            @include sprite-retina($sp_order_gray);

            span {
                font-size: 15px;
                font-weight: bold;
                color: $color-gray-14;
            }

            &.c-card__order-point {
                @include sprite-retina($sp_order_red);
            }
        }

        &--normal {
            padding: 0;

            .c-card__info {
                padding-bottom: 11px;
            }
        }
    }
    .c-tag-wrap {
        white-space: normal;
        overflow: hidden;
        margin: 0 12px;
        height: 18px;
        position: absolute;
        left: 12px;
        bottom: 30px;
        font-size: 0;
    }

    .c-tag {
        display: inline-block;
        font-size: $font-size-body2;
        border-radius: 0;
        border: 0;
        color: $color-11st-blue;
        background-color: $color-gray-12;
        padding: 2px 4px;
        margin: 0 4px 4px 0;
        height: initial;
        line-height: initial;
        z-index: 2;
    }
}
