[data-type="BillBoard"] {
    .w-swiper {
        [class*="swiper-button"]{
            visibility: hidden;
        }
    }
    .c-pagination__indicator {
        line-height: 35px;
        span {
            width: 8px;
            height: 8px;
            margin: 0 3px;
            background-color: #000;
            border-color: #fff;
            opacity: .3;
            &.on {
                opacity: 1;
            }
        }
    }
}
