[data-type="ProductGrid_Department"] {
    @include grid(2, 2, true);
    background-color: #fff;

    .c-card.c-card__gallery {
        .c-card__thumb {
            width: initial;
            height: initial;
            @media screen and (max-width: 374px) and (min-width: 360px) {
                width: initial;
                height: initial;
            }
        }
        .c-card__info {
            .c-card__benefit {
                display: block;
                padding-top: 5px;
                height: 24px;
                color: #968e86;
                font-size: 13px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .c-relates {
        &.c-relates__gallery {
            padding: 0;
            margin-top: 3px;
            background-color: #fafafa;
            border-top: 1px solid #f4f4f4;
            border-bottom: 1px solid #f4f4f4;
            .c-relates__function {
                float: none;
                padding-left: 8px;
                .c-relates__button-link {
                    color: #968e86;
                    padding: 6px 0;
                    padding-right: 20px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &:after {
                        top: 53%;
                        border-color: #999;
                    }
                }
            }
        }
    }
}
