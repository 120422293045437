[data-type="Contents_11day_Corner"] {
    background: rgb(155, 0, 0);
    background: -webkit-linear-gradient(-45deg, rgba(237, 4, 0, 1) 0%, rgba(187, 0, 0, 1) 64%, rgba(155, 0, 0, 1) 100%);
    background: linear-gradient(135deg, rgba(237, 4, 0, 1) 0%, rgba(187, 0, 0, 1) 64%, rgba(155, 0, 0, 1) 100%);
    .l-grid__col {
        // background-image: url("../../img/11festa/bg_counter2.png");
        // background-repeat: no-repeat;
        // background-position: 0 0;
        // background-size: 100%;
        // object-fit: cover;
        width: initial;
        margin: 0 16px;

        .c-title {
            padding-top: 20px;
            text-align: center;
            span {
                @include size(74px 33px);
                display: block;
                margin: 0 auto;
                background: url("../../img/11festa/logo2.png") no-repeat 0 0;
                background-size: 74px 33px;
                text-indent: -999999px;
            }
            &__subtext {
                padding-top: 8px;
                font-size: $font-size-body2;
                color: $color-gray-14;
                text-align: center;
                letter-spacing: $body-spacing;
            }
            &-image {
                width: 328px;
                height: 91px;
                margin: 0 auto;
                img {
                    width: 100%;
                }
            }
        }

        .c-counter {
            padding-top: 8px;
            padding-bottom: 2px;
            font-size: 20px;
            color: $color-gray-14;
            text-align: center;
            &__number {
                display: inline-block;
                width: 30px;
                height: 30px;
                margin: 0 1px;
                border-radius: 2px;
                background-color: #2b2936;
                font-size: 19px;
                font-weight: bold;
                color: $color-gray-14;
                line-height: 33px;
                text-align: center;
                vertical-align: middle;
            }
            &__string {
                display: inline-block;
                vertical-align: bottom;
            }

            .odometer {
                &.odometer-auto-theme {
                    display: -moz-inline-box;
                    display: inline-block;
                    position: relative;
                    vertical-align: middle;
                    *vertical-align: auto;
                    -moz-box-orient: vertical;
                    .odometer-digit {
                        display: -moz-inline-box;
                        display: inline-block;
                        position: relative;
                        width: 30px;
                        height: 30px;
                        margin: 0 1px;
                        border-radius: 2px;
                        background-color: #2b2936;
                        font-size: 19px;
                        font-weight: bold;
                        color: $color-gray-14;
                        line-height: 33px;
                        text-align: center;
                        vertical-align: middle;
                        *vertical-align: auto;
                        -moz-box-orient: vertical;

                        .odometer-digit-spacer {
                            display: -moz-inline-box;
                            display: inline-block;
                            visibility: hidden;
                            vertical-align: middle;
                            *vertical-align: auto;
                            -moz-box-orient: vertical;
                        }

                        .odometer-digit-inner {
                            display: block;
                            position: absolute;
                            overflow: hidden;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            text-align: left;
                        }

                        .odometer-ribbon {
                            display: block;
                        }

                        .odometer-ribbon-inner {
                            display: block;
                            -webkit-backface-visibility: hidden;
                            backface-visibility: hidden;
                        }

                        .odometer-value {
                            display: block;
                            -webkit-transform: translateZ(0);
                            transform: translateZ(0);
                        }

                        .odometer-value.odometer-last-value {
                            position: absolute;
                            width: 100%;
                        }
                    }
                    .odometer-formatting-mark {
                        vertical-align: bottom;
                    }
                }

                &.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner {
                    -webkit-transition: -webkit-transform 2s;
                    -moz-transition: -moz-transform 2s;
                    -ms-transition: -ms-transform 2s;
                    -o-transition: -o-transform 2s;
                    transition: transform 2s;
                }
                &.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner,
                &.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner {
                    -webkit-transform: translateY(-100%);
                    -moz-transform: translateY(-100%);
                    -ms-transform: translateY(-100%);
                    -o-transform: translateY(-100%);
                    transform: translateY(-100%);
                }
                &.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
                    -webkit-transition: -webkit-transform 2s;
                    -moz-transition: -moz-transform 2s;
                    -ms-transition: -ms-transform 2s;
                    -o-transition: -o-transform 2s;
                    transition: transform 2s;
                    -webkit-transform: translateY(0);
                    -moz-transform: translateY(0);
                    -ms-transform: translateY(0);
                    -o-transform: translateY(0);
                    transform: translateY(0);
                }
                &.odometer-auto-theme .odometer-value {
                    text-align: center;
                }
            }
        }

        .c-category {
            &--11festa {
                overflow: hidden;
                margin-top: 14px;
                border-radius: 4px;
                background-color: $color-gray-14;
                .c-listimg {
                    margin-bottom: 0;
                    padding: 8px 8px 12px;
                    &--icon {
                        .c-listimg__item {
                            .c-listimg__link {
                                overflow: hidden;
                                border: 0;
                                .c-listimg__icon-wrap {
                                    width: 60px;
                                    height: 60px;
                                }
                                .c-listimg__name {
                                    height: 21px;
                                    font-size: $font-size-body2;
                                    color: $color-gray-02;
                                    line-height: 28px;
                                    letter-spacing: $body-spacing;
                                }
                                &:after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }

            &--grand {
                margin: 20px 0 24px;
                border-radius: 4px;
                background-color: $color-gray-14;
                box-shadow: 0 2px 6px 0 rgba($color: $color-gray-01, $alpha: 0.06), 0 0 1px 0 rgba($color: $color-gray-01, $alpha: 0.28);
                backdrop-filter: blur(4px);

                .s-list {
                    padding: 12px 8px 12px 12px;
                }

                .c-listimg {
                    display: flex;
                    overflow: hidden;
                    margin: 0 auto;
                    flex-wrap: wrap;

                    &__link {
                        border: none;
                        color: #222;
                    }

                    &__item {
                        width: 68px;
                        margin-right: 4px;
                        padding: 0 2px;
                        box-sizing: border-box;
                    }

                    &__name {
                        margin-top: 6px;
                    }

                    &__icon-wrap {
                        width: 56px;
                        height: 56px;
                    }
                }
            }
        }

        .c-title-image + .c-category--11festa {
            position: relative;
            margin-top: -7px;
        }
        .c-relates {
            border-top: 0;
            background-color: transparent;
            font-size: $font-size-body1;
            letter-spacing: $body-spacing;
            &--big {
                color: $color-gray-14;
            }
            &__link {
                &:before {
                    display: none;
                }
                .c-relates__arrow {
                    &:after {
                        background-image: url("/MW/img/11festa/more.png");
                        background-position: 0 0;
                        background-size: 24px 24px;
                    }
                }
            }
        }
    }
}
