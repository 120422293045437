[data-type="Tabs_ImgText_Scroll_Brand_Sticky"] {
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .w-magnet {
        width: 100%;
        max-width: 1280px;
        background: $color-gray-14;
    }
    .w-scroll-css {
        padding: 12px 0;
    }
    .s-list {
        padding: 0 10px 0 16px;

        li {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 16px;
                background: $color-gray-14;
                content: "";
            }
            .c-chip {
                margin-bottom: 0;
            }
        }
    }
    .c-chip {
        display: flex;
        height: 36px;
        border-radius: 18px;
        font-size: 15px;
        align-items: center;

        &__image {
            @include size(28px);
            display: inline-block;
            overflow: hidden;
            margin: 0 6px 0 -6px;
            border-radius: 100%;
        }
        &--selected {
            background: $color-11st-red;
            border-color: transparent;
            font-weight: bold;
            color: $color-gray-14;
        }
    }
    .c-lazyload:before {
        background-color: transparent;
        background-size: 20px 16px;
    }

    // 어드민에서 lastUnderLine = 'Y로 하단 라인을 노출한 경우 l-grid__row–-border 클래스가 적용, 이 상태에서 sticky 될 때 하단 라인이 노출되어야 함
    &.l-grid__row--border .c-magnet-multiple--fixed {
        border-bottom: 1px solid $color-gray-12;
    }
}
