[data-type="Tabs_ColorRoundBtn_Scroll"] {
    @import "../../eui/component/chip";

    .c-magnet {
        height: initial !important;
        .w-scroll {
            padding: 12px 0;
        }
    }
    .w-scroll {
        padding: 12px 0;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .w-scroll-css {
        padding: 12px 0;
    }
    .s-list {
        padding: 0 0 0 16px;

        li {
            position: relative;
            padding: 0;
        }
    }
    .c-chip {
        padding: 0;
        margin: 0 16px 0 0;
        border: 0;

        &[aria-selected="true"] {
            padding: 0 12px;
            border: 1px solid rgba($color: $color-11st-red, $alpha: 0.2);
        }
    }
}
