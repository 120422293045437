[data-type="BannerContents_LogoText_Search"] {
    @include grid(2, 2, false);
    background-color: #fff !important;
    .c-searchlink {
        width: 100%;
        padding: 0;
        &__button {
            position: relative;
            display: flex;
            height: 40px;
            border-radius: 20px;
            background-color: $color-gray-12;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 1px solid $color-gray-11;
                border-radius: 20px;
            }
            &-search {
                @include size(32px);
                display: block;
                margin: 4px;
                position: absolute;
                right: 0;
                img {
                    width: 32px;
                    height: 32px;
                }
            }
        }
        &__service {
            position: relative;
            width: 100%;
            display: flex;
        }
        &__img {
            @include size(auto 24px);
            display: block;
            margin: 8px 4px 0 16px;
            img {
                width: auto;
                height: 24px;
            }
        }
        &__text {
            display: block;
            position: relative;
            height: 40px;
            line-height: 42px;
            font-size: 15px;
            color: $color-gray-03;
        }
    }
}
