[data-type="ContentsScroll_TrendFormBox"] {
    letter-spacing: $body-spacing;
    .s-list {
        padding: 0 12px 20px 12px;
        li {
            padding: 0 6px;
        }
    }
    .b-content-box {
        @include rounding-box;

        padding: 16px 16px 2px 16px;
        width: 298px;

        .c-title-link {
            position: relative;
            display: block;
            height: 24px;
            margin-bottom: 12px;
            line-height: 23px;

            span {
                position: relative;
                display: inline-block;
                padding-right: 21px;
                height: 26px;
                overflow: hidden;
                white-space: pre-line;
                font-size: 17px;
                color: $color-gray-03;

                &:after {
                    @include ico-arrow-right(10px, #666);
                    position: absolute;
                    top: 5px;
                    right: 5px;
                }
            }
        }

        .b-card--col2 {
            display: table;
            width: 100%;
            padding-bottom: 14px;
            border-bottom: 1px solid $color-gray-12;
            .c-card {
                display: inline-block;
                width: 126px;
                vertical-align: top;
                &:first-child {
                    margin-right: 6px;
                }
                &:last-child {
                    margin-left: 6px;
                }
                &__thumb {
                    &:before {
                        content: "";
                        display: block;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        z-index: 1;
                    }
                }
                &__info {
                    padding: 10px 0 6px 0;
                    height: 50px;
                }
                &__title {
                    font-size: $font-size-body1;
                    color: $color-gray-03;
                    word-break: keep-all;
                    margin-top: 0;
                    line-height: 18px;
                }
            }
        }

        .c-list {
            &__item {
                padding: 0;
                display: block;
                height: 45px;
                &:first-child {
                    .c-list__item-link {
                        padding-top: 0;
                        height: 45px;
                    }
                }
                &:last-child {
                    .c-list__item-link {
                        border-bottom: 0;
                        height: 45px;
                        padding-bottom: 0;
                    }
                }
                &-link {
                    margin: 0;
                    padding: 0;
                    height: 45px;
                    line-height: 47px;
                    border-bottom: 1px solid $color-gray-12;
                    white-space: initial;
                    font-size: $font-size-body1;
                }
            }

            &__text {
                line-height: 20px;
                &-color {
                    color: $color-11st-blue;
                    line-height: 20px;
                    padding-right: 8px;
                }
            }
        }
    }
}
