[data-type="CouponBanner"] {
    .c-lazyload {
        padding-bottom: 0;
        height: 40px;

        &:before {
            background-color: transparent;
            background-size: 30px 26px;
        }
        img {
            @include size(300px 40px);
        }
    }
}
