[data-type="Tabs_ImgTextRound_Col2"] {
    padding: 16px 0;
    background: $color-gray-14;
    .c-tab {
        font-size: 0;
        text-align: center;
        &__list {
            display: inline-block;
            width: auto;
            background: $color-gray-11;
            border-radius: 17px;

            .c-tab__item {
                position: relative;
            }

            .c-tooltip {
                position: absolute;
                left: 1px;
                top: -32px;
                z-index: 10;
                color: $color-11st-red;
                background: #fff;
                border: 1px solid rgba($color: $color-11st-red, $alpha: 0.2);
                line-height: 1;
                em {
                    font-weight: bold;
                }
                &:before {
                    width: 10px;
                    height: 10px;
                    border: 1px solid red;
                    border-bottom-color: red;
                    background-color: #fff;
                    border-left-width: 0;
                    border-top-width: 0;
                    transform: rotateZ(45deg);
                    top: 23px;
                    left: 17px;
                    border-color: rgba($color: $color-11st-red, $alpha: 0.2);
                }
                &__text {
                    white-space: nowrap;
                }
            }
            .c-tooltip__text {
                white-space: nowrap;
            }
        }
    }
    .c-tab__item .c-tab__link {
        min-width: 135px;
        height: 34px;
        padding: 9px 10px;
        text-align: center;
        font-size: 15px;
        font-weight: normal;
        position: relative;
        z-index: 2;
        color: $color-gray-03;
        background: $color-gray-11;
        border: 0 none;
        border-radius: 17px;
        vertical-align: top;
        line-height: 18px;
        &:focus {
            outline: 0;
        }
        &:first-child {
            margin-right: 1px;
            border-top-left-radius: 17px;
            border-bottom-left-radius: 17px;
        }
        &:last-child {
            border: 0;
            border-top-right-radius: 17px;
            border-bottom-right-radius: 17px;
        }
        &:before {
            display: inline-block;
            margin: -1px 3px 1px 0;
            vertical-align: middle;
            content: "";
        }
        &[aria-selected="true"] {
            color: $color-gray-14;
            background: $color-11st-red;
            box-shadow: inset 0 0 0 1px $color-11st-red;
            font-weight: bold;
            .c-lazyload {
                img {
                    background-color: $color-11st-red;
                }
            }
        }
        .c-lazyload {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin: -1px 4px 1px 0;
            padding: 0;
            vertical-align: middle;
            &:before {
                width: 18px;
                height: 18px;
                background-color: transparent;
                background-size: 18px auto;
            }
            img {
                width: 100%;
                height: auto;
                background-color: $color-gray-11;
            }
        }
        &--open {
            &:after {
                @include sprite-retina($sp_eui_icon_beta);
                display: inline-block;
                margin: 0 0 0 4px;
                vertical-align: top;
                content: "";
            }
            &[aria-selected="true"] {
                &:after {
                    @include sprite-retina($sp_eui_icon_beta_selected);
                }
            }
        }
    }
}
