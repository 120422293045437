@import "../../common/helpers/variables";
@import "../../skpui/sprites/sp_components";

// 신규 베스트 탭과 동일 UI 사용 - 작업 후 별도 파일로 분리
[data-type="GridScroll_ImgText_CtgrNavi"] {
    background-color: transparent;
    position: relative;

    .l-grid__col--12 {
        position: relative;
    }

    // PUIMW-412 삭제예정 시작
    .w-scroll {
        overflow: visible;

        &:after {
            content: "";
            position: relative;
            display: block;
            width: 100%;
            height: 1px;
            background-color: #f4f4f4;
            z-index: 3;
        }
    }
    // PUIMW-412 삭제예정 끝
    .w-scroll-css {
        overflow: hidden;
        position: relative;
        border-bottom: 1px solid $color-gray-12;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    .b-category-navi {
        position: relative;
        overflow: hidden;
        background-color: #fff;
        z-index: 99;
    }

    // skpui.magnet 기능 사용시
    .c-magnet--fixed {
        &.is--active {
            .w-magnet {
                width: 100%;

                // PUIMW-412 삭제예정 시작
                .w-scroll:after {
                    content: "";
                    position: relative;
                    display: block;
                    width: 100%;
                    height: 1px;
                    background-color: #f4f4f4;
                    z-index: 4;
                }
                // PUIMW-412 삭제예정 끝
            }
        }
    }

    .c-listimg {
        &__item {
            position: relative;
            width: 20%;

            &:after {
                position: absolute;
                top: 0;
                bottom: -100%;
                width: 1px;
                background: $color-gray-12;
                content: "";
            }
            .c-listimg__link {
                overflow: hidden;
                padding: 8px 0 4px;
                border-right: none;

                .c-listimg__icon-wrap {
                    .c-lazyload {
                        &:before {
                            background: none;
                        }
                    }

                    img.c-listimg__icon {
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .c-listimg__name {
                    height: 18px;
                    margin-top: 2px;
                    white-space: pre-line;
                    overflow: hidden;
                    line-height: 18px;
                }
            }

            &.on {
                .c-listimg__link {
                    padding-left: 1px;
                    color: $color-11st-red;
                    background: linear-gradient(180deg, #f4f4f4, #fafafa);
                    border-bottom: 1px solid #f4f4f4;
                    font-weight: bold;
                }
            }

            &:nth-child(4n + 1) {
                clear: none;
            }
        }
    }

    .c-button-bottom {
        position: relative;
        display: block;
        height: 38px;
        border-bottom: 1px solid #f4f4f4;
        background: #fff;

        button {
            position: absolute;
            top: 2px;
            right: 0;
            width: 60px;
            height: 34px;
            padding-right: 28px;
            background: transparent;
            color: #333;
            font-size: 13px;
            border: 0;
            outline: none;

            &:before {
                @include size(8px);
                @include rotate(225deg);
                position: absolute;
                top: 20px;
                right: 14px;
                margin: -6px 0 0 -6px;
                border: 1px solid #333;
                border-width: 0 1px 1px 0;
                content: "";
            }

            &:after {
                display: none;
            }
            &[aria-expanded="true"] {
                &:after {
                    display: block;
                    content: "접기";
                }
            }
        }
    }

    .close {
        // PUIMW-412 삭제예정 시작
        .w-scroll {
            overflow: hidden;
        }
        // PUIMW-412 삭제예정 끝

        .w-scroll-css {
            overflow-x: auto;
        }

        .c-listimg {
            white-space: nowrap;
            display: table;
            margin-bottom: 0;
            padding-right: 40px;

            &__item {
                display: table-cell;
                float: none;
                width: initial;

                &:after {
                    display: none;
                }

                .c-listimg__link {
                    min-width: 74px;
                    border: 0;
                }

                &:nth-child(4n + 1) {
                    clear: none;
                }

                &.on {
                    .c-listimg__link {
                        background: none;
                    }
                }
            }
        }

        .c-button-bottom {
            position: initial;
            height: 0;
            border: 0;

            button {
                @include size(36px 63px);
                position: absolute;
                top: 0;
                right: 0;
                background: #fff;
                border: 0;
                color: transparent;
                border-left: 1px solid #f4f4f4;
                outline: none;

                &:before {
                    @include size(8px);
                    @include rotate(45deg);
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin: -6px 0 0 -6px;
                    border: 1px solid #333;
                    border-width: 0 1px 1px 0;
                    content: "";
                }

                &:after {
                    display: block;
                    height: 62px;
                    width: 14px;
                    position: absolute;
                    left: -15px;
                    top: 0;
                    content: "";
                    background: linear-gradient(90deg, rgba(250, 250, 250, 0) 0%, #ffffff 100%);
                }
            }
        }
    }

    .c-subcategory {
        border-top: 1px solid #d1d1d6;
        background-color: #fafafa;

        li {
            .c-subcategory__link {
                font-size: 13px;
            }
        }
    }

    .c-magnet--fixed & {
        width: 100%;
        z-index: 1;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            max-width: 1280px;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
}
