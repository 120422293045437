[data-type="FlatButton_Refresh"] {
    .c-relates {
        &__link {
            background: $color-gray-13 !important;
            outline: none;
        }
        &--big {
            font-size: 15px;
            line-height: 46px;
            &:before {
                display: none;
            }
        }
    }
    [data-type="ProductScroll_BasicBox"] + & {
        .c-relates {
            border-top: 0;
            margin-top: 0;
        }
    }
}
