[data-type="ProductScroll_DeliveryBox"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .s-list {
        padding: 0 10px 24px;

        li {
            position: relative;
            padding: 0 6px;

            .c-card {
                width: 152px;
                height: 296px;

                &__thumb {
                    width: 152px;
                    height: 152px;
                }
                &__info {
                    min-height: 144px;
                    padding: 10px 12px;
                }
                &__name {
                    display: block;
                    height: auto;
                    max-height: 36px;
                    margin-bottom: 4px;
                    line-height: 1.2;
                }
                &__rate {
                    strong {
                        font-weight: normal;
                    }
                }
                &__price {
                    font-size: $font-size-body2;
                    line-height: 20px;
                    vertical-align: middle;

                    del {
                        display: block;
                        padding-left: 0;
                        font-size: 14px;
                    }
                }
                &__benefit {
                    position: absolute;
                    left: 12px;
                    right: 0;
                    bottom: 12px;
                    height: auto;
                    padding-top: 0;
                    font-size: 13px;
                    letter-spacing: $body-spacing;

                    .benefit {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                }
                .c-starrate {
                    position: absolute;
                    left: 12px;
                    bottom: 32px;

                    &__sati,
                    &__gauge {
                        @include size(53px 10px);
                        background-size: 53px 10px;
                    }
                    &__sati {
                        margin-right: 2px;
                    }
                    &__review {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}
