[data-type="FlatButton_Arrow"] {
    .c-relates {
        width: 100%;

        &__arrow {
            font-size: 15px;

            em {
                color: $color-11st-blue;
            }
            // &:after {
            //     @include sprite-retina($sp_icons_flat_button_arrow_point);
            // }
        }
    }
}
