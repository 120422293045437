[data-type="ContentsGrid_Category_Col2"] {
    padding: 16px 10px 18px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 6px;

        &:first-child,
        &:nth-child(2) {
            padding-top: 0;
        }
    }
    .c-card {
        &__thumb {
            img {
                width: 100%;
                object-fit: cover;
            }
        }
        &__info {
            padding: 10px 12px 12px;
        }
        &__text {
            font-size: $font-size-subtitle2;
            color: $color-gray-03;
            line-height: 19px;
            height: 19px;
            white-space: pre-line;
            overflow: hidden;
            word-break: break-all;
        }
    }
    @include badge-new;
}
