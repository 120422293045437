@import "../../common/helpers/variables";
@import "../../skpui/sprites/sp_tour";
[data-type="Contents_Trip_Search"] {
    @include grid(1, 1, false);
    background-color: #e2f3fd;
    .c-tour__quick-search {
        position: relative;
        margin-bottom: -14px;
        &__tab {
            position: relative;
            display: flex;
            margin: 0 28px;
            height: 44px;
            bottom: 21px; // background-color: #5CAAED;
            background: linear-gradient(to right, #69b5e5, #5caaed);
            border-radius: 32px;
            border: 1px solid #76abd7;
            li {
                width: 33%;
                a {
                    display: block;
                    position: relative;
                    text-align: center;
                    height: 44px;
                    line-height: 42px;
                    color: #fff;
                    font-size: 17px;
                    opacity: 0.7;
                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        right: -1px;
                        top: 18px;
                        width: 4px;
                        height: 4px;
                        background: #fff;
                        opacity: 0.2;
                        border-radius: 2px;
                    }
                }
                &.on a {
                    font-weight: bold;
                    opacity: 1;
                }
                &:last-child a:after {
                    display: none;
                }
            }
        }
    }
    .c-tour__subtab-wrap {
        background-color: #fff;
        border: 1px solid #c9e3f5;
        box-shadow: 0 1px 2px 0 #e0f3ff;
        border-radius: 16px;
        padding: 0 16px 20px;
        .c-tour__subtab {
            display: table;
            width: 100%;
            border-bottom: 1px solid #eef1f3;
            li {
                display: table-cell;
                position: relative;
                a {
                    display: block;
                    position: relative;
                    text-align: center;
                    height: 43px;
                    line-height: 56px;
                    color: #666;
                    font-size: 15px;
                    .new {
                        height: 4px;
                        width: 4px;
                        border-radius: 2px;
                        overflow: hidden;
                        position: absolute;
                        top: 10px;
                        margin-left: 4px;
                        background: $color-11st-red;
                        color: transparent;
                        font-size: 2px;
                    }
                }
                &.on {
                    a {
                        color: #111;
                        font-weight: bold;
                    }
                    &:after {
                        display: block;
                        content: "";
                        width: 100%;
                        height: 1px;
                        background-color: #333;
                        position: absolute;
                        left: 0;
                        bottom: -1px;
                    }
                }
            }
            &--col4 {
                li {
                    width: 25%;
                }
            }
        }
        .c-tour__subcontent {
            display: none;
            &.on {
                display: block;
            }
            .flight {
                display: table;
                position: relative;
                width: 100%;
                padding-top: 28px;
                margin-bottom: 20px;
                &:before {
                    @include sprite-retina($sp_tour_air);
                    content: "";
                    display: block;
                    position: absolute;
                    left: 50%;
                    margin: 0 0 0 -28px;
                }
                li {
                    display: table-cell;
                    width: 50%;
                    a {
                        display: block;
                        text-align: center;
                    }
                    dl dt {
                        font-size: 30px;
                        color: #111;
                        height: 38px;
                    }
                    dl dd {
                        position: relative;
                        display: inline-block;
                        padding-right: 5px;
                        font-size: 11px;
                        color: #333;
                        &:after {
                            transform: rotate(45deg);
                            height: 6px;
                            width: 6px;
                            content: "";
                            position: absolute;
                            top: 51%;
                            right: -2px;
                            margin-top: -4px;
                            border: solid #999;
                            border-width: 1px 1px 0 0;
                        }
                    }
                    &.off {
                        dl dt,
                        dl dd {
                            color: #ccc;
                        }
                    }
                }
            }
            .search {
                width: 100%;
                height: 92px;
                padding-top: 45px;
                padding-bottom: 16px;
                display: block;
                text-align: center;
                line-height: 40px;
                .location_name {
                    position: relative;
                    font-size: 26px;
                    color: #ccc;
                    height: 27px;
                    line-height: 27px;
                    width: 300px;
                    padding-left: 36px;
                    &:before {
                        @include sprite-retina($sp_tour_search);
                        content: "";
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 4px;
                    }
                }
            }
            .text {
                position: relative;
                display: block;
                padding-top: 10px;
                padding-bottom: 27px;
                text-align: center;
                em {
                    position: relative;
                    display: inline-block;
                    color: #57a9df;
                    font-size: 12px;
                    background: url("/MW/img/skpui/bg_txt.png") repeat-x 0 bottom;
                    padding: 0 6px 2px 4px;
                    margin: 0 10px;
                    &:before {
                        @include sprite-retina($sp_tour_quo1);
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: -16px;
                    }
                    &:after {
                        @include sprite-retina($sp_tour_quo2);
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        right: -16px;
                    }
                }
            }
            .history {
                display: flex;
                justify-content: center;
                width: 100%;
                height: 40px;
                margin-bottom: 19px;
                border: 1px solid #eef1f3;
                border-radius: 4px;
                font-size: 13px;
                &__title {
                    display: table-cell;
                    padding-left: 4px;
                    padding-right: 4px;
                    font-weight: bold;
                    width: 60px;
                    height: 40px;
                    line-height: 40px;
                    color: #333;
                    @media screen and (min-width: 320px) and (max-width: 359px) {
                        width: 80px;
                    }
                }
                &__info {
                    display: table-cell;
                    padding-right: 0;
                    height: 40px;
                    line-height: 40px;
                    color: #333;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                &__date {
                    display: table-cell;
                    padding-left: 4px;
                    padding-right: 4px;
                    width: auto;
                    height: 40px;
                    line-height: 40px;
                    color: #666;
                    text-align: right;
                    white-space: nowrap;
                }
            }
        }
    }
}
