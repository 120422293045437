[data-type="Tabs_NewRoundBtn_Sticky"] {
    border-bottom: 1px solid $color-gray-12;
    .s-list {
        padding: 12px 0 12px 16px;

        li {
            padding: 0;
            .c-chip {
                margin-bottom: 0;
            }
        }
    }
    .w-magnet {
        width: 100%;
        max-width: 1280px;
        background: $color-gray-14;
    }
}
