[data-type="Cap_BigText_Highlight"] {
    letter-spacing: $body-spacing;
    .c-headline {
        height: 82px;
        padding: 16px 0 10px 0;
        .c-tag {
            font-size: $font-size-caption1;
            color: $color-11st-red;
            border-color: rgba($color: $color-11st-red, $alpha: 0.5);
            font-weight: bold;
            height: 22px;
            line-height: 22px;
            padding: 0 8px;
            margin-bottom: 7px;
        }
        &__link {
            padding: 0 90px 0 16px;
        }
        &__text {
            padding: 0 16px;
        }
        &__title {
            font-size: $font-size-headline2;
            padding: initial;
            line-height: initial;
            height: 25px;
            text-overflow: ellipsis;
            span {
                position: relative;
                padding: 0 2px;
                &:before {
                    position: absolute;
                    top: 12px;
                    right: 0;
                    left: -4px;
                    bottom: 0;
                    opacity: 0.1;
                    content: "";
                    background-color: rgba($color: $color-11st-red, $alpha: 0.9);
                }
            }
        }
        &__txtlink {
            top: 52%;
            right: 28px;
        }
    }
}
