[data-type="GridList_Img_Col3"] {
    background-color: #fff;

    .c-listimg__item {
        width: 33.3%;

        @include mq-640 {
            width: 16.666%;
        }

        &:nth-child(4n + 1) {
            clear: none;
        }

        .c-listimg__link {
            height: 43px;
            min-height: 43px;
            padding: 0;
            text-align: center;
            line-height: 32px;
            font-size: 13px;
            overflow: hidden;

            .c-listimg__icon-wrap {
                margin: 0;
                width: auto;
                padding-top: 7px;

                .c-listimg__icon {
                    @include size(80px 30px);
                }
            }
        }
    }
}