[data-type="ContentsScroll_Outlet_ExhibitionBanner"] {
    @include grid(0, 0, false);
    .s-list {
        padding: 0 12px 4px 12px;
        li {
            padding: 0 2px 4px;
        }
    }
    .c-card.c-card__slide-gallery {
        width: 122px;
        border: 0;
        .c-card__thumb {
            @include size(122px);
            .c-card__badge-text {
                display: block;
                padding: 0 8px;
                height: 24px;
                line-height: 24px;
                background: #c29254;
                color: #fff;
                font-size: 13px;
                text-align: center;
            }
        }
        .c-card__info {
            padding: 8px 0;
            .c-card__name {
                height: 36px;
                margin-bottom: 4px;
                -webkit-line-clamp: 2;
                font-size: 14px;
            }
            .c-card__price {
                vertical-align: baseline;
            }
        }
    }
}
