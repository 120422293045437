[data-type="GridScroll_ImgText"] {
    .s-list {
        padding: 0;
        li {
            padding: 0;
        }
    }
    .c-icontext__icon {
        margin: 0 auto 5px;
        img {
            border: 0;
        }
        &:after {
            border: 0;
        }
    }
}