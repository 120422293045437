[data-type="GridScroll_ImgTextCircle"] {
    .s-list {
        padding: 8px;
        li {
            padding: 8px;
        }
    }
    .c-icontext {
        &__icon {
            @include size(64px);
            border: 0;
            border-radius: 32px;
            margin: 0 auto;
            &:after {
                display: none;
            }
        }
        &__text {
            padding-top: 4px;
            font-size: 13px;
            font-weight: normal;
            width: 72px;
            height: 20px;
            overflow: hidden;
            white-space: normal;
            color: #666;
        }
    }
}
