@import "../../common/mixins";
@import "../sprites/sp_icon";

@mixin c-card-deal__info {
    padding: 13px 16px 16px;

    .c-card__name {
        margin-bottom: 3px;
        font-size: 15px;
        line-height: 19px;
    }

    .c-card__rate {
        font-size: 21px;
        font-weight: normal;
        line-height: 20px;

        strong {
            padding-right: 1px;
            font-size: 21px;
            line-height: 22px;
            letter-spacing: -0.5px;
            font-weight: normal;

            &.c-card__special {
                font-size: 16px;
            }
        }
    }

    .c-card__price {
        font-size: $font-size-body2;
        line-height: 19px;

        strong {
            padding-right: 1px;
            font-size: 22px;
            line-height: 22px;
            letter-spacing: -0.5px;
        }
    }

    .c-card__price-guide {
        height: 16px;
        margin-top: 10px;
        font-size: 13px;
        line-height: 16px;
        color: $color-gray-06;
    }

    .c-card__deal-guide {
        overflow: hidden;
        margin-top: 8px;
        padding: 2px 0;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        color: $color-11st-red;
    }
}
