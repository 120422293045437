[data-type="Cap_Today_Center"] {
    [data-type="GridScroll_ImgText_CtgrNavi"] + & {
        margin-top: 8px;
    }

    &.l-grid__row {
        background-color: #fff;
    }
    .c-headline {
        &__title {
            padding-bottom: 0;
            font-size: 22px;
            line-height: 48px;
        }
    }
}
