.c-shortcut-link {
    overflow: hidden;
    position: relative;

    li {
        float: left;
        width: 20%;

        a {
            display: block;
            position: relative;
            padding-top: 8px;
            text-align: center;

            img {
                @include size(40px 40px);

                margin: 0 auto;
            }

            span {
                display: block;
                overflow: hidden;
                height: 16px;
                margin: 0 6px;
                font-size: 12px;
                line-height: 16px;

                &.new {
                    @include size(18px 18px);

                    position: absolute;
                    left: 50%;
                    top: 6px;
                    margin-left: 12px;
                    background: url("/MW/img/life/ico_new2.png") no-repeat;
                    background: url(#{data-uri("../img/life/ico_new2.png")}) no-repeat;
                    background-size: 18px 18px;
                    text-indent: -9999px;
                }
            }
        }
    }

    &__image {
        li {
            margin-bottom: 10px;

            a {
                padding-top: 0;
                background: none;

                img {
                    @include size(56px 56px);

                    display: block;
                    margin: auto;
                }
            }
        }
    }

    &--4by {
        li {
            width: 25%;
        }
    }
}
