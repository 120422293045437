[data-type="Contents_Mart"] {
    .w-card__mart {
        position: relative;
        width: 100%;
        @include clearfix;
        font-size: 13px;
        font-weight: normal;
        color: #333;

        .c-card {
            text-align: center;
            padding: 22px 0 24px;
            float: left;
            width: 50%;
            overflow: hidden;
            position: relative;

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 21px;
                display: block;
                border-left: 1px solid #f4f4f4;
                height: 48px;
                width: 1px;
                z-index: 1;
            }

            &:first-child {
                &::before {
                    display: none;
                }
            }

            &__logo {
                display: inline-block;
                margin: 0 auto;
                color: transparent;
                height: 20px;
                position: relative;
                left: -6px;

                img {
                    height: 22px;
                }

                &::after {
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: -10px;
                    @include ico-arrow-right(7px, #999);
                }
            }

            &__info {
                padding: 8px 0 0;

                .c-card__name {
                    min-height: 42px;
                    margin-bottom: 0;
                    -webkit-line-clamp: initial;
                }
            }
        }
    }

    // MPINNER-11609 삭제예정 (.w-scroll)
    .w-scroll,
    .w-scroll-css {
        padding: 0 4px;

        .w-card__mart {
            display: table;
            overflow: hidden;
            width: initial;

            .c-card {
                display: table-cell;
                float: none;
                width: initial;

                &__info {
                    width: 152px;
                    margin: 0 auto;
                }

                &__logo {
                    height: 22px;

                    img {
                        height: 22px;
                    }
                }
            }
        }
    }
}
