@import "../../allsprites/sprites/sp_refresh";

[data-type="Cap_List_RealTime_Sorting"] {
    position: relative;

    &.l-grid__row {
        background-color: #fff !important;
    }

    // MPSR-85726 삭제예정 시작
    .c-list-header {
        font-size: 0;

        h1,
        h2,
        h3,
        h4 {
            height: 52px;
            padding: 0 16px;
            font-size: 15px;
            font-weight: normal;
            color: $color-gray-03;
            line-height: 52px;
        }
        .c-list__title {
            display: inline-block;
            padding: 0 0 0 16px;
            font-size: 13px;
            color: $color-gray-07;
        }
    }

    .c-list__sorting {
        position: absolute;
        right: 8px;
        top: 0;
        font-size: 0;

        button,
        .c-dropdown {
            position: relative;
            border: 0;
            height: 52px;
            font-size: 15px;
            color: $color-gray-04;
            background: none;
            padding: 0 28px 0 10px;

            &:focus {
                outline: 0;
            }
            &:after {
                @include ico-arrow-down(8px, #666, 1px);
                display: inline-block;
                position: absolute;
                top: 50%;
                right: 10px;
                margin: -5px 0 0 8px;
                content: "";
            }

            & + button {
                margin-left: 8px;

                &:before {
                    @include size(1px 10px);
                    position: absolute;
                    top: 50%;
                    left: -1px;
                    margin-top: -5px;
                    background: $color-gray-11;
                    content: "";
                }
            }
        }
    }
    .c-headline__time {
        margin: 6px 16px;

        &:first-child {
            position: static;
            transform: none;
        }
    }
    .c-headline__title {
        .c-headline__time {
            margin: 0;
        }
    }
    //// MPSR-85726 삭제예정 끝

    .c-headline {
        &__title {
            height: 52px;
            padding-top: 6px;
            padding-bottom: 6px;
            border-bottom: 1px solid $color-gray-11;
            font-size: 15px;
            font-weight: normal;
            color: $color-gray-03;
        }
        &__time {
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
        }
    }
    .c-list-sorting {
        display: flex;
        position: relative;
        padding: 10px 16px;
        align-items: center;

        .c-button {
            &__normal {
                color: $color-gray-02;
            }
            &__size-normal {
                padding: 0 12px;
                border-radius: 16px;

                &:after {
                    @include ico-arrow-down(7px, $color-gray-07);
                    display: inline-block;
                    margin: -3px 0 3px 10px;
                    vertical-align: middle;
                    content: "";
                }
                & + .c-button__size-normal {
                    margin-left: 6px;
                }
            }
            &__size-extra-small {
                margin-left: auto;

                &:before {
                    @include sprite-retina($sp_refresh_refresh2);
                    display: inline-block;
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
                &.c-button__dimned {
                    &:before {
                        @include sprite-retina($sp_refresh_refresh);
                    }
                }
            }
            &--active {
                position: relative;
                border-color: rgba($color: $color-11st-red, $alpha: 0.2);
                background: rgba($color: $color-11st-red, $alpha: 0.02);
                font-weight: bold;
                color: $color-11st-red;

                &:after {
                    border-color: $color-11st-red;
                }
                .sr-only {
                    @include size(3px);
                    display: inline-block;
                    position: relative;
                    top: 8px;
                    margin: 0 0 0 2px;
                    border-radius: 3px;
                    background: $color-11st-red;
                    color: transparent;
                    vertical-align: top;
                    clip: auto;
                }
            }
        }
    }
}
.c-dialog--sorting {
    .c-input-range {
        display: flex;
        margin: 16px 0 25px;
        line-height: initial;
        align-items: center;

        & + .c-input--fail-text {
            margin: -20px 0 9px;
            line-height: 1;
        }
        span {
            display: inline-block;
            width: 18px;
            font-size: 14px;
            color: $color-gray-01;
            text-align: center;
        }
    }
    .c-input-box {
        position: relative;
        flex: 1;

        .c-input {
            height: 32px;
            padding-right: 25px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 32px;

            &:focus {
                & + .c-input-del {
                    display: block;
                }
            }
        }
        .c-input-del {
            display: none;
            @include size(32px);
            position: absolute;
            top: 0;
            right: 0;
            border: 0;
            background: none;

            &:before,
            &:after {
                content: "";
                position: absolute;
                left: auto;
                right: 50%;
                top: 50%;
                width: 1px;
                height: 12px;
                margin-top: -6px;
                background: $color-gray-02;
                transform: rotate(45deg);
            }
            &:after {
                transform: rotate(-45deg);
            }
        }
    }
    .c-dialog__button--icon-refresh {
        &:before {
            @include sprite-retina($sp_refresh_refresh3);
            display: inline-block;
            margin: -1px 6px 1px 0;
            vertical-align: middle;
            content: "";
        }
    }
}
