@import "../../common/sprites/sp_eui";

[data-type="ProductList_Standard"] {
    margin-bottom: -1px;

    @media screen and (min-width: 640px) {
        display: flex;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    .l-grid__col {
        padding: 16px 0;
        border-bottom: 1px solid #f4f4f4;

        @media screen and (min-width: 640px) {
            &:nth-child(2) {
                border-top: 0;
            }
        }
    }
    .c-item-cap {
        .c-item-cap__addition {
            float: none;
        }
    }
    .c-card-item {
        &--list {
            @include clearfix();
            display: block;
            overflow: initial;
            width: initial;
            height: 100%;
            min-height: 124px;
            background: none;

            .c-item-cap {
                display: block;
                float: left;

                & + .c-card-item__link {
                    margin-left: 44px;
                }
            }
            .c-relates {
                margin-bottom: -15px;
            }
        }
        &--space {
            margin-bottom: -16px;
            padding-bottom: 58px;

            .c-card-item__cart,
            .c-card-item__addition,
            .c-relates {
                position: absolute;
                left: 154px;
                right: 16px;
                bottom: 16px;

                @media screen and (min-width: 640px) {
                    bottom: 0;
                }
                &:nth-child(4) {
                    left: 182px;
                }
            }
        }
        &__link {
            display: block;
            position: relative;
            width: initial;
            padding-left: 124px;

            &:first-child {
                padding-left: 140px;

                .c-card-item__thumb {
                    left: 16px;
                }
            }
        }
        &__thumb {
            @include size(124px);
            display: block;
            position: absolute;
            top: 0;
            left: 0;
        }
        &__info {
            display: block;
            overflow: hidden;
            padding: 0 16px 0 14px;
            background: none;
        }
        &__mart {
            margin-bottom: 6px;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 40px;
            margin-bottom: 6px;
            font-size: 16px;
            line-height: 20px;
        }
        &__rate {
            strong {
                font-size: 15px;
                font-weight: normal;
                line-height: 18px;
            }
        }
        &__price-del {
            display: inline-block;
            font-size: 12px;
            color: $color-gray-08;
            line-height: 18px;
            vertical-align: top;

            del {
                font-size: 15px;
            }
        }
        &__bargain {
            & + .c-card-item__price-info {
                margin-top: 0;
            }
        }
        &__price-info {
            margin: 10px 0;
        }
        &__price {
            display: block;
            height: auto;
            font-size: 14px;

            strong {
                font-size: 20px;
            }
        }
        &__counsel {
            margin: 10px 0;
            padding: 0;
            line-height: 19px;

            em {
                font-size: 15px;
            }
        }
        &__benefit {
            height: auto;
            margin-top: 0;
            font-size: 14px;
            color: $color-gray-04;
            line-height: 19px;

            .benefit {
                margin-top: 1px;

                & + .benefit {
                    padding-top: 0;
                }
            }
            .sktpass {
                overflow: hidden;
                height: 20px;
                padding-top: 1px;
                font-size: 14px;

                & + .benefit {
                    padding-top: 0;
                }
                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        &__button {
            top: -4px;
            right: 12px;
        }
        .c-like-button {
            &:before {
                @include size(32px);
                position: absolute;
                top: 50%;
                left: 50%;
                border: 1px solid $color-gray-10;
                border-radius: 100%;
                transform: translate(-50%, -50%);
                content: "";
            }
            &:after {
                @include sprite-retina($sp_eui_ico_like);
                margin-top: -11px;
                transform: translate(-50%, 0);
            }
            &.on {
                &:after {
                    @include sprite-retina($sp_eui_ico_like_on);
                }
            }
        }
        .c-flag-box {
            height: auto;
            max-height: 44px;
            margin-bottom: 2px;
        }
        .c-ad__btn {
            position: absolute;
            left: 0;
            bottom: -1px;
        }
    }
}
