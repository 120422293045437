@import "../../../sass/pui/sprites/sp_icons";

[data-type="FlatButton_Info"] {
    .c-button {
        &__info {
            @include text-ellipsis;
            position: relative;
            display: block;
            padding: 16px 30px 16px 16px;
            height: 48px;
            font-size: 15px;
            color: $color-gray-02;
            letter-spacing: 0;
            background: -webkit-gradient(linear, left top, right top, from(#6cf0ff), to(#75c7ff));
            background: -webkit-linear-gradient(left, #6cf0ff 0%, #75c7ff 100%);
            background: linear-gradient(90deg, #6cf0ff 0%, #75c7ff 100%);
            &:after {
                position: absolute;
                right: 16px;
                top: 24px;
                border: solid $color-gray-02;
                border-width: 1px 1px 0 0;
                width: 8px;
                height: 8px;
                margin-top: -4px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
                content: "";
            }
            strong {
                font-weight: bold;
                margin-right: 3px;
            }
        }
    }
}
