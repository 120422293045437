[data-type="Contents_Delivery_InfoBox"] {
    letter-spacing: $body-spacing;

    .l-grid__col {
        overflow: hidden;
    }

    .c-headline {
        height: 140px;
        padding: 20px 24px;
        background-color: transparent;
        background-position: 100% 0;
        background-repeat: no-repeat;
        background-size: auto 100%;

        &:last-child {
            margin-bottom: -57px;
        }
        &__title {
            height: 23px;
            padding: 0;
            font-size: 19px;
            color: $color-gray-14;
            line-height: 23px;
            white-space: normal;
        }
        &__text {
            overflow: hidden;
            height: 19px;
            margin-top: 4px;
            font-size: 15px;
            color: $color-gray-14;
            line-height: 19px;
        }
    }

    .b-content-box {
        @include rounding-box;

        margin: -57px 16px 16px;
        position: relative;
        background-color: #fff;
        z-index: 1;
        letter-spacing: $body-spacing;
    }
    .c-content-info {
        display: flex;
        overflow: hidden;
        position: relative;
        height: 56px;
        padding: 0 12px 0 12px;
        border-bottom: 1px solid $color-gray-12;
        align-items: center;
        flex: 1;
        border-width: 0 0 1px 0;
        background-color: transparent;
        width: 100%;

        &:last-child {
            border-bottom: 0;
        }
        &__text {
            position: relative;
            padding-left: 28px;
            font-size: 16px;
            color: $color-gray-03;
            line-height: 20px;
            flex: 1;
            text-align: left;

            .point {
                font-weight: normal;
                color: $color-11st-red;
            }
        }
        &__download,
        &__link {
            @include size(56px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 0;
            margin-right: -12px;
            flex: 0 1 56px;
        }
        &__download {
            border: 0;
            background: none;
            outline: none;

            img {
                @include size(36px);
            }
            &-state {
                font-size: 14px;
                color: $color-gray-07;
                line-height: 20px;
            }
        }
        &__link {
            img {
                @include size(24px);
            }
        }
        .icon {
            @include size(24px);
            position: absolute;
            top: 50%;
            left: 0;
            margin-top: -13px;
            padding: 0;

            img {
                width: 100%;
                height: 100%;
                vertical-align: top;
            }
        }
    }
}
