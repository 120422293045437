[data-type="GridList_ImgTextCard_Season_Col4"] {
    .c-listimg__item {
        .c-listimg__link {
            padding: 10px 0;
            border-width: 0 0 1px;
            .c-listimg__icon-wrap {
                @include size(80px);
                .c-listimg__icon {
                    @include size(80px);
                }
                .c-listimg__benefit {
                    position: absolute;
                    bottom: 0;
                    display: block;
                    width: 100%;
                    height: 24px;
                    line-height: 24px;
                    text-align: center;
                    font-size: 12px;
                    font-weight: bold;
                    color: #fff;
                    z-index: 1;
                    &--pink {
                        background-color: rgba(246, 106, 119, 0.9);
                    }
                    &--blue {
                        background-color: rgba(44, 177, 248, 0.9);
                    }
                }
            }
            .c-listimg__name {
                font-size: 13px;
            }
        }
    }
}
