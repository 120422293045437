[data-type="BannerContents_SearchCategory_Sticky"] {
    @include grid(0, 0, false);
    background-color: $color-gray-14 !important;
    z-index: 30;
    .c-searchlink {
        width: 100%;
        padding: 16px;
        background-color: $color-gray-14;

        &:after {
            display: block;
            content: "";
            width: 100%;
            position: absolute;
            left: 0;
            bottom: 0;
        }

        .c-searchlink__button {
            position: relative;
            display: flex;
            height: 40px;
            padding-right: 40px;
            border-radius: 20px;
            background-color: $color-gray-12;
            &:before {
                content: "";
                display: block;
                position: absolute;
                width: 100%;
                height: 100%;
                border: 1px solid $color-gray-11;
                border-radius: 20px;
            }

            &-search {
                @include size(32px);

                display: block;
                margin: 4px;
                position: absolute;
                top: 0;
                right: 0;
                img {
                    width: 100%;
                }
            }
        }

        &__img {
            @include size(auto 24px);

            display: block;
            margin: 8px 4px 0 4px;

            img {
                width: auto;
                height: 24px;
            }
        }

        &__text {
            display: block;
            position: relative;
            letter-spacing: $body-spacing;
            height: 40px;
            line-height: 42px;
            font-size: 15px;
            color: $color-gray-03;
            padding-left: 16px;
            white-space: nowrap;
            overflow: hidden;
        }
    }
    .is--active {
        .c-searchlink:after {
            display: block;
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            border-bottom: 1px solid rgba(0, 0, 0, 0.04);
        }
    }
}
