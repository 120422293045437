[data-type="FlatButton_Trip_More"] {
    background-color: $color-gray-11;

    .l-grid__col {
        padding: 10px 0;
    }
    .c-relates {
        height: 48px;
        margin: 0 16px;
        border: 1px solid rgba(0, 0, 0, 0.2);
        border-radius: 4px;
        background-color: #fff;
        letter-spacing: 0;
        &__link {
            padding: 14px 0;
            &-text:after {
                @include rotate(45deg);
                top: 50%;
                border-color: #999;
            }
        }
    }
}
