[data-type="Contents_NoticeBoard"] {
    .l-grid__col {
        position: relative;

        &:before {
            position: absolute;
            top: 0;
            left: 16px;
            right: 16px;
            height: 1px;
            background: $color-gray-12;
            content: "";
        }
        &:first-child {
            &:before {
                display: none;
            }
        }
    }
    .c-headline {
        background: none;

        &__link {
            display: flex;
            align-items: center;

            &:after {
                display: none;
            }
        }
        &__title {
            display: flex;
            overflow: hidden;
            padding-right: 16px;
            font-size: 16px;
            font-weight: normal;
            color: $color-gray-03;
            align-items: center;

            strong {
                overflow: hidden;
                height: 40px;
                font-weight: normal;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex: 1;
            }
        }
        &__type {
            display: inline-block;
            height: 24px;
            margin-right: 8px;
            padding: 0 11px;
            border: 1px solid rgba($color: $color-gray-03, $alpha: 0.2);
            border-radius: 24px;
            font-size: 12px;
            font-weight: bold;
            color: $color-gray-03;
            line-height: 23px;

            &--point {
                border-color: rgba($color: $color-11st-red, $alpha: 0.2);
                color: $color-11st-red;
            }
        }
        &__new {
            @include sprite-retina($sp_eui_new);
            overflow: hidden;
            display: inline-block;
            margin-left: 4px;
            padding: 0;
            color: transparent;

            &:before {
                display: none;
            }
        }
        &__position {
            display: inline-block;
            margin-left: auto;
            padding: 8px 16px 8px 0;
            font-size: 14px;
            color: $color-gray-07;
            line-height: 40px;
            white-space: nowrap;
        }
    }
}
