@import "../../main/main_pui/partial/flags_s-list";

[data-type="GridScroll_IconText"] {
    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        padding-top: 8px;
    }
    // MPINNER-11609 삭제예정 끝
    .w-scroll-css {
        padding-top: 8px;
    }
    .s-list {
        @include flags;
    }
}
