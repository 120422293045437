// PUI > 장바구니 담기 시 노출되는 팝업 내 요소
.c-save-product {
    position: relative;
    display: flex;
    align-items: center;
    padding: 14px 78px 14px 16px;
    border-bottom: 1px solid $color-gray-12;

    &__thumb {
        @include size(48px);
        flex-shrink: 0;
        margin-right: 10px;
    }
    &__info {
        flex: 1;
        font-size: 0;
    }
    &__text {
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        color: $color-gray-02;
        line-height: 18px;
    }
    &__text-alert {
        display: block;
        margin-top: 4px;
        font-weight: 400;
        font-size: 12px;
        color: $color-11st-red;
        line-height: 14px;
    }
    &__link {
        position: absolute;
        top: 50%;
        right: 16px;
        display: inline-block;
        margin: 0 0 1px 8px;
        font-size: 13px;
        color: $color-11st-blue;
        line-height: 18px;
        vertical-align: bottom;
        transform: translateY(-50%);

        &:after {
            @include ico-arrow-right(7px, $color-11st-blue);
            display: inline-block;
            margin: -2px 2px 1px;
            vertical-align: middle;
            content: "";
        }
    }
}
