@mixin badge-new {
    .c-badge--new {
        position: absolute;
        min-width: 35px;
        height: 20px;
        padding: 0 6px;
        font-size: 10px;
        font-weight: bold;
        line-height: 22px;
        text-align: center;
        background-color: $color-11st-red;
        border-bottom-right-radius: 3px;
        color: $color-gray-14;
        z-index: 3;
    }
}
