[data-type="Contents_No_Result"] {
    padding: 136px 0 109px;
    position: relative;
    font-weight: bold;
    text-align: center;
    font-size: 15px;
    color: #110;
    letter-spacing: 0;

    &.l-grid__row {
        background-color: #fff !important;
    }

    h1,
    h2,
    h3,
    h4 {
        font-size: 15px;
        padding: 0 32px;
    }

    strong {
        color: $color-11st-red;
    }

    p {
        margin-top: 8px;
        font-size: 13px;
        color: $color-gray-07;
        font-weight: normal;
        line-height: 1.4;
    }

    img {
        position: absolute;
        left: 50%;
        top: 64px;
        margin-left: -24px;
        display: block;
        width: 48px;
        height: 48px;
        background: url("/MW/img/common/notice.png");
        background-size: 48px 48px;
    }
}
