[data-type="Tabs_ImgText_Scroll_Red"] {
    background: #fafafa;
    .c-nav {
        background-color: #fafafa;
    }
    .c-nav__wrap {
        .c-nav__list {
            .c-nav__item {
                @include size(66px 64px);
                min-width: 66px;
                max-width: 66px;
                overflow: hidden;
                a {
                    padding: 7px 0;
                    line-height: 32px;
                }
                &.on a {
                    color: $color-11st-red;
                }
                &.on a:after {
                    background-color: $color-11st-red;
                }
            }
        }
    }
}
