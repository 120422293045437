[data-type="Tabs_TextBar_Col2_Black"] {
    @include grid(0, 0, true);
    &.l-grid__row {
        background-color: #fff;
    }

    .c-tab {
        margin: 0 16px;
        &__link {
            border: 0;
            background-color: transparent;
            color: $color-gray-04;
        }
        &__item.on {
            .c-tab__link {
                position: relative;
                border: 0;
                color: #111;
                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 1px;
                    border-bottom: 1px solid #111;
                    margin-top: -1px;
                }
            }
        }
        &__item:last-child {
            .c-tab__link {
                border-right: 0;
            }
        }
    }
}
