[data-type="ProductGrid_ServiceBox"] {
    letter-spacing: $body-spacing;

    .b-content-box {
        @include rounding-box;

        margin: 0 16px 18px;
        padding: 16px;

        .c-title-link {
            @include clearfix;

            display: block;
            position: relative;
            margin-bottom: 17px;
            line-height: 23px;
        }

        .c-title {
            display: inline-block;
            overflow: hidden;
            position: relative;
            max-width: 80%;
            height: 27px;
            margin-top: -1px;
            padding-right: 18px;
            font-size: 18px;
            font-weight: bold;
            color: $color-gray-03;
            line-height: 27px;
            vertical-align: top;

            &:after {
                @include ico-arrow-right(9px, $color-gray-07);
                position: absolute;
                top: 9px;
                right: 5px;
            }
        }

        .c-title__subtext {
            overflow: hidden;
            font-size: $font-size-body1;
            color: $color-gray-04;
            margin-top: -2px;
            height: 18px;
        }

        .c-title__thumb {
            @include size(44px);

            float: left;
            overflow: hidden;
            position: relative;
            margin-right: 12px;
            border-radius: 100%;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border: 1px solid rgba(0, 0, 0, 0.07);
                border-radius: 100%;
                content: "";
            }
            img {
                width: 100%;
                border-radius: 44px;
            }
        }

        .b-card--col2 {
            display: flex;

            .c-card {
                display: inline-block;
                vertical-align: top;
                flex: 1;

                &:first-child {
                    margin-right: 6px;
                }
                &:last-child {
                    margin-left: 6px;
                }
                &__thumb {
                    @include thumbnail-playtime;
                    @include thumbnail-playicon;
                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        width: 100%;
                        height: 100%;
                        border: 1px solid rgba(0, 0, 0, 0.05);
                        content: "";
                    }
                    &:after {
                        @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));

                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        content: "";
                    }
                }
                &__info {
                    padding: 10px 0 0;
                }
                &__name {
                    overflow: hidden;
                    min-height: 40px;
                    margin-bottom: 8px;
                    font-size: $font-size-body1;
                    font-weight: normal;
                    color: $color-gray-03;
                    word-break: keep-all;
                    line-height: 20px;
                    -webkit-line-clamp: initial;
                }
            }
            .c-tag-wrap {
                white-space: normal;
                overflow: hidden;
                display: block;
                margin: 0;
                padding-top: 4px;
                height: 24px;
                .c-tag {
                    font-size: 13px;
                    border-radius: 0;
                    border: 0;
                    color: #0b83e6;
                    background-color: #f4f4f4;
                    padding: 2px 4px;
                    margin: 0 0 4px 0;
                    height: 19px;
                    line-height: 19px;
                    z-index: 2;
                    white-space: nowrap;
                }
            }
        }

        .c-list {
            &__item {
                padding: 0;
                display: block;
                height: 45px;
                &:first-child {
                    .c-list__item-link {
                        padding-top: 0;
                        height: 45px;
                    }
                }
                &:last-child {
                    .c-list__item-link {
                        border-bottom: 0;
                        height: 45px;
                        padding-bottom: 0;
                    }
                }
                &-link {
                    margin: 0;
                    padding: 0;
                    height: 45px;
                    line-height: 47px;
                    border-bottom: 1px solid $color-gray-12;
                    white-space: initial;
                    font-size: $font-size-body1;
                }
            }

            &__text {
                line-height: 20px;
                &-color {
                    color: $color-11st-blue;
                    line-height: 20px;
                    padding-right: 8px;
                }
            }
        }
    }

    .w-swiper {
        .b-content-box {
            margin-bottom: 0;
        }
        .c-pagination {
            &__indicator {
                line-height: 44px;
            }
        }
    }
}
