[data-type="Contents_Tag_Group_Basic"] {
    @include grid(0, 2, false);
    text-align: center;
    .l-grid__col {
        padding-top: 0;
        margin-bottom: 10px !important;
        overflow: hidden;
    }
    .c-tag {
        padding: 0 12px;
        background-color: #fff;
        border-color: #e4e4e4;
        color: #333;
        max-width: 270px;
        overflow: hidden;
        white-space: pre-line;
        &.active {
            border-color: #c29254;
            background-color: #c29254;
            color: #fff;
        }
        &.c-tag--selected-ancillary {
            border-color: rgba(73, 163, 199, 0.4);
            font-weight: bold;
            color: #49a3c7;
        }
    }
    .c-tag-box--left {
        text-align: left;
    }
}
