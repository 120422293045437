[data-type="GridList_ImgText_Col5"] {
    .c-listimg {
        &__item {
            width: 20%;
            &:nth-child(4n+1) {
                clear: none;
            }

            &:nth-child(4n) a:after {
                border-right-width: 1px;
            }

            &:nth-child(5n+1) {
                clear: both;
            }

            &:nth-child(5n) a:after {
                border-right-width: 1px;
            }
            .c-listimg__link {
                position: relative;
                z-index: 1;
            }
            &.on .c-listimg__icon-wrap {
                background-position-y: 100%;
            }
            &:nth-last-child(-n+3) {
                .c-listimg__link {
                    border-bottom-width: 1px;
                }
            }
            &.on {
                .c-listimg__link {
                    padding-left: 1px;
                    background: linear-gradient(180deg, #f4f4f4 0%, #fafafa 100%);
                    color: $color-11st-red;
                    border-bottom: 1px solid #e3e3e8;
                }
            }
        }
        &__icon-wrap {
            background-size: 32px 32px;
        }
        &__name {
            overflow: hidden;
            padding-top: 6px;
            height: 22px !important;
            word-break: break-all;
            white-space: normal !important;
        }
    }
}
