[data-type="Tabs_TextSearch_Department"] {
    .c-tab {
        .c-tab__list {
            overflow: hidden;
            display: table;
            height: 44px;
            background-color: #604e4b;

            .c-tab__item {
                display: table-cell;
                padding: 0;
                width: 15%;

                .c-tab__link {
                    position: relative;
                    color: #bbb5b3;
                    font-size: 15px;
                    background-color: inherit;
                    height: 44px;
                    line-height: 44px;
                    border: 0;
                    margin-right: 0;

                    &::after {
                        height: 17px;
                        width: 1px;
                        content: "";
                        position: absolute;
                        top: 13px;
                        right: 0;
                        background: #6d5d58;
                    }
                }

                &.on {
                    .c-tab__link {
                        color: #fff;
                    }
                }
            }
        }
    }

    .c-tab--col__custom {
        .c-tab__item {
            &:nth-child(1) {
                width: 15%;
            }

            &:nth-child(2) {
                width: 20%;
            }

            &:nth-child(3) {
                width: 27%;
            }

            &:nth-child(4) {
                width: 16%;
            }

            &:nth-child(5) {
                width: 8%;

                .c-tab__link {
                    height: 32px;
                    width: 44px;
                    padding-top: 10px;
                    margin: 0 auto;
                    &::after {
                        display: none;
                    }

                    span {
                        height: 20px;
                        width: 20px;
                        display: block;
                        margin: 0 auto;
                        text-indent: -9999px;
                        line-height: 20px;
                        background: url("/MW/img/corner/department/sp_dept_icon.png") no-repeat -30px -325px;
                        background-size: 50px 370px;
                    }
                }
            }
        }
    }
}
