[data-type="Cap_Title_Sorting_Channel"] {
    height: 48px;
    padding: 16px;
    border-top: 1px solid #eee;
    line-height: 16px;

    .c-list-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .c-list-count {
        overflow: hidden;
        flex-shrink: 0;
        font-size: 15px;
        color: $color-gray-03;
        .num {
            float: right;
            margin-left: 4px;
            font-weight: bold;
        }
    }

    .c-sorting-inline {
        display: flex;
        flex-shrink: 0;
        &__item {
            display: flex;
            align-items: center;
            &:not(:first-child) {
                &:before {
                    content: "";
                    display: block;
                    width: 1px;
                    height: 12px;
                    margin: 0 10px;
                    background-color: $color-gray-11;
                }
            }
        }
        &__link {
            display: block;
            font-size: 14px;
            color: $color-gray-07;
            &[aria-selected="true"] {
                font-weight: bold;
                color: $color-11st-red;
            }
        }
    }
}
