[data-type="Tabs_TextCheck_Round"] {
    @include grid(2, 2, false);

    dl {
        dt {
            @include sr-only;
        }
        dd {
            display: flex;
        }
    }

    .c-checkbox {
        width: auto;
        height: 32px;

        & + label {
            height: 32px;
            padding-left: 0;
            font-size: 16px;
            color: $color-gray-03;
            line-height: 32px;

            &:before {
                display: none;
            }
            &:after {
                display: none;
                top: 5px;
                left: 0;
            }
        }
        &:checked {
            & + label {
                padding-left: 18px;
                color: $color-11st-red;

                &:after {
                    display: block;
                }
            }
        }
        &-wrap {
            position: relative;

            & + .c-checkbox-wrap {
                margin-left: 10px;
                padding-left: 10px;

                &:before {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    width: 1px;
                    height: 10px;
                    margin-top: -4px;
                    background: $color-gray-11;
                    content: "";
                }
            }
        }
    }
    .c-chip {
        margin-bottom: 0;
    }
    .c-filter-align {
        position: absolute;
        top: 8px;
        right: 2px;
    }
}
