[data-type="Information_Product_Empty"] {
    .l-grid__col {
        padding: 30px 0;
        text-align: center;
        font-size: 14px;
        color: #666;
        letter-spacing: 0;

        img {
            display: block;
            width: 80px;
            height: 80px;
            margin: 0 auto 8px;
        }

    }

}