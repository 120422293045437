[data-type="ProductScroll_Price_Department"] {
    background-color: #e3e3e8;
    &.l-grid__row {
        .l-grid__col {
            position: relative;
            background-color: #fff;
            &::after {
                content: "";
                display: block;
                border-bottom: 1px solid #d1d1d6;
            }
        }
    }
    .l-grid__col + .l-grid__col {
        margin-top: 8px;
    }
    .c-brandinfo {
        display: table;
        padding: 16px 16px 8px 16px;

        &__thumb {
            @include size(94px 64px);
            display: table-cell;
            position: relative;
            border: 1px solid #f4f4f4;
            background: #fff;

            img {
                @include size(80px 30px);
                margin: 17px auto;
                display: block;
            }
            .c-lazyload {
                position: absolute;
                top: 17px;
                left: 0;
                right: 0;

                &:before {
                    background-size: 28px 24px;
                }
                img {
                    margin: 0 auto;
                }
            }
        }

        &__info {
            display: table-cell;
            padding-left: 8px;
            vertical-align: middle;
        }

        &__name {
            display: block;
            overflow: hidden;
            height: 16px;
            color: #945c49;
            font-size: 13px;
            line-height: 16px;
        }

        &__title {
            display: block;
            overflow: hidden;
            height: 25px;
            color: #111;
            font-size: 16px;
            line-height: 19px;
            padding-top: 6px;
        }

        &__text {
            height: 15px;
            overflow: hidden;
            color: #666;
            font-size: 12px;
            line-height: 15px;
        }
    }

    .c-card__info {
        line-height: 16px;

        .c-card__price {
            line-height: 16px;

            strong {
                line-height: 16px;
            }
        }
    }

    .s-list {
        padding: 4px 12px 12px 12px;
    }
}
