[data-type="ProductScroll_Delivery_RelatedBox"] {
    letter-spacing: $body-spacing;

    .b-content-box {
        @include rounding-box;

        margin: 0 16px 24px;
        padding: 8px 16px;
    }
    .c-card {
        &.c-card__list {
            min-height: initial;
            padding: 8px 0;
            .c-card__link {
                display: block;
            }
            .c-card__thumb {
                @include size(84px);
            }
            .c-card__info {
                height: 112px;
                margin-left: 88px;
                padding-top: 0;
                padding-bottom: 42px;

                .c-card__name {
                    display: block;
                    height: auto;
                    max-height: 36px;
                    margin-bottom: 6px;
                    color: $color-gray-03;
                    line-height: 1.2;
                }
                .c-card__rate {
                    padding-right: 2px;

                    strong {
                        font-size: 18px;
                        font-weight: normal;
                    }
                }
                .c-card__price {
                    font-size: 14px;

                    strong {
                        font-size: 18px;
                    }
                }
                .c-card__benefit {
                    padding-top: 4px;
                    height: auto;
                    font-size: 13px;
                    color: $color-gray-04;
                    letter-spacing: $body-spacing;

                    .benefit {
                        // height: auto;

                        &:first-child {
                            padding-bottom: 0;
                        }
                    }
                }
            }
            .c-starrate {
                padding-top: 4px;
            }
        }
    }
    .b-card--recommand {
        .c-card {
            &.c-card__list {
                .c-card__thumb {
                    width: 84px !important;
                    height: 84px;
                    @include mq-375 {
                        width: 124px !important;
                        height: 124px;
                    }
                }
            }
        }
    }
    @include mq-375 {
        .c-card {
            &.c-card__list {
                .c-card__thumb {
                    @include size(124px);
                }
                .c-card__info {
                    margin-left: 129px;
                    padding-top: 8px;
                    padding-bottom: 0;
                    height: 124px;
                }
            }
        }
    }
    .w-swiper {
        .c-pagination {
            margin-top: -24px;

            &__indicator {
                line-height: 42px;
            }
        }
    }
}
