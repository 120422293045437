// Suggesting Install App
.c-layer-app {
    display: block;
    position: fixed;
    text-align: center;
    width: 100%;
    z-index: 102;

    &__info {
        display: block;
        min-width: 240px;
        height: auto;
        margin: 0 40px;
        img {
            display: block;
            width: 100%;
            @include mq-640 {
                max-width: 500px;
                margin: 0 auto;
            }
        }
        span {
            @include sr-only;
        }
    }
    &__link {
        position: relative;
        display: inline-block;
        padding-top: 12px;
        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        letter-spacing: $body-spacing;
        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            background-color: rgba(255, 255, 255, 0.8);
            position: absolute;
            bottom: -2px;
        }
    }
}

// MPSR-117615 앱설치 유도배너 디자인 변경
.c-benefit-app {
    min-width: 300px;
    padding: 28px 16px 24px;
    text-align: center;
    box-sizing: border-box;

    &__inner {
        position: relative;
        padding-right: 108px;
        padding-left: 8px;
        color: $color-gray-02;
        text-align: left;

        &:before {
            content: "";
            position: absolute;
            top: 4px;
            right: 8px;
            width: 92px;
            height: 92px;
            background: url("/MW/img/mw11st_touchicon.png") no-repeat 0 0;
            background-size: 100% auto;
        }
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        line-height: 26px;
        letter-spacing: -0.5px;

        @media screen and (max-width: 320px) {
            br {
                display: none;
            }
        }
    }
    &__point {
        color: $color-11st-red;
    }
    &__desc {
        margin-top: 8px;
        font-size: 15px;
        line-height: 20px;
    }
    &__mw {
        display: inline-block;
        margin-top: 16px;
        border: 0;
        background-color: transparent;
        color: $color-gray-07;
        font-size: 13px;
        line-height: 16px;
        vertical-align: top;
    }

    .c-button {
        display: block;
        margin-top: 20px;
        font-weight: 600;
        box-sizing: border-box;
    }
}
