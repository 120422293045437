[data-type="Tabs_TextBar_ShockingDeal"] {
    .c-tab {
        letter-spacing: 0;

        .c-tab__list {
            padding: 14px 0;
            text-align: center;
        }

        .c-tab__link {
            display: inline-block;
            position: relative;
            height: initial;
            padding: 2px 1px;
            border: 0;
            background-color: initial;
            font-size: 15px;
            line-height: 22px;
            color: #999;
            vertical-align: top;

            &:after {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 2px;
                content: '';
            }
        }

        .on {
            .c-tab__link {
                border: 0;
                background-color: initial;
                color: #333;

                &:after {
                    background-color: #333;
                }
            }
        }

        .c-tab__item:nth-child(1):nth-last-child(3),
        .c-tab__item:nth-child(1):nth-last-child(3) ~ .c-tab__item {
            width: 33.33333%;
        }

        .c-tab__item:nth-child(1):nth-last-child(4),
        .c-tab__item:nth-child(1):nth-last-child(4) ~ .c-tab__item {
            width: 25%;
        }

        .c-tab__item:nth-child(1):nth-last-child(5),
        .c-tab__item:nth-child(1):nth-last-child(5) ~ .c-tab__item {
            width: 20%;
        }
    }
    .c-nav {
        border-bottom: 0;
        .c-nav__wrap {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                animation: 1s refresh linear infinite;
                content: '';
            }
            .c-nav__list {
                display: flex;
                padding: 14px 0;
                .c-nav__item {
                    display: block;
                    flex: 1;
                    a {
                        display: inline-block;
                        position: relative;
                        padding: 2px 8px;
                        line-height: 22px;
                        color: #999;
                        &:after {
                            position: absolute;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            height: 2px;
                            content: '';
                        }
                    }
                    &.on {
                        a {
                            color: #333;
                            &:after {
                                background-color: #333;
                            }
                        }
                        .c-nav__item:after {
                            background-color: #333;
                        }
                    }
                }
            }
        }
    }
}
