[data-type="Cap_Today_Round"] {
    margin-top: 10px !important;
    padding: 24px 0 16px;

    .c-headline {
        &__title {
            height: auto;
            padding: 0 16px;
            line-height: initial;
        }
        .c-chip {
            margin: 0;
            border: 1px solid rgba($color: $color-11st-blue, $alpha: 0.2);
            background: rgba($color: $color-11st-blue, $alpha: 0.06);
            font-size: 13px;
            color: $color-11st-blue;
            vertical-align: top;
        }
    }
}

[data-type="Caption_Shocking"] + [data-type="Cap_Today_Round"] {
    margin-top: 0 !important;
    padding-top: 0;
}
