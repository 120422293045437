[data-type="ContentsScroll_Store_Row2"] {
    letter-spacing: $body-spacing;
    .s-list {
        padding: 0 12px 24px;
        li {
            padding: 0 6px;

            .b-card--box {
                position: relative;

                &:first-child {
                    .c-card {
                        margin-bottom: 16px;
                    }
                }
            }

            .c-card {
                width: 152px;
                height: 214px;
                &__thumb {
                    width: 152px;
                    height: 152px;
                }
                &__info {
                    padding: 11px 12px 10px 12px;
                }
                &__name {
                    height: 38px;
                    margin-bottom: 5px;
                    font-size: $font-size-body1;
                }
                &__rate {
                    strong {
                        font-weight: normal;
                    }
                }
                &__price {
                    line-height: 20px;
                    vertical-align: middle;
                    strong {
                        line-height: 21px;
                    }
                }
                &__title {
                    display: block;
                    overflow: hidden;
                    margin: 0;
                    height: 21px;
                    line-height: 21px;
                    white-space: pre-line;
                    font-weight: bold;
                    font-size: $font-size-subtitle1;
                    color: $color-gray-02;
                }
                &__subtext {
                    display: block;
                    font-size: $font-size-body1;
                    color: $color-gray-07;
                    height: 18px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    em {
                        color: $color-11st-blue;
                    }
                    .overflow {
                        display: block;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }

            .c-ad__btn {
                position: absolute;
                left: 1px;
                bottom: 60px;
                outline: none;
            }
        }
    }
}
