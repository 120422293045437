[data-type="Cap_Title_Promotion"] {
    letter-spacing: $body-spacing;

    .c-headline {
        display: flex;
        height: 80px;
        padding: 0 32px;
        background: #2ba0b4;
        text-align: center;
        align-items: center;

        &__text {
            flex: 1;
        }
        &__title {
            display: -webkit-box;
            overflow: hidden;
            padding: 0;
            height: auto;
            font-size: 16px;
            font-weight: normal;
            color: $color-gray-14;
            line-height: 20px;
            white-space: normal;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
}
