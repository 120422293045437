[data-type="Tabs_RoundBtn_Fill_ColorBG_Scroll"] {
    .w-magnet {
        width: 100%;
        max-width: 1280px;
    }
    .c-magnet-multiple {
        &--fixed {
            position: fixed;
            width: 100%;
            z-index: 10;
        }
        &--absolute {
            position: absolute;
        }
    }
    .c-chip {
        margin-bottom: 0;

        // MPINNER-11431 삭제예정
        &--selected,
        &[aria-pressed="true"] {
            border: 0;
            background: $color-11st-red;
            color: $color-gray-14;
            line-height: 33px;
        }
        // //MPINNER-11431 삭제예정
        &[aria-selected="true"] {
            border: 0;
            background: $color-11st-red;
            color: $color-gray-14;
            line-height: 33px;
        }
    }
    .w-scroll-css {
        padding: 12px 0;
    }
    .s-list {
        padding: 0 10px 0 16px;

        li {
            position: relative;
            padding: 0;

            &:before {
                position: absolute;
                top: 1px;
                left: 1px;
                right: 7px;
                bottom: 1px;
                border-radius: 16px;
                background: $color-gray-14;
                content: "";
            }
        }
    }
}
