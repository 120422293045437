.benefit {
    display: block;
    &:first-child {
        padding-bottom: 2px;
    }
    .benefit__11pay-point {
        display: block;
        color: $color-11st-red;
        letter-spacing: -0.5px;
        .benefit__point-value {
            color: #32c2db;
        }
        span {
            color: #666;
        }
    }
    .benefit__skpay-point {
        display: block;
        color: $color-gray-07;
        .benefit__point-value {
            color: $color-11st-blue;
        }
    }
    em {
        color: $color-11st-blue;
    }
}
