[data-type="ContentsScroll_Editor"] {
    padding: 0 16px 16px;
    background: #ecf5fd;

    .c-headline {
        background: transparent;
        &__title {
            padding: 11px 0;
            color: $color-gray-03;
            text-align: left;
            span {
                color: $color-gray-03;
            }
        }
    }

    .w-scroll {
        margin: 0 -16px;
        .s-list {
            padding: 0 12px;
            > li {
                padding: 0 6px;
            }
        }
    }

    .c-contents {
        &-item {
            @include rounding-box;
            position: relative;
            width: 300px;
            background: #fff;

            &__title {
                display: flex;
                position: relative;
                justify-content: center;
                flex-direction: column;
                height: 78px;
                margin-bottom: -1px;
                padding: 12px 12px 12px 68px;
                border-bottom: 1px solid $color-gray-11;
            }
            .c-profile__pic {
                @include size(46px);
                position: absolute;
                top: 12px;
                left: 12px;
                &:after {
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    z-index: 2;
                }
                span {
                    width: 46px;
                    height: 46px;
                }
            }

            &__rank {
                display: none;
            }

            &__author {
                display: block;
                overflow: hidden;
                font-size: 19px;
                color: $color-gray-03;
                font-weight: bold;
                white-space: nowrap;
                .c-contents-item__badge {
                    margin: -1px 0 1px 0;
                    vertical-align: middle;
                    font-weight: normal;
                    &:after {
                        display: none;
                    }
                }
            }

            &__option {
                overflow: hidden;
                margin-top: 4px;
                font-size: 0;
                white-space: nowrap;
            }

            &__badge {
                display: inline-block;
                font-size: 15px;
                color: $color-11st-blue;
                &:after {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__keyword {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
            }

            &__status {
                display: inline-block;
                font-size: 14px;
                .status {
                    display: inline-block;
                    margin-left: 2px;
                    font-size: 14px;
                    &.up {
                        color: $color-11st-red;
                        &:after {
                            @include ranking-arrow(up);
                            display: inline-block;
                        }
                    }
                    &.down {
                        color: $color-gray-04;
                        &:after {
                            @include sprite-retina($sp_eui_down2);
                            display: inline-block;
                        }
                    }
                    &:after {
                        margin: -1px 0 1px 2px;
                        vertical-align: middle;
                        content: "";
                    }
                }
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            li {
                display: block;
                padding: 0;
            }

            &__contents {
                display: block;
                position: relative;
                height: 95px;
                padding: 15px 90px 10px 12px;
                border-top: 1px solid $color-gray-11;
                .c-contents-item__timestamp {
                    display: inline-block;
                    position: static;
                    padding: 0;
                    font-size: 14px;
                    vertical-align: top;
                    color: $color-gray-06;
                    font-weight: normal;
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: inline-block;
                        width: 1px;
                        height: 11px;
                        margin: -1px 5px 1px;
                        border-right: 1px solid $color-gray-10;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }

            &__thumb {
                position: absolute;
                top: 0;
                right: 0;
                padding: 12px;
                .c-lazyload {
                    width: 71px;
                    height: 71px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
                .c-badge--new {
                    position: absolute;
                    top: 12px;
                    right: 12px;
                    width: 20px;
                    height: 20px;
                    background: $color-11st-red;
                    font-size: 11px;
                    line-height: 20px;
                    color: $color-gray-14;
                    text-align: center;
                }
            }

            &__detail {
                overflow: hidden;
                height: 48px;
            }

            &__description {
                @include text-ellipsis-multiple(2);
                overflow: hidden;
                max-height: 44px;
                font-size: 16px;
                line-height: 22px;
                color: $color-gray-02;
            }

            &__sub {
                @include text-ellipsis;
                margin-top: 3px;
                font-size: 15px;
                color: $color-gray-04;
                line-height: 21px;
            }

            &__count {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
                vertical-align: top;
            }

            .c-relates {
                width: 100%;
                margin-top: 0;
                border-top: 0;
                &__arrow {
                    em {
                        color: $color-11st-blue;
                    }
                }
            }
            .c-certified {
                display: inline-block;
                height: 20px;
                padding: 0 4px;
                margin: -1px 6px 1px 0;
                border: 1px solid rgba(241, 112, 0, 0.5);
                border-radius: 10px;
                font-size: 11px;
                text-align: center;
                line-height: 20px;
                color: #f17000;
                vertical-align: middle;
                &:before {
                    @include sprite-retina($sp_eui_ico_official_mark2);
                    display: inline-block;
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }

            &--certified {
                .c-profile__pic {
                    padding: 1.5px;
                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 46px;
                        height: 46px;
                        background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                        border-radius: 50%;
                        content: "";
                    }
                    &:after {
                        display: none;
                    }
                    span {
                        overflow: visible;
                        width: 43px;
                        height: 43px;
                        border: 2px solid $color-gray-14;
                        background-color: $color-gray-14;
                        &:before {
                            position: absolute;
                            right: -8px;
                            bottom: -8px;
                            width: 19px;
                            height: 19px;
                            background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                            border: 2px solid $color-gray-14;
                            border-radius: 50%;
                            content: "";
                        }
                        &:after {
                            @include sprite-retina($sp_eui_ico_official_mark);
                            position: absolute;
                            right: -6px;
                            bottom: -6px;
                            z-index: 3;
                            content: "";
                        }
                    }
                }
            }

            &--recently {
                .c-contents {
                    &-item {
                        &__timestamp {
                            color: $color-11st-blue;
                        }
                    }
                }
            }
        }
    }

    .c-more {
        position: absolute;
        top: 0;
        right: 18px;
        height: 60px;
        padding: 0;
        font-size: 13px;
        line-height: 60px;
        background: transparent;
        color: $color-gray-04;
        &:after {
            border-color: $color-gray-04;
        }
    }
    .iScrollHorizontalScrollbar {
        display: none;
    }
}
