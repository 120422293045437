[data-type="Contents_top10"] {
    padding: 0 16px;

    .l-grid__col:first-child {
        .c-content-item__link {
            border-top: 0;
        }
    }

    .c-content-item {
        &__link {
            display: block;
            position: relative;
            height: 105px;
            padding: 12px 134px 12px 0;
            border-top: 1px solid #f4f4f4;
        }

        &__order-text {
            float: left;
            width: 46px;
            height: 100%;
            margin-left: -16px;
            padding: 5px 0;
            text-align: center;

            span {
                display: block;
                padding-left: 4px;
                font-size: 22px;
                color: $color-gray-02;
            }
        }

        &__text {
            display: block;
            overflow: hidden;
            max-height: 40px;
            margin-top: 6px;
            font-size: 15px;
            color: #333;
            line-height: 20px;
            word-break: keep-all;
        }

        &__tag {
            display: block;
            overflow: hidden;
            max-height: 20px;
            margin-top: 8px;
            font-size: 0;

            span {
                display: inline-block;
                max-width: 100%;
                margin-right: 4px;
                padding: 0 4px;
                background: $color-gray-12;
                font-size: 13px;
                color: $color-11st-blue;
                line-height: 20px;
            }
        }

        &__thumb {
            position: absolute;
            top: 16px;
            right: 0px;
            width: 128px;
            height: 72px;

            &:before {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 9;
                width: 100%;
                height: 100%;
                border: 1px solid rgba(0, 0, 0, 0.04);
                content: "";
            }
            img {
                width: 128px;
                height: 72px;
                object-fit: cover;
            }
        }
    }

    .c-content-item__inner {
        padding-left: 53px;

        .c-content-item__link {
            padding-left: 4px;
        }
    }

    .c-content-item__indent {
        margin-left: 37px;
        padding-left: 4px;
    }
}
