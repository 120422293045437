[data-type="ProductGrid_Ranking_Review"] {
    letter-spacing: 0;

    @include grid(2, 2, true);

    &.l-grid__row {
        background-color: #fff;
    }

    .l-grid__col--6.medium-3 {
        .c-cap {
            &__rank {
                position: relative;
                left: initial;
                top: initial;
                display: inline-block;
            }
        }
    }
    .c-card__counsel {
        padding: 14px 0 28px;
    }
    .c-prd-info {
        height: 186px;
    }
    .c-ad__btn {
        position: absolute;
        left: 0;
        bottom: 173px;
    }
}
