[data-type="Cap_Box_Info"] {
    letter-spacing: 0;

    .c-headline__infoTxt {
        top: 36px;
    }
    .c-headline__info-text {
        top: 36px;
    }
}
