[data-type="FlatButton_More"] {
    .c-relates {
        width: 100%;
        &--big {
            line-height: 45px;
            font-size: 15px;
            &:before {
                display: none;
            }
        }
        &__link {
            padding: 0 16px;
        }
    }
}
