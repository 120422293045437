[data-type="BillBoard_All_240"] {
    position: relative;
    background: $color-gray-11;

    .w-swiper {
        padding-bottom: 240 / 360 * 100%;
        background: $color-gray-14;
        .swiper-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
        }

        &__item img {
            width: auto;
            height: 100%;
        }
    }
    .c-pagination {
        position: absolute;
        right: 12px;
        bottom: 12px;
        z-index: 12;

        .c-pagination__list {
            width: 70px;
            height: 28px;
            border-radius: 14px;
            background-color: rgba($color-gray-01, 0.4);
            padding: 0 22px 0 0;
            font-size: 0;
            color: $color-gray-14;
            line-height: 30px;
            text-align: right;
            white-space: nowrap;

            span,
            strong {
                display: inline-block;
                margin: 0 1px;
                font-size: 13px;
                color: rgba($color-gray-14, 0.7);
            }
            .c-pagination__active {
                font-weight: bold;
                color: $color-gray-14;
            }
        }
        .c-pagination__viewall {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;

            &:after,
            &:before {
                position: absolute;
                top: 10px;
                right: 13px;
                width: 1px;
                height: 8px;
                background-color: $color-gray-14;
                content: "";
            }
            &:after {
                transform: rotate(90deg);
            }
            span {
                @include sr-only;
            }
        }
    }
}
