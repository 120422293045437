[data-type="ProductCard_Review"] {
    .c-card-item {
        &--list {
            @include rounding-box;
        }
        &__link {
            display: block;
            padding: 14px 12px 0 14px;
        }
        &__thumb {
            @include size(78px);
            overflow: hidden;
            border-radius: 4px;
        }
        &__info {
            padding: 0 0 0 16px;
            vertical-align: middle;
        }
        &__name {
            height: 38px;
            max-height: 38px;
            margin-bottom: 2px;
            line-height: 19px;

            &:nth-last-child(3) {
                @include text-ellipsis-multiple(1);
                height: auto;
            }
        }
        &__name-addition {
            @include text-ellipsis-multiple(1);
            padding-bottom: 0;
            line-height: 17px;
        }
        .c-starrate {
            margin-top: 5px;

            &__sati,
            &__gauge {
                @include size(128px 24px);
                background-size: 128px 24px;
            }
            &__sati {
                display: inline-block;
                float: none;
                vertical-align: top;
            }
        }
        .c-relates {
            margin-top: 14px;

            &__button-link {
                height: 40px;
                padding: 9px 0;
                font-size: 14px;
                color: $color-gray-02;
                text-align: center;

                em {
                    font-weight: bold;
                    color: $color-11st-red;
                }
            }
        }
    }
    .w-swiper {
        padding: 0 26px;

        .swiper-wrapper {
            padding-bottom: 18px;
        }
        .swiper-slide {
            padding: 0 5px;

            &:first-child {
                margin-left: -15px;
            }
            &:last-child {
                margin-right: -15px;
            }
        }
        .c-pagination {
            margin-top: -18px;
        }
    }
}
