[data-type="Contents_HighlightTitle"] {
    .c-headline {
        display: flex;
        height: 80px;
        padding: 19px 40px;
        text-align: center;
        align-items: center;
        flex-wrap: wrap;

        &__text {
            flex: 1;
        }
        &__title {
            display: inline-block;
            height: auto;
            padding: 0;
            font-size: 17px;
            color: $color-gray-03;
            line-height: 1.2;

            &:before {
                position: absolute;
                left: -4px;
                right: -4px;
                bottom: 0;
                height: 12px;
                background: rgba($color: $color-11st-blue, $alpha: 0.15);
                content: "";
            }
            strong {
                display: inline-block;
                position: relative;
                vertical-align: top;
            }
        }
        &__sub {
            margin-bottom: 4px;
            font-size: 14px;
            color: $color-gray-03;
        }
    }
}
