[data-type="Cap_Img_Title"] {
    letter-spacing: 0;
    .c-headline {
        padding: 22px 16px;
        &__image {
            @include size(52px);
            display: block;
            float: left;
            overflow: hidden;
            img {
                @include size(52px);
                border: 0;
            }
        }
        &__text {
            overflow: hidden;
        }
        &__title {
            padding: 0 0 0 16px;
            height: 30px;
            line-height: 30px;
        }
        &__subtext {
            font-size: 14px;
            color: $color-gray-04;
            margin-top: 0;
            padding: 0 0 0 16px;
        }
    }
}
