[data-type="ContentsScroll_BestFormBox"] {
    @include grid(0, 0, false);
    letter-spacing: $body-spacing;

    .c-headline {
        &__title {
            height: 54px;
            font-size: $font-size-subtitle2;
            font-weight: normal;
            span {
                color: $color-11st-blue;
            }
        }
    }
    .s-list {
        padding: 0 10px 24px;
        li {
            padding: 0 6px;
            .c-card {
                width: 152px;
                height: 218px;

                &__thumb {
                    width: 152px;
                    height: 152px;
                }
                &__rank {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 24px;
                    height: 24px;
                    border-bottom-right-radius: 4px;
                    background: rgba($color: $color-11st-red, $alpha: 0.8);
                    font-size: 13px;
                    color: $color-gray-14;
                    line-height: 26px;
                    text-align: center;
                    content: "";
                }
                &__info {
                    padding: 12px;
                }
                &__title {
                    display: block;
                    margin: 0;
                    height: 18px;
                    color: $color-gray-03;
                    font-size: 14px;
                    line-height: 18px;
                    white-space: pre-line;
                    overflow: hidden;
                }
                &__tag {
                    display: block;
                    float: none;
                    margin-top: 3px;
                    height: 19px;
                    font-size: 15px;
                    line-height: 19px;
                    color: $color-11st-blue;
                    overflow: hidden;
                }
            }

            .more {
                @include scroll-more(130px, 212px);
            }
        }
    }
}
