@import "../../common/sprites/sp_coupon";

[data-type="Banner_Notice"] {
    .c-banner {
        &__coupon {
            position: relative;
            display: block;
            padding: 0 16px;
            min-height: 39px;
            height: initial;
            color: #333;
            letter-spacing: 0;
            background: -webkit-gradient(linear, left top, right top, from(#8f89d2), to(#93aaf2));
            background: -webkit-linear-gradient(left, #8f89d2 0%, #93aaf2 100%);
            background: linear-gradient(90deg, #8f89d2 0%, #93aaf2 100%);
            &:after {
                position: absolute;
                right: 16px;
                top: 20px;
                content: '';
                display: block;
                border: solid #fff;
                border-width: 1px 1px 0 0;
                width: 8px;
                height: 8px;
                margin-top: -4px;
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            .c-banner__title {
                strong {
                    font-weight: bold;
                }
            }
            &-down {
                &:after {
                    display: none;
                }
            }
        }
        &__image {
            display: inline-block;
            position: relative;
            margin-left: 4px;
            padding: 0 4px;
            width: auto;
            height: 20px;
            vertical-align: middle;
            background: url("../../img/common/sprites/coupon_b.png") repeat-x 0 0;
            background-size: 4px 20px;

            &:before {
                @include sprite-retina($sp_coupon_coupon_s);
                background-color: transparent;
                content: '';
                display: block;
                position: absolute;
                left: -2px;
                top: 0;
            }
            &:after {
                @include sprite-retina($sp_coupon_coupon_e);
                background-color: transparent;
                content: '';
                display: block;
                position: absolute;
                top: 0;
                right: -2px;
            }
            .number {
                position: relative;
                display: inline-block;
                padding-right: 10px;
                height: 20px;
                line-height: 20px;
                vertical-align: top;
                z-index: 2;
                color: #666;
                font-size: 12px;

            }
        }
        &__subtext {
            display: block;
            width: 100%;
            padding: 12px 0;
            font-size: 12px;
            border-top: 1px solid rgba(255, 255, 255, 0.2);
            color: #fff;
        }
    }
}
