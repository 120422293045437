[data-type="Tabs_RoundBtn_Scroll"] {
    &.c-nav {
        border-top: 1px solid #e4e4e4;
    }

    .l-grid__row + .l-grid__row & {
        margin-top: -1px;
    }

    script + script + .l-grid__row & {
        margin-top: -1px;
    }

    &.l-grid__row {
        .l-grid__col {
            padding-bottom: 0;

            .c-nav {
                border-top: 1px solid #e4e4e4;
            }

            &:after {
                display: none;
            }
        }
    }

    .l-grid__row + &.l-grid__row {
        margin-top: -1px;
    }
}
