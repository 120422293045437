[data-type="FlatButton_ImgTextButton"] {
    letter-spacing: 0;
    .c-headline {
        padding: 24px 130px 20px 16px;
        &__title {
            @include text-ellipsis;
            height: auto;
            margin: 0;
            padding: 0;
            font-size: 17px;
            color: $color-gray-02;
            line-height: normal;
            span {
                color: $color-11st-red;
            }
        }
        &__subtext {
            @include text-ellipsis;
            font-size: 13px;
            color: $color-gray-04;
            margin: 0;
            padding: 0;
            + .c-headline__title {
                margin: 6px 0 0;
            }
        }
        .c-button {
            position: absolute;
            right: 18px;
            top: 22px;
            height: 40px;
            margin: 0;
            padding: 0 16px;
            color: $color-11st-red;
            border: 1px solid $color-11st-red;
            border-radius: 20px;
            line-height: 40px;
            font-size: 15px;
            font-weight: bold;
            &:before {
                @include sprite-retina($sp_eui_ico_editor_apply);
                display: inline-block;
                margin: -1px 4px 1px 0;
                vertical-align: middle;
                content: "";
            }
            &__mypage {
                height: 34px;
                padding: 0 13px;
                font-size: 13px;
                color: $color-11st-blue;
                border: 1px solid $color-11st-blue;
                border-radius: 17px;
                line-height: 34px;
                &:before {
                    display: none;
                }
            }
        }
        .c-profile {
            &__pic {
                @include size(40px);
                margin: -2px 9px 2px 0;
                span {
                    @include size(40px);
                }
            }
        }
    }
}
