[data-type="Contents_ColorBGTitle"] {
    letter-spacing: $body-spacing;
    background-color: $color-gray-11;

    .c-headline {
        display: flex;
        height: 80px;
        padding: 20px 16px;
        align-items: center;

        &__link {
            &:after {
                display: none;
            }
        }
        &__title {
            height: auto;
            padding: 0;
            line-height: 20px;
            text-overflow: ellipsis;
            color: $color-gray-14;
            font-size: 17px;
            font-weight: bold;
        }
        &__text {
            position: relative;
            overflow: hidden;
            text-align: center;
            flex: 1;
        }
        &__subtext {
            overflow: hidden;
            height: 18px;
            margin: 0 0 4px;
            padding: 0;
            color: $color-gray-14;
            font-size: $font-size-subtitle2;
            white-space: normal;
        }
    }
}
