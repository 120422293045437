[data-type="Contents_ReviewBox"] {
    padding: 0 16px 20px;

    .c-review {
        li {
            margin-top: 12px;

            &:first-child {
                margin-top: 0;
            }
        }
    }
    .c-card-item {
        &--box {
            width: auto;
            height: auto;
        }
        &__info {
            padding: 17px 0 20px;
            text-align: center;
        }
        &__name-addition {
            overflow: hidden;
            height: 19px;
            margin-bottom: 5px;
            padding: 0 40px;
            font-size: 15px;
            line-height: 19px;
        }
        &__quotation {
            position: relative;
            overflow: hidden;
            display: -webkit-inline-box;
            height: 40px;
            margin-bottom: 4px;
            padding: 0 40px;
            font-size: 15px;
            color: $color-gray-03;
            line-height: 20px;
            word-break: break-all;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;

            &:before {
                @include review-quote(0, 24px);
            }
        }
    }
    .c-starrate {
        display: flex;
        margin: 0;
        font-size: 0;
        text-align: center;
        justify-content: center;
        align-items: center;

        &:first-child {
            margin-bottom: 12px;
        }
        &__sati,
        &__gauge {
            @include size(90px 18px);
            background-size: 90px 18px;
        }
        &__sati {
            display: inline-block;
            float: none;
            background-image: url("/MW/img/svg/logo/icon_star.svg");
        }
        &__gauge {
            background-image: url("/MW/img/svg/logo/icon_star_fill.svg");
        }
        &__review {
            display: inline-block;
            float: none;
            font-size: 14px;
        }
        &.c-starrate--amazon {
            dt {
                @include size(16px 16px);
                margin: 1px 6px -1px 0;
                background-size: 16px 16px;
            }
        }
    }
}
