[data-type="Contents_RankingBoard"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .b-ranking {
        padding-bottom: 20px;
        li {
            padding-bottom: 6px;
            transition: height 0.3s ease 0.5s;
        }
        &-item {
            @include rounding-box;

            &__title {
                position: relative;
                display: block;
                padding: 16px 45px 16px 38px;
            }

            &__rank {
                position: absolute;
                top: 15px;
                left: 16px;
                z-index: 1;
                width: 20px;
                strong {
                    font-size: $font-size-title;
                    color: $color-gray-02;
                    font-style: italic;
                    font-weight: normal;
                }
                .c-keyword-item__status {
                    position: absolute;
                    display: block;
                    width: 9px;
                    height: 10px;
                    top: 27px;
                    right: 11px;
                    .status {
                        position: absolute;
                        display: block;
                        width: 10px;
                        height: 10px;
                        text-indent: -99999px;
                        overflow: hidden;
                        &:before {
                            content: "";
                            display: block;
                        }
                        &.up:before {
                            @include ranking-arrow(up);
                        }
                        &.down:before {
                            @include ranking-arrow(down);
                        }
                        &.neut {
                            height: 2px;
                            top: 3px;
                            &:before {
                                @include ranking-arrow(same);
                            }
                        }
                        &.new {
                            width: 24px;
                            text-indent: 0;
                            font-weight: bold;
                            letter-spacing: -1px;
                            position: absolute;
                            left: -10px;
                            &:before {
                                content: "NEW";
                                color: $color-11st-red;
                                font-size: 10px;
                            }
                        }
                    }
                }
            }

            &__keyword {
                color: $color-gray-02;
                padding-bottom: 4px;
            }

            &__desc {
                font-size: $font-size-body1;
                color: $color-gray-03;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            &__open {
                position: absolute;
                right: 0;
                top: 0;
                display: block;
                width: 44px;
                height: 68px;
                text-indent: -999999px;
                &:after {
                    position: absolute;
                    top: 26px;
                    left: 13px;
                    content: "";
                    display: block;
                    width: 24px;
                    height: 24px;
                    transition: all 0.3s ease 0s;
                    @include ico-arrow-down(9px, #999, $weight: 1px);
                }
            }

            &__content {
                position: relative;
                display: block;
                max-height: 0px;
                opacity: 0;
                padding: 0;
                overflow: hidden;
                transition: max-height 0.3s ease-out 0s, opacity 0.2s 0s, padding 0s 0.3s;
                .c-card__thumb {
                    @include thumbnail-playtime;
                    @include thumbnail-playicon;
                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
                    }
                }
                .c-card__text {
                    padding-top: 12px;
                    max-height: 50px;
                    overflow: hidden;
                    font-size: $font-size-body1;
                    color: $color-gray-03;
                    line-height: 1.5;
                }
                .c-card__more-text {
                    position: absolute;
                    bottom: 13px;
                    right: 18px;
                }
            }

            &.b-ranking-item--show {
                .b-ranking-item__open {
                    &:after {
                        @include rotate(316deg);
                        top: 32px;
                        transition: all 0.3s ease 0.7s;
                    }
                }
                .b-ranking-item__content {
                    max-height: 700px;
                    opacity: 1;
                    padding: 0 16px 16px;
                    overflow: hidden;
                    transition: max-height 0.3s ease-in 0.8s, opacity 0.2s 1.1s, padding 0s 0.8s;
                }
            }
        }
    }
}
