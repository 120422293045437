[data-type="Contents_BigImgBox"] {
    padding: 0 4px 12px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 0 4px;
    }
    .c-card {
        margin: 0 8px 12px;
        &__thumb {
            @include thumbnail-playtime;
            @include thumbnail-playicon;

            &:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                z-index: 1;
                @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__info {
            padding: 12px 16px 16px;
        }
        &__content {
            overflow: hidden;
            .c-card__title {
                display: block;
                overflow: hidden;
                margin: 0;
                margin-bottom: 4px;
                height: initial;
                color: $color-gray-02;
                font-size: $font-size-subtitle1;
                line-height: 23px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            .c-card__text {
                max-height: 40px;
                font-size: $font-size-body1;
                line-height: 20px;
                color: $color-gray-03;
            }
            .c-card__more-text {
                position: absolute;
                bottom: 16px;
                right: 14px;
                line-height: 20px;
            }
        }
    }
    .c-pagination {
        margin: -14px 0 -8px;
    }
    .w-swiper {
        .c-card {
            margin-bottom: 18px;
        }
    }
}
