[data-type="GridScroll_ImgText_RoundBox"] {
    .c-icontext-list {
        &__item {
            padding: 2px 3px;
        }
    }
    .c-icontext {
        position: relative;
        width: 60px;
        margin: 2px 3px;
        padding: 6px 6px 0;
        border-radius: 0;
        background: none;

        &__icon {
            @include size(44px);
            overflow: initial;
            margin: 0 auto;

            &:after {
                display: none;
            }
        }
        &__text {
            overflow: hidden;
            height: 15px;
            margin: 6px -8px 0;
            padding-top: 0;
            font-weight: normal;
            color: $color-gray-03;
            line-height: 15px;
            letter-spacing: -0.5px;
            text-overflow: ellipsis;
        }
        .c-badge {
            @include size(32px 16px);
            left: initial;
            right: 0;
            z-index: 1;
            padding: 0;
            font-size: 9px;
            color: transparent;
        }
    }
    .w-swiper {
        padding: 6px 7px 8px;
        text-align: center;

        .swiper-wrapper {
            display: inline-flex;
            width: auto;
            margin: 0 auto;
        }
        .swiper-slide {
            @include size(66px auto);
        }
        .swiper-scrollbar {
            @include size(56px 4px);
            overflow: hidden;
            position: relative;
            left: auto;
            bottom: auto;
            z-index: 1;
            margin: 8px auto 2px;
            background: $color-gray-11;

            &-drag {
                background: linear-gradient(to right, $color-11st-gradient-orange, $color-11st-red 56%, $color-11st-gradient-pink 88%);
            }
        }
    }
}
