[data-type="ProductList_Module"] {
    .l-grid__col {
        margin-bottom: -1px;
        padding-top: 1px;

        &:first-child {
            .c-card-item--list {
                &:before {
                    display: none;
                }
            }
        }
    }
    .c-card-item--list {
        padding: 16px;

        &:before {
            position: absolute;
            top: 0;
            left: 16px;
            right: 16px;
            height: 1px;
            background: $color-gray-12;
            content: "";
        }
        .c-card-item__rank {
            border-radius: 0;
        }
        .c-card-item__thumb {
            @include size(112px 112px);
        }
        .c-card-item__info {
            padding: 0 0 0 14px;

            .sktpass {
                overflow: hidden;
                height: 20px;
                padding-top: 1px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        .c-card-item__name {
            display: block;
            height: auto;
            max-height: 40px;
            margin-bottom: 6px;
        }
        .c-card-item__rate {
            strong {
                font-weight: normal;
            }
            .c-card-item__special {
                font-size: 12px;
            }
        }
        .c-card-item__benefit {
            margin-top: 7px;
        }
        .c-card-item__counsel {
            margin: 6px 0 9px;
            padding: 0;

            em {
                font-size: 15px;
                line-height: 19px;
            }
        }
        .c-relates {
            margin: 16px 0 -16px 126px;
        }
    }
}
