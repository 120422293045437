[data-type="GridList_BrandBox"] {
    padding: 0 10px 12px;
    letter-spacing: $body-spacing;

    .l-grid__col {
        padding: 0 6px;
    }
    .c-card-item--box {
        @include size(100% 83px);
        margin-bottom: 12px;

        .c-card-item__thumb {
            @include size(80px 30px);
            position: static;
            margin: 14px auto 8px;
        }
        .c-card-item__new {
            @include sprite-retina($sp_eui_new);
            overflow: hidden;
            position: absolute;
            top: 8px;
            right: 8px;
            color: transparent;
        }
        .c-card-item__info {
            padding: 0 8px 14px;
            text-align: center;
        }
        .c-card-item__name {
            display: block;
            height: 17px;
            margin: 0;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 17px;
        }
    }
}
