[data-type="Tabs_BoxBtn_Col3"] {
    .l-grid__col {
        padding: 16px;
    }
    .c-tab-box-border {
        .on .c-tab__link {
            border-radius: 4px;
        }
    }
    &#blckSn-7716,
    &#blckSn-8551 {
        margin: 0 !important;
    }
}
