[data-type="Cap_Img_Keyword"] {
    .c-headline__title {
        height: initial;
        line-height: initial;
        .c-headline__icon {
            @include size(32px);
            display: inline-block;
            vertical-align: bottom;
            img {
                @include size(32px);
                position: initial;
            }
        }
        .c-headline__keyword {
            display: inline-block;
            padding-left: 8px;
            height: 32px;
            line-height: 32px;
            vertical-align: bottom;
        }
    }
}
