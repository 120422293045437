@import "../../../svg/sp_luxury";

.header-fixed {
    padding-top: 57px;
    .sortBox {
        top: 57px;
    }
    // 선배포를 위한 추가 마크업 배포후 해당 scss파일로 이동 필요
    .search_keyword_swiper li a b {
        color: $color-11st-red;
    }
}
.b-fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 31;
    background-color: $color-gray-14;
}
.header-relative {
    .b-fixed {
        position: relative;
    }
}
.c-gnb {
    position: relative;
    height: 56px;
    background-color: $color-gray-14;
    color: $color-gray-01;
    border-bottom: 1px solid $color-gray-10;

    .c-n-search-bar {
        margin: -6px 0 -5px 36px;
    }

    &__menu {
        overflow: hidden;
        position: relative;
        height: 56px;
        .c-gnb__search-fake-input {
            position: absolute;
            width: 0;
            height: 0;
            opacity: 0;
        }
        &--visible {
            overflow: visible;
            .c-gnb {
                &__service-title,
                &__search-box {
                    display: inline-block;
                    width: -webkit-fill-available;
                    vertical-align: top;
                }
                &__tooltip {
                    display: block;
                }
            }
        }
    }
    &__logo {
        @include size(56px 30px);
        position: absolute;
        left: 54px;
        top: 14px;
        display: block;
        margin: 0;
    }
    &__link {
        &-11st {
            position: absolute;
            display: block;
            margin: 2px 0;
            img {
                @include size(56px 24px);
                display: block;
            }
        }
        &-amazon {
            position: absolute;
            display: none;
            padding: 10px 0 4px 0;
            &::before {
                @include size(1px 28px);
                content: "";
                display: block;
                background-color: #222f3f;
                position: absolute;
                left: -11px;
                top: 2px;
            }
            img {
                @include size(66px 20px);
                display: block;
            }
        }
    }
    &__gohome {
        display: block;
        margin: 2px 0;
        img {
            @include size(56px 24px);
            display: block;
        }
    }
    &__service-title {
        color: $color-gray-01;
        font-size: $font-size-title;
        font-weight: bold;
        letter-spacing: 0;
        margin: 19px 88px 8px 117px;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
        white-space: pre-line;
        a {
            color: $color-gray-01;
            &:hover,
            &:focus {
                color: $color-gray-01;
                text-decoration: none;
            }
        }
    }
    &__sidemenu {
        @include sprite-retina($sp_gnb2_sidemenu);
        display: block;
        text-indent: -99999px;
        position: absolute;
        top: 10px;
        left: 8px;
    }
    &__search-box {
        display: block;
        position: relative;
        margin: 10px 55px 0 114px;
        border-radius: 19px;
        border: 1px solid $color-gray-12;
        background-color: $color-gray-12;
        height: 34px;

        .c-gnb__search-input,
        input {
            display: block;
            padding: 0 10px;
            width: 100%;
            height: 34px;
            font-size: 12px;
            border-radius: 19px;
            letter-spacing: $body-spacing;
            background-color: transparent !important;
            border: 0;
            outline: none;
            &::placeholder {
                color: rgba(0, 0, 0, 0.4);
            }
        }
        .c-gnb__search-label {
            position: absolute;
            top: 50%;
            // 검색버튼 포함 중앙 포지셔닝
            left: calc(50% - 2px);
            transform: translate(-50%, -50%);
            img {
                // 사이즈 고정
                width: 120px;
                height: 28px;
                vertical-align: top;
            }
        }
    }
    &__search-icon {
        @include sprite-retina($sp_gnb2_search);
        position: absolute;
        top: 10px;
        right: 55px;
        overflow: hidden;
        white-space: nowrap;
        text-indent: -9999px;
    }
    &__cart {
        @include sprite-retina($sp_gnb2_cart);
        position: absolute;
        top: 10px;
        right: 16px;
        text-indent: -9999px;
        @import "../../../skpui/component/badge";
        .c-badge {
            top: -2px;
            left: initial;
            right: -3px;
            font-size: 11px;
            height: 16px;
            min-width: 16px;
            line-height: 16px;
            padding: 0 3px 0 2px;
        }
    }
    &__tooltip {
        display: none;
        position: absolute;
        top: calc(100% - 6px);
        right: 10px;
        width: -webkit-max-content;
        width: max-content;
        padding: 11px 14px;
        border-radius: 30px;
        background-color: rgba($color-gray-02, 0.9);
        text-indent: 0;
        opacity: 0;
        filter: drop-shadow(0 2px 4px 0px rgba(0, 0, 0, 0.08));
        transition: opacity 0.5s ease-in-out;

        // tail
        &:before {
            content: "";
            position: absolute;
            top: -5px;
            right: 18px;
            width: 15px;
            height: 6px;
            background-color: rgba($color-gray-02, 0.9);
            clip-path: polygon(6px 6px, 9.7px 1.06667px, 9.7px 1.06667px, 9.8312px 0.922669253px, 9.9816px 0.810668624px, 10.1464px 0.730668131px, 10.3208px 0.682667792px, 10.5px 0.666667625px, 10.6792px 0.682667648px, 10.8536px 0.730667879px, 11.0184px 0.810668336px, 11.1688px 0.922669037px, 11.3px 1.06667px, 15px 6px, 6px 6px);
        }

        &--show {
            opacity: 1;
            animation-name: fade-out;
            animation-delay: 5s;
            animation-duration: 0.5s;
            animation-timing-function: ease-out;
            animation-fill-mode: forwards;
        }
    }
    &__tooltip-text {
        display: block;
        overflow: hidden;
        max-width: 315px;
        height: 14px;
        font-weight: 700;
        font-size: 13px;
        color: $color-gray-14;
        line-height: 14px;
        @media screen and (max-width: 374px) {
            max-width: calc(100vw - 60px);
        }

        &--ellipsis {
            max-width: 180px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    // white 11번가 로고
    .logo-11st {
        overflow: hidden;
        display: block;
        width: 37px;
        height: 16px;
        color: transparent;
        font-size: 10px;
        background: url("/MW/img/svg/logo/logo-11st-white.svg") no-repeat 0 0;
        background-size: 100%;
    }
    // black 11번가 로고
    .logo-11st-dark {
        overflow: hidden;
        display: block;
        width: 37px;
        height: 16px;
        color: transparent;
        font-size: 10px;
        background: url("/MW/img/svg/logo/logo-11st.svg") no-repeat 0 0;
        background-size: 100%;
    }
    // 패션 추천 로고
    .logo-ootd {
        overflow: hidden;
        display: block;
        width: 74px;
        height: 23px;
        color: transparent;
        font-size: 1px;
        background: url("/MW/img/svg/logo/logo-ootd.svg") no-repeat 0 0;
        background-size: 100%;
        box-sizing: border-box;
    }

    &--amazon {
        .c-gnb__logo {
            @include size(126px 30px);
            top: 12px;
        }
        .c-gnb__link {
            &-11st {
                width: 37px;
                padding: 8px 0 10px 0;
                margin: 0;
                animation: none;
                img {
                    transition: none;
                    @include size(37px 16px);
                }
            }
            &-amazon {
                display: block;
                left: 58px;
                animation: none;
                img {
                    transition: none;
                }
            }
        }
        .c-gnb__search-box {
            margin-left: 148px;
            transition: all 0.4s;
            .c-gnb__search-icon {
                // @include sprite-retina($sp_gnb2_search-amazon);
                background-image: url("/MW/img/common/ic_gnb_search_amazon.gif");
                background-position: 0 0;
                width: 28px;
                height: 28px;
                background-size: 28px 28px;
                top: 3px;
                right: 3px;
            }
        }
        &.c-gnb--search,
        &.c-gnb--app.c-gnb--search {
            height: 108px;

            &::after {
                display: none;
            }
            .c-gnb__menu {
                height: 108px;
            }
            .c-gnb__search-box {
                margin: 56px 16px 0;
                height: 38px;
                border-radius: 20px;
                border: 1px solid $color-gray-12;
                background-color: $color-gray-12;
                transition: initial;

                .c-gnb__search-input,
                input {
                    height: 38px;
                    padding: 0 16px;
                    font-size: $font-size-body1;
                }
                .del {
                    height: 24px;
                    top: 7px;
                    right: 8px;
                }
            }
        }
    }
    &--main {
        border-bottom: 0;
        .c-gnb__logo {
            left: 14px;
        }
        .c-gnb__link {
            &-11st {
                animation: fade-in 0.5s;
                img {
                    transition: all 0.4s;
                }
            }
            &-amazon {
                animation: fade-in 0.5s;
                img {
                    transition: all 0.4s;
                }
            }
        }
        .c-gnb__search-box {
            position: relative;
            margin: 10px 60px 0 80px;
            padding-right: 30px;
            transition: all 0.4s;
            .c-gnb__search-icon {
                @include sprite-retina($sp_gnb2_search-red);
                border: 0;
                background-color: transparent;
                top: 5px;
                right: 6px;
            }
        }
        &.c-gnb--amazon {
            .c-gnb__search-box {
                margin-left: 148px;
                .c-gnb__search-icon {
                    // @include sprite-retina($sp_gnb2_search-amazon);
                    background-image: url("/MW/img/common/ic_gnb_search_amazon.gif");
                    background-position: 0 0;
                    width: 28px;
                    height: 28px;
                    background-size: 28px 28px;
                    top: 3px;
                    right: 3px;
                }
            }
        }
        &.c-gnb--11st {
            .c-gnb__search-box {
                .c-gnb__search-icon {
                    // @include sprite-retina($sp_gnb2_search-11st);
                    background-image: url("/MW/img/common/ic_gnb_search_11st.gif");
                    background-position: 0 0;
                    width: 28px;
                    height: 28px;
                    background-size: 28px 28px;
                    top: 3px;
                    right: 3px;
                }
            }
        }
    }
    &--search {
        border-bottom: 0;
        height: 57px;
        &:after {
            content: "";
            display: block;
            position: relative;
            bottom: 1px;
            height: 2px;
            background: $color-11st-gradient;
        }
        .c-gnb__logo {
            left: 54px;
        }
        .c-gnb__search-box {
            height: 34px;
            margin: 12px 55px 0 106px;
            padding-right: 30px;
            border: 0;
            background-color: transparent;

            .c-gnb__search-input,
            input {
                display: block;
                width: 100%;
                color: $color-gray-01;
                font-size: $font-size-title;
                font-weight: bold;
                letter-spacing: 0;
                border: 0;
                text-align: left;
            }
            button.c-gnb__search-input {
                overflow: hidden;
                white-space: nowrap;
            }
            .del {
                @include sprite-retina($sp_gnb2_cancel);
                display: block;
                position: absolute;
                right: 3px;
                top: 5px;
                background-color: transparent;
                text-indent: -99999px;
                border: 0;
            }
        }
    }
    &--11st {
        &-amazon {
            animation: fade-out 0.5s;
        }
    }
    &--app {
        .c-gnb__logo {
            left: 14px;
        }
        .c-gnb__service-title {
            margin-left: 75px;
        }
        .c-gnb__sidemenu {
            display: none;
        }
        &.c-gnb--search {
            .c-gnb__search-box {
                margin: 12px 55px 0 70px;
            }
        }
        &.c-gnb--detail-brand {
            .c-gnb__sidemenu {
                & + .c-gnb__logo {
                    margin-left: 0;
                }
            }
        }
    }

    // 우아럭스 GNB
    &--luxury {
        border-bottom: 1px solid #eee;

        .logo-luxury {
            display: block;
            height: 28px;
            padding-top: 6px;
            color: $color-luxury;
            box-sizing: border-box;

            img {
                display: block;
                height: 16px;
            }
        }

        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: center;
                width: auto;
                height: 28px;
            }
            &__link + .c-gnb__link {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid #ccc;
            }
            &__cart {
                @include get-sprite-luxury("ic-gnb-cart", 36);

                right: 10px;

                .c-badge {
                    padding-right: 4px;
                    padding-left: 4px;
                    font-family: $font-family3;
                    font-weight: bold;
                    background-color: $color-luxury;
                }
            }
            &__search-icon {
                @include get-sprite-luxury("ic-gnb-search", 36);

                right: 53px;
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
    }
    &--luxury-combi {
        .c-n-search-bar {
            margin-right: 36px;
        }
    }

    // PDP 브랜드픽 GNB
    &--detail-brand {
        border-bottom: 1px solid $color-gray-11;
        .logo-11st {
            overflow: hidden;
            display: block;
            width: 37px;
            height: 16px;
            color: transparent;
            font-size: 10px;
            background: url("/MW/img/svg/logo/11st.svg") no-repeat 0 0;
            background-size: 100% auto;
        }
        .logo-brand {
            @include text-ellipsis;
            display: block;
            height: 28px;
            padding: 3px 0;
            font-size: 18px;
            line-height: 24px;
            color: $color-gray-01;
            box-sizing: border-box;

            img {
                display: block;
                height: 28px;
                margin: -3px 0;
            }
        }
        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 96px 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: center;
                width: auto;
                height: 28px;
            }
            &__link + .c-gnb__link {
                display: block;
                overflow: hidden;
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid $color-gray-03;
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }

            &__sidemenu {
                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
    }

    // 패션추천 GNB
    &--ootd {
        border-bottom: 0;
        transition: all 0.15s cubic-bezier(0.42, 0, 1, 1);
        backdrop-filter: blur(0);

        .c-gnb {
            &__menu {
                height: auto;
                padding: 14px 16px;
            }
            &__logo {
                position: static;
                display: flex;
                align-items: stretch;
                width: auto;
                height: 28px;
            }
            &__link {
                display: flex;
                align-items: center;
            }
            &__link + .c-gnb__link {
                margin-left: 10px;
                padding-left: 10px;
                border-left: 1px solid $color-gray-03;
            }
            &__cart {
                background: url("/MW/img/ootd/ic_gnb_cart.svg") no-repeat 0 0;
                background-size: 100%;

                .c-badge {
                    padding-right: 4px;
                    padding-left: 4px;
                    font-family: $font-family3;
                    font-weight: bold;
                }
            }
            &__back {
                @include get-sprite-luxury("ic-gnb-back", 36);

                position: absolute;
                top: 10px;
                left: 8px;
                border: 0;
                background-color: transparent;

                & + .c-gnb__logo {
                    margin-left: 36px;
                }
            }
        }
        .logo-11st {
            background-image: url("/MW/img/svg/logo/11st.svg");
        }
    }
    &--ootd-dark {
        background-color: transparent;
        border-bottom-color: transparent;

        .logo-11st {
            background-image: url("/MW/img/svg/logo/logo-11st-white.svg");
        }
        .logo-ootd {
            background-image: url("/MW/img/svg/logo/logo-ootd-white.svg");
        }
        .c-gnb__cart {
            background-image: url("/MW/img/svg/ooah-pick/ic-cart.svg");
        }

        .c-gnb__link + .c-gnb__link {
            border-color: rgba(255, 255, 255, 0.3);
        }
    }
    &--ootd-blur {
        background-color: rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(120px);
    }
}
.c-headline--low {
    .c-headline__title {
        height: 56px;
        padding: 8px 16px;
    }
}
@keyframes fade-in {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

// 파트너 제휴 배너 노출 시
body {
    &.partner-banner {
        .header-fixed {
            padding-top: 0;
        }
        .header-fixed.header-fixed--banner {
            padding-top: 0;
        }
        .header-fixed.header-fixed--banner .c-banner-applink {
            position: relative;
        }
        .header-fixed.header-fixed--banner .b-fixed {
            position: relative;
            top: auto;
        }
        .header-fixed .b-fixed {
            position: relative;
        }
        &.is-scroll {
            .header-fixed {
                padding-top: 57px;
            }
            .header-fixed.header-fixed--banner {
                padding-top: 107px;
            }
            .header-fixed.header-fixed--banner .c-banner-applink {
                position: fixed;
            }
            .header-fixed.header-fixed--banner .b-fixed {
                position: fixed;
                top: 50px;
            }
            .header-fixed .b-fixed {
                position: fixed;
            }
        }
    }
}
