@import "../../allsprites/sprites/sp_search";

[data-type="ContentsScroll_Notice"] {
    .c-notice {
        position: relative;
        letter-spacing: 0;
        &-list__title {
            height: 47px;
            line-height: 54px;
            border-bottom: 1px solid #f4f4f4;
            padding-left: 16px;
            font-size: 15px;
            color: #111;
        }
        &-list__link {
            position: absolute;
            height: 47px;
            line-height: 54px;
            right: 0;
            top: 0;
            color: #666;
            font-size: 15px;
            padding: 0 16px 0 16px;
            &:after {
                @include ico-arrow-right(7px, #999, 1px);
                content: "";
                display: inline-block;
                margin: -1px 0 1px 6px;
                vertical-align: middle;
            }
        }
        &__item {
            position: relative;
            display: block;
            overflow: hidden;
            padding: 0;
            margin: 0 16px;
            height: 40px;
            line-height: 40px;
            border-right: 0;
            border-bottom: 1px solid #f4f4f4;
            text-align: left;
            font-size: 15px;
            color: #111;
            @include text-ellipsis;

            em {
                display: inline-block;
                height: 20px;
                padding: 3px 6px;
                margin-right: 8px;
                color: $color-11st-red;
                font-size: 11px;
                border: 1px solid rgba($color: $color-11st-red, $alpha: 0.4);
                background-color: rgb(255, 247, 248);
                border-radius: 2px;
                line-height: 14px;
            }
        }
        .c-pagination {
            position: relative;
            border-top: 1px solid #eee;
            margin-top: -1px;
            z-index: 1;
            text-align: initial;
            &__indicator {
                line-height: 32px;
                span {
                    border: none;
                    background-color: rgba(0, 0, 0, 0.14);
                    &.on {
                        background-color: $color-11st-red;
                        border: 0;
                    }
                }
            }
        }
    }
}
