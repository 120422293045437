[data-type="ProductGrid_Basic_Col3"] {
    padding: 0 8px;
    background-color: #fff !important;
    .l-grid__col {
        padding: 0 4px;

        .c-card.c-card__gallery {
            .c-card__thumb {
                @include size(auto);
            }
            .c-card__info {
                padding: 8px 0;
                .c-card__name {
                    font-size: 13px;
                    height: 36px;
                    margin-bottom: 0;
                }
                .c-card__price {
                    min-height: 20px;
                    strong {
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
