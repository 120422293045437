[data-type="ProductList_DealBox"] {
    .c-headline {
        padding-bottom: 28px;
        background: linear-gradient(90deg, rgba(132, 215, 226, 1) 0%, rgba(165, 230, 204, 1) 70%, rgba(166, 231, 206, 1) 100%);

        &__title {
            font-size: 17px;
            text-align: center;
        }
    }
    .c-content-box {
        margin: -30px 16px 24px;

        li + li {
            margin-top: 16px;
        }
    }
    .c-card-item {
        &--list {
            @include rounding-box;
            padding: 16px;
        }
        &__thumb {
            @include size(124px);

            .c-lazyload {
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        &__info {
            padding: 0 0 0 14px;
        }
        &__name {
            display: block;
            height: auto;
            max-height: 40px;
            margin-bottom: 7px;
            font-size: 16px;
            line-height: 20px;
        }
        &__price-info {
            margin: 10px 0 11px;
        }
        &__rate {
            strong {
                font-weight: normal;
            }
            .c-card-item__special {
                font-size: 12px;
            }
        }
        &__benefit {
            .sktpass,
            .benefit {
                font-size: 14px;
                line-height: 17px;
            }
            .sktpass {
                &:before {
                    margin-right: 0;
                }
                span {
                    display: inline;
                }
            }
            .benefit {
                color: $color-gray-04;
            }
        }
        .c-flag-box {
            margin-bottom: 2px;
        }
    }
}
