[data-type="ProductList_Trip"] {
    @include grid(0, 0, false);
    background-color: transparent;
    margin-top: 8px;
    overflow: hidden;
    .l-grid__col+.l-grid__col {
        margin-top: 8px;
    }
    .c-card {
        min-height: 136px;
        .c-card__thumb {
            @include size(136px);
        }
        .c-card__info {
            padding-top: 16px;
            .c-card__name {
                line-height: 1.3em;
                height: 37px;
            }
            .c-prd-info__meta {
                margin-top: 15px;
            }
        }
    }
}