[data-type="GridList_ImgText_Col2"] {
    .c-list {
        &__item {
            &-link {
                height: 48px;
                line-height: 48px;
                .c-list__icon {
                    @include size(40px 40px);
                    img {
                        @include size(40px 40px);
                    }
                }
            }
        }
    }
}
