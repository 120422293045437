[data-type="Tabs_TextBar_Scroll_Department"] {
    .c-nav__wrap {
        .c-nav__list {
            width: auto;
        }
        .c-nav__item {
            color: #999;

            &.on {
                a {
                    font-weight: bold;
                    color: #604e4b;

                    &:after {
                        background-color: #945c49;
                    }
                }
            }
        }
    }
}
