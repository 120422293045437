[data-type="Caption_Outlet"] {
    border-top: 2px solid #eee;

    .c-headline {
        &__text {
            .c-headline__title {
                color: #000;
                font-size: 16px;
            }
        }
    }
}
