@import "../../pui/sprites/sp_order";

[data-type="Contents_myreco_ShoppingTheme"] {
    letter-spacing: $body-spacing;

    li {
        padding: 6px 16px;

        &:first-child {
            padding-top: 16px;
        }
        &:last-child {
            padding-bottom: 10px;
        }
    }
    .c-headline {
        &__title {
            height: 52px;
            padding-top: 10px;
            font-size: $font-size-subtitle2;
            font-weight: normal;
            line-height: 36px;

            span {
                color: $color-11st-blue;
            }
        }
    }
    // PUIMW-412 삭제예정 시작
    .w-scroll {
        padding: 0 10px 24px;
        .c-card {
            @include size(100%);
        }
    }
    // PUIMW-412 삭제예정 끝
    .w-scroll-css {
        padding: 0 10px 24px;

        .c-card {
            @include size(100%);
        }
    }
    .s-list {
        padding: 0;

        li {
            padding: 0 6px;
        }
        .c-card {
            width: 298px;
            height: 335px;
        }
        .more {
            @include scroll-more(150px, 331px);
        }
    }
    .c-card {
        color: $color-gray-02;

        &__thumb {
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            @include thumbnail-playtime;
            @include thumbnail-playicon;
            &:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                z-index: 1;
                @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            }
        }
        &__writer {
            font-size: 0;

            &-info {
                display: inline-block;
                margin: 1px 0 -1px;
                font-size: 12px;
                vertical-align: middle;
            }
            .c-card__thumb {
                display: inline-block;
                overflow: hidden;
                margin-right: 8px;
                border-radius: 100%;
                background: rgba(0, 0, 0, 0.03);
                vertical-align: middle;
                @include size(28px);
            }
        }
        &__info {
            padding: 10px 16px 19px;

            .c-card__content {
                position: relative;
            }
            .c-card__title {
                display: block;
                overflow: hidden;
                height: auto;
                max-height: 54px;
                margin: 8px 0;
                font-size: 20px;
                line-height: 26px;
            }
            .c-card__text {
                overflow: hidden;
                max-height: 40px;
                font-size: 14px;
                color: $color-gray-02;
                line-height: 20px;
                @include text-ellipsis-multiple(2);
            }
            .c-card__more-text {
                position: absolute;
                bottom: 1px;
                right: 2px;
            }
        }
        &__order-text {
            position: absolute;
            top: -12px;
            right: 16px;
            z-index: 1;
            width: 31px;
            height: 34px;
            line-height: 28px;
            text-align: center;
            @include sprite-retina($sp_order_gray);

            span {
                font-size: 15px;
                color: $color-gray-14;
            }

            &.c-card__order-point {
                @include sprite-retina($sp_order_red);
            }
        }
        &__tag {
            display: block;
            overflow: hidden;
            max-height: 20px;
            margin: 13px 56px 0 0;

            span {
                display: inline-block;
                overflow: hidden;
                max-width: 100%;
                height: 20px;
                padding: 0 4px;
                background: $color-gray-12;
                font-size: 13px;
                color: $color-11st-blue;
                line-height: 20px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
            & + .c-card__more-text {
                background: none;
            }
        }
    }
    &#blckSn-7058,
    &#blckSn-6183 {
        padding: 0 !important;
    }
}
