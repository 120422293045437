@import "../../pui/sprites/sp_icons";

[data-type="ExhibitionBannerA"].l-grid__row {
    @include grid(0, 0, false);
    background-color: transparent;

    .l-grid__col {
        background-color: #fff;
        padding: 16px;
        & + .l-grid__col {
            margin-top: 8px;
        }
    }
    .c-linkimg {
        border: 1px solid #f4f4f4;
        display: block;
    }
    // PUIMW-412 삭제예정 시작
    .w-scroll {
        margin: 0 -16px -16px;
        padding-bottom: 8px;
    }
    // PUIMW-412 삭제예정 끝
    .w-scroll-css {
        margin: 0 -16px -16px;
        padding-bottom: 8px;
    }
    .s-list {
        .more {
            @include scroll-more(104px, 192px);

            .more_inner {
                border: 1px solid rgba(0, 0, 0, 0.05);
                background-color: $color-gray-13;
                font-size: 13px;
                line-height: 16px;
                color: $color-gray-07;

                &:before {
                    @include size(48px);
                    @include border-radius(50%);
                    @include sprite-retina($sp_icons_more_arrow);
                    margin: 0 auto 8px;
                    background-color: $color-gray-07;
                }
            }
        }
    }
    [data-type="ProductDeal_Shocking"] + & {
        margin: 8px 0;
    }
}
