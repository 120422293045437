[data-type="GridScroll_Img"] {
    background-color: #fff;
    .s-list {
        padding: 0px;
        border-bottom: 1px solid #d1d1d6;

        li {
            .c-iconimg {
                display: block;
                padding: 4px 12px 6px;
                line-height: 0;

                img {
                    width: 80px;
                    height: 30px;
                }
            }

            &:nth-child(n+2) {
                border-left: 1px solid #f4f4f4;
            }
        }
    }
}
