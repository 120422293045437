[data-type="GridList_ImgText_Col4_NoLine"] {
    background-color: #fff !important;
    .c-listimg {
        padding-bottom: 16px;
        &__item {
            width: 25%;
            .c-listimg__link {
                border-width: 0 0 0 0;
                .c-listimg__name {
                    padding-top: 4px;
                    font-size: 13px;
                }
            }
        }
    }
}
