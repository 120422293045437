@import "../../../sass/pui/sprites/sp_icons";

[data-type="ContentsScroll_Challenge"] {
    .c-challenge {
        height: 500px;
        padding: 42px 16px 0;
        background-repeat: no-repeat;
        background-size: cover;
    }
    .c-category {
        display: inline-block;
        height: 30px;
        padding: 7px 10px;
        font-size: 14px;
        color: $color-gray-14;
        line-height: 17px;
        font-weight: bold;
        text-align: center;
        background: linear-gradient(106deg, #00e9ff 9%, #19a4ff 33%, #0090f3 64%, #0090f3 64%);
        border-radius: 15px;
    }
    .c-hashtag {
        display: block;
        position: relative;
        margin-top: 14px;
        font-size: 26px;
        color: $color-gray-14;
        font-weight: bold;
    }
    .c-morelink {
        position: absolute;
        bottom: 5px;
        right: 0;
        font-size: 13px;
        color: $color-gray-14;
        &:after {
            content: "";
            display: inline-block;
            margin: -1px 0 1px 3px;
            @include ico-arrow-right(6px, $color-gray-14, 1px);
        }
    }
    // MPINNER-11609 삭제예정 시작
    .w-scroll {
        margin: 17px -18px 0;
        .s-list {
            padding: 0 12px;
            li {
                padding: 0 6px;
            }
        }
    }
    .iScrollHorizontalScrollbar {
        display: none;
    }
    // MPINNER-11609 삭제예정 끝
    .w-scroll-css {
        margin: 17px -18px 0;

        &::-webkit-scrollbar {
            display: none;
            -webkit-appearance: none;
        }
    }
    .s-list {
        padding: 0 12px;

        li {
            padding: 0 6px;
        }
    }

    .c-contents {
        &-item {
            @include rounding-box;
            position: relative;
            width: 265px;
            height: 283px;
            background: #fff;

            &__title {
                display: flex;
                position: relative;
                justify-content: center;
                flex-direction: column;
                height: 65px;
                padding: 14px 10px 10px 94px;
                border-bottom: 1px solid $color-gray-11;
            }
            .c-profile__pic {
                @include size(40px);
                position: absolute;
                top: 13px;
                left: 44px;
                &:after {
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    z-index: 2;
                }
                span {
                    width: 40px;
                    height: 40px;
                }
            }

            &__rank {
                position: absolute;
                top: 24px;
                left: 12px;
                z-index: 1;
                strong {
                    font-size: 18px;
                    color: $color-11st-red;
                    line-height: 21px;
                    span {
                        display: inline-block;
                        font-size: 15px;
                        font-weight: normal;
                        vertical-align: top;
                    }
                }
            }

            &__author {
                display: block;
                overflow: hidden;
                font-size: 16px;
                color: $color-gray-03;
                font-weight: bold;
                white-space: nowrap;
                .c-contents-item__badge {
                    margin: -1px 0 1px 4px;
                    vertical-align: middle;
                    font-weight: normal;
                    &:after {
                        display: none;
                    }
                }
            }

            &__option {
                display: flex;
                margin-top: 4px;
                font-size: 0;
                white-space: nowrap;
            }

            &__badge {
                display: inline-block;
                font-size: 14px;
                color: $color-11st-blue;
                &:after {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__keyword {
                display: inline-block;
                overflow: hidden;
                font-size: 13px;
                color: $color-gray-06;
            }

            &__subscribe {
                display: inline-block;
                overflow: hidden;
                font-size: 13px;
                color: $color-gray-06;
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-left: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__contents {
                padding: 0 12px;
                display: block;
                .c-contents-item__timestamp {
                    display: inline-block;
                    position: static;
                    padding: 0;
                    font-size: 14px;
                    vertical-align: middle;
                    color: $color-gray-06;
                    font-weight: normal;
                    &:before {
                        display: none;
                    }
                    &:after {
                        display: inline-block;
                        width: 1px;
                        height: 11px;
                        margin: -1px 5px 1px;
                        border-right: 1px solid $color-gray-10;
                        vertical-align: middle;
                        content: "";
                    }
                }
            }
            &__thumb {
                height: 118px;
                padding: 12px 0 10px;
                box-sizing: content-box;
                .c-lazyload {
                    height: 118px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__detail {
                margin-bottom: 3px;
                padding: 0;
            }

            &__description {
                @include text-ellipsis-multiple(2);
                font-size: 16px;
                line-height: 22px;
                color: $color-gray-02;
            }

            &__sub {
                @include text-ellipsis-multiple(3);
                margin-top: 4px;
                font-size: 15px;
                color: $color-gray-04;
                line-height: 21px;
            }

            &__count {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
                vertical-align: middle;
            }

            .c-certified {
                display: inline-block;
                height: 20px;
                padding: 0 4px;
                margin: -1px 6px 1px 0;
                border: 1px solid rgba(241, 112, 0, 0.5);
                border-radius: 10px;
                font-size: 11px;
                text-align: center;
                line-height: 18px;
                color: #f17000;
                vertical-align: middle;
                &:before {
                    @include sprite-retina($sp_eui_ico_official_mark2);
                    display: inline-block;
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }

            &--certified {
                .c-profile__pic {
                    padding: 1.5px;
                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 40px;
                        height: 40px;
                        background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                        border-radius: 50%;
                        content: "";
                    }
                    &:after {
                        display: none;
                    }
                    span {
                        overflow: visible;
                        width: 37px;
                        height: 37px;
                        border: 2px solid $color-gray-14;
                        background-color: $color-gray-14;
                        &:before {
                            position: absolute;
                            right: -8px;
                            bottom: -8px;
                            width: 19px;
                            height: 19px;
                            background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                            border: 2px solid $color-gray-14;
                            border-radius: 50%;
                            content: "";
                        }
                        &:after {
                            @include sprite-retina($sp_eui_ico_official_mark);
                            position: absolute;
                            right: -6px;
                            bottom: -6px;
                            z-index: 3;
                            content: "";
                        }
                    }
                }
            }
        }
    }

    .c-relates {
        width: 155px;
        margin: 19px auto 0;
        padding: 0;
        border: 0;
        background: none;
        &__link {
            height: 44px;
            margin: 0;
            padding: 12px 0;
            border: 1px solid rgba($color-gray-14, 0.6);
            border-radius: 22px;
            text-align: center;
            font-size: 15px;
            color: $color-gray-14;
        }
        &__refresh {
            padding-right: 22px;
            &:after {
                top: 0;
                @include sprite-retina($sp_eui_refresh_white);
                content: "";
            }
        }
    }

    .c-button {
        &__subscribe {
            position: absolute;
            right: 12px;
            top: 16px;
            height: 34px;
            margin: 0;
            padding: 0 13px;
            font-size: 13px;
            color: $color-gray-03;
            border: 1px solid $color-gray-08;
            background: $color-gray-14;
            border-radius: 17px;
            font-size: 13px;
            line-height: normal;
        }
    }
}
