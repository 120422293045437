@keyframes placeHolderShimmer {
    0% {
        background-position: -500px 0;
    }
    100% {
        background-position: 500px 0;
    }
}

.b-placeholder-1 {
    background: #fff;
    position: relative;
    height: 147px;
    &.b-placeholder-2 {
        box-sizing: border-box;
        padding: 6px;
        height: 60px;
    }
    &.b-placeholder-3 {
        height: 52px;
        .col-1 {
            width: 58px;
            height: 24px;
            left: 16px;
            top: 14px;
        }
        .col-2 {
            width: 200px;
            height: 24px;
            left: 80px;
            top: 14px;
        }
        .col-5 {
            display: none;
        }
    }
    &.b-placeholder-4 {
        @include rounding-box;
        position: relative;
        height: 182px;
        .box-1 {
            position: absolute;
            top: 119px;
            left: 134px;
            right: 14px;
            bottom: 12px;
            width: auto;
            height: auto;
            border-top: 1px solid $color-gray-12;
        }
        .mask-1 {
            top: 0;
            left: 0;
            width: 120px;
            height: 180px;
        }
        .mask-2 {
            top: 12px;
            left: 134px;
            width: 100px;
            height: 17px;
        }
        .mask-3 {
            top: 40px;
            left: 134px;
            right: 14px;
            height: 40px;
        }
        .mask-4 {
            top: 90px;
            left: 134px;
            width: 100px;
            height: 17px;
        }
        .mask-5 {
            top: 12px;
            left: 0;
            width: 36px;
            height: 36px;
        }
        .mask-6 {
            top: 13px;
            left: 44px;
            right: 0;
            height: 16px;
        }
        .mask-7 {
            top: 31px;
            left: 44px;
            width: 75px;
            height: 16px;
        }
    }
    .line-1 {
        display: none;
    }
    .box-1 {
        width: 36px;
        height: 36px;
        left: 5%;
        top: 16px;
    }
    .box-2 {
        width: 36px;
        height: 36px;
        left: 25%;
        top: 16px;
    }
    .box-3 {
        width: 36px;
        height: 36px;
        left: 45%;
        top: 16px;
    }
    .box-4 {
        width: 36px;
        height: 36px;
        left: 65%;
        top: 16px;
    }
    .box-5 {
        width: 36px;
        height: 36px;
        left: 84%;
        top: 16px;
    }
    .box-6 {
        width: 36px;
        height: 36px;
        left: 5%;
        top: 83px;
    }
    .box-7 {
        width: 36px;
        height: 36px;
        left: 25%;
        top: 83px;
    }
    .box-8 {
        width: 36px;
        height: 36px;
        left: 45%;
        top: 83px;
    }
    .box-9 {
        width: 36px;
        height: 36px;
        left: 65%;
        top: 83px;
    }
    .box-10 {
        width: 36px;
        height: 36px;
        left: 84%;
        top: 83px;
    }
}

.b-placeholder__mask {
    animation-duration: 12s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f4f4f4;
    background: linear-gradient(90deg, #f4f4f4 2%, #ededed 60%, #f4f4f4 100%);
    position: absolute;
    opacity: 0.8;
}
