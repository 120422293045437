[data-type="Contents_Timeline"] {
    padding: 0 16px 16px;
    letter-spacing: $body-spacing;

    .c-contents {
        .c-linkimg {
            display: block;
            position: relative;

            &:after {
                position: absolute;
                right: 0;
                left: 0;
                bottom: 0;
                height: 1px;
                background: rgba(0, 0, 0, 0.04);
                content: "";
            }
        }

        li {
            position: relative;
            margin-top: 20px;
            padding-top: 27px;
            &:first-child {
                margin-top: 0;
            }
            &.c-contents--sametime {
                margin-top: 10px;
                padding-top: 0;
                .c-contents-item__timeline {
                    display: none;
                }
            }
        }
        &-item {
            @include rounding-box;
            background: #fff;
            &__timeline {
                position: absolute;
                top: 0;
                left: 0;
                padding-left: 10px;
                font-size: 13px;
                color: $color-gray-06;
                font-weight: normal;
                &:before {
                    position: absolute;
                    top: 5px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    background-color: $color-gray-06;
                    border-radius: 50%;
                    content: "";
                }
            }
            &__title {
                display: flex;
                position: relative;
                height: 56px;
                padding: 14px 13px 10px 52px;
                border-bottom: 1px solid $color-gray-11;
                align-items: center;
            }
            .c-profile__pic {
                @include size(32px);
                position: absolute;
                top: 12px;
                left: 12px;
                &:after {
                    border-radius: 50%;
                    border: 1px solid rgba(0, 0, 0, 0.04);
                    z-index: 2;
                }
                span {
                    width: 32px;
                    height: 32px;
                }
            }

            &__author {
                display: block;
                overflow: hidden;
                height: 20px;
                font-size: 16px;
                color: $color-gray-03;
                font-weight: bold;
                white-space: nowrap;
            }

            &__option {
                overflow: hidden;
                flex: 1;
                height: 20px;
                font-size: 0;
                white-space: nowrap;
            }

            &__badge {
                display: inline-block;
                padding-left: 5px;
                font-size: 14px;
                color: $color-11st-blue;
            }

            &__keyword {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
                line-height: 20px;
                &:before {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__contents {
                display: block;
                position: relative;
                min-height: 118px;
                padding: 15px 122px 10px 12px;
            }

            &__thumb {
                position: absolute;
                top: 0;
                right: 0;
                padding: 12px;
                .c-lazyload {
                    width: 94px;
                    height: 94px;
                }
                img {
                    width: 100%;
                    height: 100%;
                }
            }

            &__detail {
                overflow: hidden;
                height: 68px;
                margin-bottom: 3px;
            }

            &__description {
                @include text-ellipsis-multiple(2);
                overflow: hidden;
                font-size: 16px;
                line-height: 22px;
                color: $color-gray-02;
            }

            &__sub {
                @include text-ellipsis-multiple(2);
                margin-top: 4px;
                font-size: 15px;
                color: $color-gray-04;
                line-height: 21px;
            }

            &__timestamp {
                display: inline-block;
                position: static;
                padding: 0;
                font-size: 14px;
                vertical-align: top;
                color: $color-gray-06;
                font-weight: normal;
                &:before {
                    display: none;
                }
                &:after {
                    display: inline-block;
                    width: 1px;
                    height: 11px;
                    margin: -1px 5px 1px;
                    border-right: 1px solid $color-gray-10;
                    vertical-align: middle;
                    content: "";
                }
            }

            &__count {
                display: inline-block;
                font-size: 14px;
                color: $color-gray-06;
                vertical-align: top;
            }

            &--recently {
                .c-contents {
                    &-item {
                        &__timeline {
                            color: $color-11st-blue;
                        }
                    }
                }
            }

            .c-certified {
                display: inline-block;
                height: 20px;
                padding: 0 4px;
                margin: -1px 6px 1px 0;
                border: 1px solid rgba(241, 112, 0, 0.5);
                border-radius: 10px;
                font-size: 11px;
                text-align: center;
                line-height: 20px;
                color: #f17000;
                vertical-align: middle;
                &:before {
                    @include sprite-retina($sp_eui_ico_official_mark2);
                    display: inline-block;
                    margin: -1px 2px 1px 0;
                    vertical-align: middle;
                    content: "";
                }
            }

            &--certified {
                .c-profile__pic {
                    padding: 1.5px;
                    &:before {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 32px;
                        height: 32px;
                        background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                        border-radius: 50%;
                        content: "";
                    }
                    &:after {
                        display: none;
                    }
                    span {
                        overflow: visible;
                        width: 29px;
                        height: 29px;
                        border: 2px solid $color-gray-14;
                        background-color: $color-gray-14;
                        &:before {
                            position: absolute;
                            right: -8px;
                            bottom: -8px;
                            width: 19px;
                            height: 19px;
                            background: linear-gradient(147deg, #20f0ff 14%, #19a1ff 86%);
                            border: 2px solid $color-gray-14;
                            border-radius: 50%;
                            content: "";
                        }
                        &:after {
                            @include sprite-retina($sp_eui_ico_official_mark);
                            position: absolute;
                            right: -6px;
                            bottom: -6px;
                            z-index: 3;
                            content: "";
                        }
                    }
                }
            }
        }
    }

    .c-relates {
        border-top: 0;
        background: $color-gray-14;
    }
}
