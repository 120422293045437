[data-type="Contents_HistoryBox"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .b-bundle-list {
        li {
            position: relative;
            padding-bottom: 12px;
        }
        .c-date-history {
            color: $color-gray-07;
            font-size: $font-size-body2;
            font-weight: normal;
            padding-left: 10px;
            margin-bottom: 4px;
            height: 20px;
            line-height: 20px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                left: 0;
                top: 7px;
                width: 4px;
                height: 4px;
                border-radius: 4px;
                background-color: $color-gray-09;
            }
        }
        .c-rounding-box {
            @include rounding-box;
            position: relative;
            padding: 16px;
            margin-bottom: 10px;
            &:nth-last-child(1) {
                margin-bottom: 0;
            }

            .c-card {
                &__list {
                    min-height: 87px;
                    // padding-right: 28px;
                    // margin-right: 28px;
                    &:after {
                        @include ico-arrow-right(9px, #999);
                        display: block;
                        position: absolute;
                        top: 38px;
                        right: 10px;
                    }
                }
                &__thumb {
                    @include size(87px);
                    @include thumbnail-playtime;
                    @include thumbnail-playicon;
                    &:after {
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 0;
                        content: "";
                        width: 100%;
                        height: 100%;
                        z-index: 1;
                        @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
                    }
                }
                &__info {
                    display: -webkit-box;
                    overflow: hidden;
                    min-height: 40px;
                    margin: -3px 33px 0 100px;
                    padding: 0;
                    line-height: 20px;
                    font-size: $font-size-body1;
                    color: $color-gray-03;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
                &__writer {
                    position: absolute;
                    top: 84px;
                    left: 115px;
                    display: block;
                    margin: 0;
                    padding-top: 0;
                    color: $color-11st-blue;
                    overflow: hidden;
                    white-space: nowrap;
                    font-size: 0;
                    &-thumb {
                        @include size(20px);
                        display: inline-block;
                        overflow: hidden;
                        position: relative;
                        margin-right: 4px;
                        border-radius: 100%;
                        vertical-align: middle;

                        &:after {
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            border: 1px solid rgba(0, 0, 0, 0.04);
                            border-radius: 100%;
                            content: "";
                        }
                        img {
                            width: 100%;
                        }
                    }
                    &-info {
                        display: inline-block;
                        vertical-align: middle;
                        font-size: $font-size-body1;
                        color: $color-gray-07;
                        white-space: nowrap;
                    }
                }
            }
            .c-tag-wrap {
                position: absolute;
                top: 58px;
                left: 115px;
                white-space: normal;
                overflow: hidden;
                display: block;
                margin: 0;
                margin-right: 55px;
                height: 20px;
                z-index: 1;
                .c-tag {
                    display: inline-block;
                    font-size: 13px;
                    border-radius: 0;
                    border: 0;
                    color: #0b83e6;
                    background-color: #f4f4f4;
                    padding: 1px 4px;
                    margin: 0 4px 4px 0;
                    height: 19px;
                    line-height: 19px;
                    z-index: 2;
                    white-space: nowrap;
                }
            }
            .c-checkbox-area {
                display: none;
            }
        }
    }

    .edit-mode & {
        .b-bundle-list {
            .c-rounding-box {
                padding-left: 48px;

                .c-card {
                    &__list {
                        &:after {
                            display: none;
                        }
                    }
                    &__info {
                        margin-right: 0;
                    }
                }
                .c-tag-wrap,
                .c-card__writer {
                    left: 147px;
                }
                .c-checkbox-area {
                    display: block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    left: 0;
                    bottom: 0;
                    z-index: 5;

                    input {
                        top: 50%;
                        left: 16px;
                        transform: translateY(-50%);
                    }
                    label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 5;

                        &:before {
                            top: 50%;
                            left: 16px;
                            transform: translateY(-50%);
                        }
                        &:after {
                            top: 50%;
                            left: 24px;
                            margin-top: -6px;
                        }
                    }
                }
            }
        }
    }
}
