[data-type="Tabs_ColorBoxBtn"] {
    padding: 16px;

    .c-tab-box-border {
        .c-tab {
            &__link {
                height: 32px;
                padding: 0 12px;
                background: $color-gray-13;
                line-height: 34px;

                &:before {
                    border-color: $color-gray-12;
                }
            }
        }
    }
}
