[data-type="FlatButton_Center"] {
    &.l-grid__row {
        .l-grid__col--12 {
            float: left;
        }
    }
    .c-relates {
        padding: 11px 0 12px;
        background-color: rgba(250, 250, 250, 1);
        &__link {
            &.close {
                .c-relates__link-text {
                    &:after {
                        transform: rotate(315deg);
                        margin-top: 0;
                    }
                }
            }
        }
        &__link-text {
            strong {
                color: rgba(44, 177, 248, 1);
                font-weight: normal;
            }
            &--goto {
                &:after {
                    @include rotate(45deg);
                    top: 55%;
                }
            }
        }
    }
    [data-type="ProductDeal_Shocking"] + &:not(#blckSn-3272) {
        background-color: transparent !important;
        .c-relates {
            margin: 8px 0;
        }
    }

    &#blckSn-3616,
    &#blckSn-3617,
    &#blckSn-3618,
    &#blckSn-3619,
    &#blckSn-3620,
    &#blckSn-3621,
    &#blckSn-3622,
    &#blckSn-3623,
    &#blckSn-3624,
    &#blckSn-3626,
    &#blckSn-3629,
    &#blckSn-3630 {
        background-color: #eee;
        .c-relates {
            margin: 8px 0;
        }
    }
}
