[data-type="ProductList_BookBox"] {
    letter-spacing: $body-spacing;

    .c-content-box {
        @include rounding-box;
        @include clearfix;
        overflow: hidden;
        margin: 0 16px 24px;
        padding: 0 8px 0 16px;

        li {
            position: relative;
            padding-top: 16px;
            border-top: 1px solid $color-gray-12;

            &:first-child {
                border-top: 0;
            }
        }
    }
    .c-card-item {
        &__rank {
            border-radius: 0;
        }
        &__thumb {
            @include size(100px 150px);
        }
        &__info {
            height: 175px;
            padding-left: 14px;

            .sktpass {
                overflow: hidden;
                height: 20px;
                padding-top: 1px;

                &:before {
                    margin-right: 0;
                    margin-left: 0;
                }
                span {
                    display: inline;
                }
            }
        }
        &__rate {
            strong {
                font-weight: normal;
            }
        }
        &__name {
            display: block;
            height: auto;
            max-height: 38px;
            margin-bottom: 6px;
            line-height: 19px;
        }
        &__benefit {
            margin-top: 8px;
        }
        .c-nation {
            margin-bottom: 6px;
        }
        .c-flag__item {
            margin-bottom: 6px;
        }
    }
    .c-pagination {
        margin-top: -23px;
        padding-bottom: 2px;
    }
}
