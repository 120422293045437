[data-type="Cap_Box_Notice"] {
    letter-spacing: $body-spacing;
    .c-headline {
        &__infoToggle {
            right: 13px;
            margin-top: -12px;
        }
        &__toggleBtn {
            border: 0;
            background-color: transparent;
            @include sprite-retina($sp_eui_info2);
            outline: none;
        }
        &__info {
            right: 13px;
        }
    }
}
