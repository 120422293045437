.c-shortcut {
    overflow: hidden;
    position: relative;

    &__item {
        float: left;
        width: 20%;

        a {
            display: block;
            position: relative;
            padding: 6px 0;
            text-align: center;
            border-right: 1px solid #f4f4f4;
        }

        @include between(1, 5) {
            a {
                border-bottom: 1px solid #f4f4f4;
            }
        }
    }

    &__icon {
        display: block;
        padding-top: 2px;

        img {
            @include size(40px 40px);
        }
    }

    &__text {
        display: block;
        overflow: hidden;
        height: 16px;
        margin: 0 6px;
        font-size: 13px;
        line-height: 16px;
        color: $color-gray-03;

        &.new {
            @include size(18px 18px);

            position: absolute;
            left: 50%;
            top: 6px;
            margin-left: 12px;
            background: url("/MW/img/life/ico_new2.png") no-repeat;
            background: url(#{data-uri("../img/life/ico_new2.png")}) no-repeat;
            background-size: 18px 18px;
            text-indent: -9999px;
        }
    }

    &--4col {
        .c-shortcut__item {
            width: 25%;
            border-right: 1px solid #f4f4f4;

            @include each(4) {
                border-right: 0;
            }
        }
    }
}
