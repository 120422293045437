@import "../../main/sprites/sp_flags";
.c-card__flag-icon {
    text-indent: -9999px;
}
.flags {
    &_www {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_www_s);
        }
    }
    &_usa {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_usa_s);
        }
    }
    &_fra {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_fra_s);
        }
    }
    &_ity {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_ity_s);
        }
    }
    &_eng {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_eng_s);
        }
    }
    &_ger {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_ger_s);
        }
    }
    &_jpn {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_jpn_s);
        }
    }
    &_spn {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_spn_s);
        }
    }
    &_twi {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_twi_s);
        }
    }
    &_hk {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_hk_s);
        }
    }
    &_can {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_can_s);
        }
    }
    &_chn {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_chn_s);
        }
    }
    &_etc {
        .c-card__flag-icon {
            @include sprite-retina($sp_flags_etc_s);
        }
    }
}
[data-type="ProductScroll_Global"] {
    background-color: #fff;

    .s-list {
        padding-bottom: 12px;
    }
}
[data-type-item="ProductScroll_Global"] {
    background: #fff;
    border-bottom: 1px solid #d1d1d6;

    &:last-child {
        margin-bottom: 0;
    }
    .c-headline {
        border-bottom: 1px solid #f4f4f4;
    }
}
