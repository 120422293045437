[data-type="ContentsGrid_DetailBox_Col2"] {
    letter-spacing: $body-spacing;
    padding: 0 8px 12px;
    .l-grid__col {
        padding: 8px 6px 12px;
        padding-top: 0;
    }
    .c-card {
        &__link {
            display: block;
            overflow: hidden;
        }
        &__thumb {
            @include thumbnail-playtime;
            @include thumbnail-playicon;
            &:after {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                content: "";
                width: 100%;
                height: 100%;
                z-index: 1;
                @include thumbnail-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
            }
        }
        &__info {
            padding: 12px 12px 2px;
        }
        .c-tag-wrap {
            white-space: normal;
            overflow: hidden;
            display: block;
            margin: 0 12px;
            height: 28px;
        }
        .c-tag {
            font-size: $font-size-body1;
            border-radius: 0;
            border: 0;
            color: $color-11st-blue;
            background-color: $color-gray-12;
            padding: 2px 4px;
            margin-right: 4px;
            height: 20px;
            line-height: 18px;
            z-index: 2;
        }
        &__title {
            display: block;
            margin: 0 0 10px 0;
            font-size: $font-size-body1;
            height: 57px;
            line-height: 20px;
            overflow: hidden;
            color: #333;
        }
        &__writer {
            display: block;
            margin: 0 11px 12px 11px;
            padding-top: 0;
            color: $color-11st-blue;
            overflow: hidden;
            white-space: nowrap;
            font-size: 0;
            &-thumb {
                @include size(20px);
                display: inline-block;
                overflow: hidden;
                margin-right: 4px;
                border: 1px solid rgba(0, 0, 0, 0.04);
                border-radius: 100%;
                vertical-align: middle;
                img {
                    width: 100%;
                }
            }
            &-info {
                @include text-ellipsis;
                display: block;
                margin-top: -17px;
                padding-left: 24px;
                font-size: $font-size-body1;
                color: $color-gray-07;
            }
        }
    }
}
