[data-type="ExhibitionBanner_Brand"] {
    padding-bottom: 6px;
    letter-spacing: $body-spacing;

    .c-linkimg {
        display: block;
        position: relative;
    }
    .c-content-box {
        @include rounding-box;
        overflow: hidden;
        position: relative;
        margin: -52px 16px 0;
        padding: 20px;
        background: $color-gray-14;

        .c-content__text {
            overflow: hidden;
            height: 62px;
            font-size: 15px;
            color: $color-gray-04;
            line-height: 21px;
        }
        .c-content__more {
            position: absolute;
            right: 20px;
            bottom: 19px;
            padding: 0 5px 0 24px;
            background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, white 23px);
            font-size: 15px;
            color: $color-11st-blue;
            line-height: 21px;

            &:after {
                @include ico-arrow-right(8px, $color-11st-blue);
                display: inline-block;
                margin: -1px 0 1px 6px;
                vertical-align: middle;
                content: "";
            }
        }
    }
}
