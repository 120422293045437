[data-type="Product_StorePromotion"] {
    @include grid(0, 2, false);
    letter-spacing: $body-spacing;

    .b-content-list {
        padding-bottom: 14px;

        > li {
            margin-bottom: 12px;
        }
    }

    .b-content-box {
        @include rounding-box;

        padding: 18px 16px 0;
    }

    .c-content-title {
        &__keyword {
            font-size: 19px;
            color: $color-gray-03;

            a {
                display: inline-block;
                overflow: hidden;
                position: relative;
                max-width: 100%;
                height: 23px;
                padding-right: 15px;
                vertical-align: top;
                white-space: nowrap;
                text-overflow: ellipsis;

                &:after {
                    @include ico-arrow-right(9px, $color-gray-04);
                    position: absolute;
                    top: 50%;
                    right: 2px;
                    margin-top: -5px;
                }
            }
        }
        &__desc {
            overflow: hidden;
            height: 19px;
            margin: 4px 0 14px;
            font-size: 15px;
            color: $color-gray-04;

            a {
                &:before {
                    @include ico-store;
                    margin: -1px 4px 1px 0;
                    vertical-align: middle;
                }
            }
            span {
                display: inline-block;
                overflow: hidden;
                max-width: 65%;
                max-width: calc(100% - 107px);
                vertical-align: top;
                white-space: nowrap;
                text-overflow: ellipsis;

                @media screen and (max-width: 359px) {
                    max-width: 58%;
                    max-width: calc(100% - 107px);
                }
            }
        }
    }

    .w-card__gallery {
        @include clearfix;
        margin: 0 -6px 6px;

        .l-grid__col {
            padding: 0 6px;
        }
    }

    .c-card__gallery {
        .c-card__thumb {
            width: auto;
            height: auto;

            &:after {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 1px solid rgba(0, 0, 0, 0.07);
                content: "";
            }
        }
        .c-card__info {
            padding: 10px 0 14px;

            .c-card__name {
                display: block;
                height: 34px;
                margin-bottom: 6px;
                font-size: 14px;
                line-height: 17px;
            }
            .c-card__price {
                height: auto;
                line-height: 20px;

                strong {
                    vertical-align: top;
                }
            }
        }
    }

    .c-addition {
        margin-top: -6px;
        background: none;
        font-size: 14px;
        color: $color-gray-03;

        &-link {
            padding: 14px 0;
        }
    }
}
