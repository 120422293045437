[data-type="ProductList_TrendBox"] {
    @include grid(0, 0, false);

    .c-keyword {
        padding: 20px 20px 18px;

        &__row {
            display: flex;
            align-items: baseline;
            justify-content: center;

            & + .c-keyword__row {
                margin-top: 6px;
            }
        }
        &__text {
            display: flex;
            overflow: hidden;
            font-size: 18px;
            font-weight: bold;
            color: $color-gray-03;
            white-space: nowrap;
            align-items: baseline;
        }
        &__text-type2 {
            position: relative;
            padding-right: 16px;
            font-size: 14px;
            color: $color-gray-03;
            line-height: 24px;
        }
        &__point {
            display: inline-block;
            margin-right: 6px;
            color: $color-11st-blue;
        }
        .c-info {
            @include size(24px);
            position: absolute;
            top: 50%;
            right: -7px;
            border: 0;
            background: none;
            transform: translateY(-50%);

            &:after {
                @include svg-icon($icon-info, 18px, 18px);
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                content: "";
            }
        }
        .c-chip {
            overflow: hidden;
            margin: 0 6px 0 0;
            padding: 0 10px;
            border-color: rgba($color: $color-11st-blue, $alpha: 0.4);
            border-radius: 4px;
            background: rgba($color: $color-11st-blue, $alpha: 0.04);
            font-size: 16px;
            font-weight: bold;
            color: $color-11st-blue;
            text-overflow: ellipsis;
        }
    }
    .c-n-dropdown {
        display: inline-block;
        position: relative;
        z-index: 1;
        margin-right: 4px;
        white-space: nowrap;
        &:before {
            display: none;
        }

        &__option {
            min-width: 55px;
            max-width: 91px;
            height: 24px;
            &:after {
                right: 11px;
                border-width: 1px 1px 0 0;
            }
        }
        &__button {
            overflow: hidden;
            padding: 0 22px 0 10px;
            border: 1px solid $color-gray-10;
            border-radius: 14px;
            font-weight: 400;
            font-size: 13px;
            color: $color-gray-03;
            line-height: 24px;
            text-overflow: ellipsis;
        }
        &__list {
            display: none;
            overflow: hidden;
            position: absolute;
            top: 100%;
            left: -3px;
            min-width: 106px;
            max-width: 220px;
            margin-top: 6px;
            background: $color-gray-14;
            border-radius: 4px;
            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.14), 0 3px 10px 0 rgba(0, 0, 0, 0.08);
        }
        &__item {
            display: block;
            overflow: hidden;
            width: 100%;
            height: 43px;
            padding-right: 24px;
            padding-left: 16px;
            border: 0;
            border-top: 1px solid $color-gray-12;
            background: $color-gray-14;
            font-size: 15px;
            color: $color-gray-04;
            line-height: 19px;
            text-align: left;
            text-overflow: ellipsis;

            &:first-child {
                border-top: 0;
            }
            &[aria-selected="true"] {
                background: rgba($color: $color-gray-12, $alpha: 0.75);
                font-weight: bold;
                color: $color-gray-02;
            }
        }
        &--selected {
            z-index: 20;

            .c-n-dropdown__button:after {
                margin-top: -2px;
                transform: rotate(-45deg);
            }
            .c-n-dropdown__list {
                display: block;
            }
        }
    }
    .c-content-box {
        @include rounding-box;
        @include clearfix;
        overflow: hidden;
        height: 595px;
        margin: 0 16px 24px;
        padding: 0 16px 8px;

        li {
            position: relative;
            border-top: 1px solid $color-gray-12;

            &:first-child {
                border-top: 0;
            }
        }
    }
    .c-card-item {
        padding: 16px 0;

        &__thumb {
            @include size(112px);

            img {
                @include size(100%);
            }
        }
        &__info {
            height: 112px;
            padding: 0 0 0 14px;
        }
        &__rate {
            strong {
                font-weight: normal;
            }
        }
        &__name {
            display: block;
            height: auto;
            max-height: 38px;
            margin-bottom: 6px;
            line-height: 19px;
        }
        &__benefit {
            margin-top: 8px;
        }
        &__comment {
            margin-top: 8px;
            font-size: 14px;
            color: $color-gray-04;

            strong,
            .c-card-item__point {
                font-weight: bold;
                color: $color-11st-blue;
            }
        }
        .c-nation {
            margin-bottom: 6px;
        }
    }
    .c-pagination {
        margin-top: -23px;
        padding-bottom: 2px;
    }
}
