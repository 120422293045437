[data-type="Contents_Mart_Info"] {
    .l-grid__col {
        position: relative;
    }

    .c-tab__mart {
        padding: 18px 0 32px;
        text-align: center;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 14px;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            background-color: #fff;
        }
        [role="tablist"] {
            display: inline-block;
            background-color: rgba(255, 255, 255, 0.12);
            box-shadow: #f4f4f4;
            border-radius: 17px;
        }
        [role="tab"] {
            color: #fff;
            border-radius: 17px;
            border: 1px solid rgba(255, 255, 255, 0.4);
            padding: 8px 21px;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            position: relative;
            z-index: 2;
            &[aria-selected="true"] {
                background-color: rgba(255, 255, 255, 1);
                &[aria-controls="ar-today"] {
                    color: #0b83e6;
                }
                &[aria-controls="ar-dawn"] {
                    color: #7741bc;
                }
            }
        }

        [role="tab"] + div {
            position: absolute;
            left: 0;
            top: 0;
            height: 80px;
            width: 100%;
            background-size: cover;
            background-repeat: no-repeat;
        }

        [aria-selected="false"] + div {
            background-image: url("/MW/img/mart/bg-oneday@2x.png");
        }

        [aria-selected="trie"] + div {
            background-image: url("/MW/img/mart/bg-dawn@2x.png");
        }
    }

    .c-headline {
        padding: 0 16px;

        .c-headline__title {
            font-size: 15px;
            height: 58px;
            font-weight: normal;
            padding: 11px 0px;

            &--icon {
                height: 24px;
                position: relative;
                width: initial;
                top: 4px;
                margin-right: 5px;
            }
        }
    }

    .w-card__mart {
        @include clearfix;
        padding: 0 16px 20px;
        font-size: 13px;
        font-weight: normal;
        color: #333;
        position: relative;

        > div {
            float: left;
            width: 50%;

            &:first-child {
                .c-card {
                    margin-left: 0;
                }
            }
        }

        &::before {
            clear: both;
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            border-bottom: 1px solid #f4f4f4;
            content: "";
            width: 100%;
        }

        .c-card {
            display: block;
            text-align: center;
            padding: 14px 0 16px;
            margin-left: 12px;
            @include rounding-box;

            &__logo {
                display: inline-block;
                margin: 0 auto;
                color: transparent;
                height: 20px;
                position: relative;
                left: -6px;

                img {
                    height: 20px;
                }

                &::after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 8px;
                    right: -10px;
                    @include ico-arrow-right(7px, #999);
                }
            }

            &__info {
                padding: 1px 0 11px;

                .c-card__name {
                    min-height: 42px;
                    margin-bottom: 0;
                    -webkit-line-clamp: initial;
                }
            }

            &__info--dimm {
                color: #999;
            }

            &__status {
                background-color: #f4f4f4;
                border-radius: 15px;
                height: 30px;
                display: inline-block;
                padding: 0 12px;
                color: #999;
                line-height: 30px;
                font-weight: normal;
                letter-spacing: 0;

                &--time {
                    width: 14px;
                    position: relative;
                    top: 2px;
                }
            }
        }

        &--logout {
            .c-card {
                padding: 22px 0 13px;
            }
        }
    }

    .w-card__mart--vertical {
        padding: 0;
        margin: 0 16px 20px;
        @include rounding-box;

        &::before {
            left: -18px;
            bottom: -21px;
            width: 140%;
        }

        > div {
            float: none;
            width: 100%;

            &:first-child {
                .c-card {
                    margin-left: 14px;
                    border-top: 0;
                }
            }
        }

        .c-card {
            height: 48px;
            margin-left: 0;
            position: relative;
            border-radius: 0;
            border: 0;
            box-shadow: none;
            border-top: 1px solid #f4f4f4;
            margin: 0 14px;
            text-align: left;
            overflow: hidden;

            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 20px;
                right: 2px;
                @include ico-arrow-right(7px, #999);
            }

            &__logo {
                float: left;
                margin: 0;
                position: static;
                left: 0;
                height: 22px;

                img {
                    height: 22px;
                }

                &::after {
                    display: none;
                }
            }

            &__info {
                float: left;
                margin-left: 6px;
                padding: 0;
                vertical-align: top;
                line-height: 22px;
                width: 0px;
                background-color: transparent;

                //해상도별 말줄임 처리
                @include mq-320 {
                    min-width: 66px;
                }

                @include mq-360 {
                    min-width: 100px;
                }

                @include mq-375 {
                    min-width: 117px;
                }

                @include mq-400 {
                    min-width: auto;
                    width: auto;
                }

                span {
                    float: left;
                    vertical-align: top;
                    width: 100%;
                    @include text-ellipsis;
                }
            }

            &__status {
                float: right;
                margin-right: 15px;
                height: 28px;
                line-height: 30px;
                font-size: 12px;
                margin-top: -4px;
                padding: 0 10px;
                white-space: nowrap;
                position: absolute;
                right: 0;
                top: 14px;

                &--time {
                    top: 3px;
                }
            }
        }
    }

    .w-swiper {
        position: relative;

        .swiper-wrapper {
            position: relative;
            width: 200%;
            overflow: hidden;

            .swiper-slide {
                width: 50%;
                float: left;
            }

            .w-card__mart--vertical {
                min-height: 146px;
                margin-bottom: 32px;
            }
        }

        .c-pagination {
            position: absolute;
            width: 100%;
            bottom: 12px;

            &__indicator {
                line-height: 10px;

                span {
                    height: 8px;
                    width: 8px;
                }
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            width: 32px;
            height: 32px;
            top: 50%;
            margin-top: -9px;
            color: transparent;
            text-indent: 0;

            &::before {
                position: static;
                top: 0;
                left: 0;
            }
        }
        .swiper-button-prev {
            left: 0;
        }

        .swiper-button-next {
            right: 0;
        }
    }
}
